export const GET_ALL_NOTIFICATION_LIST = "GET_ALL_NOTIFICATION_LIST";
export const SET_FIREBASE__TOKEN = "SET_FIREBASE_TOKEN";
export const COURSE_LOADER_RESET = "COURSE_LOADER_RESET";
export const STORE_THUMBNAILS = "STORE_THUMBNAILS";
export const GET_COURSES_LIST = "GET_COURSES_LIST";
export const GET_COURSES_STARS_ID = "GET_COURSES_STARS_ID";
export const RESET_API_COUNT_COURSE = "RESET_API_COUNT_COURSE";
export const UPDATE_COURSES_STARS_ID = "UPDATE_COURSES_STARS_ID";
export const GET_ALL_DASHBOARD_LIST = "GET_ALL_DASHBOARD_LIST";
export const GET_RECENTLY_VIEWED_QUESTIONS_LIST =
  "GET_RECENTLY_VIEWED_QUESTIONS_LIST";
export const GET_ALL_SUBJECT_lIST = "GET_ALL_SUBJECT_lIST";
export const GET_ALL_COMMON_CHAT_FRIENDS_LIST =
  "GET_ALL_COMMON_CHAT_FRIENDS_LIST";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GET_DASHBOARD_LIST = "GET_DASHBOARD_LIST";
export const GET_MYQUESTIONS_LIST = "GET_MYQUESTIONS_LIST";
export const GET_MYLIBRARY_QUESTION_LIST = "GET_MYLIBRARY_QUESTION_LIST";
export const GET_REMINDER_LIST = "GET_REMINDER_LIST";
export const GET_MYUPDATEDLIBRARY_QUESTION_LIST =
  "GET_MYUPDATEDLIBRARY_QUESTION_LIST";
export const GET_FRIENDS_REQUEST_LIST = "GET_FRIENDS_REQUEST_LIST";
export const GET_RECOMMENDED_FRIENDS_LIST = "GET_RECOMMENDED_FRIENDS_LIST";
export const GET_FRIENDS_LIST = "GET_FRIENDS_LIST";
export const GET_RECENTLY_ADDED_FRIENDS_LIST =
  "GET_RECENTLY_ADDED_FRIENDS_LIST";
export const GET_ALL_SURVEY_LIST = "GET_ALL_SURVEY_LIST";
export const DASHBOARD_LOADER_RESET = "DASHBOARD_LOADER_RESET";
export const CLOSE_SURVEY_LIST = "CLOSE_SURVEY_LIST";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const UPDATE_QUESTION_LIST = "UPDATE_QUESTION_LIST";
export const GET_RECENTLY_BADGES_LIST = "GET_RECENTLY_BADGES_LIST";
export const GET_SUBJECT_QUESTION_LIST = "GET_SUBJECT_QUESTION_LIST";
export const UPDATE_SUBJECT_QUESTION_LIST = "UPDATE_SUBJECT_QUESTION_LIST";
export const GET_POSTS_OVERALL = "GET_POSTS_OVERALL";
export const GET_ALL_NOTIFICATION_LIST_FALSE =
  "GET_ALL_NOTIFICATION_LIST_FALSE";
export const GET_BADGES_POINTS_HISTORY = "GET_BADGES_POINTS_HISTORY";
export const GET_ALL_BADGES_LIST = "GET_ALL_BADGES_LIST";
export const REMOVE_NOTIFICATION_LIST_BYID = "REMOVE_NOTIFICATION_LIST_BYID";
export const DELETE_QUESTION_LIST = "DELETE_QUESTION_LIST";
export const DELETE_DASHBOARD_QUESTION_LIST = "DELETE_DASHBOARD_QUESTION_LIST";
export const DELETE_LIBRARY_QUESTION_LIST = "DELETE_LIBRARY_QUESTION_LIST";
export const DELETE_SUBJECT_QUESTION_LIST = "DELETE_SUBJECT_QUESTION_LIST";
export const QUESTION_LOADER = "QUESTION_LOADER";
export const SUBJECT_LOADER = "SUBJECT_LOADER";
export const MYLIBRARY_QUESTION_LOADER = "MYLIBRARY_QUESTION_LOADER";
export const SUBJECT_QUESTION_LOADER = "SUBJECT_QUESTION_LOADER";
export const GET_COURSES_COUNT = "GET_COURSES_COUNT";
export const STORE_PERSONAL_LANGUAGE = "STORE_PERSONAL_LANGUAGE";
export const GET_ALL_COMMON_SUBJECTS = "GET_ALL_COMMON_SUBJECTS";
export const GET_ALL_COMMON_LANGUAGE = "GET_ALL_COMMON_LANGUAGE";
export const GET_LOGOUT_DATA = "GET_LOGOUT_DATA";
export const GET_ALL_COMMON_TAGS = "GET_ALL_COMMON_TAGS";
export const GET_ALL_COMMON_GRADES = "GET_ALL_COMMON_GRADES";
export const GET_ALL_COMMON_CURRICULUM_TRACK =
  "GET_ALL_COMMON_CURRICULUM_TRACK";
export const GET_ALL_COMMON_SCHOOL = "GET_ALL_COMMON_SCHOOL";
export const GET_ALL_COMMON_FRIENDS_LIST = "GET_ALL_COMMON_FRIENDS_LIST";
export const STORE_LOGIN_DETAILS = "STORE_LOGIN_DETAILS";
export const STORE_AUTH_USER = "STORE_AUTH_USER";
export const GET_ALL_SUBJECT_lIST_BY_GROUPS = "GET_ALL_SUBJECT_lIST_BY_GROUPS";
export const GET_ALL_SUBJECT_lIST_BY_EVENTS = "GET_ALL_SUBJECT_lIST_BY_EVENTS";
export const GET_MY_EVENTS_AND_GROUPS = "GET_MY_EVENTS_AND_GROUPS";
export const GET_PAST_EVENTS = "GET_PAST_EVENTS";
export const GET_MESSAGE_LIST_BY_ID = "GET_MESSAGE_LIST_BY_ID";
export const GET_SUBJECT_REQUESTS_LIST = "GET_SUBJECT_REQUESTS_LIST";
export const GET_INDUSTRY_LIST = "GET_INDUSTRY_LIST";
export const GET_REGION_LIST = "GET_REGION_LIST";
export const GET_All_MEMBER_LIST = "GET_All_MEMBER_LIST";
export const GET_AREA_OF_INTEREST_LIST = "GET_AREA_OF_INTEREST_LIST";
export const GET_ALL_GROUPS_BY_MEMBER_COUNT = "GET_ALL_GROUPS_BY_MEMBER_COUNT";
export const UPDATE_LOGIN_DETAILS = "UPDATE_LOGIN_DETAILS";
export const GET_MY_REQUEST_LIST = "GET_MY_REQUEST_LIST";
export const GET_OTHER_REQUEST_LIST = "GET_OTHER_REQUEST_LIST";
export const GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS =
  "GET_MY_GROUPS_BY_MESSAGES_AND_MEMBERS";
export const GET_ALL_COMMON_PROFESSION = "GET_ALL_COMMON_PROFESSION";
export const GET_ALL_COMMON_EXPECTATION_HELP =
  "GET_ALL_COMMON_EXPECTATION_HELP";
export const GET_ALL_COMMON_EXPECTATION_INTEREST =
  "GET_ALL_COMMON_EXPECTATION_INTEREST";
export const GET_USER_REQUESTED_LIST = "GET_USER_REQUESTED_LIST";
export const GET_CHALLENGES_AND_RESOURCES = "GET_CHALLENGES_AND_RESOURCES";
export const GET_RESOURCE_POST_BY_DOCUMENT = "GET_RESOURCE_POST_BY_DOCUMENT";
export const GET_RESOURCE_POST_BY_IMAGE = "GET_RESOURCE_POST_BY_IMAGE";
export const GET_RESOURCE_POST_BY_VIDEO = "GET_RESOURCE_POST_BY_VIDEO";
export const GET_CHALLENGES_APPLY_LIST = "GET_CHALLENGES_APPLY_LIST";
export const UPDATE_ELIGIBILITY_DETAILS = "UPDATE_ELIGIBILITY_DETAILS";
export const GET_CHALLENGE_LIST = "GET_CHALLENGE_LIST";
export const GET_RESOURCE_LIST = "GET_RESOURCE_LIST";
export const SET_REFRESH_TOKEN_DETAILS = "SET_REFRESH_TOKEN_DETAILS";
export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const GET_MY_GROUPS_HOME = "GET_MY_GROUPS_HOME";
export const GET_ALL_COMMON_ALL_PROOF = "GET_ALL_COMMON_ALL_PROOF";
export const NAVIGATION_TYPE = "NAVIGATION_TYPE";
export const GET_USER_POINTS = "GET_USER_POINTS";
export const GET_SEARCH_FRIENDS_LIST = "GET_SEARCH_FRIENDS_LIST";
export const GET_SEARCH_REQUEST_LIST = "GET_SEARCH_REQUEST_LIST";
export const GET_SEARCH_RECOMMENDED_LIST = "GET_SEARCH_RECOMMENDED_LIST";
export const GET_SEARCH_USER_REQUESTED_LIST = "GET_SEARCH_USER_REQUESTED_LIST";
export const GET_SEARCH_CHALLENGE_AND_GROUPS =
  "GET_SEARCH_CHALLENGE_AND_GROUPS";
export const GET_ALL_TYPE_OF_COMMUNITY = "GET_ALL_TYPE_OF_COMMUNITY";
export const GET_ASK_QUESTION_LIST = "GET_ASK_QUESTION_LIST";
export const GET_ALL_USER_COMMUNITY_LIST = "GET_ALL_USER_COMMUNITY_LIST";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_SELECTED_USER_FALSE = "SET_SELECTED_USER_FALSE";
export const GET_REQUESTS_PLAY_LIST = "GET_REQUESTS_PLAY_LIST";
export const GET_REQUESTS_PLAY_LIST_DATA = "GET_REQUESTS_PLAY_LIST_DATA";
export const GET_FORUM_QUESTION_LIST = "GET_FORUM_QUESTION_LIST";
export const GET_FORUM_ANSWER__LIST = "GET_FORUM_ANSWER__LIST";
export const GET_FORUM_SEARCH_QUESTION_LIST = "GET_FORUM_SEARCH_QUESTION_LIST";
export const GET_FORUM_TAG_LIST = "GET_FORUM_TAG_LIST";
export const HOME_IMAGE_STATUS = "HOME_IMAGE_STATUS";
export const GET_ALL_BLOCKED_USER_LIST = "GET_ALL_BLOCKED_USER_LIST";
export const WORK_SPACE_TYPE = "WORK_SPACE_TYPE";
export const GET_USER_WORKSPACE_LIST = "GET_USER_WORKSPACE_LIST";
export const GET_PIE_CHART_LIST = "GET_PIE_CHART_LIST";
export const GET_ADMIN_CATEGORY_LIST = "GET_ADMIN_CATEGORY_LIST";
export const GET_ADVERTISEMENT_DATA = "GET_ADVERTISEMENT_DATA";
export const GET_CHAT_LIST_DATA = "GET_CHAT_LIST_DATA";
export const REMOVE_CUSTOM_NOTIFICATION_LIST_BYID =
  "REMOVE_CUSTOM_NOTIFICATION_LIST_BYID";
export const GET_ALL_COUNTRY_PHONE_CODE = "GET_ALL_COUNTRY_PHONE_CODE";
export const GET_USER_COMMENTS = "GET_USER_COMMENTS";
export const GET_SINGLE_POST_DATA = "GET_SINGLE_POST_DATA";
export const WORK_SPACE_LOGIN_SUCCESS = "WORK_SPACE_LOGIN_SUCCESS";
export const WORK_SPACE_LOGIN = "WORK_SPACE_LOGIN";
export const GET_MY_COMMUNITY_LIST = "GET_MY_COMMUNITY_LIST";
export const GET_COMMUNITY_TAG_LIST = "GET_COMMUNITY_TAG_LIST";
export const URL_UPDATE_LOGIN_DETAILS = "URL_UPDATE_LOGIN_DETAILS";
export const GET_POST_LIST = "GET_POST_LIST";
export const GET_POST_LIST_LOADER_STATUS = "GET_POST_LIST_LOADER_STATUS";
export const NEW_POST = "NEW_POST";
export const UPDATE_POST_LIKE = "UPDATE_POST_LIKE";
export const DELETE_POST = "DELETE_POST";
export const COMMENTS_COUNT_UPDATE = "COMMENTS_COUNT_UPDATE";
export const SUBSCRIBE_POST = "SUBSCRIBE_POST";
export const UPDATE_POST = "UPDATE_POST";
export const WORK_SPACE_WRONG_URL = "WORK_SPACE_WRONG_URL";
export const GROUP_LOADER = "GROUP_LOADER";
export const REFERRAL_LOGIN_DETAILS = "REFERRAL_LOGIN_DETAILS";
export const GET_TOUR_STATUS = "GET_TOUR_STATUS";
export const GET_ALL_MENTOR_LIST = "GET_ALL_MENTOR_LIST";
export const TRIGGER_TOUR_POPUP = "TRIGGER_TOUR_POPUP";
export const SAVE_TOUR_STATUS = "SAVE_TOUR_STATUS";
export const CHANGE_REQUEST_LOADER_STATUS = "CHANGE_REQUEST_LOADER_STATUS";
export const CHANGE_OTHER_REQUEST_LOADER_STATUS =
  "CHANGE_OTHER_REQUEST_LOADER_STATUS";
export const GET_JOB_REQUEST_DETAILS = "GET_JOB_REQUEST_DETAILS";
export const GET_JOB_REQUEST_LOADER_STATUS = "GET_JOB_REQUEST_LOADER_STATUS";
export const GET_JOB_REQUEST_COUNT = "GET_JOB_REQUEST_COUNT";
export const GET_JOB_REQUEST_SEARCH_LIST = "GET_JOB_REQUEST_SEARCH_LIST";
export const CHANGE_HOME_LOADER_STATUS = "CHANGE_HOME_LOADER_STATUS";
export const REMOVE_JOB_REQUEST_COUNT = "REMOVE_JOB_REQUEST_COUNT";
export const AFTER_TRIGGER_POPUP = "AFTER_TRIGGER_POPUP";
export const ANNOUNCEMENT_DETAILS = "ANNOUNCEMENT_DETAILS";
export const GET_TAG_USER_LIST = "GET_TAG_USER_LIST";
export const SINGLE_POST_LOADER = "SINGLE_POST_LOADER";
export const GET_WORKSPACE_OPTIONS = "GET_WORKSPACE_OPTIONS";
export const UPDATE_WORKSPACE_LOGO = "UPDATE_WORKSPACE_LOGO";
export const HASH_TAG_LIST = "HASH_TAG_LIST";
export const DIRECTORY_LIST = "DIRECTORY_LIST";
export const SINGLE_DIRECTORY_LIST = "SINGLE_DIRECTORY_LIST";
export const DIRECTORY_LOADER = "DIRECTORY_LOADER";
export const GET_RESOURCE_POST_TAGS = "GET_RESOURCE_POST_TAGS";
export const GET_RESOURCE_POST = "GET_RESOURCE_POST";
export const RESOURCE_POST_LOADER = "RESOURCE_POST_LOADER";
export const GET_COMMUNITY_LIST_LOADER = "GET_COMMUNITY_LIST_LOADER";
export const MENTOR_LIST_LOADER = "MENTOR_LIST_LOADER";
export const MENTOR_DETAILS = "MENTOR_DETAILS";
export const NEED_RENDER_COMPONENT = "NEED_RENDER_COMPONENT";
export const POST_LIKE_USER_LIST = "POST_LIKE_USER_LIST";
export const RESOURCE_MEDIA_FILES = "RESOURCE_MEDIA_FILES";
export const GET_REQUIREMENT_STATUS = "GET_REQUIREMENT_STATUS";
export const UPDATE_MEMBER_LIST = "UPDATE_MEMBER_LIST";
export const SINGLE_RESOURCE_POST = "SINGLE_RESOURCE_POST";
export const NEED_PHOTO_VERIFICATION = "NEED_PHOTO_VERIFICATION";
export const GROUP_REQUEST_LIST = "GROUP_REQUEST_LIST";
export const DELETE_GROUP_POST = "DELETE_GROUP_POST";
export const POST_NOT_PRESENT = "POST_NOT_PRESENT";
export const GET_SUB_INDUSTRY = "GET_SUB_INDUSTRY";
export const GET_SUB_SPECIALIZATION = "GET_SUB_SPECIALIZATION";
export const GET_MENTOR_INDUSTRY = "GET_MENTOR_INDUSTRY";
export const GET_MENTOR_SPECIALIZATION = "GET_MENTOR_SPECIALIZATION";
export const GET_FRIENDS_LIST_BASED_WORKSPACE =
  "GET_FRIENDS_LIST_BASED_WORKSPACE";
export const UPDATE_PROFILE_DETAILS = "UPDATE_PROFILE_DETAILS";
export const GET_CONNECTION_FRIEND_LIST = "GET_CONNECTION_FRIEND_LIST";
export const UPDATE_CONNECTION_FRIEND_LIST = "UPDATE_CONNECTION_FRIEND_LIST";
export const LOGIN_USER_STATUS_ERROR = "LOGIN_USER_STATUS_ERROR";
export const GET_RESOURCE_LIST_V2 = "GET_RESOURCE_LIST_V2";
export const LIKE_POINTS = "LIKE_POINTS";
export const LIKE_AND_COMMENT_UPDATE = "LIKE_AND_COMMENT_UPDATE";
export const DIRECTORY_FAVOURITE = "DIRECTORY_FAVOURITE";
export const ANNOUNCEMENT_POP_UP = "ANNOUNCEMENT_POP_UP";
export const DIRECTORY_CATEGORY_LIST = "DIRECTORY_CATEGORY_LIST";
// New Implements
export const ASK_HELP_LIST = "ASK_HELP_LIST";
export const ASK_HELP_SINGLE_POST = "ASK_HELP_SINGLE_POST";
export const ASK_HELP_LOADER = "ASK_HELP_LOADER";
export const GET_COMMUNITY_MEMBER_ID = "GET_COMMUNITY_MEMBER_ID";
export const GET_USER_COMMUNITY_LIST = "GET_USER_COMMUNITY_LIST";

export const KEY_CLOAK_LOGIN_V2 = "KEY_CLOAK_LOGIN_V2";
export const KEY_CLOAK_LOGIN_V2_ERROR = "KEY_CLOAK_LOGIN_V2_ERROR";
export const KEY_CLOAK_LOGIN_SUCCESS_V2 = "KEY_CLOAK_LOGIN_SUCCESS_V2";
export const LOGIN_LOADER = "LOGIN_LOADER";
export const REGISTER_ERROR_STATUS = "REGISTER_ERROR_STATUS";
export const EVENT_LIST = "EVENT_LIST";
export const EVENT_LOADER = "EVENT_LOADER";
export const EVENT_VIDEO_LIST = "EVENT_VIDEO_LIST";
export const LOAD_LOCAL_POST = "LOAD_LOCAL_POST";
export const GET_USER_SLOTS = "GET_USER_SLOTS";
export const TREADING_USER_LIST = "TREADING_USER_LIST";
export const DYNAMIC_FORM_DATA = "DYNAMIC_FORM_DATA";
export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const USER_SUBSCRIPTION_STATUS = "USER_SUBSCRIPTION_STATUS";
export const MODIFY_SUBSCRIPTION_STATUS = "MODIFY_SUBSCRIPTION_STATUS";
export const UPDATE_SOCIAL_PROFILE_DETAILS = "UPDATE_SOCIAL_PROFILE_DETAILS";
export const CATEGORY_V2_LIST = "CATEGORY_V2_LIST";
export const CALENDAR_SERVICE_LIST = "CALENDAR_SERVICE_LIST";
export const SPECIALIZATION_V2_LIST = "SPECIALIZATION_V2_LIST";
export const GET_USER_SERVICE = "GET_USER_SERVICE";
export const GET_SERVICE_DASHBOARD = "GET_SERVICE_DASHBOARD";
export const SET_IS_SERVICE_USER = 'SET_IS_SERVICE_USER';
