import React, { useState, useEffect, useRef,useCallback, useLayoutEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Avatar,
  useTheme,
  Button,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FaUserTie } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../config/app-config";
import { withTranslation } from "react-i18next";
import { FaEdit, FaRegFileAlt } from "react-icons/fa";
import { HiOutlineEye, HiOutlineCurrencyRupee } from "react-icons/hi";
import { FaUsers, FaChartLine } from "react-icons/fa";
import { getServiceDashBoard } from "../../../redux/actions/calendarAction";
import RequestList from "./RequestList";
import { FaCalendarCheck } from "react-icons/fa";
import { MdScheduleSend } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[4],
    background: `linear-gradient(145deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
  },
  metricGrid: {
    marginBottom: theme.spacing(4),
  },
  metricCard: {
    position: "relative",
    overflow: "visible",
    maxWidth: 430,
    width: "100%",
    borderRadius: theme.shape.borderRadius * 2,
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: theme.shadows[6],
    },
  },
  metricContent: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    minHeight: 100,
    minWidth: 110,
  },
  metricIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 48,
    height: 48,
    borderRadius: 12,
    marginRight: theme.spacing(2),
    "& svg": {
      fontSize: 24,
    },
  },
  metricText: {
    "& h6": {
      fontSize: "0.875rem",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
      letterSpacing: 0.5,
    },
    "& h3": {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: theme.palette.text.primary,
      marginTop: theme.spacing(0.5),
    },
  },
  actionButton: {
    padding: theme.spacing(1.5, 3),
    borderRadius: 12,
    fontWeight: 700,
    textTransform: "none",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    border: "none",
    letterSpacing: 0.5,
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[4],
    },
  },
  editButton: {
    background: `linear-gradient(34deg, #a00030 0%, #a00030 100%)`,
    color: theme.palette.common.white,
  },
  moreActionsButton: {
    background: `linear-gradient(34deg, #a00030 0%, #a00030 100%)`,
    color: theme.palette.common.white,
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    marginLeft: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    borderLeft: `2px solid ${theme.palette.divider}`,
  },
  avatar: {
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[2],
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: theme.shadows[4],
    },
  },
  headerText: {
    "& h1": {
      fontWeight: 800,
      letterSpacing: -0.5,
      color: theme.palette.text.primary,
    },
    "& p": {
      fontWeight: 500,
      color: theme.palette.text.secondary,
      letterSpacing: 0.2,
    },
  },
  amountIcon: {
    fontSize: 20,
    marginRight: 4,
    color: theme.palette.success.main,
  },
  smallAmountIcon: {
    fontSize: 16,
    marginRight: 2,
    color: theme.palette.success.main,
  },
  totalAmount: {
    fontSize: "1.3rem !important",
    fontWeight: "700 !important",
  },
  settledAmount: {
    fontSize: "0.9rem !important",
    fontWeight: "500 !important",
  },
  pendingAmount: {
    fontSize: "0.9rem !important",
    fontWeight: "500 !important",
    color: theme.palette.error.main,
  },
  amountLabel: {
    display: 'block',
    fontWeight: 500,
    fontSize: '0.65rem',
    color: theme.palette.text.secondary,
    marginTop: 2,
  },
  amountSubLabel: {
    fontSize: '0.6rem',
    color: theme.palette.text.secondary,
    marginLeft: 4,
  },
  settledPendingContainer: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: 8,
    height: '100%',
  },
  amountRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
}));

// const getTrendIndicator = (current, previous) => {
//   if (previous > 0) {
//     const percentage = (((current - previous) / previous) * 100).toFixed(1);
//     return {
//       type: "percentage",
//       value: percentage,
//     };
//   }
//   if (current > 0) {
//     return {
//       type: "new",
//       value: "New",
//     };
//   }
//   return null;
// };

const AdminDashboard = ({ t, makeAction, neededTypeRedirect }) => {
  const classes = useStyles();
  const meetingMenu = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  // Scroll control refs
  const scrollController = useRef(new AbortController());
  const resizeObserver = useRef(null);
  const isMounted = useRef(true);


  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const { serviceDashboard, userSlots } = useSelector((state) => state.event);

  useEffect(() => {
    if (userSlots?.preference?.id) {
      dispatch(getServiceDashBoard({ calendarId: userSlots?.preference?.id }));
    }
  }, [userSlots, dispatch]);

  

  const metrics = [
    {
      title: "Personalized Page Views",
      value: serviceDashboard?.data?.viewCount ?? 0,
      icon: FaRegFileAlt,
      title: "Page Views",
      current: serviceDashboard?.data?.viewCount ?? 0,
      previous: serviceDashboard?.data?.previousViewCount ?? 0,
      icon: <FaChartLine />,
      color: theme.palette.primary.main,
      type: "view",
    },
    {
      title: "Service Bookings",
      value: serviceDashboard?.data?.totalSltosBooking ?? 0,
      icon: <FaCalendarCheck />,
      current: serviceDashboard?.data?.totalSltosBooking ?? 0,
      previous: serviceDashboard?.data?.previousTotalSltosBooking ?? 0,
      color: theme.palette.secondary.main,
      type: "all",
    },
    {
      title: "Total Clients",
      value: serviceDashboard?.totalClients ?? 0,
      icon: <FaUserTie />,
      color: theme.palette.warning.main,
      current: serviceDashboard?.totalClients ?? 0,
      previous: 0,
      type: "clients",
    },
    {
      title: "Revenue",
      current: serviceDashboard?.amount ?? 0,
      settled: serviceDashboard?.amount ?? 0, 
      previous: serviceDashboard?.previousAmount ?? 0,
      icon: <HiOutlineCurrencyRupee />,
      color: theme.palette.success.main,
      type: "all",
    },
  ].map((metric) => {
    // const trendIndicator = getTrendIndicator(metric.current, metric.previous);
    return { ...metric };
  });

  const [state, setState] = useState({ type: "" });

  // Cleanup observers and abort controllers
  useEffect(() => {
    return () => {
      isMounted.current = false;
      scrollController.current.abort();
      if(resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, []);

  // Enhanced scroll handler
  const scrollToSection = useCallback(() => {
    if (!meetingMenu.current) return;

    // Cancel any ongoing scroll operations
    scrollController.current.abort();
    scrollController.current = new AbortController();

    // Wait for layout stabilization
    const waitForStableLayout = (attempt = 0) => {
      const initialHeight = meetingMenu.current.offsetHeight;
      
      setTimeout(() => {
        if (!isMounted.current) return;
        
        if (meetingMenu.current.offsetHeight === initialHeight || attempt > 5) {
          performScroll();
        } else {
          waitForStableLayout(attempt + 1);
        }
      }, 100);
    };

    // Actual scroll performance
    const performScroll = () => {
      if (!meetingMenu.current) return;

      // Use CSS custom property for header height
      const root = document.documentElement;
      root.style.setProperty('--scroll-padding', '100px'); // Match header height

      meetingMenu.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    };

    // Set up Resize Observer
    if (!resizeObserver.current) {
      resizeObserver.current = new ResizeObserver((entries) => {
        if (entries.some(entry => entry.target === meetingMenu.current)) {
          waitForStableLayout();
        }
      });
    }

    if (meetingMenu.current) {
      resizeObserver.current.observe(meetingMenu.current);
    }

    waitForStableLayout();
  }, []);

  // Scroll trigger effect
  useEffect(() => {
    if (state.type) {
      scrollToSection();
    }
  }, [state.type, scrollToSection]);

  // Update state with scroll preservation
  const updateState = useCallback((name, value) => {
    setState(prev => ({ ...prev, [name]: value }));
  }, []);

  const viewDetailsPage = useCallback((type) => {
    if (type === "all" || type === "upcoming" || type === "past" || type === "clients") {
      updateState("type", type);
    } else if (type === "recommend" || type === "review") {
      neededTypeRedirect(type, 5);
    } else {
      makeAction(5);
    }
  }, [updateState, neededTypeRedirect, makeAction]);

  const handleBooking = () => {
    meetingMenu.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const renderMetricCard = (metric) => (
    <Grid item xs={12} sm={6} md={3} key={metric.title}>
      <Card className={classes.metricCard} onClick={() => viewDetailsPage(metric.type)}>
        <CardContent className={classes.metricContent}>
          <Box className={classes.metricIcon} style={{ background: metric.color }}>
            {React.cloneElement(metric.icon, { style: { fontSize: 24 } })}
          </Box>
          <Box className={classes.metricText} style={{ width: '100%' }}>
            <Typography variant="subtitle2">{metric.title}</Typography>
            
            {metric.title === "Revenue" ? (
              <Grid container spacing={0} style={{ marginTop: 4 }}>
                {/* Left Side - Total Amount */}
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <HiOutlineCurrencyRupee className={classes.amountIcon} />
                    <Typography variant="h3" className={classes.totalAmount}>
                      {metric.current}
                    </Typography>
                  </Box>
                  <Typography variant="caption" className={classes.amountLabel}>
                    Total
                  </Typography>
                </Grid>
  
                {/* Right Side - Settled & Pending */}
                <Grid item xs={6}>
                  <Box className={classes.settledPendingContainer}>
                    <Box className={classes.amountRow}>
                      <HiOutlineCurrencyRupee className={classes.smallAmountIcon} />
                      <Typography variant="body2" className={classes.settledAmount}>
                        {metric.current - metric.settled}
                      </Typography>
                      <Typography variant="caption" className={classes.amountSubLabel}>
                        Settled
                      </Typography>
                    </Box>
                    
                    <Box className={classes.amountRow} style={{ marginTop: 4 }}>
                      <HiOutlineCurrencyRupee className={classes.smallAmountIcon} />
                      <Typography variant="body2" className={classes.pendingAmount}>
                        {metric.current}
                      </Typography>
                      <Typography variant="caption" className={classes.amountSubLabel}>
                        Pending
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h3">{metric.current}</Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box className={classes.header}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.headerText}>
            <Typography variant="h6" component="h1" gutterBottom>
              Service Dashboard
            </Typography>
          </Box>

          <Box className={classes.avatarContainer}>
            <Button
              variant="contained"
              className={`${classes.actionButton} ${classes.editButton}`}
              startIcon={<MdScheduleSend style={{ fontSize: 18 }} />}
              onClick={() => makeAction(4)}
            >
              {t("My Scheduler")}
            </Button>
            <Button
              variant="contained"
              className={`${classes.actionButton} ${classes.editButton}`}
              startIcon={<FaEdit style={{ fontSize: 18 }} />}
              onClick={() => makeAction(0)}
            >
              {t("My Business Profile")}
            </Button>
            <Button
              variant="outlined"
              className={`${classes.actionButton} ${classes.moreActionsButton}`}
              startIcon={<HiOutlineEye style={{ fontSize: 20 }} />}
              onClick={() => makeAction(5)}
            >
              {t("More Actions")}
            </Button>
            <Avatar
              className={classes.avatar}
              src={`${API_URL}api/getUserPhoto/${loginDetails.userId}/name`}
            />
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3} className={classes.metricGrid}>
        {metrics.map(renderMetricCard)}
      </Grid>

      <Box ref={meetingMenu} mt={6}
              style={{ 
                scrollMarginTop: 'var(--scroll-padding, 100px)',
                overflowAnchor: 'none',
                willChange: 'transform',
                paddingBottom: '40px'
              }}>
        <RequestList
          id={userSlots?.preference?.id}
          type={state.type}
          dashboardData={serviceDashboard} 
          style={{ borderRadius: theme.shape.borderRadius * 2 }}
        />
      </Box>
    </Container>
  );
};

export default withTranslation()(AdminDashboard);