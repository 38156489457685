import React from "react";
import { connect } from "react-redux";
import LoginService from "./api/LoginService";
import {
  getAllUserCommunityList,
  getAllCommonSubjectList,
  getAllCommonLanguageList,
  getAllCommonTagsList,
  getAllCommonCurriculumTrackList,
  getAllCommonSchoolList,
  getAllCommonExpectationInterestList,
  getAllCommonExpectationHelpList,
  getAllChatFriendsList,
  getAllCommonFriendsList,
  getAllCommonIndustry,
  getAllCommonRegion,
  getAllAreaOfInterest,
  getAllCommonProfessionList,
  getAllUserPoints,
  getAllCountryName,
  triggerTourPopup,
  getTourStatus,
  getApplicationRequirements,
} from "./redux/actions/commonApiActions";
import { getAllNotSawAskHelpListCount } from "./redux/actions/myRequestActions";
import {
  getAllMyGroupEvents,
  getMyGroupsByMembersandMessages,
} from "./redux/actions/subjectActions";
import { getLocalStorage } from "./utils";
import { updateLocalPost } from "./redux/actions/PostCardActions";
import { getSubscriptionList } from "./redux/actions/calendarAction";
var newData = {
  //  data: 'Data from HOC...',
};

var MyHOC = (ComposedComponent) =>
  class extends React.Component {
    componentDidMount() {
      this.setState({
        data: newData.data,
      });
    }
    render() {
      return <ComposedComponent {...this.props} {...this.state} />;
    }
  };

//var CryptoJS = require("crypto-js");

class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //this.loginData = this.loginData.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser.type) {
      // alert("First Calling")

      // this.props.getAllCommonLanguageList()
      if (getLocalStorage("POSTS")) {
        this.props.updateLocalPost(getLocalStorage("POSTS"));
      }
      this.props.getAllCommonTagsList();
      this.props.getAllCommonIndustry();
      // this.props.getAllCommonRegion()
      //  this.props.getAllCommonCurriculumTrackList()
      this.props.getAllCommonSchoolList();
      this.props.getAllCommonFriendsList();
      this.props.getAllChatFriendsList();
      this.props.getAllAreaOfInterest();
      // this.props.getAllUserCommunityList()
      // this.props.getAllMyGroupEvents("Groups",0);
      // this.props.getMyGroupsByMembersandMessages();
      this.props.getAllCommonProfessionList();
      this.props.getAllCommonExpectationHelpList();
      this.props.getAllCommonExpectationInterestList();
      // this.props.getAllUserPoints();
      // this.props.getAllCountryName();
      this.props.getAllNotSawAskHelpListCount();
      this.props.getTourStatus();
      this.props.getApplicationRequirements();
      this.props.getSubscriptionList();

      // if (
      //   this.props.subjectsList.length == 0 ||
      //   this.props.subjectsList == undefined ||
      //   this.props.subjectsList == null
      // ) {
      //   // this.props.getAllCommonSubjectList(true, this.props.personalLanguage)
      // } else {
      //   // alert("this.props.subjectsList")
      // }
      //  this.loginData()
      if (
        this.props.curriculumtrackList.length == 0 ||
        this.props.curriculumtrackList == undefined ||
        this.props.curriculumtrackList == null
      ) {
        this.props.getAllCommonCurriculumTrackList(
          true,
          this.props.personalLanguage
        );
      } else {
      }
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    // if (this.props.authUser.type) {
    //   if (prevProps.subjectsList !== this.props.subjectsList) {
    //     if (
    //       this.props.subjectsList.length == 0 ||
    //       this.props.subjectsList == undefined ||
    //       this.props.subjectsList == null
    //     ) {
    //       // this.props.getAllCommonSubjectList(true, this.props.personalLanguage)
    //     } else {
    //       // alert("this.props.subjectsList")
    //     }
    //   }
    // if (prevProps.curriculumtrackList !== this.props.curriculumtrackList) {
    //   if (
    //     this.props.curriculumtrackList.length == 0 ||
    //     this.props.curriculumtrackList == undefined ||
    //     this.props.curriculumtrackList == null
    //   ) {
    //     this.props.getAllCommonCurriculumTrackList(true, this.props.personalLanguage)
    //   } else {
    //   }
    // }
    // }
  }

  render() {
    return (
      <div>
        <h1>{this.props.data}</h1>
      </div>
    );
  }

  // loginData() {
  //   console.log("login Data")
  //   var loginUser = {
  //     email: "test_2@betamonks.com",
  //     password: "Pass12!@",
  //   }
  //   console.log(loginUser)
  //   var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(loginUser), 'test_2@betamonks.com').toString();
  //   console.log(ciphertext)
  //   LoginService.getLogin(ciphertext)
  //     .then(
  //       response => {
  //         var result = response.data;
  //         console.log("LOGIN ====")
  //         console.log(result)
  //         console.log("LOGIN ==== VALUES " + result.data.profile_complete)
  //         var bytes = CryptoJS.AES.decrypt(result.data, 'test_2@betamonks.com');
  //         var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //       }
  //     )
  //     .catch(error => {
  //       console.log(error)

  //     }
  //     )
  // }
}

const mapStateToProps = function (state) {
  console.log("state.commonApi.curriculumtracklist");
  console.log(state.commonApi.curriculumtracklist);
  return {
    personalLanguage: state.profileValue.lang,
    subjectsList: state.commonApi.subjectslist,
    authUser: state.loginDetailsApi.authUser,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    industryList: state.commonApi.industrylist,
    regionList: state.commonApi.regionlist,
    tagDatas: state.subjects.myeventsandgroups,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getAllCommonSubjectList: (key, personalLanguage) =>
      dispatch(getAllCommonSubjectList(key, personalLanguage)),
    getAllCommonLanguageList: () => dispatch(getAllCommonLanguageList()),
    getAllAreaOfInterest: () => dispatch(getAllAreaOfInterest()),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
    getAllCommonTagsList: () => dispatch(getAllCommonTagsList()),
    getAllCommonIndustry: () => dispatch(getAllCommonIndustry()),
    getAllCommonRegion: () => dispatch(getAllCommonRegion()),
    getAllCommonCurriculumTrackList: (key, personalLanguage) =>
      dispatch(getAllCommonCurriculumTrackList(key, personalLanguage)),
    getAllCommonSchoolList: () => dispatch(getAllCommonSchoolList()),
    getAllCommonFriendsList: () => dispatch(getAllCommonFriendsList()),
    getAllChatFriendsList: () => dispatch(getAllChatFriendsList()),
    getAllMyGroupEvents: (type, page) =>
      dispatch(getAllMyGroupEvents(type, page)),
    getMyGroupsByMembersandMessages: () =>
      dispatch(getMyGroupsByMembersandMessages()),
    getAllCommonProfessionList: () => dispatch(getAllCommonProfessionList()),
    getAllCommonExpectationHelpList: () =>
      dispatch(getAllCommonExpectationHelpList()),
    getAllCommonExpectationInterestList: () =>
      dispatch(getAllCommonExpectationInterestList()),
    getAllUserPoints: () => dispatch(getAllUserPoints()),
    getAllCountryName: () => dispatch(getAllCountryName()),
    getAllNotSawAskHelpListCount: () =>
      dispatch(getAllNotSawAskHelpListCount()),
    getTourStatus: () => dispatch(getTourStatus()),
    getApplicationRequirements: () => dispatch(getApplicationRequirements()),
    updateLocalPost: (data) => dispatch(updateLocalPost(data)),
    getSubscriptionList: () => dispatch(getSubscriptionList()),
  };
};

export default connect(mapStateToProps, mapDispacthToProps)(MyComponent);
