import axios from "axios";
import LoginService from "../../api/LoginService";
import { KEY_CLOAK_URL } from "../../config/keycloak-config.js";
import AuthService from "../../session/AuthService";
import {
  STORE_LOGIN_DETAILS,
  UPDATE_LOGIN_DETAILS,
  SET_CLIENT_ID,
  STORE_AUTH_USER,
  UPDATE_ELIGIBILITY_DETAILS,
  SET_REFRESH_TOKEN_DETAILS,
  WORK_SPACE_LOGIN_SUCCESS,
  URL_UPDATE_LOGIN_DETAILS,
  REFERRAL_LOGIN_DETAILS,
  UPDATE_PROFILE_DETAILS,
  LOGIN_USER_STATUS_ERROR,
  GET_LOGOUT_DATA,
  ANNOUNCEMENT_POP_UP,
  KEY_CLOAK_LOGIN_V2_ERROR,
  KEY_CLOAK_LOGIN_SUCCESS_V2,
  LOGIN_LOADER,
  REGISTER_ERROR_STATUS,
  DYNAMIC_FORM_DATA,
  UPDATE_SOCIAL_PROFILE_DETAILS,
} from "../types";
import { extractValuesSingleFromUrl, setLocalStorage } from "../../utils.js";
import { getWorkSpaceOptionTypes } from "./commonApiActions.js";
import { API_URL } from "../../config/app-config.js";

export const storeLoginDetails = (loginDetails, login_type) => (dispatch) => {
  console.log("LOGIN_DETAILS_COMPLETE");
  console.log(loginDetails);

  const updatedLoginDetails = {
    ...loginDetails,
    isServiceUser: loginDetails?.isServiceUser || false,
  };

  dispatch(isAuthData(updatedLoginDetails, login_type));
  dispatch({
    type: STORE_LOGIN_DETAILS,
    payload: updatedLoginDetails,
  });
};

export const updateLoginDetails = (workSpace) => (dispatch) => {
  dispatch({
    type: UPDATE_LOGIN_DETAILS,
    payload: workSpace,
  });
};
export const urlUpdateLoginDetails = (workSpace) => (dispatch) => {
  dispatch({
    type: URL_UPDATE_LOGIN_DETAILS,
    payload: workSpace,
  });
};
export const updateEligibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_ELIGIBILITY_DETAILS,
    payload: true,
  });
};

export const isAuthData = (loginDetails, login_type) => async (dispatch) => {
  console.log("login details: ", loginDetails);
  let user = loginDetails.uuid;

  var authData = {};
  // if (user != null || user !== undefined) {
  //   authData = {
  //     loginType: login_type,
  //     type: true,
  //   };
  // } else {
  //   authData = {
  //     loginType: login_type,
  //     type: false,
  //   };
  // }
  if (!loginDetails.hasOwnProperty("uuid")) {
    authData = {
      loginType: login_type,
      type: true,
    };
  } else {
    if (user == null) {
      authData = {
        loginType: login_type,
        type: true,
      };
    } else if (user) {
      authData = {
        loginType: login_type,
        type: true,
      };
    } else {
      authData = {
        loginType: login_type,
        type: false,
      };
    }
  }

  dispatch({
    type: STORE_AUTH_USER,
    payload: authData,
  });
};

export function keycloakLoginSuccess(data) {
  return {
    type: "KEYCLOAK_LOGIN_SUCCESS",
    payload: data,
  };
}

export function setCLientId(data) {
  return {
    type: "SET_CLIENT_ID",
    payload: data,
  };
}

export const setIsServiceUser = (isServiceUser) => ({
  type: "SET_IS_SERVICE_USER",
  payload: isServiceUser,
});

export const setUserDetails =
  (data, token, personalData) => async (dispatch) => {
    let params = {
      pushDeviceId: token,
      pushType: "web",
    };
    if (personalData?.countryId) {
      params.countryId = personalData.countryId;
    }
    if (personalData?.country) {
      params.country = personalData.country;
    }
    if (personalData?.personalLanguage) {
      params.personalLanguage = personalData.personalLanguage;
    }
    console.log("Firebase Params", params);

    LoginService.getKeycloakLoginValues(params)
      .then((response) => {
        console.log("response", response);

        if (response.data.data === "This user blocked by admin") {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "blocked_by_admin",
            message:
              "Your login has been blocked by the admin. Please contact support",
          });
          let errorData = {
            apiName: `${API_URL}keycloak/v1/login`,
            exceptionName: "blocked_by_admin",
            errorMessage: response.data,
            userId: 0,
            userEmail: "",
            requestData: params,
          };
          saveUserException(errorData);
        } else if (response.data.status == -1) {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "some_thing_wrong",
            message: "An error occurred. Please try logging in again!",
          });
          let errorData = {
            apiName: `${API_URL}keycloak/v1/login`,
            exceptionName: "some_thing_wrong",
            errorMessage: response.data,
            userId: 0,
            emailId: "",
            requestData: params,
          };
          saveUserException(errorData);
        } else {
          try {
            if (sessionStorage.getItem("WORK_SPACE_LINK_USER")) {
              console.log("User visit via workspace shared community url");
              dispatch(userWorkspaceLinkUpdate(data));
            } else {
              console.log("LOGIN_USER_DETAILS_UPDATE");
              let workSpace = response.data?.data?.defaultWorkSpace;
              sessionStorage.setItem(
                "WORKSPACE",
                workSpace ? workSpace : "default"
              );
              const refImage = localStorage.getItem("R-I");
              localStorage.setItem("R-I", refImage === "A" ? "B" : "A");
              const result = response.data.data;
              dispatch({
                type: "SET_USER_DETAILS",
                payload: data,
                loginDetails: result,
              });

              const updatedUserDetails = {
                ...data,
                isServiceUser: result?.isServiceUser || false,
              };

              dispatch({
                type: "SET_USER_DETAILS",
                payload: updatedUserDetails,
                loginDetails: result,
              });

              dispatch(isAuthData(result, "normal_user"));
              console.log(response);
              const formPresent = sessionStorage.getItem(
                "DYNAMIC_FORM_PRESENT"
              );
              console.log("Dynamic form present", formPresent);
              if (formPresent) {
                const slug = extractValuesSingleFromUrl(formPresent, "form");
                console.log("Dynamic form slug", slug);
                if (slug) {
                  setTimeout(
                    dispatch({
                      type: DYNAMIC_FORM_DATA,
                      payload: slug,
                      isUpdate: true,
                    }),
                    sessionStorage.removeItem("DYNAMIC_FORM_PRESENT"),
                    15000
                  );
                }
              } else {
                setTimeout(
                  dispatch({
                    type: ANNOUNCEMENT_POP_UP,
                    payload: !result.newUser ? result?.modelType : "",
                  }),
                  15000
                );
              }
            }
          } catch (e) {
            console.log("Error", e);
          }
        }
      })
      .catch((error) => {
        console.log("Key-clock-error", error?.response);
        console.log("Key-clock-error", error);
        if (error?.message === "Network Error") {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "network-error",
            message:
              "We're experiencing issues. Please try again after some time.",
          });
        } else if (error?.response) {
          console.log("error", error.response);
          if (error.response?.data?.data === "This user blocked by admin") {
            dispatch({
              type: LOGIN_USER_STATUS_ERROR,
              payload: "blocked_by_admin",
              message:
                "Your login has been blocked by the admin. Please contact support",
            });
            let errorData = {
              apiName: `${API_URL}keycloak/v1/login`,
              exceptionName: "blocked_by_admin",
              errorMessage: error.response,
              userId: 0,
              emailId: "",
              requestData: params,
            };
            saveUserException(errorData);
          } else {
            dispatch({
              type: LOGIN_USER_STATUS_ERROR,
              payload: "some_thing_wrong",
              message:
                "Access is temporarily unavailable. Please try again later",
            });
            let errorData = {
              apiName: `${API_URL}keycloak/v1/login`,
              exceptionName: "some_thing_wrong",
              errorMessage: error?.response,
              userId: 0,
              emailId: "",
              requestData: params,
            };
            saveUserException(errorData);
          }
        } else {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "some_thing_wrong",
            message:
              "Access is temporarily unavailable. Please try again later",
          });
          let errorData = {
            apiName: `${API_URL}keycloak/v1/login`,
            exceptionName: "some_thing_wrong",
            errorMessage: error?.response,
            userId: 0,
            emailId: "",
            requestData: params,
          };
          saveUserException(errorData);
        }
      });
  };

export function ClearUserDetails(data) {
  return {
    type: "CLEAR_USER_DETAILS",
    payload: data,
  };
}

export const getRefreshTokenDetails = (data) => async (dispatch) => {
  try {
    console.log("REQUEST FOR REFRESH TOKEN", data);
    console.log(data);

    LoginService.refreshToken(data)
      .then((response) => {
        console.log("refresh-response", response);
        if (response.status == 200) {
          if (!response.data) {
            // console.log("refresh-data-not present");
            // // Trigger Logout
            // dispatch({
            //   type: GET_LOGOUT_DATA,
            //   payload: [],
            // });
            // dispatch({
            //   type: SET_REFRESH_TOKEN_DETAILS,
            //   payload: "",
            // });
            // sessionStorage.clear();
            AuthService.triggerLogout();
          } else {
            sessionStorage.setItem("SESSION", "N");
            sessionStorage.setItem("TOKEN", response.data.access_token);
            dispatch({
              type: SET_REFRESH_TOKEN_DETAILS,
              payload: response.data,
            });
          }
        } else {
          console.log("refresh-status-error");
          // Trigger Logout

          // dispatch({
          //   type: GET_LOGOUT_DATA,
          //   payload: [],
          // });
          dispatch({
            type: SET_REFRESH_TOKEN_DETAILS,
            payload: "",
          });
          // sessionStorage.clear();
          AuthService.triggerLogout();
        }
      })
      .catch((error) => {
        console.log("refresh-error", error);
        // Trigger Logout
        // dispatch({
        //   type: GET_LOGOUT_DATA,
        //   payload: [],
        // });
        dispatch({
          type: SET_REFRESH_TOKEN_DETAILS,
          payload: "",
        });
        // sessionStorage.clear();
        AuthService.triggerLogout();
      });
  } catch (error) {
    // console.log("refresh-error-catch", error);
    // // Trigger Logout

    // dispatch({
    //   type: GET_LOGOUT_DATA,
    //   payload: [],
    // });
    dispatch({
      type: SET_REFRESH_TOKEN_DETAILS,
      payload: "",
    });
    // AuthService.removeAuth();
    // AuthService.triggerLogout();
  }
  return [];
};

export function getTokenFixedStatus(data) {
  return {
    type: "SET_TOKEN_FIXED",
    payload: data,
  };
}

export const workSpaceUserLogin = (id) => async (dispatch) => {
  try {
    let obj = {
      workSpaceId: +id,
    };
    LoginService.save_user_work_space(obj)
      .then((response) => {
        var loginList = "";
        loginList = response.data.data.user;
        dispatch({
          type: WORK_SPACE_LOGIN_SUCCESS,
          payload: loginList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  } catch (error) {
    dispatch({
      type: WORK_SPACE_LOGIN_SUCCESS,
      payload: [],
    });
  }
  return [];
};
export const workSpaceUserUpdate = (data) => async (dispatch) => {
  try {
    LoginService.updateUserProfile(data)
      .then((response) => {
        var loginList = "";
        loginList = response.data.data.user;
        let workSpace = response.data?.data?.user?.defaultWorkSpace;
        console.log("N_WORKSPACE_UPDATE", workSpace);
        sessionStorage.setItem("WORKSPACE", workSpace ? workSpace : "default");
        dispatch({
          type: WORK_SPACE_LOGIN_SUCCESS,
          payload: loginList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  } catch (error) {
    dispatch({
      type: WORK_SPACE_LOGIN_SUCCESS,
      payload: [],
    });
  }
  return [];
};

export const referralUserAddToWorkSpace = (email, dnsp) => async (dispatch) => {
  try {
    let obj = {
      invitedEmail: email,
      dnsp: dnsp,
    };
    LoginService.referralUserAddToWorkSpace(obj)
      .then((response) => {
        var loginList = "";
        loginList = response.data.data;
        dispatch({
          type: REFERRAL_LOGIN_DETAILS,
          payload: loginList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  } catch (error) {
    dispatch({
      type: REFERRAL_LOGIN_DETAILS,
      payload: [],
    });
  }
  return [];
};
export const updateProfileDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      payload: data,
      type: UPDATE_PROFILE_DETAILS,
    });
  } catch (e) {
    console.log("Error", e);
  }
};
export const updateSocialMediaDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      payload: data,
      type: UPDATE_SOCIAL_PROFILE_DETAILS,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const announcementStatus = (data) => async (dispatch) => {
  dispatch({
    type: ANNOUNCEMENT_POP_UP,
    payload: data,
  });
};
// export const getRefreshTokenDetails = (data) => async (dispatch) => {
//   try {
//     LoginService.refreshToken(data).then((response) => {

//       sessionStorage.setItem("SESSION", "N");
//       sessionStorage.setItem("TOKEN", response.data.access_token);

//       dispatch({
//         type: SET_REFRESH_TOKEN_DETAILS,
//         payload: response.data,
//       });
//       console.log(response);

//     })
//       .catch((error) => {
//         dispatch({
//           type: SET_REFRESH_TOKEN_DETAILS,
//           payload: "",
//         });
//       });

//   } catch (error) {
//     dispatch({
//       type: SET_REFRESH_TOKEN_DETAILS,
//       payload: "",
//     });
//   }
//   return [];
// };

export const socialKeyClockLoginAction = (type, token) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_LOADER,
      payload: true,
    });
    const params = new URLSearchParams();
    params.append(
      "grant_type",
      "urn:ietf:params:oauth:grant-type:token-exchange"
    );
    params.append(
      "subject_token_type",
      "urn:ietf:params:oauth:token-type:access_token"
    );
    params.append("client_id", "Yuukke-oauth");
    params.append("client_secret", "d6873a87-4ffd-4a0d-af59-aa98bde54cab");
    params.append("subject_issuer", type);
    params.append("subject_token", token);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    console.log(
      "SOCIAL_LOGIN_RequestUrl ",
      KEY_CLOAK_URL + "realms/Yuukke/protocol/openid-connect/token"
    );
    console.log("Access Token =====> ", token);
    // Key clock token get method
    axios
      .post(
        KEY_CLOAK_URL + "realms/Yuukke/protocol/openid-connect/token",
        params,
        config
      )
      .then((response) => {
        const result = response.data;
        console.log("KEY_CLOAK_TOKEN", response.data);
        if (result?.access_token) {
          sessionStorage.setItem("TOKEN", result.access_token);
          sessionStorage.setItem("REFRESH_TOKEN", result.refresh_token);
          setLocalStorage("R_TOKEN", result.refresh_token);
          // Get User Details

          dispatch(setUserDetails("web", null));
          dispatch({
            type: KEY_CLOAK_LOGIN_SUCCESS_V2,
            payload: result,
          });
          // dispatch(getKeyCloakUserDetails(null,"web"));
        } else {
          console.error("******* Access token not present *******");

          dispatch({
            payload: true,
            type: KEY_CLOAK_LOGIN_V2_ERROR,
          });
        }
      })
      .catch((err) => {
        console.error("******* Key cloak server error *******");

        dispatch({
          payload: true,
          type: KEY_CLOAK_LOGIN_V2_ERROR,
        });
        console.error("keycloak-error", err);
      });
  } catch (e) {
    console.error("******* Unexpected error *******");

    dispatch({
      payload: true,
      type: KEY_CLOAK_LOGIN_V2_ERROR,
    });
  }
};

export const mobileNumberRegister = (data, loginUpdate) => async (dispatch) => {
  try {
    LoginService.mobileRegister(data)
      .then((response) => {
        const result = response?.data?.data;
        console.log("KEY_CLOAK_TOKEN", response.data);
        if (result === "USER_EXISTS") {
          dispatch({
            payload: "This user already exists",
            type: REGISTER_ERROR_STATUS,
          });
        } else if (result?.access_token) {
          sessionStorage.setItem("TOKEN", result.access_token);
          sessionStorage.setItem("REFRESH_TOKEN", result.refresh_token);
          setLocalStorage("R_TOKEN", result.refresh_token);

          // Get UsKEY_CLOAK_LOGIN_SUCCESS_V2er Details
          dispatch(setUserDetails("web", null, loginUpdate));
          dispatch({
            type: KEY_CLOAK_LOGIN_SUCCESS_V2,
            payload: result,
          });

          // dispatch(getKeyCloakUserDetails(null,"web"));
        } else {
          console.error("*******Register Access token not present *******");

          dispatch({
            payload: true,
            type: KEY_CLOAK_LOGIN_V2_ERROR,
          });
        }
      })
      .catch((e) => {
        console.error("*******Register Unexpected error *******");

        dispatch({
          payload: true,
          type: KEY_CLOAK_LOGIN_V2_ERROR,
        });
      });
  } catch (e) {
    console.error("*******Register Unexpected error *******");
    dispatch({
      payload: true,
      type: KEY_CLOAK_LOGIN_V2_ERROR,
    });
  }
};

export const getKeyCloakUserDetails = () => async (dispatch) => {
  try {
    LoginService.getKeyCloakUserInfo()
      .then((response) => {
        const result = response.data;
        console.log("Key-cloak-user-info", result);
        // if(result?.email){

        // }else{
        //   console.log("Getting userDetails key clock error", result);
        //   dispatch({
        //     payload: true,
        //     type: KEY_CLOAK_LOGIN_V2_ERROR,
        //   });
        // }
      })
      .catch((er) => {});
  } catch (e) {
    console.log("Getting userDetails unexpected", e);
    dispatch({
      payload: true,
      type: KEY_CLOAK_LOGIN_V2_ERROR,
    });
  }
};

export const keyCloakLoader = (status) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADER,
    payload: status,
  });
};
export const registerErrorUpdate = (status) => async (dispatch) => {
  dispatch({
    type: REGISTER_ERROR_STATUS,
    payload: status,
  });
};

export const loginErrorUpdate = (status) => async (dispatch) => {
  dispatch({
    type: KEY_CLOAK_LOGIN_V2_ERROR,
    payload: status,
  });
};

export const updateKeyCloakToken = (data) => async (dispatch) => {
  try {
    sessionStorage.setItem("TOKEN", data.access_token);
    sessionStorage.setItem("REFRESH_TOKEN", data.refresh_token);
    setLocalStorage("R_TOKEN", data.refresh_token);

    // Get User Details
    dispatch(setUserDetails("web", null));
    dispatch({
      type: KEY_CLOAK_LOGIN_SUCCESS_V2,
      payload: data,
    });
  } catch (e) {
    dispatch({
      payload: true,
      type: KEY_CLOAK_LOGIN_V2_ERROR,
    });
  }
};

export const getUserDetailsByToken = (data) => async (dispatch) => {
  try {
    console.log("REQUEST FOR REFRESH TOKEN", data);
    console.log(data);
    dispatch(keyCloakLoader(true));
    LoginService.refreshToken(data)
      .then((response) => {
        console.log("refresh-response", response);
        if (response.status === 200) {
          if (response.data?.access_token) {
            sessionStorage.setItem("TOKEN", response.data?.access_token);
            sessionStorage.setItem(
              "REFRESH_TOKEN",
              response.data?.refresh_token
            );
            setLocalStorage("R_TOKEN", response.data?.refresh_token);
            dispatch(setUserDetails("web", null));
            dispatch({
              type: KEY_CLOAK_LOGIN_SUCCESS_V2,
              payload: response.data,
            });
          }
        } else {
          console.log("Token not present");
          dispatch(keyCloakLoader(false));
        }
      })
      .catch((error) => {
        console.error("Refresh token failed :", error);
        dispatch(keyCloakLoader(false));
      });
  } catch (error) {
    console.error("Refresh token failed :", error);
    dispatch(keyCloakLoader(false));
  }
  return [];
};

const userWorkspaceLinkUpdate = (typeData) => async (dispatch) => {
  const slug = extractValuesSingleFromUrl(
    sessionStorage.getItem("WORK_SPACE_LINK_USER"),
    "id"
  );
  console.log("WORK_SPACE SLUG", slug);
  if (slug) {
    let data = {
      slug: slug,
    };
    LoginService.updateUserWorkSpace(data)
      .then((response) => {
        sessionStorage.removeItem("WORK_SPACE_LINK_USER");
        console.log("UPDATED_DETAILS", response.data);
        if (response.data.data === "This user blocked by admin") {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "blocked_by_admin",
            message:
              "Your login has been blocked by the admin. Please contact support",
          });
        } else if (response.data.data === "INVALID_SLUG") {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "some_thing_wrong",
            message:
              "Workspace not present in given url , please check the url !",
          });
        } else if (response.data.status == -1) {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "some_thing_wrong",
            message: "An error occurred. Please try logging in again!",
          });
        } else {
          let obj = { ...response.data.data.loginResponse };
          const findWorkSpace = obj.userWorkSpaceList.find(
            (item) => item.slug === slug
          );
          console.log("WORK_SPACE_DETAILS", findWorkSpace);
          if (findWorkSpace) {
            console.log("PROCESSING_UPDATE", findWorkSpace);
            obj.userWorkSpace = findWorkSpace;
          }
          console.log("WORK_SPACE_DETAILS_UPDATED", obj);
          console.log("N_WORKSPACE_UPDATE", slug);
          sessionStorage.setItem("WORKSPACE", slug);
          dispatch({
            type: "SET_USER_DETAILS",
            payload: typeData,
            loginDetails: obj,
          });
          dispatch(isAuthData(obj, "normal_user"));
          dispatch(getWorkSpaceOptionTypes());
        }
      })
      .catch((error) => {
        sessionStorage.removeItem("WORK_SPACE_LINK_USER");
        if (error?.response) {
          console.log("error", error.response);
          if (error.response?.data?.data === "This user blocked by admin") {
            dispatch({
              type: LOGIN_USER_STATUS_ERROR,
              payload: "blocked_by_admin",
              message:
                "Your login has been blocked by the admin. Please contact support",
            });
          } else {
            dispatch({
              type: LOGIN_USER_STATUS_ERROR,
              payload: "some_thing_wrong",
              message:
                "Access is temporarily unavailable. Please try again later",
            });
          }
        } else {
          dispatch({
            type: LOGIN_USER_STATUS_ERROR,
            payload: "some_thing_wrong",
            message:
              "Access is temporarily unavailable. Please try again later",
          });
        }
        console.log("Key-clock-error", error?.response);
        console.log("Key-clock-error", error);
      });
  } else {
    console.log("SLUG NOT PRESENT ,REDIRECT ERROR PAGE");
    dispatch({
      type: LOGIN_USER_STATUS_ERROR,
      payload: "some_thing_wrong",
      message: "Workspace not present in given url , please check url !",
    });
  }
};

export const makeActionInDynamicForm = (slug) => async (dispatch) => {
  dispatch({
    type: DYNAMIC_FORM_DATA,
    payload: slug,
    isUpdate: false,
  });
};

export const saveUserException = (data) => {
  try {
    if (data.errorMessage) {
      data.errorMessage = JSON.stringify(data.errorMessage);
    }
    if (data.requestData) {
      data.requestData = JSON.stringify(data.requestData);
    }

    LoginService.saveUserException(data)
      .then((response) => {
        console.log("USER EXCEPTION SAVING COMPLETED ");
      })
      .catch((er) => {});
  } catch (e) {
    console.log("USER EXCEPTION SAVING ERROR ", e);
  }
};
