import React from "react";
import { withTranslation } from "react-i18next";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { withRouter } from "react-router-dom";

const socialIcons = [
  {
    icon: <IoLogoInstagram className="svg-icons text-white" />,
    link: "https://www.instagram.com/yuukkeglobal/",
  },
  {
    icon: <FaLinkedin className="svg-icons text-white" />,
    link: "https://www.linkedin.com/company/yuukke/?original_referer=https%3A%2F%2Fyuukke.com%2F",
  },
];
function FooterV2({ t }) {
  const image = [
    "https://corporate.yuukke.com/bcav2/auth/image/bed6e235-d4a5-409f-89d7-4d0491056dd3",
    "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/visa.jpg",
    "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/master%20card%20.png",
    "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/american_express.png",
  ];
  const quickLinks = [
    {
      title: "Become A Seller",
      link: "https://marketplace.yuukke.com/seller_register",
    },
    {
      title: "Become A Mentor",
      link: "https://yuukke.com/become-a-mentor/",
    },
    {
      title: "Become A Member",
      link: "https://login.yuukke.com/auth/realms/yuukke/protocol/openid-connect/registrations?client_id=iwnFrontend&response_type=code&scope=openid%20email&redirect_uri=https://connect.yuukke.com/&kc_locale=en",
    },
    {
      title: "Become A Service Provider",
      link: "https://connect.yuukke.com/yuukke-service",
    },

    {
      title: "Join Us",
      link: "https://yuukke.com/join-entrepreneurs-community/",
    },
    {
      title: "Blog",
      link: "https://yuukke.com/blog/",
    },
    {
      title: "Women Leaders 2023",
      link: "https://yuukke.com/women-leaders-2023/",
    },
    {
      title: "Events",
      link: "https://yuukke.com/#events",
    },
  ];
  const support = [
    {
      title: "Why Yuukke",
      link: "https://yuukke.com/#why-yuukke",
    },
    {
      title: "Get Involved",
      link: "https://yuukke.com/#get-involved",
    },
    {
      title: "What We Do",
      link: "https://yuukke.com/#what-we-do",
    },
    {
      title: "Meet & Greet",
      link: "https://yuukke.com/meet-greet/",
    },
  ];
  const yuukke = [
    {
      title: "Download Mobile App",
      link: "http://yuukke.com/promotion.php",
    },
    {
      title: "About Us",
      link: "https://yuukke.com/about/",
    },
    {
      title: "Global Ambassadors",
      link: "https://yuukke.com/global-ambassadors/",
    },
  ];
  const resource = [
    {
      title: "Privacy Policy",
      link: "https://yuukke.com/wp-content/uploads/2022/12/Yuukke-Privacy-Policy.pdf",
    },
    {
      title: "Terms & Conditions",
      link: "https://yuukke.com/wp-content/uploads/2024/02/yuukke_tnc.pdf",
    },
    {
      title: "Contact Us",
      link: "https://yuukke.com/contact-us/",
    },
  ];
  const started = [
    {
      title: "Create Your Expert Profile and Meeting Preference",
      description:
        "Set up your expert profile and define your meeting preferences to showcase your skills and streamline client interactions",
    },
    {
      title: "Create Your Service and Offerings",
      description:
        "Define and list your services with clear offerings and details to attract your ideal clients",
    },
    {
      title: "Share your Personalized page and get started",
      description:
        "Share your personalized page with potential clients and start offering your services seamlessly.",
    },
  ];
  const uploadOptions = [
    {
      file: "https://customer-3uw4nujlakj1ub6w.cloudflarestream.com/5df53024f5a24a78bd50d60af3567a70/manifest/video.m3u8",
      image:
        "https://corporate.yuukke.com/bcav2/auth/image/ba05a8e9-d307-4993-a3cb-25e4290a10bc",
      steamStatus: true,
    },
  ];
  const openPhone = (event, number) => {
    window.location.href = `tel:${number}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClick = (event, email) => {
    window.location.href = `mailto:${email}`;
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <>
      <section
        style={{ background: "#000940", fontSize: "14px" }}
        className="text-white mt-5 "
      >
        <h6 className="service-wrap mt-0 pt-5">
          {t("Accepting Payment Methods")}
        </h6>
        <ul className="service-wrap d-flex flex-wrap gap-1 l-none">
          {image.map((item, i) => (
            <li key={i}>
              <img
                // onClick={() => handleButtonClick()}
                src={item}
                alt=""
                className="c-pointer"
                style={{
                  width: "4rem",
                  height: "2rem",
                  background: "white",
                  borderRadius: "5px",
                }}
              />
            </li>
          ))}
        </ul>
        <div className="service-wrap pt-4 pb-5 justify-content-between d-flex flex-wrap gap-1">
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              {t("Quick Links")}
            </li>
            {quickLinks.map((item, i) => (
              <li key={i}>
                <a
                  className="text-white"
                  style={{ fontWeight: "600" }}
                  href={item.link}
                >
                  {t(`${item.title}`)}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              {t("Support")}
            </li>
            {support.map((item, i) => (
              <li key={i}>
                <a
                  style={{ fontWeight: "600" }}
                  className="text-white"
                  href={item.link}
                >
                  {t(`${item.title}`)}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              Yuukke
            </li>
            {yuukke.map((item, i) => (
              <li key={i}>
                <a
                  style={{ fontWeight: "600" }}
                  className="text-white"
                  href={item.link}
                >
                  {t(`${item.title}`)}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none">
            <li className="mb-3 font-weight-bold" style={{ fontSize: "1rem" }}>
              {t("Resources")}
            </li>
            {resource.map((item, i) => (
              <li key={i}>
                <a
                  style={{ fontWeight: "600" }}
                  className="text-white"
                  href={item.link}
                >
                  {t(`${item.title}`)}
                </a>
              </li>
            ))}
          </ul>
          <ul className="l-none" style={{ fontWeight: "600" }}>
            <li>{t("Yuukke Global Venture Pvt Ltd.")}</li>
            <li>
              Y-213, 2nd Ave, Y Block, Anna Nagar,
              <br /> Chennai, Tamil Nadu 600040
            </li>
            <li
              className="c-pointer"
              onClick={(e) => handleClick(e, "support@yuukke.com")}
            >
              support@yuukke.com
            </li>
            <li
              className="c-pointer"
              onClick={(e) => openPhone(e, "9104446314646")}
            >
              +91 04446314646
            </li>
          </ul>
        </div>
      </section>

      <footer
        style={{ background: "#000000", fontSize: "14px" }}
        className="text-white p-3 text-center"
      >
        <div className="service-wrap d-flex align-items-center gap-1 justify-content-between flex-wrap">
          <p>© 2022-26 Yuukke Global Ventures Private Limited.</p>
          <ul className="l-none d-flex align-items-center justify-content-between gap-1 m-0">
            {socialIcons.map((item, i) => (
              <li key={i}>
                <a href={item.link}>{item.icon}</a>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    </>
  );
}

export default withTranslation()(withRouter(FooterV2));
