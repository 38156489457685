import { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { API_URL } from "../../config/app-config";

export default function CalendarTabs({
  eventDetails,
  contact,
  clarityService,
  viewClarityService,
  customTheme,
}) {
  const [activeTab, setActiveTab] = useState("About");
  const [tabs, setTabs] = useState([]);
  const [galleryImage, setGalleryImage] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    serviceAnnouncement: [],
    serviceProviderVideo: [],
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  //   const tabs = ["About", "Contact", "Photos"];

  //   const tabContent = [
  //     description,
  //     "This is the Contact section. Get in touch with us.",
  //     "This is the Photos section. Check out our upcoming events.",
  //   ];
  useEffect(() => {
    let needTabs = [];
    if (eventDetails.description) {
      needTabs.push("About");
    }
    if (eventDetails.phoneNumberShow || eventDetails.emailShow) {
      needTabs.push("Contact");
    }
    if (eventDetails.serviceGalleryImage) {
      needTabs.push("Photos");
      let image = eventDetails.serviceGalleryImage
        .split(",")
        .map((item) => `${API_URL}auth/image/${item}`);
      setGalleryImage(image);
    }

    if (eventDetails.serviceProviderVideo) {
      needTabs.push("Videos");
      let image = eventDetails.serviceProviderVideo.split(",");
      updateState("serviceProviderVideo", image);
    }
    if (eventDetails.serviceAnnouncement) {
      needTabs.push("Announcements");
      let image = eventDetails.serviceAnnouncement
        .split(",")
        .map((item) => `${API_URL}auth/image/${item}`);
      updateState("serviceAnnouncement", image);
    }
    setTabs(needTabs);
  }, []);
  console.log("eventDetails", eventDetails);
  console.log("state", state);

  const primaryColor = () => {
    if (eventDetails?.primaryColor) {
      return eventDetails?.primaryColor;
    }
    return "";
  };
  const secondaryColor = () => {
    if (eventDetails?.secondaryColor) {
      return eventDetails?.secondaryColor;
    }
    return "";
  };
  const headingColor = () => {
    if (eventDetails?.headingColor) {
      return eventDetails?.headingColor;
    }
    return "";
  };
  const secondaryHeadingColor = () => {
    if (eventDetails?.subHeadingColor) {
      return eventDetails?.subHeadingColor;
    }
    return "";
  };
  return (
    <section
      className="calendar-tabs"
      style={{
        background: customTheme ? secondaryColor() : "",
        width: "30rem",
        border: `1px solid ${customTheme ? primaryColor() : ""}`,
      }}
    >
      {/* Tabs */}
      <div className="c-tabs">
        {tabs.map((tab, index) => (
          <button
            key={index}
            style={{ color: customTheme ? headingColor() : "" }}
            className={`c-tab ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="c-tab-content">
        {activeTab === "Photos" ? (
          <div className="c-gallery">
            {/* Image Grid */}
            <div className="c-grid">
              {galleryImage.map((data, index) => (
                <img
                  key={index}
                  src={data}
                  alt={`Image ${index}`}
                  className="grid-image c-pointer"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                />
              ))}
            </div>

            {/* Lightbox */}
            {isOpen && (
              <Lightbox
                mainSrc={galleryImage[photoIndex]}
                nextSrc={galleryImage[(photoIndex + 1) % galleryImage.length]}
                prevSrc={
                  galleryImage[
                    (photoIndex + galleryImage.length - 1) % galleryImage.length
                  ]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + galleryImage.length - 1) % galleryImage.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % galleryImage.length)
                }
              />
            )}
          </div>
        ) : activeTab === "Announcements" ? (
          <div className="c-gallery">
            {/* Image Grid */}
            <p
              className="f-14 mb-3 mt-2"
              style={{
                textAlign: "start",
                color: `${customTheme ? headingColor() : ""} !important`,
              }}
              dangerouslySetInnerHTML={{
                __html: eventDetails?.announcementContent,
              }}
            />

            <div className="c-grid">
              {state.serviceAnnouncement.map((data, index) => (
                <img
                  key={index}
                  src={data}
                  alt={`Image ${index}`}
                  className="grid-image c-pointer"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                />
              ))}
            </div>

            {/* Lightbox */}
            {isOpen && (
              <Lightbox
                mainSrc={state.serviceAnnouncement[photoIndex]}
                nextSrc={
                  state.serviceAnnouncement[
                    (photoIndex + 1) % state.serviceAnnouncement.length
                  ]
                }
                prevSrc={
                  state.serviceAnnouncement[
                    (photoIndex + state.serviceAnnouncement.length - 1) %
                      state.serviceAnnouncement.length
                  ]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + state.serviceAnnouncement.length - 1) %
                      state.serviceAnnouncement.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex(
                    (photoIndex + 1) % state.serviceAnnouncement.length
                  )
                }
              />
            )}
          </div>
        ) : activeTab === "Videos" ? (
          <div className="c-gallery">
            <div className="c-grid" style={{ gap: "2rem" }}>
              {state.serviceProviderVideo.map((data, index) => (
                <video
                  key={index}
                  style={{ width: "100%", height: "200px" }}
                  controls
                >
                  <source src={`${API_URL}auth/video/${data}`} />
                </video>
              ))}
            </div>
          </div>
        ) : activeTab === "Contact" ? (
          <ul className="l-none test-align-start p-0">
            {eventDetails?.emailShow && contact.email && (
              <li className="d-flex">
                <b style={{ color: customTheme ? headingColor() : "" }}>
                  Email :
                </b>{" "}
                <p
                  style={{ color: customTheme ? secondaryHeadingColor() : "" }}
                  className="ml-1"
                >
                  {contact.email}
                </p>
              </li>
            )}
            {eventDetails?.phoneNumberShow && contact.phoneNumber && (
              <li className="d-flex">
                <b style={{ color: customTheme ? headingColor() : "" }}>
                  Phone Number :
                </b>{" "}
                <p
                  className="ml-1"
                  style={{ color: customTheme ? secondaryHeadingColor() : "" }}
                >
                  {contact.phoneNumber}
                </p>
              </li>
            )}
            {eventDetails.countryName && (
              <li className="d-flex">
                <b style={{ color: customTheme ? headingColor() : "" }}>
                  Country :
                </b>{" "}
                <p
                  style={{ color: customTheme ? secondaryHeadingColor() : "" }}
                  className="ml-1"
                >
                  {eventDetails.countryName}
                </p>
              </li>
            )}
          </ul>
        ) : (
          <div style={{ textAlign: "start" }}>
            {/* <p className="mt-2 ">
              <b>Service Name :</b> {eventDetails.serviceName}
            </p> */}
            <p className="mt-2 ">
              <b style={{ color: customTheme ? headingColor() : "" }}>
                Service Description :
              </b>
              <p
                style={{ color: customTheme ? secondaryHeadingColor() : "" }}
                className="f-14"
                dangerouslySetInnerHTML={{
                  __html: eventDetails.description,
                }}
              />
            </p>
            {contact.personalWebsite && (
              <p className="mt-2 mb-2">
                <b style={{ color: customTheme ? headingColor() : "" }}>
                  Our Website :
                </b>{" "}
                <a
                  href={contact.personalWebsite}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click Here
                </a>
              </p>
            )}
            {clarityService && eventDetails.demoSessionAvailable && (
              <button
                className="w-fit mt-3"
                onClick={() => viewClarityService(clarityService, "clarity")}
              >
                Book a Free Clarity Session
              </button>
            )}
          </div>
        )}
      </div>
      {/* <div className="c-tab-content">{tabContent[activeTab]}</div> */}
    </section>
  );
}
