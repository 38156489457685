import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import MyYuukke from "../../../api/MyYuukke.js";
import BlockUi from "react-block-ui";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { API_URL, JITSI_URL } from "../../../config/app-config.js";
import { FaRupeeSign } from "react-icons/fa";
import { mailInit, phoneInit } from "../../../utils.js";
import { FiPhoneForwarded } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import { withTranslation } from "react-i18next";
import dummyimage from "../../../img/default_avatar.jpg";
import { toast } from "react-toastify";

const ServiceReview = ({ t }) => {
  const requestTypes = [
    { value: "all", label: "All Requests" },
    { value: "upcoming", label: "UpComing Meeting" },
    { value: "past", label: "Past Meeting" },
    // { value: 60, label: "60 Minute Meeting" },
  ];
  useEffect(() => {
    getReviewUserList();
  }, []);
  const [state, setState] = useState({
    // requestFilterType: {
    //   value: "upcoming",
    //   label: "UpComing Meeting",
    // },
    downloadList: [],
    singleRequestData: null,
    assessmentAnswer: [],
    requestDetails: false,
    activeMenu: null,
    chartData: null,
    chartOption: [],
    recommendList: [],
    detailsPage: false,
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const getReviewUserList = (data) => {
    console.log("data", data);
    updateState("loader", true);

    MyYuukke.getServiceReviewList()
      .then((res) => {
        setState((previous) => ({
          ...previous,
          recommendList: res.data.data,
          loader: false,
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong");
        updateState("loader", false);
      });
  };

  console.log("state", state);
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <div className="p-3">
        {!state.singleRequestData ? (
          <MaterialTable
            style={{ width: "100%" }}
            columns={[
              {
                title: "UserName",
                field: "name",
                cellStyle: {
                  backgroundColor: "#FAFAFA",
                  color: "#000",
                },
              },
              {
                title: "Email",
                field: "email",
                render: (e) => e.email || "-",
              },
              {
                title: "PhoneNumber",
                field: "mobileNumber",
                render: (e) => e.mobileNumber || "-",
              },

              {
                title: "Date",
                field: "date",
                render: (e) =>
                  e.bookStartTime
                    ? moment(e.bookStartTime).locale("en").format("ll")
                    : "-",
              },
            ]}
            data={state.recommendList}
            title={`Review user's count : ${state.recommendList.length}`}
            options={{
              pageSize: 10,
              exportButton: true,
              search: true,
              sorting: true,
              headerStyle: {
                backgroundColor: "#A00030",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
            }}
            onRowClick={(event, rowData) => {
              updateState("singleRequestData", rowData);
            }}
          />
        ) : (
          <Dialog
            fullWidth
            maxWidth="lg"
            open={true}
            onClose={() => updateState("singleRequestData", null)}
          >
            <DialogTitle>
              Review Details
              <AiOutlineCloseCircle
                onClick={() => updateState("singleRequestData", null)}
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginBottom: "8px",
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                }}
              />
            </DialogTitle>
            <DialogContent dividers="paper">
              <div>
                {/* <h4
                  className="mt-2 mb-3 font-weight-bold"
                  style={{ fontSize: "1.3rem" }}
                >
                  {t("User Details")}
                </h4> */}
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        className="text-white  font-weight-bold"
                        style={{ background: "#a00030" }}
                      >
                        {t("Field")}
                      </th>
                      <th
                        className="text-white font-weight-bold"
                        style={{ background: "#a00030" }}
                      >
                        {t("Value")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="label-v2"> {t("Name")}</td>
                      <td>{state.singleRequestData.name}</td>
                    </tr>

                    <tr>
                      <td className="label-v2"> {t("Email")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.email}

                        <button
                          className="ml-auto font-weight-bold text-left"
                          style={{ width: "143px" }}
                        >
                          {" "}
                          <MdOutlineMarkEmailRead
                            className="svg-icons text-white mr-2"
                            onClick={(e) =>
                              mailInit(e, state.singleRequestData.email)
                            }
                          />
                          Send Email
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("MobileNumber")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.mobileNumber ?? ""}
                        {state.singleRequestData.mobileNumber && (
                          <button
                            className="ml-auto font-weight-bold text-left"
                            style={{ width: "143px" }}
                          >
                            <FiPhoneForwarded
                              className="svg-icons text-white mr-3"
                              onClick={(e) =>
                                phoneInit(
                                  e,
                                  state.singleRequestData.mobileNumber
                                )
                              }
                            />
                            Contact
                          </button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Suggestion")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.suggestion}
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Satisfaction")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.satisfaction} / 5
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Quality Of Service")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.qualityOfService}
                      </td>
                    </tr>
                    {/*                     
                    <tr>
                      <td className="label-v2">{t("I want get email notification above")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.receiveNotification}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="label-v2">
                        {t("I will Refer My Friends ?")}
                      </td>
                      <td className="d-flex">
                        {state.singleRequestData.referFriends ? "Yes" : "No"}
                      </td>
                    </tr>

                    <tr>
                      <td className="label-v2">{t("Meeting Time")}</td>
                      <td>
                        {moment(state.singleRequestData.bookStartTime)
                          .locale("en")
                          .format("LLL")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </BlockUi>
  );
};

export default withTranslation()(ServiceReview);
