import React, { Component } from "react";
import logoDark from "../../images/yuukke.png";
import TextField from "@material-ui/core/TextField";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import UserImg from "../UserImg";
import RightModal from "./RightModal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { NavLink } from "react-router-dom";
import "./Admin.css";
import Notifications from "./Notifications";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import * as APPCONFIG from "../../config/app-config.js";
import AuthService from "../../session/AuthService";
import ProfileService from "../../api/ProfileService";
import { Form, Modal } from "react-bootstrap";
import { Button, MenuItem, TextareaAutosize, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
//import dummyimage from '../../img/avatar_dummy.png';
import dummyimage from "../../img/default_avatar.jpg";
import { connect } from "react-redux";
import { AiOutlineCloseCircle, AiOutlineShoppingCart } from "react-icons/ai";
import {
  getNavigationType,
  getWorkSpaceType,
  getUserWorkSpaceList,
  getTourStatus,
  getWorkSpaceOptionTypes,
  getAnnouncementList,
  getAllUserCommunityList,
  needPhotVerification,
} from "../../redux/actions/commonApiActions";
import { storePersonalLanguage } from "../../redux/actions/profileActions";
import {
  getNotificationList,
  removeNotificationListById,
} from "../../redux/actions/notificationActions";
import { getLogoutData } from "../../redux/actions/logoutActions";
import { getAllUserPoints } from "../../redux/actions/commonApiActions";
import {
  getRefreshTokenDetails,
  setCLientId,
  getTokenFixedStatus,
  workSpaceUserLogin,
} from "../../redux/actions/loginDetailsActions";
import IdleTimer from "react-idle-timer";
//Upload your profile photo
import {
  MdOutlineEventAvailable,
  MdOutlineVideoSettings,
} from "react-icons/md";
//set up your cover photo
import { AiOutlineMenu } from "react-icons/ai";
//update your profile
//create your post//create your business profile
//create a event
//create a group
import Http from "../../api/Http";
import Grid from "@material-ui/core/Grid";
import { BiComment } from "react-icons/bi";
import JobRequest from "./JobRequest";
import contestFill from "../../img/contestFill.png";
import creategroupcolor from "../../img/creategroupcolor.png";
import createPostcolor from "../../img/createPostcolor.png";
import profileColor from "../../img/profileColor.png";
import eyeFill from "../../img/eyeFill.png";
import logoutFill from "../../img/logoutFill.png";
import { storeLoginDetails } from "../../redux/actions/loginDetailsActions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MessagesFileOption from "../../screens/Community/Subjects/MessagesFileOption";
import MyYuukke from "../../api/MyYuukke";
import NotificationSound from "../../img/notification-sound/message_ incoming.mp3";
import { BsCalendar2Check } from "react-icons/bs";
import { IoMdAddCircleOutline } from "react-icons/io";
import ApplicationTour from "./ApplicationTour";
import { triggerTourPopup } from "../../redux/actions/commonApiActions";
import { getAllNotSawAskHelpListCount } from "../../redux/actions/myRequestActions";
import {
  generateMarketPlaceUlr,
  getWorkSpaceOption,
  giveUserAction,
  isYuukkeWorkspace,
  readOnlyWorkspace,
  workSpaceAdmin,
} from "../../utils";
import Joyride from "react-joyride";
import { setIsServiceUser } from "../../redux/actions/loginDetailsActions";

var notificationhit = false;
var InActive = false;
var showNotification = false;
var clickAway = false;
var userTourInNC = false;
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showPlusMenu: false,
      showNotification: false,
      notificationCount: 0,
      notificationList: [],
      deleteNotificationState: false,
      mobileView: false,
      triggerLogout: false,
      showRightModal: false,
      jobRequest: false,
      openLanguageModel: false,
      language: "",
      openWorkSpaceModel: false,
      imageFile: null,
      fileOption: false,
      workSpaceName: "",
      workSpaceDescription: "",
      headerLog: null,
      newWorkSpaceName: "",
      workSpaceId: "",
      flipped: false,
      languageMenuStatus: false,
      beyondWorkspace: false,
      runTour: false,
      isToggleEnabled: false,
    };
    this.audioPlayer = React.createRef();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.showRightModal = this.showRightModal.bind(this);
    this.handleClickAwayRight = this.handleClickAwayRight.bind(this);
    this.showPlusMenu = this.showPlusMenu.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.movetochats = this.movetochats.bind(this);
    this.moveToGetJobRequest = this.moveToGetJobRequest.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.handleClickAwayPlus = this.handleClickAwayPlus.bind(this);
    this.handleClickAwayNotification =
      this.handleClickAwayNotification.bind(this);
    this.notificationCount = this.notificationCount.bind(this);
    this.deleteAllNotification = this.deleteAllNotification.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.showRightModal = this.showRightModal.bind(this);
    this.popupDeleteNotification = this.popupDeleteNotification.bind(this);
    this.zeroNotification = this.zeroNotification.bind(this);
    this.resize = this.resize.bind(this);
    this.getAuthTokenMSToken = this.getAuthTokenMSToken.bind(this);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.movetoaskaquestion = this.movetoaskaquestion.bind(this);
    this.movetocalls = this.movetocalls.bind(this);
    this.ShowJobRequest = this.ShowJobRequest.bind(this);
    this.handleOutFriendRequest = this.handleOutFriendRequest.bind(this);
  }

  async componentDidMount() {
    // if (!this.props.tourStatus) {
    //   //Get otherRequest list
    //   this.props.getAllNotSawAskHelpListCount();
    //   this.props.getTourStatus();
    // }
    if (!this.props.toursStages && this.props.userWorkSpaceList) {
      console.log("Trigger Tour popup  header");
      this.props.triggerTourPopup("header");
      // const check = this.props.userWorkSpaceList.find(
      //   (item) => item?.slug === "beyond-barriers"
      // );
      // console.log("Beyond-check", check);
      // if (check) {
      //   this.setState({
      //     beyondWorkspace: true,
      //   });
      // }
    }

    // this.setState({
    //   isToggleEnabled: this.props.isServiceUser
    // });

    this.setState({
      workSpaceId: this.props.defaultWorkSpaceId,
      runTour: true,
    });
    if (
      this.props.loginDetails?.pLanguage !== null &&
      this.props.loginDetails?.pLanguage !== ""
    ) {
      this.setState({
        language: this.props.loginDetails?.pLanguage,
      });
    } else {
      this.setState({
        language: "English",
      });
    }

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    // this.props.getAllUserPoints();
    try {
      setInterval(async () => {
        if (this.props.authUser?.type && this.props.accessToken) {
          if (this.props.notificationCount > 0) {
            notificationhit = false;
            if (!InActive) {
              this.props.getNotificationList(notificationhit);
            }
          } else {
            notificationhit = true;
            if (!InActive) {
              this.props.getNotificationList(notificationhit);
            }
          }
        }
      }, 30000);
      setInterval(async () => {
        if (this.props.authUser.type) {
          if (!InActive) {
            this.getAuthTokenMSToken();
          }
        }
      }, 270000);
    } catch (e) {
      console.log(e);
    }
  }

  getAuthTokenMSToken() {
    ProfileService.getToken()
      .then((response) => {
        console.log(response.data);
        AuthService.setMSServiceToken(response.data);
      })
      .catch((response) => {
        console.log(response);
      });
  }

  resize() {
    this.setState({ mobileView: window.innerWidth <= 600 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));

    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleToggle = () => {
    const newState = !this.state.isToggleEnabled;
    this.setState({ isToggleEnabled: newState }, () => {
      this.props.setIsServiceUser(newState);

      if (newState) {
        // Start tour when switching to service view
        this.setState({ runTour: true });
        this.props.history.push(`/${this.props.workspaceName}/serviceHomePage`);
      } else {
        this.props.history.push(`/${this.props.workspaceName}/home`);
      }
    });
  };

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      console.log("...inside the wrapper click event");
      this.setState({
        showMenu: false,
        showPlusMenu: false,
        languageMenuStatus: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userWorkSpace &&
      this.props.userWorkSpace !== prevProps.userWorkSpace
    ) {
      this.setState({
        // headerLog: this.props.userWorkSpace.logo
        //   ? `${APPCONFIG.API_URL}auth/image/${this.props.userWorkSpace.logo}`
        //   : logoDark,
      });
    }

    if (
      this.props.notificationList &&
      prevProps.notificationList &&
      prevProps.notificationList.length !== 0 &&
      prevProps.notificationList.length < this.props.notificationList.length
    ) {
      this.audioPlayer.current.play();
    }

    if (this.props.tokenFixed !== prevProps.tokenFixed) {
      if (this.props.tokenFixed) {
        if (this.props.tokenFixed) {
          window.location.reload(true);
          this.props.getTokenFixedStatus(false);
        }
      }
    }
    if (
      this.props.loginDetails.pLanguage !== prevProps.loginDetails.pLanguage
    ) {
      this.setState({
        language: this.props.loginDetails.pLanguage,
      });
    }
    if (this.props.defaultWorkSpaceId !== prevProps.defaultWorkSpaceId) {
      this.setState({
        workSpaceId: this.props.defaultWorkSpaceId,
      });
      // let logo =
      //   this.props.userWorkSpaceList &&
      //   this.props.userWorkSpaceList.filter(
      //     (data) => this.props.defaultWorkSpaceId === data.workSpaceId
      //   );
      // this.setState({
      //   headerLog: logo[0]?.logo
      //     ? `${APPCONFIG.API_URL}auth/image/${logo[0].logo}`
      //     : logoDark,
      // });
    }
  }

  render() {
    const { t } = this.props;
    const { showMenu } = this.state;
    const { showRightModal, languageMenuStatus, language } = this.state;
    const { showPlusMenu } = this.state;
    // console.log('user_details_data', this.props.role)
    // if (this.props.loginDetails?.pLanguage === "English") {
    //   this.props.storePersonalLanguage("en");
    //   AuthService.storeCommonLanguages("en");
    // } else if (this.props.loginDetails?.pLanguage === "French") {
    //   this.props.storePersonalLanguage("fr");
    //   AuthService.storeCommonLanguages("fr");
    // } else if (this.props.loginDetails?.pLanguage === "Luxembourgish") {
    //   this.props.storePersonalLanguage("lx");
    //   AuthService.storeCommonLanguages("lx");
    // } else if (this.props.loginDetails?.pLanguage === "Tamil") {
    //   AuthService.storeCommonLanguages("ta");
    //   this.props.storePersonalLanguage("ta");
    // } else if (this.props.loginDetails?.pLanguage === "Hindi") {
    //   AuthService.storeCommonLanguages("hi");
    //   this.props.storePersonalLanguage("hi");
    // } else if (this.props.loginDetails?.pLanguage === "Spanish") {
    //   AuthService.storeCommonLanguages("sp");
    //   this.props.storePersonalLanguage("sp");
    // } else if (this.props.loginDetails?.pLanguage === "Telugu") {
    //   AuthService.storeCommonLanguages("te");
    //   this.props.storePersonalLanguage("te");
    // } else {
    //   this.props.storePersonalLanguage("en");
    //   AuthService.storeCommonLanguages("en");
    // }
    const data = this.props.loginDetails?.profile_uuid;
    const userName = this.props.loginDetails?.dName;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    //const profileImage = data ? `data:image;base64,${data}` : dummyimage;
    var homedata = "squarepost";
    var mynetworkData = "inviteuser";
    var logoutTitle = t("Are you sure?");
    const logout = (e) => {
      e.preventDefault();
      e.stopPropagation();
      Swal.fire({
        title: logoutTitle,
        text: t("You want to logout?"),
        icon: "warning",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: t("Yes, logout"),
        cancelButtonText: t("No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: t("Logout Successfully"),
            icon: "info",
            type: "info",
            showConfirmButton: true,
          }).then((result) => {
            AuthService.removeAuth();
            localStorage.removeItem("R_TOKEN");
            this.props.getLogoutData();
            if (Http.isProfileExists("keycloak")) {
              AuthService.triggerLogout();
            }
            //this.props.history.push(`/`)
          });
        }
      });
    };

    const TOUR_STEPS = [
      {
        target: ".service-toggle-button",
        content: t("Use this button to switch to My Business Page"),
        disableBeacon: true,
      }
    ];

    const CustomTooltip = ({ step, tooltipProps }) => (
      <div
        {...tooltipProps}
        style={{
          backgroundColor: "#fff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          position: "relative", // Allow absolute positioning inside
        }}
      >
        {/* Small close (×) icon in top-right corner */}
        <button
          onClick={() => this.setState({ runTour: false })}
          style={{
            position: "absolute",
            top: "5px",
            right: "8px",
            background: "none",
            border: "none",
            fontSize: "40px",
            cursor: "pointer",
            color: "#555", // Subtle color
            padding: "1px",
          }}
        >
          ×
        </button>

        <h4>{step.title}</h4>
        <p>{step.content}</p>
      </div>
    );

    const login = () => {
      AuthService.removeAuth();
      this.props.getLogoutData();
      if (Http.isProfileExists("keycloak")) {
        AuthService.triggerLogout();
      }
    };
    return (
      // <header className="headerWrapper">
      <>
        <div className="topbar-wrapper">
          <audio ref={this.audioPlayer} src={NotificationSound} />
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            // timeout={1000 * 5 * 1}
            timeout={1000 * 60 * 3}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
          <ApplicationTour
            openDropDown={this.openDropDown}
            closeTour={this.closeTour}
          />
          {/* <ToastContainer position="top-right" autoClose={5000} /> */}
          <div className="header-menu-new">
            <section className="headerDynamicLogo tour-headerIcon">
              <img
                className="new-desktop-wrapper"
                src={
                  this.props.userWorkSpace?.logo
                    ? `${APPCONFIG.API_URL}auth/image/${this.props.userWorkSpace.logo}`
                    : logoDark
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = logoDark;
                }}
                alt=""
                onClick={() => this.moveToHome()}
                style={{ cursor: "pointer" }}
              />
              {this.props.authenticated && this.props.accessToken ? (
                <AiOutlineMenu
                  className="new-mobileMenu svg-icons-dir mr-auto"
                  onClick={this.showRightModal}
                ></AiOutlineMenu>
              ) : (
                <img
                  className="new-mobileMenu mr-auto h-auto"
                  src={logoDark}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = logoDark;
                  }}
                  alt=""
                  style={{ width: "10rem" }}
                  onClick={
                    this.props.authenticated &&
                      this.props.accessToken &&
                      this.props.refreshToken
                      ? logout
                      : login
                  }
                />
              )}
            </section>
            <section className="d-flex ml-auto">
              {this.props.authenticated && this.props.accessToken && (
                <BiComment
                  className="new-mobileMenu ml-auto"
                  style={{
                    cursor: "pointer",
                    marginTop: "30px",
                    marginBottom: "20px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={this.movetochats}
                />
              )}
              {this.props.userWorkSpaceList &&
                this.props.userWorkSpaceList.length > 1 &&
                getWorkSpaceOption(this.props.menuOptions, "workSpaceMenu") && (
                  <div
                    className={
                      this.state.beyondWorkspace
                        ? "workspace work-space-tour new-desktop-wrapper"
                        : "workspace new-desktop-wrapper"
                    }
                  >
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={this.state.workSpaceId}
                        onChange={this.workSpaceDropDown}
                        label="Age"
                        accent1Color={"blue"}
                      >
                        {this.props.userWorkSpaceList &&
                          this.props.userWorkSpaceList.map((option, index) => (
                            <MenuItem
                              key={option.workSpaceId}
                              value={option.workSpaceId}
                            >
                              {option.workSpaceName === "Default"
                                ? "Yuukke"
                                : option.workSpaceName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              <div
                className="d-flex ml-4 align-items-center"
                style={{ gap: "0.5rem" }}
              >
                {" "}
                {this.props.authenticated &&
                  this.props.accessToken &&
                  this.props.refreshToken && (
                    <p
                      className="new-desktop-wrapper "
                      style={{ cursor: "pointer" }}
                      onClick={this.pointPage}
                    >
                      <div class="waviy">
                        {Array.from("You have").map((item, index) => (
                          <span style={{ "--i": index }} key={index}>
                            {item == " " ? <>&nbsp;</> : item}
                          </span>
                        ))}

                        <span style={{ "--i": 8 }} className="badgesTextPink">
                          {this.props.totalUserPoints
                            ? this.props.totalUserPoints
                            : 0}{" "}
                          &nbsp;{t("points")} !
                        </span>
                      </div>
                    </p>
                  )}{" "}
                {this.props.loginDetails.isServiceUser && (
                  <Tooltip title="Click to switch to Business Page" arrow>
                    <label
                      className="service-toggle-container"
                      style={{ display: "flex", alignItems: "center", gap: "8px", cursor: "pointer" }}
                    >
                      <div className="service-toggle-button">
                        <input
                          type="checkbox"
                          checked={this.state.isToggleEnabled}
                          onChange={this.handleToggle}
                          className="toggle-input"
                        />
                        <span className="toggle-slider">
                          <span className="toggle-circle" />
                        </span>
                      </div>
                    </label>
                  </Tooltip>
                )}


                {/* Joyride Tour */}
                <Joyride
                  steps={TOUR_STEPS}
                  run={this.state.runTour}
                  continuous={true}
                  scrollToFirstStep={false}
                  showSkipButton={false}
                  showCloseButton={true}
                  tooltipComponent={CustomTooltip}
                  styles={{
                    options: {
                      primaryColor: "#4CAF50",
                      zIndex: 10000,
                    },
                    beacon: {
                      display: "none",
                    },
                  }}
                  locale={{
                    last: t("Finish")
                  }}
                  callback={(data) => {
                    if (data.status === "finished" || data.status === "skipped") {
                      this.setState({ runTour: false });
                    }
                  }}
                />
                <div
                  class="selected-lang-option tour-language new-desktop-wrapper ml-2"
                  onClick={() => this.changeLngStatus()}
                >
                  {this.state.language === "Tamil" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}
                      {" தமிழ்"}
                    </p>
                  ) : this.state.language === "Gujarati" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}
                      {" ગુજરાતી"}
                    </p>
                  ) : this.state.language === "Kannadam" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}
                      {" ಕನ್ನಡ"}
                    </p>
                  ) : this.state.language === "Odia" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}
                      {" ଓଡିଆ"}
                    </p>
                  ) : this.state.language === "French" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("FR")}
                      {" Français"}
                    </p>
                  ) : this.state.language === "Hindi" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}
                      {" हिंदी"}
                    </p>
                  ) : this.state.language === "Spanish" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("ES")}
                      {" Española"}
                    </p>
                  ) : this.state.language === "Telugu" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}{" "}
                      {"తెలుగు"}
                    </p>
                  ) : this.state.language === "Bengali" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}{" "}
                      {"বাংলা"}
                    </p>
                  ) : this.state.language === "Arabic" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("SA")}{" "}
                      {"عربي"}
                    </p>
                  ) : this.state.language === "Assamese" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}{" "}
                      {"Assamese"}
                    </p>
                  ) : this.state.language === "Kashmiri" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("IN")}{" "}
                      {"کٲشُر"}
                    </p>
                  ) : this.state.language === "Mandarin" ? (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches &&
                        this.getFlagEmoji("CN")}{" "}
                      {"普通话"}
                    </p>
                  ) : (
                    <p>
                      {!window.matchMedia("(max-width:738px)").matches && (
                        <span>&#127760;</span>
                      )}
                      {" English"}
                    </p>
                  )}

                  {/* {languageMenuStatus && (
                    <div class="lang-card" ref={this.wrapperRef}>
                      <div class="lang-card-overflow">
                        <div
                          className={language === "English" ? "bg-color" : ""}
                          onClick={(e) =>
                            this.handleChangeLanguage(e, "English")
                          }
                        >
                          <p>
                            <span>&#127760; </span>
                            {" English"}
                          </p>{" "}
                        </div>
                        <hr class="m-0" />
                        <div
                          className={language === "Tamil" ? "bg-color" : ""}
                          onClick={(e) => this.handleChangeLanguage(e, "Tamil")}
                        >
                          <p>
                            {" "}
                            {this.getFlagEmoji("IN")}
                            {" தமிழ்"}
                          </p>{" "}
                        </div>
                        <hr class="m-0" />
                        <div
                          className={language === "Gujarati" ? "bg-color" : ""}
                          onClick={(e) =>
                            this.handleChangeLanguage(e, "Gujarati")
                          }
                        >
                          <p>
                            {" "}
                            {this.getFlagEmoji("IN")}
                            {" ગુજરાતી"}
                          </p>{" "}
                        </div>
                        <hr class="m-0" />
                        <div
                          className={language === "Kannadam" ? "bg-color" : ""}
                          onClick={(e) =>
                            this.handleChangeLanguage(e, "Kannadam")
                          }
                        >
                          <p>
                            {" "}
                            {this.getFlagEmoji("IN")}
                            {" ಕನ್ನಡ"}
                          </p>{" "}
                        </div>
                        <div
                          className={language === "Odia" ? "bg-color" : ""}
                          onClick={(e) => this.handleChangeLanguage(e, "Odia")}
                        >
                          <p>
                            {" "}
                            {this.getFlagEmoji("IN")}
                            {" ଓଡିଆ"}
                          </p>{" "}
                        </div>

                        <hr class="m-0" />
                        <div
                          className={language === "HIndi" ? "bg-color" : ""}
                          onClick={(e) => this.handleChangeLanguage(e, "Hindi")}
                        >
                          <p>
                            {this.getFlagEmoji("IN")}
                            {" हिंदी"}
                          </p>{" "}
                        </div>
                        <hr class="m-0" />
                        <div
                          className={language === "Telugu" ? "bg-color" : ""}
                          onClick={(e) =>
                            this.handleChangeLanguage(e, "Telugu")
                          }
                        >
                          <p>
                            {this.getFlagEmoji("IN")}
                            {" తెలుగు"}
                          </p>{" "}
                        </div>
                        <hr class="m-0" />
                        <div
                          className={language === "French" ? "bg-color" : ""}
                          onClick={(e) =>
                            this.handleChangeLanguage(e, "French")
                          }
                        >
                          <p>
                            {this.getFlagEmoji("FR")}
                            {" Français"}
                          </p>{" "}
                        </div>
                        <hr class="m-0" />
                        <div
                          className={language === "Spanish" ? "bg-color" : ""}
                          onClick={(e) =>
                            this.handleChangeLanguage(e, "Spanish")
                          }
                        >
                          <p>
                            {this.getFlagEmoji("ES")}
                            {" Española"}
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                {this.props.authenticated &&
                  this.props.accessToken &&
                  this.props.refreshToken && (
                    <>
                      <ClickAwayListener
                        onClickAway={this.handleClickAwayNotification}
                      >
                        <div
                          style={{
                            position: "relative",
                            marginLeft: "0px",
                            margin: "8px",
                          }}
                          className="notifictionMainIconWrapper tour-notification ml-2"
                        >
                          <Tooltip title="Notifications" placement="bottom">
                            <div
                              className="headerIcon "
                              onClick={this.showNotification}
                            >
                              <>
                                <NotificationsNoneIcon
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                  }}
                                />

                                {this.props.notificationCount > 0 && (
                                  <p className="notification-puls">
                                    {this.props.notificationCount}
                                  </p>
                                )}
                              </>
                            </div>
                          </Tooltip>
                          {showNotification && (
                            <Notifications
                              showNotification={this.showNotification}
                              zeroNotifications={this.zeroNotification}
                              deletesingleNotifications={
                                this.deletesingleNotifications
                              }
                              deleteNotifications={this.deleteNotification}
                              notificationCounts={this.notificationCount}
                              handleAway={this.showNotification}
                            />
                          )}
                        </div>
                      </ClickAwayListener>
                      <Tooltip title="Create" placement="bottom">
                        <div className="tour-create">
                          <IoMdAddCircleOutline
                            style={{
                              cursor: "pointer",
                              width: "26px",
                              height: "25px",
                              marginTop: "8px",
                            }}
                            onClick={this.showPlusMenu}
                          />
                        </div>
                      </Tooltip>

                      {this.props.marketPlace &&
                        getWorkSpaceOption(
                          this.props.menuOptions,
                          "marketPlaceMenu"
                        ) && (
                          <Tooltip
                            title="Go to Market Place"
                            placement="bottom"
                          >
                            <div className="new-desktop-wrapper  tour-marketPlace">
                              <AiOutlineShoppingCart
                                style={{
                                  cursor: "pointer",
                                  marginTop: "8px",
                                  // marginBottom: "20px",
                                  // marginLeft: "20px",
                                  width: "26px",
                                  height: "26px",
                                }}
                                onClick={this.movetoMarket}
                              />
                            </div>
                          </Tooltip>
                        )}
                      <Tooltip title="Calender" placement="bottom">
                        <div className="new-desktop-wrapper tour-calender ml-2">
                          <BsCalendar2Check
                            style={{
                              cursor: "pointer",
                              marginTop: "8px",
                              // marginBottom: "20px",
                              // marginLeft: "20px",
                              width: "23px",
                              height: "20px",
                            }}
                            onClick={this.movetoCalender}
                          />
                        </div>
                      </Tooltip>
                    </>
                  )}
                {showPlusMenu ? (
                  <div className="dropdonwCard" ref={this.wrapperRef}>
                    <ul>
                      {!readOnlyWorkspace(
                        this.props.userWorkSpace?.role,
                        this.props.userWorkSpace?.typeHandle
                      ) && (
                          <li className="tour-crate-post">
                            <NavLink
                              to={`/${this.props.workspaceName}/yuukke-home/${homedata}`}
                              onClick={() => this.handleClickAwayPlus(0)}
                              ref={this.wrapperRef}
                            >
                              <div
                                className="dropdownIcon tour-profile"
                                style={{ marginRight: "5px" }}
                              >
                                <img
                                  src={createPostcolor}
                                  style={{
                                    marginRight: "4px",
                                    width: "32px",
                                    height: "26px",
                                  }}
                                />
                              </div>
                              <p>{t("Create a post")}</p>
                            </NavLink>
                          </li>
                        )}

                      <li
                        className="tour-invite-user d-flex align-items-center"
                        onClick={() => this.handleClickAwayPlus(1)}
                        style={{ cursor: "pointer" }}
                      >
                        <a ref={this.wrapperRef}>
                          <div className="dropdownIcon">
                            <img
                              src={contestFill}
                              style={{
                                marginRight: "12px",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          </div>

                          <p>{t("Invite user")}</p>
                        </a>
                      </li>
                      {!readOnlyWorkspace(
                        this.props.userWorkSpace?.role,
                        this.props.userWorkSpace?.typeHandle
                      ) &&
                        workSpaceAdmin(this.props.userWorkSpace?.role) && (
                          <li className="tour-create-community">
                            <NavLink
                              onClick={() => this.handleClickAwayPlus(6)}
                              to={`/${this.props.workspaceName}/createGroup`}
                              // to="/createGroup"
                              ref={this.wrapperRef}
                            >
                              <div className="dropdownIcon">
                                <img
                                  src={creategroupcolor}
                                  style={{
                                    marginRight: "8px",
                                    width: "30px",
                                    height: "28px",
                                  }}
                                />
                              </div>
                              <p>{t("Create Community")}</p>
                            </NavLink>
                          </li>
                        )}

                      {(this.props.loginUserType === 12 ||
                        this.props.loginUserRole !== 0) && (
                          <li>
                            <NavLink
                              onClick={() => this.handleClickAwayPlus(2)}
                              to={`/${this.props.workspaceName
                                }/event-form/${"Event"}/${"New"}`}
                              // to="/createGroup"
                              ref={this.wrapperRef}
                            >
                              <div className="dropdownIcon">
                                <MdOutlineEventAvailable
                                  style={{
                                    width: "28px",
                                    height: "29px",
                                    marginRight: "4px",
                                  }}
                                />
                              </div>
                              <p>{t("Create Event")}</p>
                            </NavLink>
                          </li>
                        )}

                      {/* {profiles.includes("events") && (
                        <li>
                          <NavLink
                            onClick={this.handleClickAwayPlus}
                            to="/createEvent"
                            ref={this.wrapperRef}
                          >
                            <div className="dropdownIcon">
                              <IoCreateOutline />
                            </div>
                            <p>{t("Create a event")}</p>
                          </NavLink>
                        </li>
                      )} */}
                    </ul>
                  </div>
                ) : null}
                <Tooltip
                  title={!this.props.authenticated ? "Login" : "Profile"}
                  placement="bottom"
                >
                  <div
                    className="tour-profile headerUserProfile flexDefault flexAlignCenter"
                    onClick={this.showMenu}
                  >
                    {!this.props.authenticated && this.props.renderComponent ? (
                      <NavLink
                        to="#"
                        onClick={
                          this.props.authenticated &&
                            this.props.accessToken &&
                            this.props.refreshToken
                            ? logout
                            : login
                        }
                        ref={this.wrapperRef}
                      >
                        <button>{t("Login")}</button>
                      </NavLink>
                    ) : (
                      <UserImg
                        size={this.state.mobileView ? "tiny" : "small"}
                        imgSrc={profileImage}
                        imgAlt={userName}
                        isShowStatus={true}
                        statusLabel="online"
                        onClickImg={this.onClickImg}
                      />
                    )}
                  </div>
                </Tooltip>
                {showMenu ? (
                  <div className="dropdonwCard w-auto" ref={this.wrapperRef}>
                    <ul>
                      {this.props.authenticated &&
                        this.props.accessToken &&
                        this.props.refreshToken && (
                          <>
                            <li className="tour-profile-up">
                              <NavLink
                                onClick={this.handleClickAway}
                                ref={this.wrapperRef}
                                to="/my-profile"
                              >
                                <div className="dropdownIcon">
                                  <img
                                    src={profileColor}
                                    style={{
                                      marginRight: "8px",
                                      width: "33px",
                                      height: "33px",
                                    }}
                                  />
                                </div>
                                <p>{t("Profile")}</p>
                              </NavLink>
                            </li>
                            {isYuukkeWorkspace() && (
                              <li className="">
                                <NavLink
                                  onClick={this.handleClickAway}
                                  ref={this.wrapperRef}
                                  to="/my-profile/service"
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <div className="dropdownIcon">
                                    <MdOutlineVideoSettings />
                                  </div>
                                  <p style={{ paddingLeft: "4px" }}>
                                    {t("Manage Your Service")}
                                  </p>
                                </NavLink>
                              </li>
                            )}
                            <li className="tour-profile-visibility">
                              <NavLink
                                onClick={this.handleClickAway}
                                to={`/${this.props.workspaceName}/privacySettings`}
                                ref={this.wrapperRef}
                              >
                                <div className="dropdownIcon">
                                  <img
                                    src={eyeFill}
                                    style={{
                                      marginRight: "8px",
                                      width: "36px",
                                      height: "19px",
                                    }}
                                  />
                                </div>
                                <p>{t("Visibility")}</p>
                              </NavLink>
                            </li>
                          </>
                        )}
                      {!AuthService.isMSTeamsApp() && (
                        <li>
                          <NavLink
                            to="#"
                            onClick={
                              this.props.authenticated &&
                                this.props.accessToken &&
                                this.props.refreshToken
                                ? logout
                                : login
                            }
                            ref={this.wrapperRef}
                          >
                            <div className="dropdownIcon">
                              <img
                                src={logoutFill}
                                style={{
                                  // marginRight: "8px",
                                  width: "34px",
                                  height: "28px",
                                }}
                              />
                            </div>
                            <p>
                              {this.props.authenticated &&
                                this.props.accessToken &&
                                this.props.refreshToken ? (
                                <> {t("Logout")}</>
                              ) : (
                                <> Login</>
                              )}
                            </p>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : null}
              </div>
            </section>
          </div>
          {/* <Container>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={3} sm={2} xs={2}>
                    <ApplicationTour
                      openDropDown={this.openDropDown}
                      closeTour={this.closeTour}
                    />
                    <div className="new-desktop-wrapper">
                      {" "}
                      <div
                        to="/"
                        // className="logo"
                        className="headerDynamicLogo tour-headerIcon"
                      >
                        <img
                          src={
                            this.props.userWorkSpace?.logo
                              ? `${APPCONFIG.API_URL}auth/image/${this.props.userWorkSpace.logo}`
                              : logoDark
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = logoDark;
                          }}
                          // class="header-logo-inner"
                          alt="Header Logo"
                          onClick={() => this.moveToHome()}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div
                      className="new-mobileMenu"
                      style={{
                        marginTop: "30px",
                        marginBottom: "20px",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <div>
                        {this.props.authenticated && (
                          <AiOutlineMenu
                            className="svg-icons-dir"
                            onClick={this.showRightModal}
                          ></AiOutlineMenu>
                        )}
                        {!this.props.authenticated &&
                          this.props.renderComponent && (
                            <div style={{ width: "12rem" }}>
                              <img
                                src={
                                  this.props.userWorkSpace?.logo
                                    ? `${APPCONFIG.API_URL}auth/image/${this.props.userWorkSpace.logo}`
                                    : logoDark
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = logoDark;
                                }}
                                // class="header-logo-inner"
                                alt="Header Logo"
                                onClick={() => this.moveToHome()}

                                // onClick={() => window.open(APPCONFIG.BASE_URL)}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="new-desktop-wrapper"
                  >
                    {this.props.userWorkSpaceList &&
                      this.props.userWorkSpaceList.length > 1 && (
                        <div
                          className={
                            this.state.beyondWorkspace
                              ? "workspace work-space-tour"
                              : "workspace"
                          }
                        >
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={this.state.workSpaceId}
                              onChange={this.workSpaceDropDown}
                              label="Age"
                              accent1Color={"blue"}
                            >
                              
                              {this.props.userWorkSpaceList &&
                                this.props.userWorkSpaceList.map(
                                  (option, index) => (
                                    <MenuItem
                                      key={option.workSpaceId}
                                      value={option.workSpaceId}
                                    >
                                      {option.workSpaceName === "Default"
                                        ? "Yuukke"
                                        : option.workSpaceName}
                                    </MenuItem>
                                  )
                                )}
                              
                            </Select>
                          </FormControl>
                        </div>
                      )}
                  </Grid>
                  <Grid item lg={4} md={2} sm={2} xs={8}>
                     

                    <div className="new-desktop-wrapper">
                      {" "}
                      {this.props.authenticated &&
                        this.props.accessToken &&
                        this.props.refreshToken && (
                          <p
                            className="badgesTextNormal"
                            style={{ float: "right", cursor: "pointer" }}
                            onClick={this.pointPage}
                          >
                            <div class="waviy">
                              {Array.from("You have").map((item, index) => (
                                <span style={{ "--i": index }}>
                                  {item == " " ? <>&nbsp;</> : item}
                                </span>
                              ))}

                              <span
                                style={{ "--i": 8 }}
                                className="badgesTextPink"
                              >
                                {this.props.totalUserPoints
                                  ? this.props.totalUserPoints
                                  : 0}{" "}
                                &nbsp;{t("points")} !
                              </span>
                            </div>
                           </p>
                        )}{" "}
                    </div>

                   
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={5}
                    sm={6}
                    xs={2}
                    className="relative-top-38"
                    // style={{ Leaderboardfloat: "right" }}
                  >
                    <div className="selected-lang">
                      <div>
                        {this.props.authenticated &&
                          window.matchMedia("(max-width:738px)").matches && (
                            <>
                              <BiComment
                                style={{
                                  cursor: "pointer",
                                  marginTop: "30px",
                                  marginBottom: "20px",
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                  width: "25px",
                                  height: "25px",
                                }}
                                onClick={this.movetochats}
                              />
                            </>
                          )}
                      </div>
                      <div
                        class="selected-lang-option tour-language new-desktop-wrapper"
                        onClick={this.changeLngStatus}
                      >
                        {this.state.language === "Tamil" ? (
                          <p>
                            {!window.matchMedia("(max-width:738px)").matches &&
                              this.getFlagEmoji("IN")}
                            {" தமிழ்"}
                          </p>
                        ) : this.state.language === "French" ? (
                          <p>
                            {!window.matchMedia("(max-width:738px)").matches &&
                              this.getFlagEmoji("FR")}
                            {" Français"}
                          </p>
                        ) : this.state.language === "Hindi" ? (
                          <p>
                            {!window.matchMedia("(max-width:738px)").matches &&
                              this.getFlagEmoji("IN")}
                            {" हिंदी"}
                          </p>
                        ) : this.state.language === "Spanish" ? (
                          <p>
                            {!window.matchMedia("(max-width:738px)").matches &&
                              this.getFlagEmoji("ES")}
                            {" Española"}
                          </p>
                        ) : this.state.language === "Telugu" ? (
                          <p>
                            {!window.matchMedia("(max-width:738px)").matches &&
                              this.getFlagEmoji("IN")}{" "}
                            {"తెలుగు"}
                          </p>
                        ) : (
                          <p>
                            {!window.matchMedia("(max-width:738px)")
                              .matches && <span>&#127760;</span>}
                            {" English"}
                          </p>
                        )}

                        {languageMenuStatus && (
                          <div class="lang-card" ref={this.wrapperRef}>
                            <div class="lang-card-overflow">
                              <div
                                className={
                                  language === "English" ? "bg-color" : ""
                                }
                                onClick={(e) =>
                                  this.handleChangeLanguage(e, "English")
                                }
                              >
                                <p>
                                  <span>&#127760; </span>
                                  {" English"}
                                </p>{" "}
                              </div>
                              <hr class="m-0" />
                              <div
                                className={
                                  language === "Tamil" ? "bg-color" : ""
                                }
                                onClick={(e) =>
                                  this.handleChangeLanguage(e, "Tamil")
                                }
                              >
                                <p>
                                  {" "}
                                  {this.getFlagEmoji("IN")}
                                  {" தமிழ்"}
                                </p>{" "}
                              </div>

                              <hr class="m-0" />
                              <div
                                className={
                                  language === "HIndi" ? "bg-color" : ""
                                }
                                onClick={(e) =>
                                  this.handleChangeLanguage(e, "Hindi")
                                }
                              >
                                <p>
                                  {this.getFlagEmoji("IN")}
                                  {" हिंदी"}
                                </p>{" "}
                              </div>
                              <hr class="m-0" />
                              <div
                                className={
                                  language === "Telugu" ? "bg-color" : ""
                                }
                                onClick={(e) =>
                                  this.handleChangeLanguage(e, "Telugu")
                                }
                              >
                                <p>
                                  {this.getFlagEmoji("IN")}
                                  {" తెలుగు"}
                                </p>{" "}
                              </div>
                              <hr class="m-0" />
                              <div
                                className={
                                  language === "French" ? "bg-color" : ""
                                }
                                onClick={(e) =>
                                  this.handleChangeLanguage(e, "French")
                                }
                              >
                                <p>
                                  {this.getFlagEmoji("FR")}
                                  {" Français"}
                                </p>{" "}
                              </div>
                              <hr class="m-0" />
                              <div
                                className={
                                  language === "Spanish" ? "bg-color" : ""
                                }
                                onClick={(e) =>
                                  this.handleChangeLanguage(e, "Spanish")
                                }
                              >
                                <p>
                                  {this.getFlagEmoji("ES")}
                                  {" Española"}
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {this.props.authenticated &&
                        this.props.accessToken &&
                        this.props.refreshToken && (
                          <>
                            <ClickAwayListener
                              onClickAway={this.handleClickAwayNotification}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  marginLeft: "0px",
                                  margin: "8px",
                                }}
                                className="notifictionMainIconWrapper tour-notification"
                              >
                                <Tooltip
                                  title="Notifications"
                                  placement="bottom"
                                >
                                  <div
                                    className="headerIcon "
                                    onClick={this.showNotification}
                                  >
                                    <>
                                      <NotificationsNoneIcon
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />

                                      <p>{this.props.notificationCount}</p>
                                    </>
                                  </div>
                                </Tooltip>
                                {showNotification && (
                                  <Notifications
                                    showNotification={this.showNotification}
                                    zeroNotifications={this.zeroNotification}
                                    deletesingleNotifications={
                                      this.deletesingleNotifications
                                    }
                                    deleteNotifications={
                                      this.deleteNotification
                                    }
                                    notificationCounts={this.notificationCount}
                                    handleAway={this.showNotification}
                                  />
                                )}
                              </div>
                            </ClickAwayListener>
                            <Tooltip title="Create" placement="bottom">
                              <div className="tour-create">
                                <IoMdAddCircleOutline
                                  style={{
                                    cursor: "pointer",
                                    width: "26px",
                                    height: "25px",
                                    marginTop: "8px",
                                  }}
                                  onClick={this.showPlusMenu}
                                />
                              </div>
                            </Tooltip>
                             
                            {this.props.marketPlace && (
                              <Tooltip
                                title="Go to Market Place"
                                placement="bottom"
                              >
                                <div className="new-desktop-wrapper  tour-marketPlace">
                                  <AiOutlineShoppingCart
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "8px",
                                      // marginBottom: "20px",
                                      // marginLeft: "20px",
                                      width: "26px",
                                      height: "26px",
                                    }}
                                    onClick={this.movetoMarket}
                                  />
                                </div>
                              </Tooltip>
                            )}
                            <Tooltip title="Calender" placement="bottom">
                              <div className="new-desktop-wrapper tour-calender">
                                <BsCalendar2Check
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "8px",
                                    // marginBottom: "20px",
                                    // marginLeft: "20px",
                                    width: "23px",
                                    height: "20px",
                                  }}
                                  onClick={this.movetoCalender}
                                />
                              </div>
                            </Tooltip>
                          </>
                        )}

                      {showPlusMenu ? (
                        <div className="dropdonwCard" ref={this.wrapperRef}>
                          <ul>
                            {!readOnlyWorkspace(
                              this.props.userWorkSpace?.role,
                              this.props.userWorkSpace?.typeHandle
                            ) && (
                              <li className="tour-crate-post">
                                <NavLink
                                  to={`/${this.props.workspaceName}/yuukke-home/${homedata}`}
                                  onClick={() => this.handleClickAwayPlus(0)}
                                  ref={this.wrapperRef}
                                >
                                  <div className="dropdownIcon tour-profile">
                                    <img
                                      src={createPostcolor}
                                      style={{
                                        marginRight: "8px",
                                        width: "32px",
                                        height: "26px",
                                      }}
                                    />
                                   
                                  </div>
                                  <p>{t("Create a post")}</p>
                                </NavLink>
                              </li>
                            )}

                            <li
                              className="tour-invite-user d-flex align-items-center"
                              onClick={() => this.handleClickAwayPlus(1)}
                              style={{ cursor: "pointer" }}
                            >
                              <a ref={this.wrapperRef}>
                                <div className="dropdownIcon">
                                  <img
                                    src={contestFill}
                                    style={{
                                      marginRight: "12px",
                                      width: "36px",
                                      height: "36px",
                                    }}
                                  />
                                  
                                </div>

                                <p>{t("Invite user")}</p>
                              </a>
                            </li>
                            {!readOnlyWorkspace(
                              this.props.userWorkSpace?.role,
                              this.props.userWorkSpace?.typeHandle
                            ) && (
                              <li className="tour-create-community">
                                <NavLink
                                  onClick={() => this.handleClickAwayPlus(6)}
                                  to={`/${this.props.workspaceName}/createGroup`}
                                  // to="/createGroup"
                                  ref={this.wrapperRef}
                                >
                                  <div className="dropdownIcon">
                                    <img
                                      src={creategroupcolor}
                                      style={{
                                        marginRight: "8px",
                                        width: "30px",
                                        height: "28px",
                                      }}
                                    />
                                    
                                  </div>
                                  <p>{t("Create Community")}</p>
                                </NavLink>
                              </li>
                            )}

                            {(this.props.loginUserType === 12 ||
                              this.props.loginUserRole !== 0) && (
                              <li>
                                <NavLink
                                  onClick={() => this.handleClickAwayPlus(2)}
                                  to={`/${
                                    this.props.workspaceName
                                  }/event-form/${"Event"}/${"New"}`}
                                  // to="/createGroup"
                                  ref={this.wrapperRef}
                                >
                                  <div className="dropdownIcon">
                                    <MdOutlineEventAvailable
                                      style={{
                                        width: "28px",
                                        height: "29px",
                                        marginRight: "4px",
                                      }}
                                    />
                                  </div>
                                  <p>{t("Create Event")}</p>
                                </NavLink>
                              </li>
                            )}

                            {profiles.includes("events") && (
                              <li>
                                <NavLink
                                  onClick={this.handleClickAwayPlus}
                                  to="/createEvent"
                                  ref={this.wrapperRef}
                                >
                                  <div className="dropdownIcon">
                                    <IoCreateOutline />
                                  </div>
                                  <p>{t("Create a event")}</p>
                                </NavLink>
                              </li>
                            )}
                          </ul>
                        </div>
                      ) : null}
                      <Tooltip
                        title={!this.props.authenticated ? "Login" : "Profile"}
                        placement="bottom"
                      >
                        <div
                          className="tour-profile headerUserProfile flexDefault flexAlignCenter"
                          onClick={this.showMenu}
                        >
                          {!this.props.authenticated &&
                          this.props.renderComponent ? (
                            <NavLink
                              to="#"
                              onClick={
                                this.props.authenticated &&
                                this.props.accessToken &&
                                this.props.refreshToken
                                  ? logout
                                  : login
                              }
                              ref={this.wrapperRef}
                            >
                              <button>{t("Login")}</button>
                            </NavLink>
                          ) : (
                            <UserImg
                              size={this.state.mobileView ? "tiny" : "small"}
                              imgSrc={profileImage}
                              imgAlt={userName}
                              isShowStatus={true}
                              statusLabel="online"
                              onClickImg={this.onClickImg}
                            />
                          )}
                        </div>
                      </Tooltip>
                      {showMenu ? (
                        <div className="dropdonwCard" ref={this.wrapperRef}>
                          <ul>
                            {this.props.authenticated &&
                              this.props.accessToken &&
                              this.props.refreshToken && (
                                <>
                                  <li className="tour-profile-up">
                                    <NavLink
                                      onClick={this.handleClickAway}
                                      ref={this.wrapperRef}
                                      to="/my-profile"
                                    >
                                      <div className="dropdownIcon">
                                        <img
                                          src={profileColor}
                                          style={{
                                            marginRight: "8px",
                                            width: "33px",
                                            height: "33px",
                                          }}
                                        />
                                       </div>
                                      <p>{t("Profile")}</p>
                                    </NavLink>
                                  </li>
                                  <li className="tour-profile-visibility">
                                    <NavLink
                                      onClick={this.handleClickAway}
                                      to={`/${this.props.workspaceName}/privacySettings`}
                                      ref={this.wrapperRef}
                                    >
                                      <div className="dropdownIcon">
                                        <img
                                          src={eyeFill}
                                          style={{
                                            marginRight: "8px",
                                            width: "36px",
                                            height: "19px",
                                          }}
                                        />
                                       </div>
                                      <p>{t("Visibility")}</p>
                                    </NavLink>
                                  </li>
                                </>
                              )}
                            {!AuthService.isMSTeamsApp() && (
                              <li>
                                <NavLink
                                  to="#"
                                  onClick={
                                    this.props.authenticated &&
                                    this.props.accessToken &&
                                    this.props.refreshToken
                                      ? logout
                                      : login
                                  }
                                  ref={this.wrapperRef}
                                >
                                  <div className="dropdownIcon">
                                    <img
                                      src={logoutFill}
                                      style={{
                                        // marginRight: "8px",
                                        width: "34px",
                                        height: "28px",
                                      }}
                                    />
                                    
                                  </div>
                                  <p>
                                    {this.props.authenticated &&
                                    this.props.accessToken &&
                                    this.props.refreshToken ? (
                                      <> {t("Logout")}</>
                                    ) : (
                                      <> Login</>
                                    )}
                                  </p>
                                </NavLink>
                              </li>
                            )}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                   
                </Grid>
              </Container> */}

          {/* {this.props.authenticated &&
                this.props.accessToken &&
                this.props.refreshToken && (
                  <div class="languageInputWrapper desktopMenu d-flex align-items-center justify-content-center">
                    <select
                      name="language"
                      id="language"
                      class="service-small"
                      value={this.state.language}
                      onChange={this.handleChangeLanguage}
                    >
                      <option class="service-small " value="Tamil">
                        🇮🇳{" தமிழ்"}
                      </option>
                      <option class="service-small" value="English">
                        🌎{" English"}
                      </option>
                      <option class="service-small" value="French">
                        🇫🇷{" French"}
                      </option>
                    </select>
                  </div>
                )} */}

          {showRightModal && (
            <div>
              {/* {(window.innerWidth <= 768 || window.innerWidth == 768) && ( */}
              <RightModal
                show={this.state.showRightModal}
                handleClickAway={this.handleClickAwayRight}
                headerLog={`${APPCONFIG.API_URL}auth/image/${this.props.userWorkSpace.logo}`}
                userWorkSpaceList={this.props.userWorkSpaceList}
                workSpaceId={this.state.workSpaceId}
                onChangeStatus={this.workSpaceDropDown}
                marketplaceUrl={this.props.marketplaceUrl}
              ></RightModal>
              {/* )} */}
            </div>
          )}

          {this.state.deleteNotificationState && this.popupDeleteNotification()}
        </div>
        {this.state.jobRequest && (
          <JobRequest
            handleClickAway={this.handleOutFriendRequest}
          ></JobRequest>
        )}
        {this.state.openWorkSpaceModel && this.popUpSelectWorkSpace()}
      </>
    );
  }
  closeTour = () => {
    userTourInNC = false;
    showNotification = false;
    clickAway = true;
    this.setState({
      showPlusMenu: false,
      showMenu: false,
    });
  };

  openDropDown = (tourType) => {
    console.log("Change tour", tourType);
    userTourInNC = false;
    showNotification = false;
    clickAway = true;
    this.setState({
      showPlusMenu: false,
      showMenu: false,
    });
    //5 6
    // if (tourType == 3) {
    //   this.setState({
    //     showNotification: !this.state.showNotification,
    //   });
    //   showNotification = true;
    //   clickAway = false;
    //   userTourInNC = true;
    // } else
    if (tourType == 5 || tourType == 6 || tourType == 7) {
      this.setState({
        showMenu: true,
      });
    }
    // else if (tourType == 11 || tourType == 12) {
    //   this.setState({
    //     showMenu: true,
    //   });
    // }

    //showMenu
  };
  changeLngStatus = () => {
    this.props.history.push(`/${this.props.workspaceName}/user-language`);
    // document.addEventListener("mousedown", this.handleClickOutside);
    // this.setState({
    //   languageMenuStatus: !this.state.languageMenuStatus,
    // });
  };
  workSpaceDropDown = (e) => {
    if (e.target.value === "newWorkSpace") {
      this.setState({
        openWorkSpaceModel: true,
      });
    } else {
      if (!this.props.loginDetails.profile_complete && e.target.value === 0) {
        // this.props.history.push(`/photo-set-up`);
        console.log("N_WORKSPACE_UPDATE");
        sessionStorage.setItem("WORKSPACE", "default");
        this.props.workSpaceUserLogin(0);
        this.props.getAllNotSawAskHelpListCount();
        this.props.getAnnouncementList();
        this.props.getNotificationList(true);

        this.props.history.push(`/${"default"}/home`);
      } else {
        this.setState({
          workSpaceId: e.target.value,
        });
        let data =
          this.props.userWorkSpaceList &&
          this.props.userWorkSpaceList.find(
            (data) => data.workSpaceId === e.target.value
          );
        // let workSpaceName = data[0]?.workSpaceName;
        // var name = e.target.value.trim();
        // var userId = logo[0]?.userId;
        // let workSpaceName = data.workSpaceName.replace(/[, ]+/g, "-").trim();
        sessionStorage.setItem("WORKSPACE", data.slug);

        this.props.workSpaceUserLogin(e.target.value);
        if (data.slug !== "default") {
          this.props.getWorkSpaceOptionTypes();
        }
        this.props.getAllNotSawAskHelpListCount();
        this.props.getAnnouncementList();
        this.props.getNotificationList(true);

        // this.props.getWorkSpaceType(e.target.value, role, userId);
        // this.props.getUserWorkSpaceList();
        // this.props.getNavigationType(0);

        this.props.history.push(`/${data.slug}/home`);
        this.props.getNavigationType(0);
        this.props.getAllUserCommunityList();

        // const currentUrl = new URL(window.location.href)
        // currentUrl.searchParams.set('refresh', Date.now())
        // window.location.href = currentUrl.href
        // window.location.reload(true)
      }
    }
  };
  moveToHome = () => {
    const workspaceName = this.props.workspaceName
      ? this.props.workspaceName
      : "default";
    console.log("workspaceName", workspaceName);
    this.props.history.push(`/${workspaceName}/home`);
  };
  getFlagEmoji = (countryCode) => {
    return countryCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(127397 + char.charCodeAt())
      );
  };
  pointPage = () => {
    this.props.history.push(`/${this.props.workspaceName}/badges`);
  };
  createWorkSpaceModel = () => {
    this.setState({
      openWorkSpaceModel: !this.state.openWorkSpaceModel,
    });
  };

  savePrefer = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    if (event === "") {
      toast.warning("Please select new Language");
    } else {
      if (this.props.authenticated) {
        const formData = new FormData();
        formData.append("personalLanguage", value);

        const config = { headers: { "content-type": "multipart/form-data" } };
        ProfileService.userPersonalLanguageSave(formData, config)
          .then((response) => {
            this.setState({
              openLanguageModel: false,
              language: value,
            });

            var result = response.data;
            // toast.success("Saved successfully");
            AuthService.registerAuth("", result.data);

            this.props.storeLoginDetails(
              result.data,
              this.props.authUser.loginType
            );
            this.changeLanguage(result.data.pLanguage);
          })
          .catch((error) => {
            this.setState({
              openLanguageModel: false,
            });
            console.log(error);
          });
      } else {
        this.setState({
          openLanguageModel: false,
          language: value,
        });
        this.changeLanguage(value);
      }
    }
  };

  changeLanguage = (lang) => {
    i18n.changeLanguage(lang);

    if (lang === "English") {
      AuthService.storeCommonLanguages("en");
      this.props.storePersonalLanguage("en");
      //  this.props.getAllCommonSubjectList(false);
      // this.props.getAllCommonCurriculumTrackList(false);

      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "French") {
      AuthService.storeCommonLanguages("fr");
      this.props.storePersonalLanguage("fr");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Luxembourgish") {
      AuthService.storeCommonLanguages("lx");
      this.props.storePersonalLanguage("lx");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Tamil") {
      AuthService.storeCommonLanguages("ta");
      this.props.storePersonalLanguage("ta");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Gujarati") {
      AuthService.storeCommonLanguages("gu");
      this.props.storePersonalLanguage("gu");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Kannadam") {
      AuthService.storeCommonLanguages("ka");
      this.props.storePersonalLanguage("ka");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Odia") {
      AuthService.storeCommonLanguages("od");
      this.props.storePersonalLanguage("od");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Hindi") {
      AuthService.storeCommonLanguages("hi");
      this.props.storePersonalLanguage("hi");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Spanish") {
      AuthService.storeCommonLanguages("sp");
      this.props.storePersonalLanguage("sp");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else if (lang === "Telugu") {
      AuthService.storeCommonLanguages("te");
      this.props.storePersonalLanguage("te");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    } else {
      AuthService.storeCommonLanguages("en");
      this.props.storePersonalLanguage("en");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      toast.success(`${this.props.t("Saved successfully")}`);
    }
  };
  handleChangeLanguage = (e, value) => {
    if (this.state.language !== value) {
      this.savePrefer(e, value);
      this.setState({
        languageMenuStatus: false,
      });
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  popUpSelectWorkSpace() {
    const { t } = this.props;
    return (
      <Modal show="true">
        <Form id="profileForm">
          <Modal.Body>
            <Form.Group>
              <Form.Label>{t("Add New Work Space")}</Form.Label>
              <AiOutlineCloseCircle
                onClick={this.createWorkSpaceModel}
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginBottom: "8px",
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                }}
              />
            </Form.Group>
            <Grid container spacing={2} style={{ padding: "1.5rem" }}>
              <Grid item lg={12} md={12}>
                <Form.Label>{t("Work Space Name")}</Form.Label>
                <TextField
                  id="outlined-basic"
                  // label="Add work space Name"
                  name="newWorkSpaceName"
                  value={this.state.newWorkSpaceName}
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Form.Label>{t("WorkSpace Description")}</Form.Label>

                <TextareaAutosize
                  aria-label="empty textarea"
                  name="workSpaceDescription"
                  value={this.state.workSpaceDescription}
                  class="text-area-workSpace"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <Form.Label>{t("Add Work Space Logo")}</Form.Label>
                <div>
                  <MessagesFileOption
                    fileTypeKey={"image"}
                    fileOption={this.state.fileOption}
                    fileOptionEnable={this.fileOptionEnable}
                    fromQuestionImage={this.getQuestionMediaImage}
                    fromQuestionMedia={this.getQuestionMediaFiles}
                    editObject={this.props.editObject}
                    grouporeventFiles={this.state.grouporeventFiles}
                  ></MessagesFileOption>
                </div>
              </Grid>
            </Grid>
          </Modal.Body>

          <Modal.Footer>
            <Grid container spacing={2}>
              {/* <Grid item lg={6} md={6}>
                <Button
                  variant="secondary"
                  style={{ color: '#ffffff', marginRight: '12px' }}
                  onClick={this.createWorkSpaceModel}
                >
                  {t('Close')}
                </Button>
              </Grid>*/}
              <Grid item lg={12} md={12}>
                <Button
                  variant="secondary"
                  style={{
                    color: "#ffffff",
                    marginRight: "12px",
                    float: "right",
                  }}
                  onClick={this.onSaveWorkSpace}
                >
                  {t("Save  ")}
                </Button>
              </Grid>
            </Grid>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  movetoCalender = () => {
    this.props.history.push(`/${this.props.workspaceName}/calendar`);
  };
  movetoMarket = () => {
    // window.open(`${this.props.marketplaceUrl}`, "_blank");
    // const url =
    //   KEY_CLOAK_URL +
    //   "realms/" +
    //   "yuukke" +
    //   "/protocol/openid-connect/auth?client_id=" +
    //   CLIENT_ID +
    //   "&redirect_uri=" +
    //   `${this.props.marketplaceUrl}` +
    //   "&response_mode=fragment&response_type=code&scope=openid&bm_token=" +
    //   this.props.accessToken;
    // console.log("Url With Access Token: ", url);
    // window.open(`${url}`, "_blank");
    generateMarketPlaceUlr(this.props.marketplaceUrl);
  };
  onSaveWorkSpace = () => {
    if (
      this.state.newWorkSpaceName === "" ||
      this.state.workSpaceDescription === ""
    ) {
      toast.warning("Fill all the field");
    } else {
      const formData = new FormData();
      formData.append("workSpaceName", this.state.newWorkSpaceName.trim());
      formData.append("description", this.state.workSpaceDescription.trim());
      if (this.state.imageFile !== null) {
        formData.append("logo", this.state.imageFile[0]);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      MyYuukke.work_space_save_or_update(formData, config)
        .then((response) => {
          if (response.data.status === -1) {
            toast.warning("This workspace name already exists");
          } else {
            this.setState({
              workSpaceDescription: "",
              newWorkSpaceName: "",
              imageFile: null,
              openWorkSpaceModel: false,
            });
            this.props.getUserWorkSpaceList();
            toast.success("WorkSpace Created Successfully");
          }
        })
        .catch((e) => {
          toast.error("Saving Error");
          this.setState({ openWorkSpaceModel: false });
          console.log("error", e);
        });
    }
  };
  fileOptionEnable = () => {
    this.setState({
      imageFile: null,
    });
  };
  getQuestionMediaImage = (files, type) => {
    this.setState({
      imageFile: files,
      type: type,
    });
  };
  getQuestionMediaFiles = (files, type) => {
    this.setState({
      imageFile: files,
      type: type,
    });
  };
  handleOutFriendRequest() {
    this.setState({ jobRequest: false });
  }

  handleClickAwayRight() {
    this.setState({ showRightModal: false });
    // this.props.getNavigationType(6)
  }

  deletesingleNotifications() {
    this.props.getNotificationList();
  }

  zeroNotification(count) {
    if (count) {
      toast.warn("No Notification Available");
      this.setState({
        showNotification: !this.state.showNotification,
      });
      showNotification = showNotification ? false : true;
    }
  }

  showMenu(event) {
    if (this.props.authenticated) {
      document.addEventListener("mousedown", this.handleClickOutside);
      this.setState({
        showMenu: !this.state.showMenu,
      });
    }
  }

  showRightModal(event) {
    this.setState({
      showRightModal: !this.state.showRightModal,
    });
  }

  showPlusMenu(event) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      document.addEventListener("mousedown", this.handleClickOutside);
      this.setState({
        showPlusMenu: !this.state.showPlusMenu,
      });
    }
  }

  showNotification(status, clickAway) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        showNotification: !this.state.showNotification,
      });
      if (status == true && clickAway) {
        showNotification = true;
        clickAway = true;
      } else {
        showNotification = showNotification ? false : true;
        clickAway = false;
      }
    }
  }
  ShowJobRequest() {
    this.setState({ jobRequest: true });
  }
  handleClickAway() {
    this.setState({
      showMenu: false,
    });
    this.props.getNavigationType(6);
  }

  handleClickAwayPlus(type) {
    this.setState({
      showPlusMenu: false,
    });
    if (type === 1) {
      let id = Math.floor(Math.random() * 1000);
      this.props.history.push(`/${this.props.workspaceName}/my-network/${id}`);
    }
    this.props.getNavigationType(type);
  }

  handleClickAwayNotification(status) {
    // if (!userTourInNC) {
    showNotification = false;
    this.setState({
      showNotification: false,
    });
    // }

    //this.props.getNavigationType(6)
  }

  deleteNotification() {
    this.setState({
      deleteNotificationState: true,
    });
    showNotification = false;
  }

  handleClose() {
    this.setState({
      deleteNotificationState: false,
    });
  }

  popupDeleteNotification() {
    return (
      <div>
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>Delete Notification</Modal.Header>
          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.deleteAllNotification}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  Are you sure you want to clear notification ?
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ color: "#ffffff", marginRight: "12px" }}
                onClick={this.handleClose}
              >
                No
              </Button>
              <Button
                type="submit"
                style={{ color: "#ffffff" }}
                variant="primary"
              >
                Yes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  movetochats() {
    this.props.history.push(`/chat-page`);
  }
  moveToGetJobRequest() {
    this.props.history.push(`/get-job-request`);
  }
  deleteAllNotification(e) {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      let getNotification = {};

      ProfileService.deleteAllNotification(getNotification)
        .then((response) => {
          var result = response.data;
          toast.success("Notification Deleted Successully..!");
          this.handleClose();
          this.props.removeNotificationListById(0);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleOnAction(event) {
    if (this.props.accessToken !== "" && this.props.refreshToken !== "") {
      if (sessionStorage.getItem("SESSION") && !this.state.triggerLogout) {
        let session = sessionStorage.getItem("SESSION");
        if (session.toUpperCase() == "X") {
          this.setState({
            triggerLogout: true,
          });

          fetch("/keycloak.json", {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then((response) => {
              console.log(response);
              return response.json();
            })
            .then((myJson) => {
              console.log("my-json-data", myJson.resource);

              if (myJson.resource !== undefined) {
                this.setState({ clientId: myJson.resource });
                console.log("this.state.clientId", this.state.clientId);
                if (this.state.clientId !== undefined) {
                  let refreshReqData = {
                    token: this.props.refreshToken,
                    client_id: this.state.clientId,
                  };
                  console.log("refresh token", refreshReqData);
                  this.props.getRefreshTokenDetails(refreshReqData);
                  this.setState({
                    triggerLogout: false,
                  });
                  console.log(this.props.accessToken);
                  console.log(this.props.tokenFixed);

                  //
                }
              }
            });
        }
      }
    } else {
      /// this.Mixpanelcall();
      AuthService.removeAuth();
      sessionStorage.clear();
      this.props.getLogoutData();
      if (Http.isProfileExists("keycloak")) {
        AuthService.triggerLogout();
      }
    }
  }

  // handleOnAction(event) {
  //   if (sessionStorage.getItem("SESSION") && !this.state.triggerLogout) {
  //     let session = sessionStorage.getItem("SESSION");
  //     if (session.toUpperCase() == "X") {
  //       this.setState({
  //         triggerLogout: true,
  //       });

  //       Swal.fire({
  //         title: "Session Expired",
  //         text: this.props.t("Please re-login to continue"),
  //         icon: "error",
  //         type: "error",
  //         confirmButtonText: this.props.t("OK"),
  //         reverseButtons: true,
  //         allowOutsideClick: false,
  //       }).then((result) => {
  //         if (result.value) {
  //           this.Mixpanelcall();
  //           AuthService.removeAuth();
  //           sessionStorage.clear();
  //           this.props.getLogoutData();
  //           if (Http.isProfileExists("keycloak"))
  //           {
  //             AuthService.triggerLogout();
  //           }
  //         }
  //       });
  //     }
  //   }
  // }

  handleOnActive(event) {
    InActive = false;
    console.log("time remaining", this.idleTimer.getRemainingTime());
    // this.SaveLastLogin();
  }

  handleOnIdle(event) {
    InActive = true;
    console.log("last active", this.idleTimer.getLastActiveTime());
    this.SaveLastLogin();
  }

  SaveLastLogin = () => {
    if (this.props.loginDetails?.sessionId) {
      let user = {
        sessionId: this.props.loginDetails.sessionId,
      };
      ProfileService.SaveLastlogin(user)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  };

  movetoaskaquestion() {
    this.props.history.push(`/ask-a-question`);
  }

  movetocalls() {
    this.props.history.push(`/book-new-call`);
  }

  notificationCount(count) {
    this.setState({
      notificationCount: count,
    });
  }
  onClickImg = () => { };
  // handleOnAction(event) {

  // }
}

const mapStateToProps = function (state) {
  return {
    isServiceUser: state.loginDetailsApi.loginDetails?.isServiceUser,
    notificationList: state.notification.list,
    notificationCount: state.notification.list.length,
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    refreshToken: state.loginDetailsApi.refreshToken,
    accessToken: state.loginDetailsApi.accessToken,
    tokenFixed: state.loginDetailsApi.tokenFixed,
    // totalUserPoints: state.commonApi.totalUserPoints,
    totalUserPoints: state.notification.pointCount,
    authenticated: state.loginDetailsApi.keycloakAuthenticated,
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    // userWorkSpaceList: state.commonApi.userWorkSpaceList,
    workspaceListHeader: state.commonApi.workspaceListHeader,
    userWorkSpaceList: state.loginDetailsApi.loginDetails?.userWorkSpaceList,
    defaultWorkSpaceId:
      state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
    userWorkSpace: state.loginDetailsApi.loginDetails?.userWorkSpace,
    loginUserType: state.loginDetailsApi.loginDetails?.uType
      ? state.loginDetailsApi.loginDetails.uType
      : 0,
    loginUserRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role
      ? state.loginDetailsApi.loginDetails.userWorkSpace.role
      : 0,
    tourStatus: state.commonApi.tourApiCalled,
    toursStages: state.commonApi.headerPopupStatus,
    toursStagesCount: state.commonApi.toursStages?.header?.header,
    renderComponent: state.commonApi.renderComponent,
    marketPlace: state.commonApi.requireMentMenu?.marketplace,
    marketplaceUrl: state.commonApi.requireMentMenu?.marketplaceUrl,
    menuOptions: state.commonApi.getWorkSpaceOption,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    setIsServiceUser: (status) => dispatch(setIsServiceUser(status)),
    getNotificationList: (notificationhit) =>
      dispatch(getNotificationList(notificationhit)),
    removeNotificationListById: () => dispatch(removeNotificationListById(0)),
    getLogoutData: () => dispatch(getLogoutData()),
    getRefreshTokenDetails: (refreshReqData) =>
      dispatch(getRefreshTokenDetails(refreshReqData)),
    setCLientId: (data) => dispatch(setCLientId(data)),
    getTokenFixedStatus: (data) => dispatch(getTokenFixedStatus(data)),
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    getAllUserPoints: () => dispatch(getAllUserPoints()),
    storePersonalLanguage: (language) =>
      dispatch(storePersonalLanguage(language)),
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
    getWorkSpaceType: (type, role, userId) =>
      dispatch(getWorkSpaceType(type, role, userId)),
    getUserWorkSpaceList: () => dispatch(getUserWorkSpaceList()),
    workSpaceUserLogin: (id) => dispatch(workSpaceUserLogin(id)),
    getTourStatus: () => dispatch(getTourStatus()),
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    getAllNotSawAskHelpListCount: () =>
      dispatch(getAllNotSawAskHelpListCount()),
    getWorkSpaceOptionTypes: () => dispatch(getWorkSpaceOptionTypes()),
    getAnnouncementList: () => dispatch(getAnnouncementList()),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(Header)));
