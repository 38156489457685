import React from "react";
import { withTranslation } from "react-i18next";

function Tabs({ t, changeTab, friendsMessage, serviceMessage, activeMenu }) {
  return (
    <div class="d-flex mt-3 gap-1 flex-wrap">
      <button
        class={
          activeMenu === 0 ? "active-menu tab-btn" : "un-active-menu tab-btn"
        }
        onClick={() => changeTab(0)}
      >
        <span> {t("Friends")}</span>
        {friendsMessage != 0 && (
          <div className="notification-puls m-0 ml-2 rounded-notification">
            {friendsMessage}
          </div>
        )}
        {/* )} */}
      </button>
      <button
        class={
          activeMenu === 1 ? "active-menu tab-btn" : "un-active-menu tab-btn"
        }
        onClick={() => changeTab(1)}
      >
        <span>{t("Service")}</span>
        {serviceMessage != 0 && (
          <div className="notification-puls m-0 ml-2 rounded-notification">
            {serviceMessage}
          </div>
        )}
      </button>
    </div>
  );
}

export default React.memo(withTranslation()(Tabs));
