import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../config/app-config";
import calendar from "../../img/calendar.png";
import dummyimage from "../../img/default_avatar.jpg";
import { BiPhone } from "react-icons/bi";
import { MdOutlineMailOutline } from "react-icons/md";
import { toCamelCase } from "../../utils";
import service_default from "../../img/service.jpg";
import Lightbox from "react-image-lightbox";
import woman from "../../img/woman.png";
import StarRating from "../../components/Rating";
import moment from "moment";
import { classNames } from "clsx";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import BlockUi from "react-block-ui";
import Swal from "sweetalert2";
import MyYuukke from "../../api/MyYuukke";
import { toast } from "react-toastify";

const ServiceCardThemeV1 = ({ data, selectService, userUpcomingMeting }) => {
  console.log("service-state", data);
  const [animateIndex, setIndex] = useState(0);
  // const messages = [
  //   "Lorem ipsum dolor sit amet consectetur adipiscing elit convallis cubilia,",
  //   "Dis accumsan condimentum convallis tincidunt iaculis sociis ",
  // ];

  const languages = useRef();
  const detailsPage = useRef();
  const serviceListPage = useRef();
  const [state, setState] = useState({
    serviceGalleryImage: [],
    serviceProviderVideo: [],
    serviceAnnouncement: [],
    cancelMeetingModel: false,
    bookingDetails: null,
    loader: false,
  });
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (data.sliderText.length > 1) {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % data.sliderText.length);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, []);
  useEffect(() => {
    let serviceProviderVideo = [];
    let serviceAnnouncement = [];
    let serviceGalleryImage = [];
    if (data.eventDetails.serviceProviderVideo) {
      serviceProviderVideo = data.eventDetails.serviceProviderVideo.split(",");
    }
    if (data.eventDetails.serviceAnnouncement) {
      serviceAnnouncement = data.eventDetails.serviceAnnouncement
        .split(",")
        .map((item) => `${API_URL}auth/image/${item}`);
    }
    if (data.eventDetails.serviceGalleryImage) {
      serviceGalleryImage = data.eventDetails.serviceGalleryImage
        .split(",")
        .map((item) => `${API_URL}auth/image/${item}`);
    }
    setState((previous) => ({
      ...previous,
      serviceProviderVideo: serviceProviderVideo,
      serviceAnnouncement: serviceAnnouncement,
      serviceGalleryImage: serviceGalleryImage,
    }));
  }, [data]);
  const handleClickEmail = (event, email) => {
    window.location.href = `mailto:${email}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClickPhone = (event, number) => {
    window.location.href = `tel:${number}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const handleLang = () => {
    // Scroll to the next section
    languages.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleDetail = () => {
    // Scroll to the next section
    detailsPage.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const handleServicList = () => {
    // Scroll to the next section
    serviceListPage.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const cancelMeeting = (data) => {
    Swal.fire({
      icon: "warning",
      title: data.payedEmail
        ? "Service Cancellation Policy"
        : "Service Cancellation Confirmation",
      text: data.payedEmail
        ? "You are canceling a paid service. Please note that cancellations made within 24 hours of the scheduled service will not be eligible for a refund. If you proceed with the cancellation, the amount paid will not be refunded."
        : "Are you sure you want to cancel the meeting?",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: data.payedEmail ? "Cancel The Meeting" : "Yes",
      cancelButtonText: data.payedEmail ? "Not Cancel" : "No",
    })
      .then((res) => {
        if (res.value) {
          updateState("loader", true);
          let payload = {
            bookingId: data.bookingId,
          };
          MyYuukke.cancelSlotBooking(payload)
            .then((res) => {
              userUpcomingMeting(data.bookingId);
              Swal.fire({
                icon: "success",
                title: "Meeting Canceled successfully",
                showConfirmButton: true,
              }).then((result) => {
                updateState("loader", false);
              });
            })
            .catch((e) => {
              toast.error("Something went wrong , try later");
              updateState("loader", false);
            });
        }
      })
      .catch((e) => {});
    // setState((previous)=>({...previous,cancelMeetingModel:!state.cancelMeetingModel,bookingDetails:data}))
  };
  return (
    <div className="profile-container">
      {/* Header */}
      <header className="s-header">
        <div className="s-logo">
          {/* <img
            src={calendar}
            alt=""
            style={{ width: "3rem", height: "3rem" }}
          /> */}
          <img
            src={
              data.eventDetails?.businessLogo
                ? `${API_URL}auth/image/${data.eventDetails?.businessLogo}`
                : calendar
            }
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = calendar;
            }}
            style={{ width: "3rem", height: "3rem" }}
          />
        </div>
        <nav>
          <ul className="nav-links m-0">
            <li className="c-pointer" onClick={() => handleLang()}>
              Languages
            </li>
            <li className="c-pointer" onClick={() => handleDetail()}>
              Service Details
            </li>
            <li className="c-pointer" onClick={() => handleServicList()}>
              Service List
            </li>
          </ul>
        </nav>
        <div className="d-flex flex-wrap">
          {/* {data.exitUser && <button className="mr-2">Cancel Booking</button>} */}
          {data.eventDetails.phoneNumberShow && (
            <p
              className="mr-2"
              onClick={() => handleClickPhone()}
              style={{ fontSize: "13px" }}
            >
              {" "}
              <BiPhone /> {data.userDetails?.phoneNumber}
            </p>
          )}
          {data.eventDetails.emailShow && (
            <p onClick={() => handleClickEmail()} style={{ fontSize: "13px" }}>
              {" "}
              <MdOutlineMailOutline /> {data.userDetails?.email}
            </p>
          )}

          {/* <img
            src="https://templates.iqonic.design/calendify/html/assets/images/user/01.jpg"
            alt="Profile"
            className="user-profile"
          /> */}
        </div>
      </header>

      {/* Profile Banner */}
      <BlockUi
        tag="div"
        message="Loading, please wait"
        style={{ minHeight: "134px" }}
        className="wallLoadingPage"
        blocking={state.loader}
      >
        <div className="service-wrap">
          <div className="img-container-v2">
            <div
              key={animateIndex}
              className={`service-animi-text ${
                data.sliderText?.length > 1
                  ? `animated-text-service ${
                      animateIndex % 2 === 0 ? "slide-left" : "slide-right"
                    }`
                  : ""
              }`}
            >
              {" "}
              {data?.sliderText[animateIndex]}
            </div>
          </div>
          <div
            className="service-profile-card-v1"
            style={{ marginTop: "2rem" }}
          >
            <section className="d-flex p-3 flex-column">
              <img
                src={
                  API_URL +
                  "api/getUserPhoto/" +
                  data.userDetails.userId +
                  "/" +
                  "name"
                }
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
                className="h-auto"
                style={{
                  width: "18rem",
                  borderRadius: "8px",
                }}
              />
              <h5
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "10px",
                }}
              >
                {data.userDetails.userName}
              </h5>
            </section>
            <section
              ref={languages}
              className="d-flex flex-column p-3"
              style={{ gap: "12px" }}
            >
              <p>
                {" "}
                <b className="mr-3">Service Name :</b>
                {data.eventDetails?.serviceName}
              </p>

              {data.userDetails?.personalWebsite && (
                <p className="d-flex" style={{ gap: "10px" }}>
                  <b className="mr-3">Personal Website :</b>

                  <p>{data.userDetails?.personalWebsite}</p>
                </p>
              )}

              {data.socialMedia?.length > 0 && (
                <>
                  <p>
                    <b className="mr-3">Social Media :</b>
                  </p>{" "}
                  <ul className="d-flex flex-column g-1 flex-wrap l-none justify-content-centre">
                    {data.socialMedia.map((item, i) => (
                      <li
                        className="mt-2 d-flex align-items-center"
                        key={i}
                        style={{ gap: "1.5rem" }}
                      >
                        <a
                          href={item.link}
                          style={{
                            height: "2rem",
                            width: "2rem",
                          }}
                        >
                          <img
                            src={item.icon}
                            className="h-100 w-100"
                            alt="img"
                          />
                        </a>
                        <p className="single-line-ellipsis">{item.link}</p>
                      </li>
                    ))}
                  </ul>{" "}
                </>
              )}

              {data.language?.length > 0 && (
                <div className="d-flex align-items-center">
                  <p>
                    <b className="mr-3">Language :</b>
                  </p>{" "}
                  <ul className="m-0 d-flex flex-wrap l-none justify-content-centre">
                    {data.language.map((item, i) => (
                      <li
                        className="d-flex flex-column"
                        key={i}
                        style={{ gap: "1.5rem" }}
                      >
                        <p className="single-line-ellipsis">{item.language}</p>
                      </li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            </section>
          </div>

          <div className="stats-container-one" ref={detailsPage}>
            <div>
              <h3>Reach Us</h3>
              <hr className="hr-line" />
              <div className="d-flex flex-column" style={{ gap: "12px" }}>
                {data.eventDetails.emailShow && (
                  <p>
                    <b>Email : </b> {data.userDetails?.email}
                  </p>
                )}
                {data.eventDetails.phoneNumberShow && (
                  <p>
                    <b>PhoneNumber : </b> {data.userDetails?.phoneNumber}
                  </p>
                )}
                {data.userDetails?.profession?.professionName && (
                  <p>
                    <b>Profession : </b>{" "}
                    {data.userDetails?.profession?.professionName}
                  </p>
                )}
              </div>
            </div>

            <div>
              <h3>Our Expertise</h3>
              <hr className="hr-line" />

              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: data.eventDetails?.description,
                }}
              />
            </div>
            {data.userUpcomingMeting?.length > 0 && (
              <div>
                <h3>UpComing Meetings</h3>
                <hr className="hr-line" />
                <div className="c-gallery p-0">
                  <div className="d-flex flex-column " style={{ gap: "1rem" }}>
                    {data.userUpcomingMeting.map((item, index) => (
                      <div
                        className="card"
                        style={{ textAlign: "start" }}
                        key={index}
                      >
                        <div className="p-3">
                          <b className="text-dark">
                            {item.serviceName}
                            {/* You have meeting with {data.userDetails.userName} on , */}
                          </b>

                          <p className="d-flex mt-3 small-font">
                            <span>
                              {moment(item.startTime)
                                .locale("en")
                                .format("lll")}{" "}
                            </span>
                            <span
                              style={{ marginLeft: "4px", marginRight: "4px" }}
                            >
                              to
                            </span>
                            <span>
                              {moment(item.endTime).locale("en").format("lll")}{" "}
                            </span>
                          </p>
                          <button
                            className="mt-2"
                            onClick={() => cancelMeeting(item)}
                          >
                            Cancel Meeting
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {state.serviceGalleryImage.length > 0 && (
              <div>
                <h3>Photos</h3>
                <hr className="hr-line" />

                <div className="c-gallery p-0">
                  {/* Image Grid */}
                  <div className="c-grid-v2">
                    {state.serviceGalleryImage.map((data, index) => (
                      <img
                        key={index}
                        src={data}
                        alt={`Image ${index}`}
                        className="grid-image c-pointer"
                        style={{ borderRadius: "5px" }}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      />
                    ))}
                  </div>

                  {/* Lightbox */}
                  {isOpen && (
                    <Lightbox
                      mainSrc={state.serviceGalleryImage[photoIndex]}
                      nextSrc={
                        state.serviceGalleryImage[
                          (photoIndex + 1) % state.serviceGalleryImage.length
                        ]
                      }
                      prevSrc={
                        state.serviceGalleryImage[
                          (photoIndex + state.serviceGalleryImage.length - 1) %
                            state.serviceGalleryImage.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + state.serviceGalleryImage.length - 1) %
                            state.serviceGalleryImage.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex(
                          (photoIndex + 1) % state.serviceGalleryImage.length
                        )
                      }
                    />
                  )}
                </div>
              </div>
            )}
            {(data.eventDetails?.announcementContent ||
              state.serviceAnnouncement?.length > 0) && (
              <div>
                <h3>Announcements</h3>
                <hr className="hr-line" />
                <div className="c-gallery p-0">
                  {/* Image Grid */}
                  <p
                    className="f-14 "
                    style={{ textAlign: "start" }}
                    dangerouslySetInnerHTML={{
                      __html: data.eventDetails?.announcementContent,
                    }}
                  />

                  <div className="c-grid-v2">
                    {state.serviceAnnouncement.map((data, index) => (
                      <img
                        key={index}
                        src={data}
                        alt={`Image ${index}`}
                        className="grid-image c-pointer"
                        style={{ borderRadius: "5px" }}
                        onClick={() => {
                          setPhotoIndex(index);
                          setIsOpen(true);
                        }}
                      />
                    ))}
                  </div>

                  {/* Lightbox */}
                  {isOpen && (
                    <Lightbox
                      mainSrc={state.serviceAnnouncement[photoIndex]}
                      nextSrc={
                        state.serviceAnnouncement[
                          (photoIndex + 1) % state.serviceAnnouncement.length
                        ]
                      }
                      prevSrc={
                        state.serviceAnnouncement[
                          (photoIndex + state.serviceAnnouncement.length - 1) %
                            state.serviceAnnouncement.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + state.serviceAnnouncement.length - 1) %
                            state.serviceAnnouncement.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex(
                          (photoIndex + 1) % state.serviceAnnouncement.length
                        )
                      }
                    />
                  )}
                </div>
              </div>
            )}
            {state.serviceProviderVideo?.length > 0 && (
              <div>
                <h3>Videos</h3>
                <hr className="hr-line" />
                <div className="c-gallery p-0">
                  <div className="c-grid-v2" style={{ gap: "2rem" }}>
                    {state.serviceProviderVideo.map((data, index) => (
                      <video
                        key={index}
                        style={{ width: "100%", height: "200px" }}
                        controls
                      >
                        <source src={`${API_URL}auth/video/${data}`} />
                      </video>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <h6 className="service-heading-t2">Service List </h6>
          <ul
            className="d-flex flex-column g-2 l-none mt-4"
            ref={serviceListPage}
          >
            {data.serviceList.map((item, i) => (
              <li
                onClick={() => selectService(item)}
                key={i}
                className="card service-card-v2 p-4 "
              >
                <section
                  // className="h-100"
                  style={{ width: "8rem", height: "11rem", flexShrink: "0" }}
                >
                  <img src={service_default} alt="" className="h-100 w-100" />
                </section>
                <div className="service-card-v2-con">
                  <div style={{ textAlign: "start" }} className="p-3 ">
                    <h4
                      className="font-weight-bold m-0"
                      style={{ textTransform: "capitalize" }}
                    >
                      {toCamelCase(item.serviceName)}
                    </h4>
                    <p
                      className="mt-1 text-secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {item.appointmentValue}{" "}
                    </p>
                    <p style={{ fontSize: "13px" }} className="mt-2">
                      {item.description}
                    </p>
                  </div>
                  <div className="book-service-btn">
                    <button>Book a Service</button>
                  </div>
                </div>
                {/* <p className="mt-2">
                {item.appointmentType}{" "}
                <span className="font-weight-bold">{`₹${item.amount}`}</span>
              </p> */}
                {/* <p className="d-flex items-center   mt-3">
                  <FaRegClock className="mr-2 svg-icons c-pointer" />{" "}
                  {item.duration} mins
                </p> */}
              </li>
            ))}
            {data.serviceList.length === 0 && (
              <div className="text-muted">No services found</div>
            )}
          </ul>
          {/* details*/}
          <div className="review-recom-form">
            {data.reviewList?.length > 0 && (
              <div className="card-v3">
                <h3 className="card-v3-heading">Review</h3>
                <hr className="hr-line" />
                <ul
                  className="d-flex flex-column m-0 p-3 scroll-wrap-service"
                  style={{ gap: "2rem" }}
                >
                  {data.reviewList.map((item, i) => (
                    <li key={i} className="card p-3">
                      <p className="text-secondary f-12">
                        " {item.suggestion} "
                      </p>
                      <div className="d-flex align-items-center gap-2 mt-3">
                        <img
                          src={woman}
                          alt=""
                          className="h-auto "
                          style={{ width: "2rem" }}
                        />
                        <div>
                          <h6 style={{ marginBottom: "4px" }}>{item.name}</h6>
                          <StarRating
                            value={item.satisfaction ?? 5}
                            updateState={() => {}}
                            size={10}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {data.recommendList?.length > 0 && (
              <div className="card-v3">
                <h3 className="card-v3-heading">Recommends</h3>
                <hr className="hr-line" />
                <ul
                  className="d-flex flex-column m-0 p-3 scroll-wrap-service"
                  style={{ gap: "2rem" }}
                >
                  {data.recommendList.map((item, i) => (
                    <li key={i} className="card p-3">
                      <p className="text-secondary f-12">
                        " {item.suggestion} "
                      </p>
                      <div className="d-flex align-items-center gap-2 mt-3">
                        <img
                          // src={
                          //   item.mediaFile
                          //     ? `${API_URL}auth/image/${item.mediaFile}`
                          //     : woman
                          // }
                          src={woman}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = woman;
                          }}
                          className="h-auto "
                          style={{ width: "2rem" }}
                        />

                        <h6>{item.name}</h6>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </BlockUi>
      <footer className="s-footer">
        <div
          className="service-wrap d-flex align-items-center"
          style={{ justifyContent: "space-between" }}
        >
          <p>Powered by Yuukke</p>
          <p>Copyright 2025 Yuukke All Rights Reserved.</p>
        </div>
      </footer>
      {/* {state.cancelMeetingModel &&
      <Modal
      show={true}
      onHide={() => {
        cancelMeeting(null);
      }}
    >
      <Modal.Header className="font-weight-bold">
        <p>Need to Your Details :</p>
        <AiOutlineCloseCircle
          onClick={() => cancelMeeting(null)}
          style={{
            cursor: "pointer",
            float: "right",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <BlockUi
          tag="div"
          message="Loading, please wait"
          style={{ minHeight: "134px" }}
          className="wallLoadingPage"
          blocking={state.loader}
        >
          <p></p>
        </BlockUi>
      </Modal.Body>
    </Modal>} */}
    </div>
  );
};

export default ServiceCardThemeV1;

// export default function ServiceCardThemeV1() {
//   useEffect(() => {
//     var main = document.getElementById("base");

//     main.style.backgroundImage = `linear-gradient(205deg, rgb(0 9 64 / 0%) 0%, rgb(160 0 48 / 0%) 100%)`;
//   }, []);
//   return (
//     <div style={{ background: "#f5f6fa", height: "100vh" }}>
//       <div className="service-theme-v1-header">
//         <div className="service-wrap">
//           <img
//             src={calendar}
//             alt=""
//             style={{ width: "3rem", height: "3rem" }}
//           />
//           <ul>
//             <li>About</li>
//             <li>Contact</li>
//             <li>Photo</li>
//             <li>Videos</li>
//             <li>Announcement</li>
//           </ul>
//           <img
//             style={{ width: "4rem", height: "4rem" }}
//             className="rounded-circle"
//             src={
//               API_URL +
//               "api/getUserPhoto/" +
//               814 +
//               // state.userDetails.userId +
//               "/" +
//               "name"
//             }
//             alt="user"
//             onError={({ currentTarget }) => {
//               currentTarget.onerror = null; // prevents looping
//               currentTarget.src = dummyimage;
//             }}
//           />
//         </div>
//       </div>
//       <div>
//         <div
//           className="service-wrap"
//           style={{ minHeight: "100vh", marginTop: "8rem" }}
//         >
//           <div className="service-v1-gallery">
//             <img
//               src="https://corporate.yuukke.com/bcav2/auth/image/d9d1708e-215f-42fa-a4be-e520b3cccc45"
//               alt=""
//             />
//           </div>
//           <ul className="l-none">
//             <li>
//               <h6>This is my heading</h6>
//               <p>
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                 Scelerisque.
//               </p>
//             </li>
//             <li>
//               <h6>Service Type</h6>
//               <p>Education</p>
//             </li>
//             <li>
//               <h6>TimeZone</h6>
//               <p>Asia</p>
//             </li>
//             <li>
//               <h6>Language</h6>
//               <p>Asia</p>
//             </li>
//             <li>
//               <h6>Contact</h6>
//               <p>Asia</p>
//             </li>
//             <li>
//               <h6>Phots</h6>
//               <p>Asia</p>
//             </li>
//             <li>
//               <h6>Videos</h6>
//               <p>Asia</p>
//             </li>
//             <li>
//               <h6>Announcement</h6>
//               <p>Asia</p>
//             </li>
//           </ul>
//         </div>
//       </div>
//       <div>
//         <div className="service-wrap"></div>
//       </div>
//     </div>
//   );
// }
