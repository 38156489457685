import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionList,
  getUserService,
  getUserSlots,
} from "../../../redux/actions/calendarAction";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { setIsServiceUser } from "../../../redux/actions/loginDetailsActions";
import Services from "./Services";
import Joyride from "react-joyride"; // Import Joyride
import Swal from "sweetalert2";
import AuthService from "../../../session/AuthService";
import { getLogoutData } from "../../../redux/actions/logoutActions";
import Http from "../../../api/Http";
import CalendarContainer from "./CalendarContainer";
import { ToastContainer, toast } from "react-toastify";

import { FaHome } from "react-icons/fa"; // Import back icon
import {
  getAllAreaOfInterest,
  getAllChatFriendsList,
  getAllCommonExpectationHelpList,
  getAllCommonExpectationInterestList,
  getAllCommonFriendsList,
  getAllCommonIndustry,
  getAllCommonProfessionList,
  getAllCommonSchoolList,
  getAllCommonTagsList,
  getApplicationRequirements,
} from "../../../redux/actions/commonApiActions";

import { Button, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  homeButton: {
    background: "linear-gradient(135deg, #007bff 0%, #0056b3 100%)",
    color: "#ffffff !important",
    borderRadius: "6px", 
    padding: "6px 6px",
    fontSize: "11px", 
    fontWeight: "bold",
    minWidth: "40px", 
    width: "auto", 
    paddingLeft: "16px",
    textTransform: "none",
    boxShadow: "0px 3px 6px rgba(0, 123, 255, 0.3)",
    display: "flex",
    alignItems: "center",
    gap: "6px", 
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: "linear-gradient(135deg, #0056b3 0%, #003d82 100%)",
      transform: "translateY(-1px)",
      boxShadow: "0px 4px 10px rgba(0, 123, 255, 0.4)",
    },
    "&:active": {
      transform: "scale(0.97)", 
      boxShadow: "0px 2px 5px rgba(0, 123, 255, 0.2)",
    },
  },  
  logoutButton: {
    background: "linear-gradient(135deg,#a00030 0%,#a00030 100%)",
    color: "#ffffff !important",
    borderRadius: "10px",
    padding: "10px 20px",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    // boxShadow: "0px 4px 8px rgba(255, 77, 77, 0.3)",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: "linear-gradient(135deg, #cc0000 0%, #990000 100%)",
      transform: "translateY(-2px)",
      boxShadow: "0px 6px 12px rgba(255, 77, 77, 0.4)",
    },
    "&:active": {
      transform: "scale(0.95)",
      boxShadow: "0px 2px 6px rgba(255, 77, 77, 0.2)",
    },
  },
}));

const ServiceUsersHome = (props) => {
  const classes = useStyles();
  const [isToggleEnabled, setIsToggleEnabled] = useState(false);
  const [initialServicePage, setInitialServicePage] = useState(false);
  const [runTour, setRunTour] = useState(false); // State to control the tour
  const [hasShownModal, setHasShownModal] = useState(false); // Add this state
  const history = useHistory();
  const { userServiceList, userSlots } = useSelector((state) => state.event);
  const [state, setState] = useState({
    serviceList: [],
    activeType: null,
    activeMenu: 0,
    title: "",
    prize: 0,
    activeMinutes: {
      value: 15,
      label: "15 Minute Meeting",
    },
    userServiceList: userServiceList,
    serviceId: null,
    allMenu: true,
    menuType: "",
    assessmentListUpdated: [],
    assessmentData: [],
    removeId: [],
    subMenu: "",
    shareCalendar: false,
    assessmentNeed: false,

    requestList: [],
    downloadList: [],
    singleRequestData: null,
    assessmentAnswer: [],
    shareUrl: "",
  });

  const dispatch = useDispatch();

  const logout = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { t } = props;

    Swal.fire({
      title: t("Are you sure?"),
      text: t("You want to logout?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("Yes, logout"),
      cancelButtonText: t("No, cancel!"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear authentication tokens and data
        AuthService.removeAuth();
        localStorage.removeItem("R_TOKEN");

        // Dispatch logout action
        dispatch(getLogoutData());

        // Handle Keycloak logout if applicable
        if (Http.isProfileExists("keycloak")) {
          AuthService.triggerLogout();
        }

        // Redirect to login page
        history.push("/login");
      }
    });
  };

  const TOUR_STEPS = [
    {
      target: ".toggle-button",
      content: "Use this to switch to Yuukke community",
      disableBeacon: true,
    },
  ];

  //     useEffect(() => {
  //         console.log("userSlots updated:", userSlots);
  //         if (userSlots && userSlots.preference === null && !hasShownModal) {
  //             Swal.fire({
  //                 title: "Update Your Service Profile",
  //                 html: `
  // <div style="text-align: center;">
  //     <img src="https://corporate.yuukke.com/bcav2/auth/image/d14916c9-8f4e-4778-a5d7-5b33819460c5"
  //          alt="Profile Update"
  //          style="width: 65px; height: 65px; margin-bottom: 20px;" />

  //     <p style="font-size: 18px; font-weight: bold; color: #333;">
  //         Boost Your Profile – Update Now!
  //     </p>

  //     <p style="font-size: 16px; color: #666; margin-bottom: 15px;">
  //         Your service profile is <strong>60% complete</strong>.
  //     </p>

  //     <div style="background: #f8f8f8; border-radius: 12px; padding: 15px; margin: 20px auto; max-width: 300px;">
  //         <p style="font-size: 14px; color: #444; font-weight: 500;">
  //             Progress: <strong>60% Complete</strong>
  //         </p>
  //         <div style="background: #ddd; border-radius: 6px; height: 12px; width: 100%; position: relative;">
  //             <div style="background: #4CAF50; width: 60%; height: 100%; border-radius: 6px;"></div>
  //         </div>
  //     </div>

  //     <p style="font-size: 14px; color: #888; margin-top: 10px;">
  //         A complete profile increases your visibility! ✨
  //     </p>
  // </div>

  //                 `,
  //                 confirmButtonText: "Got it",
  //                 confirmButtonColor: "#4CAF50",
  //                 allowOutsideClick: true, // Allows closing on outside click
  //                 customClass: {
  //                     container: 'swal2-container',
  //                     popup: 'swal2-popup-custom',
  //                     title: 'swal2-title-custom',
  //                     confirmButton: 'swal2-confirm-custom',
  //                 },
  //                 didOpen: () => {
  //                     const popup = Swal.getPopup();
  //                     popup.style.animation = "bounceIn 0.5s ease-out";
  //                 },
  //             }).then((result) => {
  //                 console.log("User closed modal:", result);
  //                 setHasShownModal(true); // Update state when closed
  //                 Swal.close(); // Ensure modal is closed

  //                 // Remove modal from DOM
  //                 setTimeout(() => {
  //                     document.querySelector('.swal2-container')?.remove();
  //                     document.body.classList.remove('swal2-shown', 'swal2-height-auto');
  //                 }, 100);
  //             });
  //         }
  //     }, [userSlots, hasShownModal]);

  useEffect(() => {
    // Start the tour when the component mounts
    setRunTour(true);
    dispatch(getAllCommonTagsList());
    dispatch(getAllCommonIndustry());
    dispatch(getAllCommonSchoolList());
    dispatch(getAllCommonFriendsList());
    dispatch(getAllAreaOfInterest());
    dispatch(getAllChatFriendsList());
    dispatch(getAllCommonProfessionList());
    dispatch(getAllCommonExpectationHelpList());
    dispatch(getAllCommonExpectationInterestList());
    dispatch(getApplicationRequirements());
    dispatch(getSubscriptionList());
  }, []);

  const handleToggle = () => {
    setIsToggleEnabled((prevState) => {
      const newState = !prevState;

      if (prevState === false && newState === true) {
        dispatch(setIsServiceUser(false));
        console.log("Redirecting to /default/home");
        history.push("/default/home");
      }
      return newState;
    });
  };
  
  const removeMenuStatus = () => {
    setState((previous) => ({
      ...previous,
      activeWrapperMenu: null,
      activeMenu: 0,
      initialServicePage: false,
    }));
  };

  const CustomTooltip = ({ step, tooltipProps }) => (
    <div
      {...tooltipProps}
      style={{
        backgroundColor: "#fff",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        position: "relative", // Allow absolute positioning inside
      }}
    >
      {/* Small close (×) icon in top-right corner */}
      <button
        onClick={() => setRunTour(false)}
        style={{
          position: "absolute",
          top: "5px",
          right: "8px",
          background: "none",
          border: "none",
          fontSize: "40px",
          cursor: "pointer",
          color: "#555", // Subtle color
          padding: "1px",
        }}
      >
        ×
      </button>

      <h4>{step.title}</h4>
      <p>{step.content}</p>
    </div>
  );

  console.log("state", state);
  return (

    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <ToastContainer position="top-right" autoClose={5000} />
      <Joyride
        steps={TOUR_STEPS}
        run={runTour}
        continuous={true}
        scrollToFirstStep={false}
        showSkipButton={false}
        showCloseButton={false}
        disableScrolling={true}
        tooltipComponent={CustomTooltip} // Use custom tooltip
        styles={{
          options: {
            primaryColor: "#4CAF50",
            zIndex: 10000,
          },
          beacon: {
            display: "none",
          },
        }}
        locale={{
          last: "Finish",
        }}
        scrollOffset={50}
      />
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          zIndex: 1000,
        }}
      >
        <img
          src={
            "https://corporate.yuukke.com/bcav2/auth/image/39bb22f2-52e9-43a3-951e-0618f8bc85fc"
          }
          alt="logo"
          style={{ width: "170px", height: "50px" }}
        />
      </div>

      <div
        style={{
          borderBottom: "1px solid #80808063",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
        }}
      >
        <h4 className="heading-h4-v2" style={{ margin: 0 }}></h4>

        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {/* <Button
            onClick={handleBackButtonClick}
            className={classes.homeButton}
            startIcon={<FaHome style={{ fontSize: "18px" }} />}
          >
          </Button> */}
          {/* Toggle Button */}
           <Tooltip title="Click here to switch Yuukke Community" arrow>
          <label
            className="toggle-button" // Add a class for the tour step
            style={{
              position: "relative",
              display: "inline-block",
              width: "60px",
              height: "28px",
            }}
          >
            <input
              type="checkbox"
              checked={isToggleEnabled}
              onChange={handleToggle}
              style={{ opacity: 0, width: 0, height: 0 }}
            />
            <span
              style={{
                position: "absolute",
                cursor: "pointer",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: isToggleEnabled ? "#4CAF50" : "#ccc",
                transition: "0.4s",
                borderRadius: "28px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  height: "22px",
                  width: "22px",
                  left: "3px",
                  bottom: "3px",
                  backgroundColor: "white",
                  transition: "0.4s",
                  borderRadius: "50%",
                  transform: isToggleEnabled
                    ? "translateX(22px)"
                    : "translateX(0)",
                }}
              />
            </span>
          </label>
          </Tooltip>
          <Button
            onClick={logout}
            className={classes.logoutButton}
            startIcon={<span style={{ fontSize: "18px" }}></span>}
          >
            Logout
          </Button>
        </div>
      </div>

      <div>
        <CalendarContainer />
      </div>
    </BlockUi>
  );
};

export default withTranslation()(withRouter(ServiceUsersHome));
