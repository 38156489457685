import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import MyYuukke from "../../../api/MyYuukke.js";
import BlockUi from "react-block-ui";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { API_URL, JITSI_URL } from "../../../config/app-config.js";
import { FaRupeeSign } from "react-icons/fa";
import { mailInit, phoneInit } from "../../../utils.js";
import { FiPhoneForwarded } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import { withTranslation } from "react-i18next";
import dummyimage from "../../../img/default_avatar.jpg";
import { toast } from "react-toastify";

const ServiceRecommendation = ({ t }) => {
  const requestTypes = [
    { value: "all", label: "All Requests" },
    { value: "upcoming", label: "UpComing Meeting" },
    { value: "past", label: "Past Meeting" },
    // { value: 60, label: "60 Minute Meeting" },
  ];
  useEffect(() => {
    getRecommendUserList();
  }, []);
  const [state, setState] = useState({
    // requestFilterType: {
    //   value: "upcoming",
    //   label: "UpComing Meeting",
    // },
    downloadList: [],
    singleRequestData: null,
    assessmentAnswer: [],
    requestDetails: false,
    activeMenu: null,
    chartData: null,
    chartOption: [],
    recommendList: [],
    detailsPage: false,
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  // const graphData = (data) => {
  //   let v_chartOptions = {
  //     bezierCurve: false,
  //     elements: {
  //       line: {
  //         tension: 0,
  //       },
  //     },
  //   };

  //   const groupedData = Object.values(
  //     requestList.reduce((acc, item) => {
  //       const date = moment(item.createdDate).locale("en").format("ll");

  //       // If the date already exists in the accumulator, increment the count
  //       if (acc[date]) {
  //         acc[date].count += 1;
  //       } else {
  //         // If not, add the date with count 1
  //         acc[date] = { date: date, count: 1 };
  //       }

  //       return acc;
  //     }, {}) // Initial value is an empty object
  //   );
  //   console.log("groupedData", groupedData);
  //   let labels = [];
  //   let values = [];
  //   groupedData.forEach((item) => {
  //     labels.push(item.date);
  //     values.push(item.count);
  //   });

  //   let v_chartData = {
  //     datasets: [
  //       {
  //         label: "Requests",
  //         data: values,
  //         backgroundColor: [
  //           "#36a2ec69",
  //           // 'rgba(220,220,220,1)',
  //           // '#ff6384',
  //           // '#36a2ec',
  //           // '#ffcd57',
  //           // '#33ff33
  //         ],
  //         borderColor: "#80808026",
  //         fill: true,
  //         lineTension: 0.2,
  //         pointStrokeColor: "#ff6384",
  //         fillColor: "#36a2ec69",
  //         pointColor: "#ff6384",
  //         strokeColor: "rgba(220,220,220,1)",
  //       },
  //     ],
  //     labels,
  //   };
  //   setState((previous) => ({
  //     ...previous,
  //     chartData: v_chartData,
  //     chartOption: v_chartOptions,
  //   }));
  // };

  const getRecommendUserList = (data) => {
    console.log("data", data);
    updateState("loader", true);

    MyYuukke.getServiceRecommendList()
      .then((res) => {
        setState((previous) => ({
          ...previous,
          recommendList: res.data.data,
          loader: false,
          activeMenu: "recommend",
        }));
      })
      .catch((err) => {
        toast.error("Something went wrong");
        updateState("loader", false);
      });
  };

  console.log("state", state);
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <div className="p-3">
        <div className="d-flex flex-wrap align-items-centre">
          {/* {state.activeMenu === null && (
            <section style={{ width: "15rem" }} className="mb-3">
              <label className="label-v2 mt-1">{t("Filter")}</label>
              <Select
                options={requestTypes}
                value={state.requestFilterType}
                onChange={(e) => {
                  updateState("requestFilterType", e);
                  viewRequestList(e.value);
                }}
                // menuPlacement="top"
              />
            </section>
          )} */}
          {/* <button
            style={{ height: "fit-content" }}
            className="ml-auto mb-3"
            onClick={() => updateState("detailsPa", state.activeMenu)}
          >
            {state.activeMenu === "recommend" ? (
              <IoArrowBackCircleOutline className="mr-2 svg-icons text-white" />
            ) : (
              <VscGraph className="mr-2 svg-icons text-white" />
            )}
            {state.activeMenu === "recommend"
              ? t("Back")
              : t("View Recommend List")}
          </button> */}
        </div>
        {!state.detailsPage ? (
          <MaterialTable
            style={{ width: "100%" }}
            columns={[
              {
                title: "Profile Image",
                field: "mediaFile",
                cellStyle: {
                  backgroundColor: "#FAFAFA",
                  color: "#000",
                },
                render: (rowData) => (
                  <img
                    src={
                      rowData.mediaFile
                        ? `${API_URL}auth/image/${rowData.mediaFile}`
                        : dummyimage
                    }
                    alt="Profile"
                    style={{ width: 50, height: 50, borderRadius: "50%" }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                  />
                ),
              },
              {
                title: "UserName",
                field: "name",
                cellStyle: {
                  backgroundColor: "#FAFAFA",
                  color: "#000",
                },
              },
              {
                title: "Email",
                field: "email",
                render: (e) => e.email || "-",
              },
              {
                title: "Profession",
                field: "profession",
                render: (e) => e.profession || "-",
              },
              {
                title: "Suggestion",
                field: "suggestion",
                render: (e) => e.suggestion || "-",
              },
              {
                title: "Date",
                field: "date",
                render: (e) =>
                  e.date ? moment(e.date).locale("en").format("ll") : "-",
              },
            ]}
            data={state.recommendList}
            title={`Recommend user's count : ${state.recommendList.length}`}
            options={{
              pageSize: 10,
              exportButton: true,
              search: true,
              sorting: true,
              headerStyle: {
                backgroundColor: "#A00030",
                color: "#FFF",
              },
              actionsColumnIndex: -1,
            }}
            // onRowClick={(event, rowData) => {
            //   getRequestSingleData(rowData);
            // }}
          />
        ) : (
          <Dialog
            fullWidth
            maxWidth="lg"
            open={true}
            onClose={() => updateState("requestDetails", false)}
          >
            <DialogTitle>
              Request Details
              <AiOutlineCloseCircle
                onClick={() => updateState("requestDetails", false)}
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginBottom: "8px",
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                }}
              />
            </DialogTitle>
            <DialogContent dividers="paper">
              <div>
                <h4
                  className="mt-2 mb-3 font-weight-bold"
                  style={{ fontSize: "1.3rem" }}
                >
                  {t("User Details")}
                </h4>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        className="text-white  font-weight-bold"
                        style={{ background: "#a00030" }}
                      >
                        {t("Field")}
                      </th>
                      <th
                        className="text-white font-weight-bold"
                        style={{ background: "#a00030" }}
                      >
                        {t("Value")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="label-v2"> {t("ServiceName")}</td>
                      <td>{state.singleRequestData.serviceName}</td>
                    </tr>

                    <tr>
                      <td className="label-v2">
                        {" "}
                        {t("Slot Booked User Name")}
                      </td>
                      <td>{state.singleRequestData.userName}</td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Slot Booked Email")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.email}
                        <button className="ml-auto font-weight-bold">
                          <MdOutlineMarkEmailRead
                            className="svg-icons text-white mr-2"
                            onClick={(e) =>
                              mailInit(e, state.singleRequestData.email)
                            }
                          />
                          Send Email
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Contact Number")}</td>
                      <td className="d-flex">
                        {state.singleRequestData.mobileNumber}
                        <button
                          className="ml-auto font-weight-bold text-left"
                          style={{ width: "143px" }}
                        >
                          <FiPhoneForwarded
                            className="svg-icons text-white mr-3"
                            onClick={(e) =>
                              phoneInit(e, state.singleRequestData.mobileNumber)
                            }
                          />
                          Contact
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Description")}</td>
                      <td>{state.singleRequestData.bio}</td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Appointment Type")}</td>
                      <td>{state.singleRequestData.appointmentType}</td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Paid Amount")}</td>
                      <td>
                        <FaRupeeSign className="mr-1" />
                        {state.singleRequestData.amount ?? 0}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">{t("Meeting Time")}</td>
                      <td>
                        {moment(state.singleRequestData.startTime)
                          .locale("en")
                          .format("LLL")}
                      </td>
                    </tr>
                    <tr>
                      <td className="label-v2">
                        {t("MeetingActiveDuration Type")}
                      </td>
                      <td>
                        {state.singleRequestData.meetingActiveDuration} mins
                      </td>
                    </tr>

                    <tr>
                      <td className="label-v2">{t("Meeting Link")}</td>
                      <td>
                        {state.singleRequestData.url
                          ? state.singleRequestData.meetingInApplication
                            ? `${JITSI_URL}/${state.singleRequestData.url}?&isGroup=true&sp=yes#config`
                            : state.singleRequestData.url
                          : "Meeting not created"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {state.singleRequestData.assessmentNeed && (
                  <>
                    <h4
                      className="mt-4 mb-3 font-weight-bold"
                      style={{ fontSize: "1.3rem" }}
                    >
                      {t("Pre Questions Responds")}
                    </h4>

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="text-white  font-weight-bold"
                            style={{ background: "#a00030" }}
                          >
                            {t("Question")}
                          </th>
                          <th
                            className="text-white  font-weight-bold"
                            style={{ background: "#a00030" }}
                          >
                            {t("Question Type")}
                          </th>
                          <th
                            className="text-white font-weight-bold"
                            style={{ background: "#a00030" }}
                          >
                            {t("Answer")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.assessmentAnswer.map((item, i) => (
                          <tr key={i}>
                            <td className="label-v2">{item.question}</td>
                            <td style={{ textTransform: "capitalize" }}>
                              {item.answerType}
                            </td>
                            <td>{t(`${item.answer}`)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </BlockUi>
  );
};

export default withTranslation()(ServiceRecommendation);
