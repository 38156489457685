const messagerstate = {
  friends: [],
  messages: [],
  lastMessages: [],
  messageSendSuccess: false,
  isMessageLoading: true,
  activeFriendId: 0,
  serviceLastMessage: [],
  serviceCurrentFriend: null,
  serviceFriendId: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = messagerstate, { type, payload, isNotOwner }) {
  switch (type) {
    case "CLEAR_CURRENT_USER_MESSAGES":
      const initialClearState = {
        ...state,
        messages: [],
        isMessageLoading: payload,
      };

      return {
        ...state,
        messages: [],
        isMessageLoading: true,
      };

    case "GETALL_FRIENDS_SUCCESS":
      return {
        ...state,
        friends: payload.data,
      };
    case "GET_FRIENDS_FAILURE":
      return {
        ...state,
      };
    case "CURRENT_FRIEND_MESSAGE_SUCCESS":
      console.log("CURRENT_FRIEND_MESSAGE_SUCCESS", payload);
      const newMessageInitialState = [...payload.data];
      const lastMsg =
        payload.friendId &&
        state.lastMessages.map((item) => {
          if (
            +payload.friendId === +item.senderId ||
            +payload.friendId === +item.receiverId
          ) {
            return {
              ...item,
              status:
                newMessageInitialState[newMessageInitialState?.length - 1]
                  .status,
            };
          }
          return {
            ...item,
          };
        });
      // const messageUpdatedState = {
      //   ...state,
      //   messages: payload,
      //   isMessageLoading: false,
      // };

      return {
        ...state,
        messages: newMessageInitialState,
        lastMessages: lastMsg ? lastMsg : state.lastMessages,
        isMessageLoading: false,
        serviceFriendId: payload.friendId,
      };

    case "SEND_MESSAGE_SUCCESS":
      // const updatedSendMessageState = {
      //   ...state,
      //   messageSendSuccess: true,
      //   messages: [...state.messages, payload.data],
      // };
      console.log("SEND_MESSAGE_SUCCESS", payload.data);
      return {
        ...state,
        messageSendSuccess: true,
        messages: [...state.messages, payload.data],
      };

    case "SEND_MEETING_SUCCESS":
      return {
        ...state,
        messageSendSuccess: true,
        messages: [...state.messages, payload.data],
      };

    case "SEND_IMAGE_SUCCESS":
      return {
        ...state,
        messageSendSuccess: true,
        messages: [...state.messages, payload.data],
      };

    case "SOCKET_MESSAGE":
      console.log("SOCKET_MESSAGE", payload);
      return {
        ...state,
        messages: [...state.messages, payload],
      };

    case "MESSAGE_SEEN_STATUS":
      const updateLastMessageStatusindex = state.messages.findIndex(
        (msgId) => state.messages._id === msgId
      );
      const updateLastMessageStatus = [...state.messages];
      updateLastMessageStatus.splice(
        updateLastMessageStatusindex,
        1,
        payload.message
      );
      return {
        ...state,
        messages: updateLastMessageStatus,
      };

    case "GETLAST_MESSAGE_SUCCESS":
      // const arr = [...state.lastMessages, ...payload.data];
      const arr = payload.data;
      console.log("GETLAST_MESSAGE_SUCCESS", arr);
      return {
        ...state,
        lastMessages: arr,
      };

    case "UPDATE_FRIEND_MESSAGE":
      const index =
        state.lastMessages.length > 0 &&
        state.lastMessages.findIndex(
          (msg) =>
            msg !== null &&
            msg !== undefined &&
            (payload.receiverId === msg.senderId ||
              payload.receiverId === msg.receiverId) &&
            (payload.senderId === msg.senderId ||
              payload.senderId === msg.receiverId)
        );
      const previousLastMessage = [...state.lastMessages];
      // console.log(
      //   "UPDATE_FRIEND_MESSAGE previousLastMessage",
      //   previousLastMessage
      // );
      // console.log("UPDATE_FRIEND_MESSAGE index", index);
      // console.log("UPDATE_FRIEND_MESSAGE", isNotOwner);
      // console.log("UPDATE_FRIEND_MESSAGE payload", payload);
      // console.log("UPDATE_FRIEND_MESSAGE activeFriendId", state.activeFriendId);

      if (!isNotOwner && +payload?.senderId !== state.activeFriendId) {
        console.log(
          "update count",
          previousLastMessage[index]?.unseenCount + 1
        );
        payload.unseenCount =
          previousLastMessage[index]?.unseenCount + 1 > 0
            ? previousLastMessage[index]?.unseenCount + 1
            : 1;
      }
      const newLastMessage = [
        ...previousLastMessage.slice(0, index),
        payload,
        ...previousLastMessage.slice(index + 1),
      ];

      return {
        ...state,
        // messageSendSuccess: payload,
        lastMessages: newLastMessage,
      };

    case "CLEAR_MESSAGE_SEND_SUCCEESS":
      return {
        ...state,
        messageSendSuccess: payload,
      };
    case "UPDATE_VIEW_COUNT":
      console.log("UPDATE_VIEW_COUNT", payload);
      const updateData = state.lastMessages.map((item, i) => {
        if (payload.id === item?.messageId || payload.id === item?._id) {
          item.unseenCount = 0;
        }
        return item;
      });
      console.log("UPDATE_VIEW_COUNT updateData", updateData);

      return {
        ...state,
        lastMessages: updateData ? updateData : [],
        activeFriendId: payload.friendId,
      };

    case "UPDATE_RECEIVE_MESSAGE":
      console.log("UPDATE_RECEIVE_MESSAGE payload", payload);
      console.log(
        "UPDATE_RECEIVE_MESSAGE activeFriendId",
        state.activeFriendId
      );

      const activeFriend = +payload.senderId === state.activeFriendId;
      const updateMessageList =
        activeFriend &&
        state.messages.map((item) => {
          return {
            ...item,
            status: "seen",
          };
        });
      return {
        ...state,
        messages: activeFriend
          ? [
              ...(updateMessageList ? updateMessageList : state.messages),
              payload,
            ]
          : state.messages,
      };
    case "CHAT_POPUP_ACTION":
      console.log("CHAT_POPUP_ACTION", payload);
      return {
        ...state,
        activeFriendId: payload,
      };
    case "MESSAGE_SEEN":
      console.log("MESSAGE_SEEN", payload);
      const messageSeen = +payload.receiverId === state.activeFriendId;

      const updateMessage =
        messageSeen &&
        state.messages.map((item) => {
          return {
            ...item,
            status: "seen",
          };
        });
      const lastMessageUpdate = state.lastMessages.map((item) => {
        if (payload?._id === item?._id) {
          return {
            ...item,
            status: "seen",
          };
        }
        return {
          ...item,
        };
      });
      console.log("MESSAGE_SEEN updateList", updateMessage);
      console.log("MESSAGE_SEEN lastMessageUpdate", lastMessageUpdate);

      return {
        ...state,
        messages: messageSeen ? updateMessage : state.messages,
        lastMessages: lastMessageUpdate
          ? lastMessageUpdate
          : state.lastMessages,
      };
    case "GET_LAST_SERVICE_MESSAGE":
      console.log("GET_LAST_SERVICE_MESSAGE", payload.data);
      return {
        ...state,
        serviceLastMessage: payload.data,
      };
    case "UPDATE_VIEW_COUNT_V2":
      console.log("UPDATE_VIEW_COUNT_V2", payload);
      const updateV2TotalData = state.lastMessages.map((item, i) => {
        if (payload === item?.messageId) {
          item.unseenCount = 0;
        }
        return item;
      });
      const updateV2ServiceData = state.serviceLastMessage.map((item, i) => {
        if (payload === item?.messageId) {
          item.unseenCount = 0;
        }
        return item;
      });
      console.log("UPDATE_VIEW_COUNT_V2 updateData", updateV2ServiceData);
      console.log("UPDATE_VIEW_COUNT_V2 lastMessage", updateV2TotalData);

      return {
        ...state,
        lastMessages: updateV2TotalData
          ? updateV2TotalData
          : state.lastMessages,
        serviceLastMessage: updateV2ServiceData
          ? updateV2ServiceData
          : state.serviceLastMessage,
        // activeFriendId: payload.friendId,
      };
    case "UPDATE_SERVICE_LAST_MESSAGE":
      console.log("UPDATE_SERVICE_LAST_MESSAGE", payload);
      console.log("Before Update :", state.serviceLastMessage);
      let findLastMessage = state.serviceLastMessage.map((item, i) => {
        if (payload.id === item?.messageId) {
          return (item = payload.data);
        }
        return item;
      });
      console.log("After Update :", findLastMessage);
      return {
        ...state,
        serviceLastMessage: findLastMessage,
        serviceCurrentFriend: payload.data,
      };

    case "UPDATE_SERVICE_RECEIVE_MESSAGE":
      console.log("UPDATE_SERVICE_RECEIVE_MESSAGE", payload);
      const updateLastMessageCount = state.serviceLastMessage.map((item, i) => {
        if (+payload?.reqId === +item?.service?.serviceRequestId) {
          item.unseenCount = 0;
        }
        return item;
      });
      const isChatOpen = +state?.serviceFriendId === +payload?.reqId;
      console.log("UPDATE_SERVICE_RECEIVE_MESSAGE isChatOpen", isChatOpen);
      console.log(
        "UPDATE_SERVICE_RECEIVE_MESSAGE serviceFriendId",
        state.serviceFriendId
      );

      return {
        ...state,
        messages:
          isChatOpen && payload
            ? [...state.messages, payload.message]
            : state.messages,
        serviceLastMessage: updateLastMessageCount
          ? updateLastMessageCount
          : state.serviceLastMessage,
      };

    default:
      return state;
  }
}
