// import { useState, memo, useRef, useEffect } from "react";
// import { FaRegIdBadge } from "react-icons/fa";

// // const data = [
// //   "https://corporate.yuukke.com/bcav2/auth/image/bed6e235-d4a5-409f-89d7-4d0491056dd3",
// //   "https://marketplace.yuukke.com/themes/yuukke/shop/assets/images/visa.jpg",
// //   "https://corporate.yuukke.com/bcav2/auth/image/bed6e235-d4a5-409f-89d7-4d0491056dd3",
// // ];

// function Slideshow({ registerPage, videoFileView }) {
//   const slides = [
//     {
//       id: 1,
//       content: (
//         <>
//           <p style={{ fontSize: "20px" }}>
//             <span className="font-weight-bold" style={{ fontSize: "21px" }}>
//               Revolutionize Your Business with Yuukke – A Platform for Women
//               Service Providers!
//             </span>
//             <p className="mt-3" style={{ fontSize: "19px" }}>
//               <b>Yuukke</b> empowers you to <b>share your passion</b> and
//               expertise with the world" Whether you're building a{" "}
//               <b>business online or coaching others</b> to reach their goals,
//               we've got everything you need to <b>rise and grind.</b>
//             </p>
//           </p>
//           {/* <button
//             onClick={() => registerPage()}
//             className="service-btn font-bold p-2 mt-4 w-fit-content"
//           >
//             <FaRegIdBadge className="mr-2 svg-icons text-white" />
//             Register Your Service Now
//           </button> */}
//           <div className="pt-3 pb-3">
//             <button
//               onClick={() => registerPage()}
//               className=" font-bold w-fit-content"
//             >
//               Register Your Service Now
//             </button>
//             <button
//               onClick={() => videoFileView()}
//               className=" font-bold w-fit-content ml-3"
//             >
//               Know More
//             </button>
//           </div>
//         </>
//       ),
//       image:
//         "https://corporate.yuukke.com/bcav2/auth/image/5d54c076-8a8f-4f96-8ec9-100bed8fd403",
//     },
//     {
//       id: 2,
//       content: (
//         <>
//           <p style={{ fontSize: "20px" }}>
//             <span className="font-weight-bold">
//               Empower Your Expertise as a Woman Service Provider with Our
//               Digital Support to:
//             </span>
//             <ul style={{ padding: "22px" }} className="m-0">
//               <li>Expand your global reach</li>
//               <li>Generate recurring revenue</li>
//               <li>Automate your service schedule</li>
//               <li>Scale your impact</li>
//             </ul>
//             <span>
//               All with the flexibility to{" "}
//               <b>
//                 offer services anytime, anywhere through our Mobile App and Web
//                 App
//               </b>
//             </span>
//           </p>
//           <div className="pt-3 pb-3">
//             <button
//               onClick={() => registerPage()}
//               className=" font-bold w-fit-content"
//             >
//               Register Your Service Now
//             </button>
//             <button
//               onClick={() => videoFileView()}
//               className=" font-bold w-fit-content ml-3"
//             >
//               Know More
//             </button>
//           </div>
//         </>
//       ),
//       image:
//         "https://corporate.yuukke.com/bcav2/auth/image/de290962-5a8f-4be4-9724-12e9905f7768",
//     },
//     {
//       id: 3,
//       content: (
//         <>
//           <p style={{ fontSize: "20px" }}>
//             <span className="font-weight-bold" style={{ fontSize: "21px" }}>
//               At Yuukke, we believe in empowering women to take control of their
//               businesses and thrive in a global marketplace
//             </span>
//             <p className="mt-3" style={{ fontSize: "19px" }}>
//               With Yuukke, you’re not just building a business—you’re joining a
//               community of women who support, inspire, and empower each other
//               every step of the way.
//             </p>
//           </p>
//           <div className="pt-3 pb-3">
//             <button
//               onClick={() => registerPage()}
//               className=" font-bold w-fit-content"
//             >
//               Register Your Service Now
//             </button>
//             <button
//               onClick={() => videoFileView()}
//               className=" font-bold w-fit-content ml-3"
//             >
//               Know More
//             </button>
//           </div>
//         </>
//       ),
//       image:
//         "https://corporate.yuukke.com/bcav2/auth/image/0862fe07-47bf-41f9-8cb4-e1aae9853ded",
//     },
//   ];
//   const [activeIndex, setIndex] = useState(0);
//   const timeoutRef = useRef(null);

//   function resetTimeout() {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//   }
//   const handlePrev = () => {
//     setIndex((prevIndex) =>
//       prevIndex === 0 ? slides.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setIndex((prevIndex) =>
//       prevIndex === slides.length - 1 ? 0 : prevIndex + 1
//     );
//   };
//   useEffect(() => {
//     resetTimeout();
//     timeoutRef.current = setTimeout(
//       () =>
//         setIndex((prevIndex) =>
//           prevIndex === slides.length - 1 ? 0 : prevIndex + 1
//         ),
//       5000
//     );

//     return () => {
//       resetTimeout();
//     };
//   }, [activeIndex]);

//   return (
//     <div className="carouselv2-container">
//       <div className="carouselv2">
//         {/* Slides */}
//         <div
//           className="carouselv2-inner"
//           style={{ transform: `translateX(${-activeIndex * 100}%)` }}
//         >
//           {slides.map((slide, index) => (
//             <div
//               key={slide.id}
//               className="carouselv2-item"
//               // style={{ height: "100vh" }}
//             >
//               <div className="row flex-col-reverse">
//                 <div className="col-md-6 d-flex flex-column  p-4">
//                   {slide.content}
//                 </div>

//                 <div className="col-md-6 p-0 service-sec-1-img">
//                   <img
//                     src={slide.image}
//                     alt={`Slide ${slide.id}`}
//                     className="w-100 h-100 object-cover"
//                   />
//                 </div>
//               </div>
//             </div>
//           ))}
//           {/* {data.map((img, index) => (
//             <div key={index} className="carouselv2-item">
//               <img src={img} alt={`Slide ${index + 1}`} />
//             </div>
//           ))} */}
//         </div>

//         {/* Left Arrow */}
//         <button className="carouselv2-control prevv2" onClick={handlePrev}>
//           ❮
//         </button>

//         {/* Right Arrow */}
//         <button className="carouselv2-control nextv2" onClick={handleNext}>
//           ❯
//         </button>

//         {/* Indicators */}
//         <div className="carouselv2-indicators">
//           {slides.map((_, idx) => (
//             <span
//               key={idx}
//               onClick={() => setIndex(idx)}
//               className={activeIndex === idx ? "active" : ""}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default memo(Slideshow);

import { useState, memo, useRef, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { FaRegIdBadge } from "react-icons/fa";
import { withRouter } from "react-router-dom";

function Slideshow({ data, t }) {
  const [activeIndex, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const slidesPerPage = window.matchMedia("(max-width:739px)").matches ? 1 : 2; // Show 2 slides at a time
  const totalSlides = Math.ceil(data.length / slidesPerPage); // Total groups

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const handlePrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
    );
  };
  console.log("activeIndex", activeIndex);
  return (
    <div className="carouselv2-container relative">
      <div className="carouselv2">
        <div
          className="carouselv2-inner"
          style={{ transform: `translateX(${-activeIndex * 100}%)` }}
        >
          {data.map((slide, index) => (
            <div className="s-min-50">
              <div key={slide.id} className="carouselv2-item">
                <div
                  style={{
                    height: "13rem",
                    overflow: "hidden",
                    borderRadius: "7px",
                  }}
                >
                  <img
                    src={slide.image}
                    alt={`Slide ${slide.id}`}
                    className="w-100 h-100 object-cover"
                  />
                </div>
                <div className="pl-3 pr-3 mt-3">
                  <h6>{t(`${slide.heading}`)}</h6>
                  <p>{t(`${slide.content}`)}</p>
                </div>
              </div>
            </div>
          ))}

          {/* {data.map((img, index) => (
                <div key={index} className="carouselv2-item">
                  <img src={img} alt={`Slide ${index + 1}`} />
                </div>
              ))} */}
        </div>

        {/* Indicators */}
        {/* <div className="carouselv2-indicators">
              {data.map((_, idx) => (
                <span
                  key={idx}
                  onClick={() => setIndex(idx)}
                  className={activeIndex === idx ? "active" : ""}
                />
              ))}
            </div> */}
      </div>
      {/* Left Arrow */}
      <button className="carouselv2-control prevv2" onClick={handlePrev}>
        ❮
      </button>

      {/* Right Arrow */}
      <button className="carouselv2-control nextv2" onClick={handleNext}>
        ❯
      </button>
    </div>
  );
}

export default withTranslation()(withRouter(Slideshow));
