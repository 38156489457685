import dummyimage from "../img/default_avatar.jpg";
import faceBook from "../images/SocialMediaGif/Facebook.png";
import instagram from "../images/SocialMediaGif/instagram.png";
import linkedIn from "../images/SocialMediaGif/Linkedin.png";
import { GoBriefcase } from "react-icons/go";
import { IoCalendarOutline } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";
import { useRef, useState } from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import demoHeader from "../images/SocialMediaGif/home_yuukke.png";
import Lightbox from "react-image-lightbox";

export default function ServiceDemoPage({
  primary,
  secondary,
  heading,
  subHeading,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const tabs = ["About", "Contact", "Photos", "Videos", "Announcements"];
  const [activeTab, setActiveTab] = useState("About");
  const tabs = ["About", "Contact", "Photos", "Videos", "Announcements"];

  let socialMedia = [
    { name: "faceBook", image: faceBook },
    { name: "instagram", image: instagram },
    { name: "linkedIn", image: linkedIn },
  ];
  const [galleryImage, setGalleryImage] = useState([
    "https://corporate.yuukke.com/bcav2/auth/image/d9d1708e-215f-42fa-a4be-e520b3cccc45",
  ]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    serviceAnnouncement: [
      "https://corporate.yuukke.com/bcav2/auth/image/d9d1708e-215f-42fa-a4be-e520b3cccc45",
    ],
    serviceProviderVideo: [
      "https://corporate.yuukke.com/bcav2/auth/image/d9d1708e-215f-42fa-a4be-e520b3cccc45",
    ],
  });
  let serviceList = [
    {
      id: 123,
      amount: 0,
      appointmentValue: "Primary Discussion",
      assessmentNeed: false,
      serviceName: "Yogo Class",
      appointmentName: "primary_discussion",
      description: null,
      type: null,
      duration: 15,
    },
    {
      amount: 0,
      appointmentName: "one_to_one_consultation",
      serviceName: "Business Mentor",
      assessmentNeed: false,
      id: 124,
      appointmentValue: "One-to-One Consultation",
      description: null,
      type: null,
      duration: 45,
    },
  ];
  const listRef = useRef(null);
  const isScrollingRef = useRef(false);
  const currentIndexSafe = Math.min(currentIndex, serviceList.length - 1);

  const handleScroll = (direction) => {
    if (listRef.current) {
      const width = listRef.current.children[0].offsetWidth + 16;
      listRef.current.scrollBy({
        left: direction === "left" ? -width : width,
        behavior: "smooth",
      });
    }
  };
  const handleScrolling = (direction) => {
    const list = listRef.current;
    if (!list || isScrollingRef.current) return;

    const cardWidth = 300; // card width
    const gap = 16; // gap between cards

    let newIndex = currentIndex + direction;
    if (newIndex < 0) newIndex = 0;
    if (newIndex >= serviceList.length) newIndex = serviceList.length - 1;

    // Set flag to prevent additional updates during scroll animation
    isScrollingRef.current = true;

    // Update the index immediately if needed
    setCurrentIndex(newIndex);

    // Smooth scroll to the exact card position
    list.scrollTo({
      left: newIndex * (cardWidth + gap),
      behavior: "smooth",
    });

    // Reset the flag after the animation completes
    setTimeout(() => {
      isScrollingRef.current = false;
    }, 800);
  };
  return (
    <div
      style={{ background: primary, border: "none", boxShadow: "none" }}
      className="login-options-opt flex-row  p-0 m-3 justify-content-center align-items-start flex-wrap"
    >
      <section
      // style={{ width: "32rem" }}
      >
        <div
          className="d-flex align-items-center"
          style={{ minHeight: "75px" }}
        >
          <img
            src={demoHeader}
            alt=""
            style={{
              width: "4rem",
              height: "2rem",
            }}
            className="  mr-3"
          />
          <h4
            style={{
              fontWeight: "600",
              // minWidth: window.matchMedia("(max-width:739px)").matches
              //   ? "auto"
              //   : "30rem",
              textTransform: "capitalize",
              color: heading,
            }}
            className="three-line m-0"
          >
            Teaching
          </h4>
        </div>
        <div
          className="login-options-opt mt-0"
          style={{ background: secondary }}
        >
          <div
            className="position-relative d-flex flex-column justify-content-centre align-items-center"
            style={{
              // minWidth: window.matchMedia("(max-width:739px)").matches
              //   ? "auto"
              //   : "30rem",
              gap: "0",
            }}
          >
            <div style={{ width: "3rem", height: "3rem" }}>
              <img
                style={{ borderRadius: "10px" }}
                src={dummyimage}
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = dummyimage;
                }}
              />
            </div>

            <div className="d-flex flex-column p-3 w-100 justify-content-centre align-items-center">
              <h3
                style={{ color: heading }}
                className="font-weight-bold mt-1   text-capitalize"
              >
                Rani
              </h3>
              <ul className="d-flex g-1 flex-wrap l-none justify-content-centre">
                {socialMedia.map((item, i) => (
                  <li
                    className="mt-2"
                    style={{
                      height: "2rem",
                      width: "2rem",
                    }}
                    key={i}
                  >
                    <a href={"http://demo"}>
                      <img src={item.image} className="h-100 w-100" alt="img" />
                    </a>
                  </li>
                ))}
              </ul>
              <p
                style={{ color: heading }}
                className="f-14 align-center font-weight-bold"
              >
                <GoBriefcase className="mr-2 svg-icons text-white  " />
                Self Employed
              </p>
            </div>
            <section
              className="calendar-tabs"
              style={{
                background: secondary,
                width: "30rem",
                border: `1px solid ${primary}`,
              }}
            >
              <div className="c-tabs">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    style={{ color: heading }}
                    className={`c-tab ${activeTab === tab ? "active" : ""}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              {/* Tab Content */}
              <div className="c-tab-content">
                {activeTab === "Photos" ? (
                  <div className="c-gallery">
                    {/* Image Grid */}
                    <div className="c-grid">
                      {galleryImage.map((data, index) => (
                        <img
                          key={index}
                          src={data}
                          alt={`Image ${index}`}
                          className="grid-image c-pointer"
                          style={{ borderRadius: "5px" }}
                          onClick={() => {
                            setPhotoIndex(index);
                            setIsOpen(true);
                          }}
                        />
                      ))}
                    </div>

                    {/* Lightbox */}
                    {isOpen && (
                      <Lightbox
                        mainSrc={galleryImage[photoIndex]}
                        nextSrc={
                          galleryImage[(photoIndex + 1) % galleryImage.length]
                        }
                        prevSrc={
                          galleryImage[
                            (photoIndex + galleryImage.length - 1) %
                              galleryImage.length
                          ]
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex + galleryImage.length - 1) %
                              galleryImage.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex((photoIndex + 1) % galleryImage.length)
                        }
                      />
                    )}
                  </div>
                ) : activeTab === "Announcements" ? (
                  <div className="c-gallery">
                    {/* Image Grid */}
                    <p
                      className="f-14 mb-3 mt-2"
                      style={{ color: `${heading} !important` }}
                    >
                      Join us for the upcoming events
                    </p>

                    <div className="c-grid">
                      {state.serviceAnnouncement.map((data, index) => (
                        <img
                          key={index}
                          src={data}
                          alt={`Image ${index}`}
                          className="grid-image c-pointer"
                          style={{ borderRadius: "5px" }}
                          onClick={() => {
                            setPhotoIndex(index);
                            setIsOpen(true);
                          }}
                        />
                      ))}
                    </div>

                    {/* Lightbox */}
                    {isOpen && (
                      <Lightbox
                        mainSrc={state.serviceAnnouncement[photoIndex]}
                        nextSrc={
                          state.serviceAnnouncement[
                            (photoIndex + 1) % state.serviceAnnouncement.length
                          ]
                        }
                        prevSrc={
                          state.serviceAnnouncement[
                            (photoIndex +
                              state.serviceAnnouncement.length -
                              1) %
                              state.serviceAnnouncement.length
                          ]
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setPhotoIndex(
                            (photoIndex +
                              state.serviceAnnouncement.length -
                              1) %
                              state.serviceAnnouncement.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setPhotoIndex(
                            (photoIndex + 1) % state.serviceAnnouncement.length
                          )
                        }
                      />
                    )}
                  </div>
                ) : activeTab === "Videos" ? (
                  <div className="c-gallery">
                    <div className="c-grid" style={{ gap: "2rem" }}>
                      {state.serviceProviderVideo.map((data, index) => (
                        <video
                          key={index}
                          style={{ width: "100%", height: "200px" }}
                          controls
                        >
                          <source src={data} />
                        </video>
                      ))}
                    </div>
                  </div>
                ) : activeTab === "Contact" ? (
                  <ul className="l-none test-align-start p-0">
                    <li className="d-flex">
                      <b style={{ color: heading }}>Email :</b>{" "}
                      <p style={{ color: subHeading }} className="ml-1">
                        service@gmail.com
                      </p>
                    </li>

                    <li className="d-flex">
                      <b style={{ color: heading }}>Phone Number :</b>{" "}
                      <p style={{ color: subHeading }} className="ml-1">
                        89272927292
                      </p>
                    </li>

                    <li className="d-flex">
                      <b style={{ color: heading }}>Country :</b>{" "}
                      <p style={{ color: subHeading }} className="ml-1">
                        India
                      </p>
                    </li>
                  </ul>
                ) : (
                  <div style={{ textAlign: "start" }}>
                    {/* <p className="mt-2 ">
                    <b>Service Name :</b> {eventDetails.serviceName}
                  </p> */}
                    <p className="mt-2 ">
                      <b style={{ color: heading }}>Service Description :</b>
                      <p style={{ color: subHeading }} className="f-14">
                        Yoga services focused on enhancing physical, mental, and
                        emotional well-being. Your sessions help individuals
                        improve flexibility, strength, and mindfulness through
                        guided practices. Whether for relaxation, fitness, or
                        stress relief, your tailored approach meets various
                        needs. You provide a supportive and inclusive space for
                        all levels, from beginners to advanced practitioners.
                        Your goal is to empower individuals to cultivate
                        balance, inner peace, and a healthier lifestyle through
                        yoga.
                      </p>
                    </p>

                    <p className="mt-2 mb-2">
                      <b style={{ color: heading }}>Our Website :</b>{" "}
                      <a
                        href={"http://demo.com"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </section>
          </div>
          <p
            style={{ color: heading }}
            className="f-14 align-center font-weight-bold  mt-auto   ml-3"
          >
            Powered by{" "}
            <a
              href="https://yuukke.com/"
              className="   ml-1"
              style={{
                textDecoration: "underline",
                color: `${heading} !important`,
              }}
            >
              Yuukke
            </a>
          </p>
        </div>
      </section>
      <section
        className="d-flex flex-column p-0 mt-4 position-relative"
        style={{
          flex: 1,
          maxWidth: "400px",
          width: "90%",
          margin: "4rem auto 0 auto",
          paddingTop: "5rem",
          position: "relative",
        }}
      >
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h4
            className="m-0"
            style={{ color: heading, fontWeight: "600", textAlign: "start" }}
          >
            Book Your Services
          </h4>
        </div>

        {/* Service List Container */}
        <div style={{ position: "relative" }}>
          <ul
            className="l-none booking-grid"
            // ref={listRef}
            style={{
              display: "flex",
              overflowX: "auto",
              flexWrap: "nowrap",
              gap: "1rem",
              paddingBottom: "0.5rem",
              color: "black",
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              scrollSnapType: "x mandatory",
            }}
          >
            {serviceList.map((item, i) => (
              <li
                // onClick={() => selectService(item)}
                key={i}
                className="w-100 h-100 cal-small-box box-rd text-capitalize d-flex flex-column"
                style={{
                  minWidth: "300px",
                  flexShrink: 0,
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  scrollSnapAlign: "center",
                  background: secondary,
                }}
              >
                <h4
                  className="m-0 three-line cal-heading"
                  style={{
                    fontWeight: "600",
                    textTransform: "capitalize",
                    maxWidth: "21rem",
                    color: heading,
                  }}
                >
                  {item.serviceName}
                </h4>
                <h6
                  style={{ color: subHeading }}
                  className="  cal-sub-heading mt-2"
                >
                  {item.appointmentValue}
                </h6>
                <section className="gray-box mt-auto">
                  <div className="d-flex align-items-center justify-content-center">
                    <IoCalendarOutline
                      style={{ color: heading }}
                      className=" svg-icons"
                    />
                    <p className="d-flex flex-column ml-2">
                      <span
                        style={{ color: heading }}
                        className="cal-sub-heading font-weight-bold"
                      >
                        {item.duration} mins
                      </span>
                      <span
                        style={{ color: subHeading }}
                        className="cal-sub-heading"
                      >
                        Video Meeting
                      </span>
                    </p>
                  </div>
                  <span className="gray-box font-weight-bold p-2">
                    <FaRupeeSign className="mr-1" />
                    {item.amount}
                  </span>
                </section>
              </li>
            ))}
          </ul>

          {/* Scroll Left Button - shown only if there is content to the left */}
          {serviceList.length > 1 && currentIndex > 0 && (
            <button
              style={{
                background: primary,
                color: heading,
                cursor: "pointer",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: "-25px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => handleScrolling(-1)}
            >
              <MdNavigateBefore
                style={{
                  color: "white",
                  fontSize: "32px",
                }}
              />
            </button>
          )}

          {/* Scroll Right Button - shown only if there is content to the right */}
          {serviceList.length > 1 && currentIndex < serviceList.length - 1 && (
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                right: "-25px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => handleScrolling(1)}
            >
              <MdNavigateNext
                style={{
                  color: "white",
                  fontSize: "32px",
                }}
              />
            </button>
          )}
        </div>

        {/* Counter Display */}
        {serviceList.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.5rem",
              marginTop: "1.5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "#333",
              }}
            >
              <span
                style={{
                  padding: "0.25rem 0.5rem",
                  backgroundColor: "rgb(113 21 21)",
                  color: "white",
                  borderRadius: "12px",
                  minWidth: "30px",
                  textAlign: "center",
                }}
              >
                {currentIndexSafe + 1}
              </span>
              <span style={{ color: "#666" }}>/</span>
              <span style={{ color: "white" }}>{serviceList.length}</span>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
