import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AuthService_old from "../session/AuthService_old";
import i18n from "../i18n";
import * as APPCONFIG from "../config/app-config.js";
import { API_URL } from "../config/app-config.js";
import dummyimage from "../img/default_avatar.jpg";
import { GoCalendar } from "react-icons/go";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import {
  getAllNotSawAskHelpList,
  userSawAskHelpEntrySave,
} from "../redux/actions/myRequestActions";
import BlockUi from "react-block-ui";
import { IoMdArrowRoundForward, IoMdExpand } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import { giveUserAction, premiumUser } from "../utils";
import { needPhotVerification } from "../redux/actions/commonApiActions";
class RightModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showEventMenu: true,
      pageNo: 0,
      tabType: "allRequest",
    };
  }

  componentDidMount() {
    console.log("showMenu");

    let langValue = AuthService_old.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }
  componentDidUpdate(prevProps) {
    if (this.props.show && this.props.show !== prevProps.show) {
      console.log("showMenu changed");
      this.setState({
        pageNo: 0,
        tabType: "allRequest",
      });
      this.props.getAllNotSawAskHelpList(0, "", "allRequest");
    }
  }
  nextRequest = () => {
    this.props.getAllNotSawAskHelpList(
      this.state.pageNo + 1,
      "",
      this.state.tabType
    );
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
  };
  render() {
    const { t } = this.props;

    return (
      <>
        <Modal
          // size="sm"
          style={{ right: 0 }}
          // className="right"
          className={this.props.show ? "right slide-in show" : "right slide-in"}
          show={this.props.show}
          onHide={this.props.handleClickAway}
        >
          <Modal.Title style={{ height: "auto" }}>
            <div className="righdropdownIcon">
              <div className="need-help-Modal">
                <div className="d-flex align-items-center mb-2 mt-3">
                  <button className="ml-2" onClick={this.addHelpForm}>
                    {t("Ask Help")}
                  </button>
                  <IoMdExpand
                    className="need-help-Modal-close"
                    style={{
                      color: "black",
                    }}
                    onClick={this.openOtherRequest}
                  />
                </div>

                <h5 className="ml-2">{t("ASK HELP REQUEST LIST")}</h5>
                {/* <p className="ml-2">Other Request list</p> */}

                <div
                  className="d-flex mb-4"
                  style={
                    {
                      // width: "31vw",
                    }
                  }
                >
                  <button
                    class={
                      this.state.tabType == "myRequest"
                        ? "req-active-menu"
                        : "req-un-active-menu"
                    }
                    onClick={() => this.requestTypeChange("myRequest")}
                  >
                    {t("My Requests")}
                    {/* <span class="badge badge-info ml-2">
                      {this.props?.otherRequestList?.length
                        ? this.props?.otherRequestList.length
                        : 0}
                    </span> */}
                    {/* )} */}
                  </button>
                  <button
                    class={
                      this.state.tabType == "allRequest"
                        ? "req-active-menu"
                        : "req-un-active-menu"
                    }
                    onClick={() => this.requestTypeChange("allRequest")}
                  >
                    {t("Other Requests")}
                    {/* <span class="badge badge-info ml-2">
                      {this.props?.otherRequestList?.length
                        ? this.props?.otherRequestList.length
                        : 0}
                    </span> */}
                  </button>
                </div>

                <BlockUi
                  tag="div"
                  style={{ minHeight: "100px" }}
                  blocking={this.props.homeRequestLoader}
                  message={t("Updating . . .")}
                  color="#A00030"
                >
                  <InfiniteScroll
                    dataLength={
                      this.props?.otherRequestList?.length
                        ? this.props.otherRequestList?.length
                        : 0
                    }
                    next={this.nextRequest}
                    hasMore={this.props.homeRequestSeeMore}
                    scrollableTarget="jobRequest-scrollable"
                    direction="down"
                    scrollThreshold={0.95}
                  >
                    <div
                      id="jobRequest-scrollable"
                      style={{
                        maxHeight: "78vh",
                        overflowX: "hidden",
                      }}
                    >
                      <div className="need-help-wrapper">
                        {this.props.otherRequestList &&
                          this.props.otherRequestList.map((request, index) => (
                            <div
                              className="need-help-card"
                              key={index}
                              onClick={() =>
                                this.gotoDis(request.jobId, request.userSaw)
                              }
                            >
                              <div className="arrow-request">
                                <BsArrowRight className="arrow-request-svg" />
                              </div>
                              <div class=" ask-help-imgCard">
                                <img
                                  className="rounded-circle"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  src={
                                    request.userId
                                      ? API_URL +
                                        "api/getUserPhoto/" +
                                        request.userId +
                                        "/" +
                                        request.userName
                                      : dummyimage
                                  }
                                  alt="user"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = dummyimage;
                                  }}
                                />
                              </div>
                              <div className="ml-2">
                                <div className="d-flex flex-wrap">
                                  <h6
                                    className="text-capitalize mr-2"
                                    style={{
                                      fontWeight:
                                        !request.userSaw &&
                                        this.state.tabType == "allRequest"
                                          ? "bolder"
                                          : "200",
                                    }}
                                  >
                                    {request.userName}
                                    {request.premiumData &&
                                      premiumUser(request.premiumData) && (
                                        <img
                                          src={premiumUser(request.premiumData)}
                                          alt=""
                                          className="ml-1 mr-3"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      )}
                                  </h6>
                                  {/* <a>
                                    <GoCalendar
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        marginBottom: "4px",
                                      }}
                                    ></GoCalendar>{" "}
                                    {moment(request.createdDate)
                                      .locale("en")
                                      .format("ll")}
                                  </a>{" "} */}
                                </div>
                                <p>{request.natureOfRequest}</p>
                                <p> {request.description} </p>
                              </div>
                            </div>
                          ))}
                        {this.props.otherRequestList &&
                          this.props.otherRequestList.length === 0 && (
                            <p style={{ fontSize: "12px" }}>
                              {t("No need help list present...")}
                            </p>
                          )}
                      </div>
                    </div>
                  </InfiniteScroll>
                </BlockUi>
              </div>
            </div>{" "}
          </Modal.Title>

          <Modal.Body></Modal.Body>
        </Modal>
      </>
    );
  }
  requestTypeChange = async (tabType) => {
    await this.setState({
      tabType: tabType,
      pageNo: 0,
    });
    this.props.getAllNotSawAskHelpList(0, "", tabType);
  };
  openOtherRequest = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.props.history.push(
        `/${this.props.workspaceName}/other-requests-list`
      );
      this.props.handleClickAway();
    }
  };
  gotoDis = (id, userSaw) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (!userSaw) {
        this.props.userSawAskHelpEntrySave(id);
      }
      this.props.handleClickAway();
      this.props.history.push(
        `/${this.props.workspaceName}/request-dis/${id}/${"other-req"}`
      );
    }
  };
  addHelpForm = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.props.handleClickAway();
      this.props.history.push(
        `/${this.props.workspaceName}/ask-help-form/${"new"}`
      );
    }
  };
}

const mapDispacthToProps = (dispatch) => {
  return {
    getAllNotSawAskHelpList: (pageNo, searchKey, tabType) =>
      dispatch(getAllNotSawAskHelpList(pageNo, searchKey, tabType)),
    userSawAskHelpEntrySave: (id) => dispatch(userSawAskHelpEntrySave(id)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};
const mapStateToProps = function (state) {
  return {
    workspaceName: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
    otherRequestList: state.myRequestReducers.homeOtherRequest,
    homeRequestSeeMore: state.myRequestReducers.homeRequestSeeMore,
    homeRequestLoader: state.myRequestReducers.homeRequestLoader,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(RightModal)));
