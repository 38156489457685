import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../../chatPanel/src/store/actions/MessengerAction";
import { toast } from "react-toastify";
import dummy_image from "../../../img/mentor-default.jpg";
import Picker from "emoji-picker-react";
import moment from "moment";
import { API_URL, CHAT_URL, JITSI_URL } from "../../../config/app-config";
import { BsCloudDownload } from "react-icons/bs";
import ReactPlayer from "react-player";
import { generateUlr } from "../../../utils";
import MyYuukke from "../../../api/MyYuukke";

function VcardChatBox({
  t,
  serviceUser,
  serviceProvider,
  handleChatListBoxClose,
  socket,
}) {
  // const store = useStore()
  const dispatch = useDispatch();
  const divref = useRef(null);
  const textRef = useRef(null);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isMeetingPanelOpen, setIsMeetingPanelOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isSchduleHovering, setIsSchduleHovering] = useState(false);
  const [showScheduledMeeting, setShowScheduledMeeting] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [state, setState] = useState({
    messageList: [],
  });
  // const [messages, setMessages] = useState([])
  // const [isMessageLoading, setIsMessageLoading] = useState(false)
  // const [instantMeetingLink, setInstantMeetingLink] = useState('')

  console.log("User Details :", serviceUser);

  const [newMessage, setNewMessage] = useState("");
  useEffect(() => {
    getChatList();
  }, [serviceUser]);
  useEffect(() => {
    const handleMessage = (data) => {
      console.log("Memoized State : getting Message", data);
      if (data) {
        console.log("messageList", state.messageList);
        let updateStatus = state.messageList.map((item) => {
          item.status = "seen";
          return item;
        });
        updateState("messageList", [...updateStatus, data]);
        divref.current?.scrollIntoView({ block: "end", behavior: "smooth" });
      }

      // setSocketMessage(data);

      // dispatch({
      //   type: "UPDATE_FRIEND_MESSAGE",
      //   payload: data,
      // });
      // dispatch({
      //   type: "UPDATE_RECEIVE_MESSAGE",
      //   payload: data,
      // });
      //received
      // setReceivedMessage(data);
    };

    const handleSeenMessageResponse = (data) => {
      console.log("message-seen", data);
      // dispatch({
      //   type: "MESSAGE_SEEN",
      //   payload: data,
      // });
    };

    const handleDeliveredMessageResponse = (data) => {
      console.log("message_delivered", data);
      // dispatch({
      //   type: "MESSAGE_DELIVERED",
      //   payload: data,
      // });
    };

    socket.current.on("getServiceMessage", handleMessage);
    socket.current.on("seenMessageResponse", handleSeenMessageResponse);
    socket.current.on(
      "deliveredMessageResponse",
      handleDeliveredMessageResponse
    );

    return () => {
      socket.current.off("getMessage", handleMessage);
      socket.current.off("seenMessageResponse", handleSeenMessageResponse);
      socket.current.off(
        "deliveredMessageResponse",
        handleDeliveredMessageResponse
      );
    };
  }, [socket, state.messageList]);
  //   const sendNotification = (message) => {

  //     const params = {
  //       receiverId: message.receiverId,
  //       senderId: message.senderId,
  //       notification: `${message.senderName} sent you a Message`,
  //       // notificationType: "chat_notification",
  //       status: "new_chat_message",
  //       notificationType: 9,
  //     };

  //     console.log("Notification Results :", params);

  //     ProfileService.sendCustomNotification(params).then((data) => {
  //       console.log("Notification Results :", data);
  //     });
  //   };

  const messagePayload = (message, isMeeting = false) => {
    return {
      senderName: serviceUser.name,
      senderId: serviceUser.id,
      receiverId: serviceProvider.userId,
      message: message,
      isMeeting: isMeeting,
      messageType: "service",
      serviceDetails: {
        serviceProviderId: serviceProvider.userId,
        serviceRequestId: serviceUser.id,
        serviceRequestUserName: serviceUser.name,
        serviceProverName: serviceProvider.userName,
      },
    };
  };

  const handleKeyPress = (e) => {
    console.log("newMessage.length", newMessage.length);
    console.log("newMessage.trim()", newMessage.trim());
    console.log("newMessage.trim()", newMessage.trim().length);
    if (e.keyCode === 13) {
      setNewMessage((prevText) => prevText + "\n");
    }
    // const textarearef = textRef.current;

    // if (e.key === "Enter" && !e.shiftKey) {
    //   if (newMessage.trim().length === 0) {
    //     e.preventDefault();
    //     setNewMessage("");
    //     textarearef.setSelectionRange(0, 0);
    //     textarearef.focus();
    //   } else {
    //     e.preventDefault();
    //     console.log(textarearef);

    //     const data = messagePayload(newMessage.trim());
    //     setNewMessage("");
    //     textarearef.focus();
    //     setIsEmojiOpen(false);
    //     dispatch(sendMessage(data));
    //   }
    // }
  };
  const handleSendMessage = (e) => {
    if (newMessage.trim().length === 0) {
      e.preventDefault();
      setNewMessage("");
      setIsEmojiOpen(false);
    } else {
      e.preventDefault();
      const data = messagePayload(newMessage.trim());
      //   socket.current.emit("sendMessage", messages[messages.length - 1]);
      //   console.log(
      //     "Memoized State : send Meesage",
      //     messages[messages.length - 1]
      //   );

      //   setNewMessage("");
      setIsEmojiOpen(false);
      MyYuukke.sendMessage(data)
        .then((res) => {
          console.log("res", res);
          setNewMessage("");
          let data = res.data.data;
          data.messageType = "guest";
          if (data) {
            socket.current.emit("sendMessage", data);
          }
          updateState("messageList", [...state.messageList, res.data.data]);
          divref.current?.scrollIntoView({ block: "end", behavior: "smooth" });
        })
        .catch((e) => {
          console.log("e", e);
          setNewMessage("");
          toast.error("Something went wrong");
        });
      //   dispatch(sendMessage(data));
    }
  };
  const handleEmoji = (e, { emoji }) => {
    const ref = textRef.current;
    ref.focus();
    const start = newMessage.substring(0, ref.selectionStart);
    const end = newMessage.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setNewMessage(newText);
  };

  const handleScheduledMeeting = (data) => {
    console.log(data);
    const message = {
      meeting: data,
    };

    const formData = messagePayload(message, true);

    console.log(formData);
    // dispatch(InstantMeetingAction(formData));
  };
  const handleClose = () => {
    setShowScheduledMeeting(false);
  };

  const handleInstantMeeting = (e) => {
    e.preventDefault();
    setIsMeetingPanelOpen(false);
    const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
      serviceProvider.userId
    }-${Date.now()}`;

    console.log("Meeting Link : ->", instantMeetingLink);
    const message = {
      meeting: {
        meetingLink: instantMeetingLink,
        meetingType: "Instant",
        meetingTime: new Date(),
      },
    };

    const data = messagePayload(message, true);

    // dispatch(InstantMeetingAction(data));
  };

  //   useEffect(() => {
  //     divref.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  //   }, [messages]);

  const OpenEmojiContainer = () => {
    setIsEmojiOpen(!isEmojiOpen);
  };

  const handleShowModalScreen = () => {
    setIsMeetingPanelOpen(false);
    setShowScheduledMeeting(true);
  };

  const handleSetModalScreenClose = () => {
    setShowScheduledMeeting(false);
  };
  const getChatList = () => {
    MyYuukke.getMesageList(serviceUser.id, serviceProvider.userId)
      .then((res) => {
        console.log("res", res);
        updateState("messageList", res.data.data);
        divref.current?.scrollIntoView({ block: "end", behavior: "smooth" });
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong");
      });
  };
  //messageList
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const ReadMore = ({ children }) => {
    // const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    const text = children.split("\n").map((line, index, array) => (
      <Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </Fragment>
    ));
    return (
      <p className="text">
        <div
        // dangerouslySetInnerHTML={{
        //   __html: isReadMore ? text.slice(0, 150) : text,
        // }}
        />
        <div>{text}</div>
        {/* {isReadMore ? text.slice(0, 150) : text} */}
        {/* <span
          onClick={toggleReadMore}
          className="read-or-hide"
          style={{ color: "#A00030", cursor: "pointer" }}
        >
          {text.length > 150
            ? isReadMore
              ? "...read more"
              : " show less"
            : ""}
        </span> */}
      </p>
    );
  };
  console.log("serviceProvider", serviceProvider);
  console.log("serviceUser", serviceUser);
  return (
    <div className="chatItemBox-container" style={{ right: "2rem" }}>
      <div className="chatItemBox--header" style={{ position: "relative" }}>
        <div className="chatItemBox-img-container">
          <img
            src={
              API_URL +
              "api/getUserPhoto/" +
              serviceProvider.userId +
              "/" +
              "name"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = dummy_image;
            }}
            alt="img"
            className="chatItemBox-avatar-img img"
          />

          <span>
            {
              /* currentFriend.userProfile.displayName */ serviceProvider?.userName
            }
          </span>
        </div>
        <div className="chatItemBox-icons-container">
          {/* <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
              className="chatItemBox-icon more-icons"
            >
              <path d="M3 9.5A1.5 1.5 0 114.5 8 1.5 1.5 0 013 9.5zM11.5 8A1.5 1.5 0 1013 6.5 1.5 1.5 0 0011.5 8zm-5 0A1.5 1.5 0 108 6.5 1.5 1.5 0 006.5 8z"></path>
            </svg>
          </div> */}
          {/* <div onClick={handleShowMeeting}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
              className="chatItemBox-icon video-icons"
            >
              <path d="M1 5v6a2 2 0 002 2h8V3H3a2 2 0 00-2 2zm4 0h2v2h2v2H7v2H5V9H3V7h2zm10-1v8h-1.5l-1.5-.75v-6.5L13.5 4z"></path>
            </svg>
          </div> */}
          {/* <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
              className="chatItemBox-icon shrink-icons"
            >
              <path d="M8 6.59L6.59 8 3 4.41V7H1V1h6v2H4.41zM13 9v2.59L9.41 8 8 9.41 11.59 13H9v2h6V9z"></path>
            </svg>
          </div>  */}
          <div onClick={() => handleChatListBoxClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
              className="chatItemBox-icon close-icons"
            >
              <path d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"></path>
            </svg>
          </div>
        </div>
        {/* <div
          style={{
            position: "absolute",
            top: "120%",
            right: "0",
            zIndex: "999",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            padding: "1rem",
            display: isMeetingPanelOpen === true ? "block" : "none",
            background: "white",
          }}
        >
          <p
            onClick={handleInstantMeeting}
            style={{
              padding: isHovering ? "0.5rem 0.2rem" : "0px 0px 0.5rem 0px",
              // color: isHovering ? "black" : "black",
              color: "black",
              cursor: "pointer",
              background: isHovering ? "#cacacad1" : "white",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {i18next.t("Instant Meeting")}
          </p>
          <p
            onClick={handleShowModalScreen}
            style={{
              padding: isSchduleHovering
                ? "0.5rem 0.2rem"
                : "0.5rem 0px 0rem 0px",
              color: isSchduleHovering ? "black" : "black",
              cursor: "pointer",
              background: isSchduleHovering ? "#cacacad1" : "white",
            }}
            onMouseEnter={handleSchduleMouseEnter}
            onMouseLeave={handleSchduleMouseLeave}
          >
            {i18next.t("Schedule Meeting")}
          </p>
        </div> */}
      </div>
      <div className="chatItemBox--body">
        <div className="chatItemBox-message-list">
          {state.messageList && state.messageList.length > 0 && (
            <>
              {state.messageList.map((message, i) => {
                return (
                  <div
                    className="chatItemBox-message-item"
                    ref={divref}
                    key={i}
                    id={i}
                  >
                    <>
                      <img
                        src={
                          API_URL +
                          "api/getUserPhoto/" +
                          message.senderId +
                          "/" +
                          "name"
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummy_image;
                        }}
                        alt="avatar"
                        className="img"
                      />
                      <div className="chatItemBox-message-item-user position-relative">
                        <h3 className="chatItemBox--body-sender-name">
                          {message.senderName}
                          <span className="d-flex position-relative flex-column">
                            {moment(message.created_at)
                              .locale("en")
                              .startOf("mini")
                              .fromNow()}
                            {serviceUser.id === +message?.senderId && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "18px",
                                  right: "16px",
                                }}
                              >
                                <div
                                  className={`${
                                    message?.status === "seen"
                                      ? "message-seen"
                                      : "message-deliver"
                                  } message-check position-relative bg-white`}
                                  style={{ index: 1 }}
                                ></div>
                                <div
                                  className={`${
                                    message?.status === "seen"
                                      ? "message-seen"
                                      : message?.status === "sent"
                                      ? "d-none"
                                      : "message-deliver"
                                  } message-check`}
                                ></div>
                              </div>
                            )}
                          </span>
                        </h3>
                        {message.message.text.length === 0 &&
                          message.isMeeting === false && (
                            <p
                              className="chatItemBox--body-sender-message"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {message.message.image.map((image) => {
                                console.log(`image -> ${image}`);
                                console.log(`${CHAT_URL}api/getImage/${image}`);

                                const imageTypeList = [
                                  "png",
                                  "jpg",
                                  "jpeg",
                                  "gif",
                                ];
                                const document = ["pdf", "txt"];
                                const imageTypeIndex = image.lastIndexOf(".");
                                const imageType = image
                                  .substring(imageTypeIndex + 1)
                                  .toLowerCase();
                                const isImageType =
                                  imageTypeList.includes(imageType);
                                const isDocument = document.includes(imageType);
                                console.log(
                                  "%c IS IMAGE TYPE :",
                                  "color:green",
                                  isImageType
                                );
                                console.log(
                                  "%c IS Pdf TYPE :",
                                  "color:green",
                                  isDocument
                                );
                                if (isImageType) {
                                  return (
                                    <div>
                                      <a
                                        href={`${CHAT_URL}api/getImage/${image}`}
                                        download
                                        target="_blank"
                                      >
                                        <img
                                          style={{
                                            width: "10rem",
                                            height: "10rem",
                                            marginBottom: ".3rem",
                                            border: "2px solid gray",
                                            borderRadius: "2px",
                                          }}
                                          src={`${CHAT_URL}api/getImage/${image}`}
                                          alt="Image Not Found"
                                        />
                                      </a>
                                    </div>
                                  );
                                } else if (isDocument) {
                                  return (
                                    <div
                                      className="justify-content-center"
                                      style={{
                                        width: "12rem",
                                        height: "8rem",
                                        marginBottom: ".3rem",
                                        border: "2px solid gray",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <a
                                        href={`${CHAT_URL}api/getImage/${image}`}
                                        download
                                        target="_blank"
                                        style={{
                                          background: "gray",
                                          display: "flex",
                                          flex: "3",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <BsCloudDownload
                                          size="5em"
                                          color="white"
                                        />
                                      </a>{" "}
                                      <a
                                        href={`${CHAT_URL}api/getImage/${image}`}
                                        target="_blank"
                                      >
                                        Click to view document
                                      </a>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      style={{
                                        width: "auto",
                                        height: "13rem",
                                        // marginBottom : ".3rem",
                                        // border: "2px solid gray",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <ReactPlayer
                                        className="player"
                                        controls
                                        url={`${CHAT_URL}api/getImage/${image}`}
                                      />

                                      {/* <a
                                            href={`${CHAT_URL}api/getImage/${image}`}
                                            download
                                            target="_blank"
                                            style={{
                                              background: "gray",
                                              display: "flex",
                                              flex: "3",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <BsCloudDownload
                                              size="5em"
                                              color="white"
                                            />
                                          </a>
                                          <div style={{ display: "flex" }}>
                                            click to download File
                                          </div> */}
                                    </div>
                                  );
                                }
                              })}
                            </p>
                          )}
                        {message.message.text.length > 0 &&
                          message.isMeeting === false && (
                            <p className="chatItemBox--body-sender-message">
                              <ReadMore>{message.message.text}</ReadMore>
                            </p>
                          )}
                        {message.isMeeting && (
                          <p
                            style={{
                              width: "95%",
                              height: "5rem",
                              background: "rgb(238, 243, 248)",
                              borderRadius: "5px",
                              display: "flex",
                              gap: "1rem",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            className="bg-color"
                            onClick={() =>
                              generateUlr(
                                `${JITSI_URL}${message?.message?.meeting?.meetingLink}?authenticated=true&sp=yes`
                              )
                            }
                            // href={`${JITSI_URL}${message?.message?.meeting?.meetingLink}?authenticated=true#config`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 6px",
                                borderRight: "1px solid lightgray",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                data-supported-dps="16x16"
                                fill="currentColor"
                                width="24"
                                height="24"
                                focusable="false"
                                className="chatItemBox-icon video-icons"
                              >
                                <path d="M1 5v6a2 2 0 002 2h8V3H3a2 2 0 00-2 2zm4 0h2v2h2v2H7v2H5V9H3V7h2zm10-1v8h-1.5l-1.5-.75v-6.5L13.5 4z"></path>
                              </svg>
                            </div>
                            {/* <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    columnGap: "2px",
                                    flex: "1",
                                    justifyContent: "center",
                                  }}
                                >
                                  {message?.message?.meeting?.meetingType ===
                                  "Scheduled" ? (
                                    <>
                                      <p>
                                        {message?.message?.meeting
                                          ?.meetingName || "Join Meeting"}
                                      </p>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "gray",
                                          textDecoration: "none",
                                        }}
                                      >
                                        {moment(
                                          message?.message?.meeting?.meetingTime
                                        )
                                          .locale("en")
                                          .format("dddd, MMMM Do YYYY, h:mm a")}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "gray",
                                        }}
                                      >
                                        {message?.message?.meeting
                                          ?.isMeetingfinished
                                          ? "Meeting Ended"
                                          : "Join Video Meeting"}{" "}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <p>
                                        {+message.senderId === +user.uId
                                          ? `${user.dName}'s Meeting`
                                          : `${
                                             }'s Meeting`}
                                      </p>
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "gray",
                                        }}
                                      >
                                        {message?.message?.meeting
                                          ?.isMeetingfinished
                                          ? "Meeting Ended"
                                          : "Join Video Meeting"}
                                      </span>
                                    </>
                                  )}
                                </div> */}
                          </p>
                        )}
                      </div>
                    </>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <div className="chatItemBox--footer" style={{ position: "relative" }}>
        {/* {showScheduledMeeting && (
          <ScheduleForm
            handleSchduledMeeting={handleScheduledMeeting}
            loginedUserDetails={user}
            users={currentFriend}
            showModalScreen={handleScheduledMeeting}
            handleClose={handleClose}
            handleModalScreenVisibility={handleSetModalScreenClose}
          />
        )} */}
        <div className="chatItemBox--footer-text-box">
          <textarea
            ref={textRef}
            name=""
            id=""
            placeholder="Write a Message"
            value={newMessage}
            // onChange={handleEmoji}
            onKeyPress={handleKeyPress}
            onChange={(e) => setNewMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="chatItemBox--footer-send-box">
          <div className="chatItemBox--footer-emoji-container">
            {/* <div>
              <label for="file-input" style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  data-supported-dps="16x16"
                  fill="currentColor"
                  width="16"
                  height="16"
                  focusable="false"
                >
                  <path d="M14 3H2a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1zM3 11l3-2.95L9 11H3zm10 0h-2.77L6.31 7.13a.44.44 0 00-.62 0L3 9.77V5h10v6zm-2.5-2A1.5 1.5 0 109 7.5 1.5 1.5 0 0010.5 9zm0-2.25a.75.75 0 11-.75.75.75.75 0 01.75-.75z"></path>
                </svg>
              </label>
              <input
                onChange={ImageSend}
                type="file"
                name="file"
                id="file-input"
                multiple
                style={{ display: "none", cursor: "pointer" }}
              />
            </div> */}

            <div onClick={OpenEmojiContainer} style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                data-supported-dps="16x16"
                fill="currentColor"
                width="16"
                height="16"
                focusable="false"
              >
                <path d="M4.84 6A1.16 1.16 0 116 7.17 1.17 1.17 0 014.84 6zM8 9.38a3.51 3.51 0 01-2.3-.81l-.83 1.29a4.87 4.87 0 006.25 0l-.82-1.28a3.51 3.51 0 01-2.3.8zm2-4.55A1.17 1.17 0 1011.16 6 1.17 1.17 0 0010 4.83zM8 2.88A5.12 5.12 0 112.88 8 5.12 5.12 0 018 2.88M8 1a7 7 0 107 7 7 7 0 00-7-7z"></path>
              </svg>
            </div>
          </div>
          <div className="chatItemBox--footer-send-button">
            <button
              disabled={newMessage.trim().length > 0 ? "" : "disabled"}
              onClick={handleSendMessage}
              style={{ color: "white" }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
      {isEmojiOpen && (
        <div className="emojiContainer">
          <Picker
            onEmojiClick={handleEmoji}
            disableAutoFocus={true}
            groupNames={{ smileys_people: "PEOPLE" }}
            native
          />
        </div>
      )}
    </div>
  );
}

export default VcardChatBox;
