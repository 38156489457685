import React from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "./NewPost.css";
import "./myPost.css";
import i18n from "../../../i18n";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { withTranslation } from "react-i18next";
import AuthService from "../../../session/AuthService_old";
import MessagesFileOption from "../../Community/Subjects/MessagesFileOption";
import { Grid } from "@material-ui/core";
import dummyimage from "../../../img/default_avatar.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import * as APPCONFIG from "../../../config/app-config.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextEditor from "../../../components/TextEditorHomePost";
import SurveyUi from "./SurveyUi";
import {
  getAllUserCommunityList,
  getPostTagList,
  triggerTourPopup,
} from "../../../redux/actions/commonApiActions";
import {
  checkAudioURL,
  checkImageURL,
  checkPDFURL,
  checkVideoURL,
  extractMentionsFromHTML,
  isYuukkeWorkspace,
} from "../../../utils";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import VideocamIcon from "@material-ui/icons/Videocam";
import DescriptionIcon from "@material-ui/icons/Description";
import { MdOutlineLock } from "react-icons/md";
import { GrOverview } from "react-icons/gr";
import AnonymousModel from "../../../components/AnonymousModel.jsx";
import { getSubscriptionStatus } from "../../../redux/actions/calendarAction.js";
import { subscriptionModelStatus } from "../../../redux/actions/calendarAction.js";
import TextEditorQuestion from "../../../components/TextEditorQuestion.jsx";
let newAnswer = new Array(2);
class NewPostDialogue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
      type: null,
      mediaPost: "",
      content: "",
      fileTypeKey: "",
      fileTypeState: false,
      fileOption: false,
      active: false,
      questionDescribed: "",
      programType: "General",
      postingType: "normal",
      allSubjects: [],
      tagDatas: [],
      inputs: [],
      frPollAnswer: [],
      frSurveyQuestion: [],
      frSurveyAnswer: [],
      selectGroupName: "",
      pollQuestion: "",
      pollQuestionTitle: "",
      inputsQuestion: [],
      SurveyQuestionandAns: [],
      surveyDataClear: false,
      articleHeading: "",
      anonymousPost: false,
      anonymousModel: false,
      editorPollQuestionTitle: "",
    };
    this.handleInputPollAnswer = this.handleInputPollAnswer.bind(this);
    this.handlearticleHeadingChange =
      this.handlearticleHeadingChange.bind(this);
    this.handleInputQuestion = this.handleInputQuestion.bind(this);
    this.myRef = React.createRef();
    this.editorRef = React.createRef();
    this.addTranslation = this.addTranslation.bind(this);
    this.addMoreQuestions = this.addMoreQuestions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.keyFile = this.keyFile.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.removeMoreQuestions = this.removeMoreQuestions.bind(this);
    this.removeSurveyAnswers = this.removeSurveyAnswers.bind(this);
    this.getQuestionMediaFiles = this.getQuestionMediaFiles.bind(this);
    this.closeChoosedFileData = this.closeChoosedFileData.bind(this);
    this.fileOptionEnable = this.fileOptionEnable.bind(this);
    this.PostActive = this.PostActive.bind(this);
    this.editorStateValues = this.editorStateValues.bind(this);
    this.fileGroupUpload = this.fileGroupUpload.bind(this);
    this.gotogroupDescription = this.gotogroupDescription.bind(this);
    this.searchSubjects = this.searchSubjects.bind(this);
    this.handlepostingType = this.handlepostingType.bind(this);
    this.handlesurveyQuestionTypee = this.handlesurveyQuestionTypee.bind(this);
    this.pollingEditor = this.pollingEditor.bind(this);
  }

  componentDidMount() {
    this.handlepostingType(this.props.type ? this.props.type : "normal");
    this.props.getPostTagList("friends");
    if (!this.props.toursStages) {
      console.log("Trigger Tour popup");
      this.props.triggerTourPopup("home");
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    if (this.props.tagDatas !== undefined) {
      if (this.props.tagDatas.length > 0) {
        this.setState({ allSubjects: this.props.tagDatas });
      }
    }
    console.log("FirstTwocomment****", this.state.active);
  }

  addMoreQuestions = (e) => {
    var input = this.state.inputsQuestion;
    input.push(1);
    this.setState({
      inputsQuestion: input,
    });
  };

  addTranslation = (e) => {
    var len = this.state.inputs.length;
    if (
      this.state.frPollAnswer.length !== 0 &&
      this.state.frPollAnswer[len - 1] &&
      this.state.frPollAnswer[len - 1].Sname !== ""
    ) {
      console.log("inp", this.state.frPollAnswer[len - 1].Sname);
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
      });
    } else {
      toast.warning("Please fill option");
    }
  };

  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    if (this.state.inputs.length > 1) {
      var inpu = this.state.inputs;
      inpu.splice(index, 1);
      if (this.state.frPollAnswer[index]) {
        var val = this.state.frPollAnswer;
        val.splice(index, 1);
        this.setState({
          frPollAnswer: val,
        });
      }
      await this.setState({
        inputs: inpu,
      });
    }
  }

  async removeMoreQuestions(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputsQuestion);
    var inpu = this.state.inputsQuestion;
    inpu.splice(index, 1);
    if (this.state.frSurveyQuestion[index]) {
      var val = this.state.frSurveyQuestion;
      val.splice(index, 1);
      this.setState({
        frSurveyQuestion: val,
      });
    }
    await this.setState({
      inputsQuestion: inpu,
    });
  }

  async removeSurveyAnswers(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputsSurveyAnswer);
    var inpu = this.state.inputsSurveyAnswer;
    inpu.splice(index, 1);
    if (this.state.frSurveyAnswer[index]) {
      var val = this.state.frSurveyAnswer;
      val.splice(index, 1);
      this.setState({
        frSurveyAnswer: val,
      });
    }
    await this.setState({
      inputsSurveyAnswer: inpu,
    });
  }

  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
      selectGroupName: "",
    });
    if (event.target.value === "Groups") {
      this.props.getAllUserCommunityList();
    }
  }

  handlepostingType = async (value) => {
    if (value === "advertisement") {
      this.props.getSubscriptionStatus("adpost");
    }
    await this.setState({
      // [event.target.name]: event.target.value,
      postingType: value,
      frPollAnswer: [],
      inputs: [1],
    });
  };

  handlesurveyQuestionTypee(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChange(e) {
    let files = e.target.files[0];

    this.setState({ file: files });
  }
  handleChange(event) {
    let files = event.target.files[0];
    this.setState(
      {
        file: files,
        fileUrl: URL.createObjectURL(event.target.files[0]),
        type: event.target.files[0].type,
      },
      () => {}
    );
  }

  clearData = () => {
    this.setState({
      surveyDataClear: true,
    });
  };

  render() {
    const data = this.props.loginDetails.profile_uuid;
    const userName = this.props.loginDetails.dName;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    const { type, fileUrl } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.props.closeModel}
          aria-labelledby="bookaCall"
          aria-describedby="bookaCall"
          maxWidth="md"
          fullWidth={true}
          style={{ overflow: "scroll" }}
          // style={{ width: 700, margin: "auto", borderRadius: 25 }}
        >
          <div style={{ overflow: "scroll" }}>
            <DialogTitle id="bookaCall" className="homepostTitleWrapper">
              {t("Create Post")}

              <IconButton aria-label="close" onClick={this.props.closeModel}>
                <CloseIcon onClick={this.clearData} />
              </IconButton>
            </DialogTitle>
            <DialogContent className="">
              <Grid container spacing={2} style={{ display: "inline-flex" }}>
                <Grid item md={1} xs={1} sm={1} lg={1}>
                  <div className="postuserImg">
                    <img
                      className="rounded-circle"
                      src={profileImage}
                      style={{ width: "100%", height: "100%" }}
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyimage;
                      }}
                    />
                  </div>
                </Grid>

                <Grid item md={1} xs={0} sm={0} lg={1}></Grid>

                <Grid item md={10} xs={12} sm={12} lg={10}>
                  <div className="">
                    <h6 style={{ paddingTop: "6px" }}>{userName}</h6>
                    {this.state.postingType !== "article" && (
                      <Grid
                        container
                        spacing={1}
                        style={{ display: "inline-flex" }}
                      >
                        <Grid item md={2} xs={4} sm={4} lg={2}>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "gray",
                              paddingTop: "6px",
                            }}
                          >
                            {t("Posting in")}
                          </p>
                        </Grid>

                        <Grid item md={5} xs={5} sm={5} lg={5}>
                          <div className="homePostInputWrapper home-tour-type">
                            <select
                              name="programType"
                              id="programType"
                              onChange={this.handleForm}
                            >
                              <option value="General">{t("General")}</option>
                              <option value="Friends">{t("Friends")}</option>
                              <option value="Groups">{t("Community")}</option>
                            </select>
                          </div>
                        </Grid>

                        {this.state.programType == "Groups" && (
                          <Grid item md={5} xs={12} sm={12} lg={5}>
                            <div className="homePostInputWrapper">
                              <TextField
                                id=""
                                label=""
                                style={{ width: "100%", height: "20px" }}
                                variant="outlined"
                                value={this.state.selectGroupName}
                                onChange={(e) =>
                                  this.searchSubjects(e, e.target.value)
                                }
                                placeholder={t("Search community")}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              {this.state.tagDatas.length > 0 && (
                                <div className="homepostgroupcommunityCard">
                                  {this.state.tagDatas &&
                                    this.state.tagDatas.length > 0 &&
                                    this.state.tagDatas.map(
                                      (subject, index) => (
                                        <div
                                          onClick={() =>
                                            this.gotogroupDescription(
                                              subject.subjectId,
                                              subject.subjectName
                                            )
                                          }
                                        >
                                          <p
                                            style={{
                                              padding: "8px",
                                            }}
                                          >
                                            {subject.subjectName}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    <Grid
                      container
                      spacing={1}
                      style={{ display: "inline-flex", marginTop: "8px" }}
                    >
                      <Grid item md={2} xs={4} sm={4} lg={2}>
                        <p
                          style={{
                            fontSize: "13px",
                            color: "gray",
                            paddingTop: "6px",
                          }}
                        >
                          {t("Post type")}
                        </p>
                      </Grid>
                      <Grid item md={5} xs={5} sm={5} lg={5}>
                        <div className="homePostInputWrapper home-tour-category">
                          <select
                            name="postingType"
                            id="postingType"
                            onChange={(e) =>
                              this.handlepostingType(e.target.value)
                            }
                            value={this.state.postingType}
                          >
                            <option value="normal">{t("Normal Post")}</option>
                            <option value="advertisement">
                              {t("Advertisement Post")}
                            </option>
                            <option value="survey">{t("Survey Post")}</option>
                            <option value="polling">{t("Polling Post")}</option>
                            {this.state.programType === "General" && (
                              <option value="article">{t("Article")}</option>
                            )}
                          </select>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              {(this.state.postingType == "normal" ||
                this.state.postingType == "advertisement") && (
                <div className="uploadPostInput">
                  {/* <p
                    style={{
                      fontSize: "20px",
                      color: "#d6d6d6",
                      border: "none",
                      paddingTop: "12px",
                      paddingBottom: "8px",
                    }}
                  >
                    {t("Post Title")}
                  </p> */}
                  <div className="postEditor home-tour-posting-Tab">
                    <TextEditor
                      friendslist={
                        this.state.postingType === "advertisement"
                          ? []
                          : this.props.memberList
                      }
                      ref={this.myRef}
                      editorStateValue={this.editorStateValues}
                      editorValue={this.state.questionDescribed}
                      isArticle={
                        this.state.postingType === "article" ? true : false
                      }
                    />
                  </div>
                </div>
              )}

              {/* Add survey ui here */}
              {this.state.postingType == "survey" && (
                <SurveyUi
                  surveyQuestion={this.SurveyQuestionandAns}
                  DataClear={this.state.surveyDataClear}
                />
              )}

              {/* Add Polling ui here */}
              {this.state.postingType == "polling" && (
                <div
                  className="Polling"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <Grid>
                    <div className="PTCInputWrapper">
                      <Grid container>
                        <Grid className="gridform" item xs={12} sm={12}>
                          <div className="askAQuestionDescription home-tour-posting-Tab mb-5">
                            <label className="formLabel">
                              {t("What do you want to talk about?")} *
                            </label>

                            <TextEditorQuestion
                              ref={this.editorRef}
                              editorStateValue={this.pollingEditor}
                              editorValue={this.state.editorPollQuestionTitle}
                            />
                          </div>
                          {/* <textarea
                            style={{
                              width: "100%",
                              minHeight: "200px",
                              borderRadius: "5px",
                              padding: "20px 20px",
                              background: "lightgrey",
                              border: "none",
                            }}
                            type="text"
                            onChange={this.handleForm}
                            required=""
                            name="pollQuestionTitle"
                            value={this.state.pollQuestionTitle}
                            placeholder={this.props.t("What do you want to talk about?")}
                          /> */}
                        </Grid>
                        <Grid className="gridform" item xs={12} sm={12}>
                          <textarea
                            style={{
                              width: "100%",
                              minHeight: "38px",
                              borderRadius: "5px",
                              padding: "20px 20px",
                              background: "lightgrey",
                              border: "none",
                            }}
                            type="text"
                            onChange={this.handleForm}
                            required=""
                            name="pollQuestion"
                            value={this.state.pollQuestion}
                            placeholder={this.props.t("Ask your question")}
                          />
                        </Grid>
                        {this.state.inputs.length > 0 &&
                          this.state.inputs.map((entry, index) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBlock: "1rem",
                                width: "100%",
                              }}
                            >
                              <span key={index} class="socialCardTextColor">
                                Option - {index + 1}
                              </span>

                              <Grid container spacing={2} key={entry.index}>
                                <Grid className="gridform" item xs={12} sm={11}>
                                  <input
                                    className="cpollInput"
                                    fullWidth
                                    type="text"
                                    onChange={(e) =>
                                      this.handleInputPollAnswer(
                                        e,
                                        index,
                                        "name"
                                      )
                                    }
                                    style={{
                                      width: "100%",
                                      height: "4rem",
                                      border: "none",
                                      backgroundColor: "lightgray",
                                      padding: "20px",
                                    }}
                                    required=""
                                    name="frPollAnswer"
                                    placeholder="Add your option"
                                    value={
                                      this.state.frPollAnswer &&
                                      this.state.frPollAnswer[index] &&
                                      this.state.frPollAnswer[index].Sname
                                    }
                                  />
                                </Grid>

                                <Grid className="gridform" item xs={12} sm={1}>
                                  <span
                                    style={{
                                      display: "block",
                                      cursor: "pointer",
                                      // padding: "20px",
                                      fontSize: "35px",
                                    }}
                                    class="socialCardTextColor"
                                    onClick={() =>
                                      this.removeTranslation(entry, index)
                                    }
                                  >
                                    {" "}
                                    -{" "}
                                  </span>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        <span
                          style={{
                            cursor: "pointer",
                            marginTop: "20px",
                            paddingBottom: "20px",
                          }}
                          class="socialCardTextColor"
                          onClick={this.addTranslation}
                        >
                          + {t("Add more Option")}
                        </span>
                      </Grid>
                    </div>
                  </Grid>
                </div>
              )}

              {this.state.postingType === "article" && (
                <>
                  <div style={{ paddingBlock: "1rem" }}>
                    <TextField
                      placeholder="Enter Title"
                      variant="outlined"
                      fullWidth
                      label="Title"
                      value={this.state.articleHeading}
                      onChange={this.handlearticleHeadingChange}
                    />
                  </div>
                  <div>
                    <div className="postEditor">
                      <TextEditor
                        friendslist={[]}
                        ref={this.myRef}
                        editorStateValue={this.editorStateValues}
                        editorValue={this.state.questionDescribed}
                        isArticle={
                          this.state.postingType === "article" ? true : false
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              {this.state.fileTypeState && (
                <div>
                  <AiOutlineCloseCircle
                    onClick={this.closeChoosedFileData}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                    }}
                  />

                  <MessagesFileOption
                    fileTypeKey={this.state.fileTypeKey}
                    fileOption={this.state.fileOption}
                    fileOptionEnable={this.fileOptionEnable}
                    fromQuestionMedia={this.getQuestionMediaFiles}
                    editObject={this.props.editObject}
                    needCount={
                      this.state.postingType === "article" ? true : false
                    }
                  ></MessagesFileOption>
                </div>
              )}
            </DialogContent>
            <DialogActions className="BACScheduleCallBtn">
              <Grid container spacing={0} style={{ display: "inline-flex" }}>
                <Grid item md={8} xs={12} sm={12} lg={8}>
                  {!this.state.fileTypeState && (
                    <div
                      style={{ display: "inline-flex" }}
                      className="home-tour-attach"
                    >
                      <ul
                        style={{ marginBottom: "12px" }}
                        class="groupPost-nav groupPost-nav-pills groupPost-nav-stack groupPost-small groupPost-fw-normal"
                      >
                        {(this.state.postingType == "normal" ||
                          this.state.postingType == "advertisement" ||
                          this.state.postingType == "article") && (
                          <li
                            class="groupPost-nav-item home-tour-image"
                            onClick={() => this.keyFile("image")}
                          >
                            {/* <Tooltip title="Choose Image" placement="top"> */}
                            <a
                              class="groupPost-nav-link bg-light"
                              href="#!"
                              data-bs-toggle="modal"
                              style={{ cursor: "pointer" }}
                              data-bs-target="#feedActionPhoto"
                            >
                              {" "}
                              <CameraAltIcon
                                style={{
                                  color: "#0cbc87",
                                }}
                              />{" "}
                              {t("Photo")}
                            </a>
                            {/* </Tooltip> */}
                          </li>
                        )}

                        {this.state.postingType == "normal" && (
                          <>
                            {" "}
                            <li
                              class="groupPost-nav-item home-tour-video"
                              onClick={() => this.keyFile("video")}
                            >
                              {/* <Tooltip title="Choose Video" placement="top"> */}
                              <a
                                class="groupPost-nav-link bg-light"
                                href="#!"
                                data-bs-toggle="modal"
                                style={{ cursor: "pointer" }}
                                data-bs-target="#feedActionVideo"
                              >
                                {" "}
                                <VideocamIcon
                                  style={{
                                    color: "#4f9ef8",
                                  }}
                                />{" "}
                                {t("Video")}
                              </a>
                              {/* </Tooltip> */}
                            </li>
                            <li
                              class="groupPost-nav-item"
                              onClick={() => this.keyFile("audio")}
                            >
                              {/* <Tooltip title="Choose Document" placement="top"> */}
                              <a
                                href="#"
                                class="groupPost-nav-link bg-light"
                                data-bs-toggle="modal"
                                style={{ cursor: "pointer" }}
                                data-bs-target="#modalCreateEvents"
                              >
                                {" "}
                                <DescriptionIcon
                                  style={{
                                    color: "#dc3545",
                                  }}
                                />{" "}
                                {t("File")}
                              </a>
                              {/* </Tooltip> */}
                            </li>
                          </>
                        )}
                      </ul>
                      {/* {(this.state.postingType == "normal" ||
                        this.state.postingType == "advertisement" ||
                        this.state.postingType == "article") && (
                        <Tooltip title="Choose Image" placement="top">
                          <div className="homePostMediaButtons home-tour-image">
                            <center>
                              <AiOutlinePicture
                                onClick={() => this.keyFile("image")}
                                style={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "25px",
                                  color: "blue",
                                }}
                              ></AiOutlinePicture>
                            </center>
                          </div>
                        </Tooltip>
                      )}
                      {this.state.postingType == "normal" && (
                        <Tooltip title="Choose Document" placement="top">
                          <div className="homePostMediaButtons">
                            <center>
                              {" "}
                              <IoMdAttach
                                style={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "25px",
                                  color: "gray",
                                }}
                                onClick={() => this.keyFile("audio")}
                              ></IoMdAttach>
                            </center>
                          </div>
                        </Tooltip>
                      )}
                      {this.state.postingType == "normal" && (
                        <Tooltip title="Choose Video" placement="top">
                          <div className="homePostMediaButtons home-tour-video">
                            <center>
                              {" "}
                              <AiOutlineVideoCamera
                                style={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "25px",
                                  color: "gray",
                                }}
                                onClick={() => this.keyFile("video")}
                              ></AiOutlineVideoCamera>
                            </center>
                          </div>
                        </Tooltip>
                      )} */}
                    </div>
                  )}
                  {this.state.postingType === "polling" && (
                    <div
                      onClick={() =>
                        this.anonymousModel(!this.state.anonymousPost, true)
                      }
                      className="d-flex flex-wrap align-items-center mb-3"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="boxcolor mr-1 event-box">
                        <input
                          type="checkbox"
                          value={this.state.anonymousPost}
                          checked={this.state.anonymousPost}
                          defaultChecked={this.state.anonymousPost}
                          id="anonymousPost"
                        />
                      </div>
                      <p
                        style={{ fontSize: "12px" }}
                        className="font-weight-bold"
                      >
                        {t("Do you want this polling as anonymous ?")}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sm={12}
                  lg={4}
                  style={{ float: "right" }}
                >
                  <Button
                    className="home-tour-publish"
                    type="submit"
                    onClick={(e) => this.onFormSubmit(e)}
                  >
                    {t("PUBLISH")}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </div>
        </Dialog>
        {this.state.anonymousPost && this.state.anonymousModel && (
          <AnonymousModel closeModel={this.anonymousModel} />
        )}
      </div>
    );
  }

  handlearticleHeadingChange = (e) => {
    this.setState({
      articleHeading: e.target.value,
    });
  };

  SurveyQuestionandAns = (data) => {
    console.log("final ans", data);
    this.setState({
      SurveyQuestionandAns: data,
    });
  };

  handleInputPollAnswer(event, index, type) {
    event.preventDefault();
    event.stopPropagation();

    var newAnswers = this.state.frPollAnswer;
    if (newAnswers[index]) {
      if (type == "name") {
        var obj = newAnswers[index];
        obj.Sname = event.target.value;
        newAnswers[index] = obj;
      } else {
        var obj = newAnswers[index];
        obj.Scode = event.target.value;
        newAnswers[index] = obj;
      }
    } else {
      if (type == "name") {
        var obj = newAnswers[index];
        var obj = {
          Sname: event.target.value,
          Scode: "",
        };
        newAnswers[index] = obj;
      } else {
        var obj = {
          Sname: "",
          Scode: event.target.value,
        };
        newAnswers[index] = obj;
      }
    }

    this.setState({
      frPollAnswer: newAnswers,
    });
    console.log("this.state.frsubjectName");
    console.log(newAnswer);
    console.log(this.state.frPollAnswer);
    //  this.setState({answers:answer_id.target.value})
  }

  handleInputQuestion(event, index, type) {
    event.preventDefault();
    event.stopPropagation();
    var newAnswers = this.state.frsubjectName;
    if (newAnswers[index]) {
      if (type == "name") {
        var obj = newAnswers[index];
        obj.Sname = event.target.value;
        newAnswers[index] = obj;
      } else {
        var obj = newAnswers[index];
        obj.Scode = event.target.value;
        newAnswers[index] = obj;
      }
    } else {
      if (type == "name") {
        var obj = newAnswers[index];
        var obj = {
          Sname: event.target.value,
          Scode: "",
        };
        newAnswers[index] = obj;
      } else {
        var obj = {
          Sname: "",
          Scode: event.target.value,
        };
        newAnswers[index] = obj;
      }
    }

    this.setState({
      frPollAnswer: newAnswers,
    });
    console.log("this.state.frsubjectName");
    console.log(newAnswer);
    console.log(this.state.frPollAnswer);
    //  this.setState({answers:answer_id.target.value})
  }

  editorStateValues(values) {
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }
  pollingEditor(values) {
    console.log(values);
    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        pollQuestionTitle: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        pollQuestionTitle: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  }
  PostActive(boolean) {
    this.setState({ active: boolean });
  }
  fileOptionEnable() {
    this.setState({ fileOption: false, questionFile: null });
  }

  closeChoosedFileData() {
    this.setState({
      fileTypeState: false,
      fileTypeKey: "",
      type: "",
      file: [],
    });
    this.fileOptionEnable();
    this.getQuestionMediaFiles();
  }

  getQuestionMediaFiles(files, type) {
    this.setState({ file: files, type: type });
  }

  keyFile(key) {
    this.setState({ fileTypeKey: key, fileTypeState: true });
  }

  onFormSubmit = async (e) => {
    //false -->friends ,true --> general
    e.preventDefault(); // Stop form submit
    const htmlString = this.state.content ? this.state.content : "";
    const output = extractMentionsFromHTML(htmlString);
    await this.setState({
      content: output,
    });

    if (
      isYuukkeWorkspace() &&
      this.state.postingType === "advertisement" &&
      this.props.subscription.subscriptionDetails &&
      this.props.subscription.subscriptionType === "adpost" &&
      !this.props.subscription.subscriptionStatus
    ) {
      toast.warning("Please subscribe suitable plan for you");
      this.props.subscriptionModelStatus("adpost");
      this.props.closeModel();
      return;
    }

    // General
    if (this.state.programType == "General") {
      if (
        this.state.postingType !== "survey" &&
        this.state.postingType !== "polling" &&
        this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() ==
          "" &&
        this.state.file === undefined
      ) {
        toast.warn("Please share something....");
      } else if (
        this.state.postingType === "advertisement" &&
        (this.state.file === undefined || this.state.file === null)
      ) {
        toast.warn("Please choose  image....");
      } else {
        if (
          this.state.postingType === "advertisement" ||
          this.state.postingType === "normal"
        ) {
          if (this.state.content != "" && this.state.file == undefined) {
            // this.props.fromPostContent(this.state.content, true);

            this.props.fromPostContent(
              this.state.emptyFile,
              "text",
              this.state.content,
              true,
              this.state.postingType,
              "notGroup"
            );
          } else if (
            this.state.content == undefined &&
            this.state.file != null
          ) {
            let files = this.state.file;
            var type = "file";

            if (checkImageURL(this.state.file[0].name)) {
              type = "image";
            } else if (checkVideoURL(this.state.file[0].name)) {
              type = "video";
            } else if (checkAudioURL(this.state.file[0].name)) {
              type = "audio";
            } else if (checkPDFURL(this.state.file[0].name)) {
              type = "pdf";
            }

            this.fileUpload(files, type, true);
          } else {
            let files = this.state.file;
            var type = "file";

            if (checkImageURL(this.state.file[0].name)) {
              type = "image";
            } else if (checkVideoURL(this.state.file[0].name)) {
              type = "video";
            } else if (checkAudioURL(this.state.file[0].name)) {
              type = "audio";
            } else if (checkPDFURL(this.state.file[0].name)) {
              type = "pdf";
            }

            this.fileUpload(files, type, true);
          }
        } else if (this.state.postingType === "polling") {
          if (this.state.pollQuestion === "") {
            toast.warn("Please Add Questions");
          } else if (this.state.frPollAnswer.length === 0) {
            toast.warn("Please Add Answers");
          } else if (this.state.frPollAnswer.length < 2) {
            toast.warn("Add minimum two polling options");
          } else {
            var filterAns = [];
            let array = this.state.frPollAnswer;
            array.forEach((element) => {
              filterAns.push(element.Sname);
            });
            console.log("frPollAnswer filterAns", filterAns);
            this.props.fromPostContent(
              filterAns,
              "poll",
              this.state.pollQuestion,
              true,
              this.state.postingType,
              "notGroup",
              this.state.anonymousPost,
              this.state.pollQuestionTitle
            );
          }
        } else if (this.state.postingType === "survey") {
          console.log("sur1", this.state.SurveyQuestionandAns);
          if (this.state.SurveyQuestionandAns.length === 0) {
            toast.warn("Please add your questions and answers");
          } else {
            if (this.state.SurveyQuestionandAns[0].answerType == "") {
              toast.warn("Please select your question type");
            } else if (
              this.state.SurveyQuestionandAns[0].answerType === "Text" &&
              this.state.SurveyQuestionandAns[0].textTypeAnswer == ""
            ) {
              toast.warn("Please fill answer");
            } else if (
              this.state.SurveyQuestionandAns[0].answerType === "Select" &&
              this.state.SurveyQuestionandAns[0].answers.length === 0
            ) {
              toast.warn("Please add your Answer");
            } else {
              var arrayList = this.state.SurveyQuestionandAns;
              var final = [];

              arrayList.forEach((data) => {
                if (data.answerType === "Text") {
                  final.push({
                    question: data.question,
                    answer: data.textTypeAnswer,
                    answerType: data.answerType,
                  });

                  //   final.push({
                  //     "question":data.question,
                  //     "answers":data.answers,
                  //     "answerType":data.answerType,
                  //     "textTypeAnswer":data.textTypeAnswer
                  // })
                } else {
                  final.push({
                    question: data.question,
                    answers: data.answers,
                    answerType: data.answerType,
                  });
                }
              });

              var obj = {
                questions: final,
              };

              this.props.fromPostContent(
                obj,
                "survey",
                this.state.pollQuestion,
                true,
                this.state.postingType,
                "notGroup"
              );
            }
          }

          console.log("final ans", obj);
        } else if (this.state.postingType === "article") {
          if (this.state.articleHeading === "") {
            toast.warn("Please Enter Your Article Heading");
          } else {
            let files = this.state.file;

            if (this.state.file) {
              let type = "file";
              if (checkImageURL(this.state.file[0].name)) {
                type = "image";
              } else if (checkVideoURL(this.state.file[0].name)) {
                type = "video";
              } else if (checkAudioURL(this.state.file[0].name)) {
                type = "audio";
              } else if (checkPDFURL(this.state.file[0].name)) {
                type = "pdf";
              }
              this.fileUpload(files, type, true);
            } else {
              let texttype = "text";
              this.fileUpload(files, texttype, true);
            }
          }
        }
      }
    }

    // Friends
    else if (this.state.programType == "Friends") {
      if (
        this.state.postingType !== "survey" &&
        this.state.postingType !== "polling" &&
        this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() ==
          "" &&
        this.state.file == undefined
      ) {
        toast.warn("Please share something....");
      } else if (
        this.state.postingType === "advertisement" &&
        (this.state.file === undefined || this.state.file === null)
      ) {
        toast.warn("Please choose  image....");
      } else {
        if (
          this.state.postingType === "advertisement" ||
          this.state.postingType === "normal"
        ) {
          if (this.state.content != "" && this.state.file == undefined) {
            this.props.fromPostContent(
              this.state.emptyFile,
              "text",
              this.state.content,
              false,
              this.state.postingType,
              "notGroup"
            );
          } else if (
            this.state.content == undefined &&
            this.state.file != null
          ) {
            let files = this.state.file;
            var type = "file";
            if (checkImageURL(this.state.file[0].name)) {
              type = "image";
            } else if (checkVideoURL(this.state.file[0].name)) {
              type = "video";
            } else if (checkAudioURL(this.state.file[0].name)) {
              type = "audio";
            } else if (checkPDFURL(this.state.file[0].name)) {
              type = "pdf";
            }
            this.fileUpload(files, type, false);
          } else {
            let files = this.state.file;
            var type = "file";
            if (checkImageURL(this.state.file[0].name)) {
              type = "image";
            } else if (checkVideoURL(this.state.file[0].name)) {
              type = "video";
            } else if (checkAudioURL(this.state.file[0].name)) {
              type = "audio";
            } else if (checkPDFURL(this.state.file[0].name)) {
              type = "pdf";
            }
            this.fileUpload(files, type, false);
          }
        } else if (this.state.postingType === "polling") {
          if (this.state.pollQuestion === "") {
            toast.warn("Please Add Questions");
          } else if (this.state.frPollAnswer.length === 0) {
            toast.warn("Please Add Answers");
          } else {
            var filterAnsFriend = [];
            let array = this.state.frPollAnswer;
            array.forEach((element) => {
              filterAnsFriend.push(element.Sname);
            });
            this.props.fromPostContent(
              filterAnsFriend,
              "poll",
              this.state.pollQuestion,
              false,
              this.state.postingType,
              "notGroup",
              this.state.anonymousPost,
              this.state.pollQuestionTitle
            );
          }
        } else if (this.state.postingType === "survey") {
          if (this.state.SurveyQuestionandAns.length === 0) {
            toast.warn("Please add your questions and answers");
          } else {
            if (this.state.SurveyQuestionandAns) {
              let check = true;
              for (let i = 0; i < this.state.SurveyQuestionandAns.length; i++) {
                if (this.state.SurveyQuestionandAns[i].question == "") {
                  toast.warn("Please type your question ");
                  check = false;
                } else if (
                  this.state.SurveyQuestionandAns[i].answerType == ""
                ) {
                  toast.warn("Please select your question type");
                  check = false;
                } else if (
                  this.state.SurveyQuestionandAns[i].answerType === "Text" &&
                  this.state.SurveyQuestionandAns[i].textTypeAnswer == ""
                ) {
                  toast.warn("Please fill answer");
                  check = false;
                } else if (
                  this.state.SurveyQuestionandAns[i].answerType === "Select" &&
                  this.state.SurveyQuestionandAns[i].answers.length == 0
                ) {
                  toast.warn("Please add your Answer");
                  check = false;
                }
              }
              if (check === true) {
                var arrayList = this.state.SurveyQuestionandAns;
                var final = [];

                arrayList.forEach((data) => {
                  if (data.answerType === "Text") {
                    final.push({
                      question: data.question,
                      answer: data.textTypeAnswer,
                      answerType: data.answerType,
                    });

                    //   final.push({
                    //     "question":data.question,
                    //     "answers":data.answers,
                    //     "answerType":data.answerType,
                    //     "textTypeAnswer":data.textTypeAnswer
                    // })
                  } else {
                    final.push({
                      question: data.question,
                      answers: data.answers,
                      answerType: data.answerType,
                    });
                  }
                });

                var obj = {
                  questions: final,
                };

                this.props.fromPostContent(
                  obj,
                  "survey",
                  this.state.pollQuestion,
                  false,
                  this.state.postingType,
                  "notGroup"
                );
              }
            }
          }
        }
      }
    }

    // Groups
    else if (this.state.programType == "Groups") {
      if (
        this.state.postingType !== "survey" &&
        this.state.postingType !== "polling" &&
        this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() ==
          "" &&
        this.state.file == undefined
      ) {
        toast.warn("Please share something....");
      } else if (
        this.state.postingType === "advertisement" &&
        (this.state.file === undefined || this.state.file === null)
      ) {
        toast.warn("Please choose  image....");
      } else if (
        this.state.selectedGroupId == undefined ||
        this.state.selectGroupName == undefined
      ) {
        toast.warn("Please select Community....");
      } else {
        if (
          this.state.postingType === "advertisement" ||
          this.state.postingType === "normal"
        ) {
          if (this.state.content != "" && this.state.file == undefined) {
            this.props.fromPostContent(
              this.state.emptyFile,
              "text",
              this.state.content,
              this.state.selectGroupName,
              this.state.postingType,
              "Group",
              this.state.selectedGroupId
            );
          } else if (
            this.state.content == undefined &&
            this.state.file != null
          ) {
            let files = this.state.file;
            var type = "file";
            if (checkImageURL(this.state.file[0].name)) {
              type = "image";
            } else if (checkVideoURL(this.state.file[0].name)) {
              type = "video";
            } else if (checkAudioURL(this.state.file[0].name)) {
              type = "audio";
            } else if (checkPDFURL(this.state.file[0].name)) {
              type = "pdf";
            }
            this.props.fromPostContent(
              files,
              type,
              this.state.content,
              this.state.selectGroupName,
              this.state.postingType,
              "Group",
              this.state.selectedGroupId
            );
          } else {
            let files = this.state.file;
            var type = "file";
            if (checkImageURL(this.state.file[0].name)) {
              type = "image";
            } else if (checkVideoURL(this.state.file[0].name)) {
              type = "video";
            } else if (checkAudioURL(this.state.file[0].name)) {
              type = "audio";
            } else if (checkPDFURL(this.state.file[0].name)) {
              type = "pdf";
            }
            this.props.fromPostContent(
              files,
              type,
              this.state.content,
              this.state.selectGroupName,
              this.state.postingType,
              "Group",
              this.state.selectedGroupId
            );
          }
        } else if (this.state.postingType === "polling") {
          if (this.state.pollQuestion === "") {
            toast.warn("Please Add Questions");
          } else if (this.state.frPollAnswer.length === 0) {
            toast.warn("Please Add Answers");
          } else {
            console.log("frPollAnswer", this.state.frPollAnswer);
            console.log("frPollAnswer", this.state.pollQuestion);
            var filterAns = [];
            let array = this.state.frPollAnswer;
            array.forEach((element) => {
              filterAns.push(element.Sname);
            });
            console.log("frPollAnswer filterAns", filterAns);
            this.props.fromPostContent(
              filterAns,
              this.state.pollQuestion,
              this.state.content,
              this.state.selectGroupName,
              this.state.postingType,
              "Group",
              this.state.selectedGroupId,
              this.state.pollQuestionTitle
            );
          }
        } else if (this.state.postingType === "survey") {
          if (this.state.SurveyQuestionandAns.length === 0) {
            toast.warn("Please add your questions and answers");
          } else {
            if (this.state.SurveyQuestionandAns) {
              let check = true;
              for (let i = 0; i < this.state.SurveyQuestionandAns.length; i++) {
                if (this.state.SurveyQuestionandAns[i].question == "") {
                  toast.warn("Please type your question ");
                  check = false;
                } else if (
                  this.state.SurveyQuestionandAns[i].answerType == ""
                ) {
                  toast.warn("Please select your question type");
                  check = false;
                } else if (
                  this.state.SurveyQuestionandAns[i].answerType === "Text" &&
                  this.state.SurveyQuestionandAns[i].textTypeAnswer == ""
                ) {
                  toast.warn("Please fill answer");
                  check = false;
                } else if (
                  this.state.SurveyQuestionandAns[i].answerType === "Select" &&
                  this.state.SurveyQuestionandAns[i].answers.length == 0
                ) {
                  toast.warn("Please add your Answer");
                  check = false;
                }
              }
              if (check === true) {
                var arrayList = this.state.SurveyQuestionandAns;
                var final = [];

                arrayList.forEach((data) => {
                  if (data.answerType === "Text") {
                    final.push({
                      question: data.question,
                      answer: data.textTypeAnswer,
                      answerType: data.answerType,
                    });

                    //   final.push({
                    //     "question":data.question,
                    //     "answers":data.answers,
                    //     "answerType":data.answerType,
                    //     "textTypeAnswer":data.textTypeAnswer
                    // })
                  } else {
                    final.push({
                      question: data.question,
                      answers:
                        data.answers &&
                        data.answers.map((word) => word.trimStart()),
                      answerType: data.answerType,
                    });
                  }
                });

                var obj = {
                  questions: final,
                };
                this.props.fromPostContent(
                  obj,
                  "text",
                  this.state.content,
                  this.state.selectGroupName,
                  this.state.postingType,
                  "Group",
                  this.state.selectedGroupId
                );
              }
            }
          }
          console.log("final ans", obj);
        }
      }
    }
  };

  checkFileType(name) {}

  checkImageURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(mp4|webm|ogg|m4v)$/) != null;
  }

  checkAudioURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(wav|mp3|m4v)$/) != null;
  }

  checkPDFURL(name) {
    name = name.toLowerCase();
    return name.match(/\.(pdf|xlsx|csv|ods|pptx)$/) != null;
  }

  fileUpload(file, type, data) {
    this.props.fromPostContent(
      file,
      type,
      this.state.content,
      data,
      this.state.postingType,
      "notGroup",
      null,
      this.state.articleHeading
    );
  }

  fileGroupUpload(file, type, content, data, name, id) {
    this.props.fromMediaGroup(file, type, content, data, name, id);
  }

  searchSubjects(event, value) {
    this.setState({
      [event.target.name]: event.target.value,
      selectGroupName: event.target.value,
    });
    let data = [];
    for (var i = 0; i < this.state.allSubjects.length; i++) {
      var subject = this.state.allSubjects[i];
      if (subject.subjectName.toUpperCase().includes(value.toUpperCase())) {
        data.push(subject);
      }
    }
    this.setState({ tagDatas: data });
  }

  gotogroupDescription(id, name) {
    this.setState({ tagDatas: [] });
    this.setState({ selectGroupName: name, selectedGroupId: id });
    this.props.getPostTagList("groups", id);
  }
  anonymousModel = (anonymousPost, anonymousModel) => {
    this.setState({
      anonymousPost: anonymousPost,
      anonymousModel: anonymousModel,
    });
  };
}

const mapStateToProps = function (state) {
  // console.log("Loading request friend Props");
  // console.log(state.commonApi.friendslist);
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    toursStages: state.commonApi.homePopupStatus,
    friendslist: state.commonApi.friendslist,
    memberList: state.commonApi.tagUserList,
    subscription: state.event,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    getPostTagList: (type, id) => dispatch(getPostTagList(type, id)),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
    getSubscriptionStatus: (type) => dispatch(getSubscriptionStatus(type)),
    subscriptionModelStatus: (data) => dispatch(subscriptionModelStatus(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(NewPostDialogue));
