import React from "react";
import NewPostContainer from "./NewPostContainer";
import PostCard from "./PostCard";
import AuthService from "../../../session/AuthService_old";
import PostService from "../../../api/PostService_old";
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import BlockUi from "react-block-ui";
import { HiOutlineFilter } from "react-icons/hi";
import "./myPost.css";
import { withTranslation } from "react-i18next";
import { Box, Card, CardContent, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import "react-placeholder/lib/reactPlaceholder.css";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { getAllMyGroupEventsHome } from "../../../redux/actions/subjectActions";
import Announcement from "../../Square/Home/Announcement";
import LinearProgressBar from "../../../components/LinearProgressBar";
import {
  getAllUserCommunityList,
  getFriendsListBasedWorkSpace,
  triggerTourPopup,
} from "../../../redux/actions/commonApiActions";
import {
  getPostList,
  createNewPost,
  createNewPostV2,
} from "../../../redux/actions/PostCardActions";
// import { initializeHotjar } from "../../Mixpanel/HotJar";
import HomeService from "../../../api/HomeService";
let listsIncard = [];
let group = [];
let myPost = [];

class MyCommunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      friendDataList: [],
      seeMorePostListempty: false,
      blockingUi: false,
      pageNo: 0,
      noMoreState: true,
      content: "",
      active: false,
      postData: [],
      validated: false,
      postType: 1,
      mediaPost: "",
      file: null,
      wMessage: "",
      newPost: [],
      finalPostData: [],
      friendsList: [],
      fullUserDataa: [],
      activePage: 10,
      userName: "",
      myPostState: false,
      userPhoto: "",
      userData: [],
      friendsMap: [],
      recommendedFriendsData: [],
      userPostList: [],
      profileImage: "",
      signOut: "",
      blocking: false,
      firstTimeSetup: [],
      postCardType: "",
      showPlusMenu: false,
      list: [],
      addList: [],
      payLoad: [],
      activeIndex: false,
      postBymeLists: [],
      postByme: [
        {
          id: 1,
          name: "Groups",
          myPostColor: "false",
        },
        {
          id: 2,
          name: "Friends",
          myPostColor: "false",
        },
        {
          id: 3,
          name: "General",
          myPostColor: "false",
        },
      ],
      groupsList: [],
      groupsLists: [],
      postCardStatus: true,
      myPostLists: [],
      broadCast: false,
      clearState: false,
      filterMypostList: false,
      filterFriendsList: false,
      filterGroupsList: false,
      showAnnoucment: false,
      isUploading: false,
      percentUploaded: 0,
      processingStatus: "processing",
      firstTime: false,
    };

    // this.getPost = this.getPost.bind(this);
    this.getMergePostList = this.getMergePostList.bind(this);
    this.calculateProgress = this.calculateProgress.bind(this);
    this.fromPost = this.fromPost.bind(this);
    // this.fromPostContent = this.fromPostContent.bind(this);
    this.pageNo = this.pageNo.bind(this);
    this.sharePost = this.sharePost.bind(this);

    this.deletePost = this.deletePost.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.wrapperRef = React.createRef();
    this.showPlusMenu = this.showPlusMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.optionType = this.optionType.bind(this);
    this.handleClickList = this.handleClickList.bind(this);
    this.getFriendFilterList = this.getFriendFilterList.bind(this);
    this.handleClickGroups = this.handleClickGroups.bind(this);
    this.handleClickMyList = this.handleClickMyList.bind(this);
    this.openAnnoucmentScreen = this.openAnnoucmentScreen.bind(this);
    this.closeAnnoucmentScreen = this.closeAnnoucmentScreen.bind(this);
    this.commentStatusDeleteUpdate = this.commentStatusDeleteUpdate.bind(this);
    this.commendsNewUpdate = this.commendsNewUpdate.bind(this);
    this.sharePostOnWay = this.sharePostOnWay.bind(this);
    this.updateSurveyCompletion = this.updateSurveyCompletion.bind(this);
  }

  componentDidMount() {
    // this.props.getAllMyGroupEventsHome();
    //Hotjar call only for new User
    if (this.props.loginDetails?.newUser) {
      // initializeHotjar(this.props.loginDetails.email);
      // import("../../Mixpanel/HotJar").then((module) => {
      //   console.log("HotJar file imported and executed.", module);
      // });
    }
    console.log("IS MY COMMUNITY IN");
    console.log(AuthService.isAuth());
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    let friends = this.props.workspaceFriendList;
    let groupsList = this.props.joinedGroupList;
    let myList = this.state.postByme;
    this.getFriendFilterList(friends);
    this.handleClickListGroups(groupsList);
    this.getMyPosts(myList);

    // let friendsMap = {};

    // friendsMap[this.props.loginDetails.uId] = this.props.loginDetails;

    // if (friends) {
    //   for (let i = 0; i < friends.length; i++) {
    //     console.log(friends[i]);
    //     friendsMap[friends[i].userProfile.userId] = friends[i];
    //   }
    // }

    // this.setState({
    //   friendsMap: friendsMap,
    // });
    if (this.props.joinedGroupList && this.props.joinedGroupList.length === 0) {
      this.props.getAllUserCommunityList();
    }
    this.filterOption();
    if (this.props.authUser && this.props.workspaceFriendList?.length === 0) {
      this.props.getFriendsListBasedWorkSpace();
    }

    // this.getPost();
  }
  componentDidUpdate(prevProps) {
    if (this.props.type && this.props.type !== prevProps.type) {
      this.setState({ pageNo: 0 });
      this.filterOption();
      // this.props.getAllUserCommunityList();
    }
    if (
      this.props.workspaceType &&
      this.props.workspaceType !== prevProps.workspaceType
    ) {
      this.setState({ pageNo: 0 });
      this.filterOption(null);
      this.props.getFriendsListBasedWorkSpace();
      const check =
        this.props.userWorkSpaceList &&
        this.props.userWorkSpaceList.find(
          (item) => item?.slug === "beyond-barriers"
        );
      if (!this.props.toursStages && check) {
        console.log("Trigger Tour popup  learning");
        this.props.triggerTourPopup("learning");
      }
    }
    if (this.props.postError && this.props.postError !== prevProps.postError) {
      toast.error(`${this.props.t("Error in saving post")}`);
    }
    if (
      this.props.isDeletedPost &&
      this.props.isDeletePostStatus !== prevProps.isDeletePostStatus
    ) {
      toast.error(`${this.props.t("Post has been deleted by user !")}`);
    }
    if (
      this.props.toastData &&
      !this.props.postError &&
      this.props.toastDataStatus !== prevProps.toastDataStatus
    ) {
      toast.success(`${this.props.t(this.props.toastData)}`);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  commentStatusDeleteUpdate(id, postId) {
    var postData = this.state.postData;
    console.log("commend id :", id, postId);
    const finalResult = postData.map((person) => {
      if (person.postId === postId) {
        person.commendsCount = person.commendsCount - 1;
        person.postLikeCommends = person.postLikeCommends.filter(
          (item) => item.id !== id
        );
      }
      return person;
    });
    console.log("finalResult : ", finalResult);
    this.setState({
      postData: finalResult,
    });
  }
  sharePostOnWay(result) {
    var jsonList = this.state.postData;
    var saveData = result;
    jsonList.unshift(saveData);
    this.setState({
      postData: jsonList,
    });
    console.log("after share : ", this.state.postData);
  }
  commendsNewUpdate(result, pid) {
    var postData = this.state.postData;
    var newData = result.data;
    var newCommends = [];
    for (let i = 0; i < newData.length; i++) {
      newCommends.push(newData[i]);
    }
    const finalResult = postData.map((person) => {
      if (person.postId === pid) {
        person.postLikeCommends = newCommends;
      }
      return person;
    });
    console.log("finalResult : ", finalResult);
    this.setState({
      postData: finalResult,
    });
  }
  commendsUpdate(result, pid) {
    var postData = this.state.postData;
    var commends = result;
    var commendsCount = [];
    console.log("commends", commends);
    var like = result.data.length;
    var commendsList = [];
    if (like > 0) {
      const finalResult = postData.map((person) => {
        if (person.postId === pid) {
          person.commendsCount = person.commendsCount + 1;
          if (like < 3) {
            for (let i = 0; i < commends.data.length; i++) {
              commendsCount.push(commends.data[i]);
            }

            person.latestCommends = commendsCount;
          }
          for (let i = 0; i < commends.data.length; i++) {
            commendsList.push(commends.data[i]);
          }
          person.postLikeCommends = commendsList;
        }
        return person;
      });
      console.log("finalResult : ", finalResult);
      this.setState({
        postData: finalResult,
      });
      console.log("state value changed : ", this.state.postData);
    }
  }
  updatePost = (result) => {
    let postData = this.state.postData;
    let upData = result;

    if (result) {
      var finalResult = [];
      for (let i = 0; i < postData.length; i++) {
        if (postData[i].postId === result.postId) {
          finalResult.push(upData);
        } else {
          finalResult.push(postData[i]);
        }
      }
      this.setState({
        postData: finalResult,
      });
    }
  };
  updateSurveyCompletion(result) {
    let postData = this.state.postData;
    if (result) {
      const finalResult = postData.map((person) => {
        if (person.moderationId === result) {
          person.surveyList.isCompleted = true;
        }
        return person;
      });
      this.setState({
        postData: finalResult,
      });
    }
  }
  // likestatusUpdate(result, userLike) {
  //   var postData = this.state.postData;
  //   var postId = result.data.postId;
  //   var like = result.data.userLike;
  //   var allLikes = result.data.allLikes;
  //   if (like !== undefined) {
  //     const finalResult = postData.map((person) => {
  //       if (person.postId === postId) {
  //         person.userlike = userLike;
  //         person.userlikeType = userLike ? like.reactionType : "";
  //         person.likesCount = result.data.likeCount;
  //         person.allLikes = allLikes;
  //       }
  //       return person;
  //     });
  //     console.log("finalResult : ", finalResult);
  //     this.setState({
  //       postData: finalResult,
  //     });
  //     console.log("state value changed : ", this.state.postData);
  //   }
  //   // else {
  //   //   const finalResult = postData.map((person) => {
  //   //     if (person.postId === postId) {
  //   //       person.userlike = userLike;
  //   //       person.userlikeType = "";
  //   //       person.likesCount = person.likesCount - 1;
  //   //       person.allLikes = allLikes;
  //   //     }
  //   //     return person;
  //   //   });
  //   //   console.log("finalResult : ", finalResult);
  //   //   this.setState({
  //   //     postData: finalResult,
  //   //   });
  //   //   console.log("state value changed : ", this.state.postData);
  //   // }
  // }

  subscribeUpdate = (result, type, userBookMark, id) => {
    if (type === "subscribe") {
      var postData = this.state.postData;
      var postId = result.postId;
      var subscribeId = result.subscribeId;
      var isSubscribe = result.isSubscribe;
      if (postId !== undefined) {
        const finalResult = postData.map((person) => {
          if (person.postId === postId) {
            person.postSubscribe.isSubscribe = isSubscribe;
            person.postSubscribe.subscribeId = subscribeId;
          }
          return person;
        });
        console.log("finalResult : ", finalResult);
        this.setState({
          postData: finalResult,
        });
        console.log("state value changed : ", this.state.postData);
      }
    } else {
      var postId = result;
      var postData = this.state.postData;
      const finalResult = postData.map((person) => {
        if (person.postId === postId) {
          person.userBookMark = person.userBookMark ? false : true;
        }
        return person;
      });
      console.log("finalResult : ", finalResult);
      this.setState({
        postData: finalResult,
      });

      if (this.props.type && this.props.type === "BookMark" && !userBookMark) {
        this.deletePost(id);
      }
    }
  };

  handleClickOutside(event) {
    console.log("Remove--12", event.target);
    console.log("Remove--12 wrapperRef", this.wrapperRef);
    console.log(this.wrapperRef);
    console.log(event.target);
    if (
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        showPlusMenu: false,
      });
    }
  }
  onSelectFile(e) {
    console.log("eee", e);
  }

  async optionType(e) {
    if (e == "0") {
      myPost.length = 0;
      let myList = this.state.postByme;
      await this.setState({
        filterMypostList: true,
        filterFriendsList: false,
        filterGroupsList: false,
        broadCast: false,
        clearState: false,
      });
      this.onChangeCalling();
      this.getMyPosts(myList);
    } else if (e == "1") {
      listsIncard.length = 0;
      let friends = this.props.workspaceFriendList;
      await this.setState({
        filterFriendsList: true,
        filterGroupsList: false,
        filterMypostList: false,
        broadCast: false,
        clearState: false,
        friendDataList: friends,
      });
      this.onChangeCalling();
      this.getFriendFilterList(friends);
    } else if (e == "2") {
      group.length = 0;
      let groupsList = this.props.joinedGroupList;
      await this.setState({
        filterGroupsList: true,
        filterMypostList: false,
        filterFriendsList: false,
        groupsList: groupsList,
        broadCast: false,
        clearState: false,
      });

      this.onChangeCalling();
      this.handleClickListGroups(groupsList);
    } else if (e == "3") {
      await this.setState({
        broadCast: true,
        filterGroupsList: false,
        filterMypostList: false,
        filterFriendsList: false,
        clearState: false,
      });
      this.onChangeCalling();
    } else if (e == "4") {
      await this.setState({
        clearState: true,
        filterGroupsList: false,
        filterMypostList: false,
        filterFriendsList: false,
        broadCast: false,
      });
      this.onChangeCalling();
    }
    await this.setState({
      postCardType: e,
      showPlusMenu: false,
    });
  }
  async handleForm(event) {
    await this.setState({ [event.target.name]: event.target.value });
  }
  //Groups
  async handleClickGroups(name, id, index) {
    this.setState({
      postBymeLists: [],
      addList: [],
      filterMypostList: false,
      filterGroupsList: false,
      filterFriendsList: false,
    });

    let count = this.state.groupsLists.length;
    var friendsarray = this.state.groupsList;

    if (this.state.groupsLists.length > 0) {
      group = this.state.groupsLists.filter((element) => element.userId !== id);
      await this.setState({ groupsLists: group });
      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].userId == id) {
          friendsarray[i].colorChangeGroups = "false";
        }
      }

      this.setState({ groupsList: friendsarray });
    }

    if (count == group.length) {
      group.push({ displayName: name, userId: id, colorChangeGroups: "true" });
      await this.setState({ groupsLists: group });
      let friend = [];
      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].userId == id) {
          friendsarray[i].colorChangeGroups = "true";
        }
      }
      this.setState({ groupsList: friendsarray });
    } else {
    }

    this.onChangeCalling(group, "group");
  }
  //My post
  async handleClickMyList(name, id, index) {
    this.setState({
      groupsLists: [],
      addList: [],
      filterMypostList: false,
      filterGroupsList: false,
      filterFriendsList: false,
    });
    let count = this.state.postBymeLists.length;
    var friendsarray = this.state.myPostLists;

    if (this.state.postBymeLists.length > 0) {
      myPost = this.state.postBymeLists.filter((element) => element.id !== id);
      await this.setState({ postBymeLists: myPost });

      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].id == id) {
          friendsarray[i].myPostColor = "false";
        }
      }

      //console.log("this.state.postBymeLists,If",this.state.postBymeLists)
      this.setState({ myPostLists: friendsarray });
    }

    if (count == myPost.length) {
      myPost.push({ displayName: name, id: id, myPostColor: "true" });
      await this.setState({ postBymeLists: myPost });
      let friend = [];
      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].id == id) {
          friendsarray[i].myPostColor = "true";
        }
      }
      this.setState({ myPostLists: friendsarray });

      // console.log("11postby--state",this.state.postBymeLists)
    } else {
    }

    // console.log("11friend", this.state.addList);
    // console.log("11groupsLists", this.state.groupsLists);
    // console.log("11postby",myPost);
    this.onChangeCalling(myPost, "myPost");
  }
  //friends
  async handleClickList(name, id, index) {
    this.setState({
      postBymeLists: [],
      groupsLists: [],
      filterMypostList: false,
      filterGroupsList: false,
      filterFriendsList: false,
    });
    let count = this.state.addList.length;
    var friendsarray = this.state.friendDataList;

    if (this.state.addList.length > 0) {
      listsIncard = this.state.addList.filter(
        (element) => element.userId !== id
      );
      await this.setState({ addList: listsIncard });

      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].userId == id) {
          friendsarray[i].friendCardColor = "false";
        }
      }

      this.setState({ friendDataList: friendsarray });
    }

    if (count == listsIncard.length) {
      listsIncard.push({
        displayName: name,
        userId: id,
        friendCardColor: "true",
      });
      await this.setState({ addList: listsIncard });
      let friend = [];
      for (let i = 0; i < friendsarray.length; i++) {
        if (friendsarray[i].userId == id) {
          friendsarray[i].friendCardColor = "true";
        }
      }
      this.setState({ friendDataList: friendsarray });
    } else {
    }
    this.onChangeCalling(listsIncard, "friend");
  }

  render() {
    const { t } = this.props;
    const { showPlusMenu, activeIndex, postCardStatus, firstTime } = this.state;
    return (
      <>
        {/* <BlockUi tag="div" blocking=.{this.state.blocking}> */}
        {/* <Row>
          <Col lg={12}> */}
        {this.props.homePost && (
          <>
            <NewPostContainer
              //fromPostFinalContent={this.fromPostContent}
              fromPostFinalContent={this.createHomePost}
              fromPostFinal={this.fromPost}
              // fromGroupContent={this.fromGroupContent}
              // fromMediaGroup={this.fromGroupContent}
              name="Hyser"
              squarehome={this.props.squarehome}
              tagDatas={this.props.joinedGroupList}
            />

            {/* {Object.keys(this.props.firstTimeSetupStatus).length !== 0 &&
                  (!this.props.firstTimeSetupStatus?.profile ||
                    !this.props.firstTimeSetupStatus?.education ||
                    !this.props.firstTimeSetupStatus?.skills) && (
                    <div style={{ marginBottom: "12px" }}>
                      {" "}
                      <Setupyourcommunity
                        firstTimeSetup={this.props.firstTimeSetupStatus}
                      ></Setupyourcommunity>
                    </div>
                  )} */}
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <AnnouncementLisCardWithHeaderBase
                    title={t("Announcements")}
                    listContentHeight={544}
                  > */}

                <Grid container spacing={2} style={{ cursor: "pointer" }}>
                  {this.props.announcementList &&
                    this.props.announcementList?.length > 0 && (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        // key={index}
                      >
                        {/* <Slider {...settings}>  */}
                        <Announcement
                          announcementData={this.props.announcementList}
                          openAnnouncement={this.openAnnoucmentScreen}

                          // id={announcement.id}
                          // imgUrl={announcement.file}
                          // title={announcement.title}
                          // content={announcement.description}
                        />

                        {/* </Slider> */}
                      </Grid>
                    )}

                  {/* </AnnouncementLisCardWithHeaderBase> */}
                  {/* <div style={{marginBottom:"10px"}}></div> */}
                </Grid>
                {/* </Grid> */}

                {this.state.isUploading && (
                  // <Box component="div">
                  //   <Paper
                  //     elevation={0}
                  //     style={{
                  //       // backgroundColor:"blue",
                  //       marginBlock: "10px",
                  //       marginInline: "10px",
                  //       padding: "32px",
                  //     }}
                  //   >
                  <div className="communityCard mt-3 p-4">
                    <LinearProgressBar
                      progressCompletedPercent={this.state.percentUploaded}
                      processingStatus={this.state.processingStatus}
                    />
                  </div>
                  //   </Paper>
                  // </Box>
                )}

                {/* </AnnouncementLisCardWithHeaderBase> */}
                {/* <div style={{marginBottom:"10px"}}></div> */}
              </Grid>
            </Grid>

            <Grid container spacing={0} style={{ paddingTop: "2px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <button
                  className="cardButton"
                  onClick={this.showPlusMenu}
                  ref={this.wrapperRef}
                >
                  <HiOutlineFilter
                    style={{
                      marginRight: "5px",
                      width: "18px",
                      height: "18px",
                    }}
                  />{" "}
                  {t("Filter")}{" "}
                  <button
                    // onClick={this.showPlusMenu}
                    class="btn  dropdown-toggle filter-btn"
                    dropdown-toggle
                  ></button>
                </button>
              </Grid>
            </Grid>

            {showPlusMenu && (
              <>
                <div
                  className={
                    // firstTime
                    //   ?
                    "dropdownfirstTimeCardPost"
                    // : "dropdownCardPost"
                  }
                  ref={this.wrapperRef}
                >
                  <p
                    className="chooseCardList"
                    onClick={() => this.optionType(0)}
                    ref={this.wrapperRef}
                  >
                    {t("Post by me")}
                  </p>
                  <p
                    className="chooseCardList"
                    onClick={() => this.optionType(1)}
                    ref={this.wrapperRef}
                  >
                    {t("Post by friends")}
                  </p>
                  <p
                    className="chooseCardList"
                    onClick={() => this.optionType(2)}
                    ref={this.wrapperRef}
                  >
                    {t("Community Posts")}
                  </p>
                  <p
                    className="chooseCardList"
                    onClick={() => this.optionType(3)}
                  >
                    {t("General")}
                  </p>
                  <p
                    className="chooseCardList"
                    onClick={() => this.optionType(4)}
                  >
                    {t("Clear Filter")}
                  </p>
                </div>
              </>
            )}
            {this.state.postCardType == "0" && (
              <div className="postCardCloud">
                {/* <Grid container spacing={2}> */}
                {this.state.myPostLists &&
                  this.state.myPostLists.map((name, index) => (
                    // <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Chip
                      // color={
                      //   name.myPostColor == "true" ? "primary" : "success"
                      // }
                      key={index}
                      className={
                        name.myPostColor == "true"
                          ? "navMenuTextoverFlowOrange"
                          : "navMenuTextoverFlowGray"
                      }
                      style={{
                        overflow: "hidden",
                        Whitespace: "nowrap",
                        fontSize: "12px",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                      }}
                      label={name.name}
                      onClick={() =>
                        this.handleClickMyList(name.name, name.id, index)
                      }
                      // onChange={(e) => this.onSelectFile(e)}
                    ></Chip>
                    // </Grid>
                  ))}
                {/* </Grid>{" "} */}
              </div>
            )}
            {this.state.postCardType == "1" && (
              <div className="postCardCloud">
                {/* <Grid container spacing={2}> */}
                {this.state.friendDataList &&
                  this.state.friendDataList.map((subject, index) => (
                    // <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Chip
                      key={index}
                      className={
                        subject.friendCardColor == "true"
                          ? "navMenuTextoverFlowOrange"
                          : "navMenuTextoverFlowGray"
                      }
                      style={{
                        overflow: "hidden",
                        Whitespace: "nowrap",
                        fontSize: "12px",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() =>
                        this.handleClickList(
                          subject.displayName,
                          subject.userId,
                          index
                        )
                      }
                      label={subject.displayName}
                    />
                    // </Grid>
                  ))}{" "}
                {/* </Grid> */}
              </div>
            )}
            {this.state.postCardType == "2" && (
              <div className="postCardCloud">
                {/* <Grid container spacing={2}> */}
                {this.state.groupsList &&
                  this.state.groupsList.map((group, index) => (
                    // <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Chip
                      key={index}
                      className={
                        group.colorChangeGroups == "true"
                          ? "navMenuTextoverFlowOrange"
                          : "navMenuTextoverFlowGray"
                      }
                      style={{
                        overflow: "hidden",
                        Whitespace: "nowrap",
                        fontSize: "12px",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() =>
                        this.handleClickGroups(
                          group.tagDescription,
                          group.userId,
                          index
                        )
                      }
                      label={group.tagDescription}
                    ></Chip>
                    // </Grid>
                  ))}
                {/* </Grid>{" "} */}
              </div>
            )}
          </>
        )}
        <BlockUi
          tag="div"
          message={t("Loading, please wait")}
          style={{ minHeight: "134px" }}
          className="wallLoadingPage"
          blocking={this.props.loaderStats}
        >
          {Array.isArray(this.props.postList) &&
            this.props.postList.map((communityPostData, index) => {
              return (
                <PostCard
                  index={index}
                  isBookMarkType={this.props.type}
                  onShareList={this.sharePost}
                  ondeleteList={this.deletePost}
                  postList={this.state.postData}
                  data={communityPostData}
                  login={this.props.loginDetails}
                  friends={this.props.workspaceFriendList}
                  likestatusUpdate={this.likestatusUpdate}
                  subscribeUpdate={this.subscribeUpdate}
                  sharePostOnWay={this.sharePostOnWay}
                  updatePost={this.updatePost}
                  updateSurveyCompletion={(a) => this.updateSurveyCompletion(a)}
                  isGroup={false}
                />
              );
            })}

          {Array.isArray(this.props.postList) &&
            this.props.postList.length > 0 &&
            !this.props.loaderStats &&
            this.props.seeMorePage && (
              <div className="networkDetailsBtn">
                <button
                  className="networkSeeMore"
                  onClick={this.onChangeCallingSeeMore}
                >
                  {t("See more")}
                </button>
              </div>
            )}
          {/* {!this.props.seeMorePage &&
                !this.props.loaderStats &&
                this.state.pageNo > 2 && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <h6>{t("No More Post Available")}</h6>
                  </div>
                )} */}
          {!this.props.seeMorePage &&
            !this.props.loaderStats &&
            this.props.postList.length === 0 &&
            !this.props.seeMorePage && (
              <Card style={{ marginTop: "15px" }}>
                <CardContent>
                  <center>
                    <p>{t("No Post Available")}</p>
                  </center>
                </CardContent>
              </Card>
            )}
        </BlockUi>
        {/* </ReactPlaceholder> */}
        {/* </Col>
        </Row> */}
        {/* </BlockUi> */}
      </>
    );
  }

  onChangeCalling = async (e, type) => {
    this.state.pageNo = 0;
    console.log("Group  Data Check", type);
    if (
      this.state.broadCast != true &&
      !this.state.clearState &&
      !this.state.filterMypostList &&
      !this.state.filterFriendsList &&
      !this.state.filterGroupsList
    ) {
      var filterKey = [];
      var filterValue = [];
      var friends = false;
      var mypost = false;
      var group = false;

      for (let i = 0; i < e.length; i++) {
        if (e[i].myPostColor) {
          if (e[i].displayName === "Groups") {
            filterValue.push("groups");
          } else if (e[i].displayName === "Friends") {
            filterValue.push("friends");
          } else if (e[i].displayName === "General") {
            filterValue.push("broadCast");
          }
          friends = false;
          mypost = true;
          group = false;
        } else if (e[i].friendCardColor) {
          filterKey.push(
            //  fileType: "friend",
            e[i].userId
          );
          friends = true;
          mypost = false;
          group = false;
          // console.log("11-----friendCardColor",e[i]. )
        } else if (e[i].colorChangeGroups) {
          filterKey.push(
            //  fileType: "group",
            e[i].userId
          );
          friends = false;
          mypost = false;
          group = true;
          //console.log("11---colorChangeGroups",e[i].userId)
        }
      }

      if (mypost) {
        var data = {
          page: this.state.pageNo,
          filterType: "mypost",
          filterValue,
        };
      } else if (friends) {
        var data = {
          page: this.state.pageNo,
          filterType: "friends",
          filterKey,
        };
      } else if (group) {
        var data = {
          page: this.state.pageNo,
          filterType: "groups",
          filterKey,
        };
      }
      console.log("11--value", data);
      this.filterOption(data);
    } else {
      console.log("Group  Data Check", this.state.filterGroupsList);
      if (this.state.broadCast) {
        var data = {
          page: this.state.pageNo,
          filterType: "broadCast",
        };

        this.setState({
          broadCast: false,
        });
      } else if (this.state.filterMypostList) {
        //  alert("calling")
        var data = {
          page: this.state.pageNo,
          filterType: "mypost",
          filterValue: ["groups", "friends", "broadCast"],
        };
      } else if (this.state.filterFriendsList) {
        //   alert("calling")
        let da = [];
        console.log("friends Data", this.state.friendDataList);
        for (let a = 0; a < this.state.friendDataList.length; a++) {
          da.push(this.state.friendDataList[a].userId);
        }

        var data = {
          page: this.state.pageNo,
          filterType: "friends",
          filterKey: da,
        };
      } else if (this.state.filterGroupsList) {
        // alert("calling")

        let da = [];
        console.log("groups data", this.state.groupsList);
        for (let a = 0; a < this.state.groupsList.length; a++) {
          da.push(this.state.groupsList[a].subjectId);
        }
        var data = {
          page: this.state.pageNo,
          filterType: "groups",
          filterKey: da,
        };
      } else {
        var data = {
          page: this.state.pageNo,
          filterType: "empty",
        };

        this.setState({
          clearState: false,
        });
      }
      this.filterOption(data);
      //  console.log("11--value", data);
    }
  };

  //starting
  filterOption = async (filterData) => {
    var data = filterData;
    if (!this.props.homePost) {
      if (this.props.type === "Feed") {
        data = {
          filterType: "mypost",
          filterValue: ["groups", "friends", "broadCast"],
          page: 0,
        };
      } else {
        data = {
          filterType: "bookmark",
          page: 0,
        };
      }
    } else {
      if (data != null) {
        this.setState({
          payLoad: data,
        });
      } else if (data == null) {
        console.log("11--value", data);
        data = {
          page: 0,
          filterType: "empty",
        };
      }
    }
    //pagination ---> false
    this.props.getPostList(data, this.state.pageNo, false);
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
  };

  getFilterData = (data) => {
    let postData = data;
    let finalList = [];
    postData &&
      postData.forEach((post) => {
        if (post.postCategory === "article" && !post.article) {
        } else {
          finalList.push(post);
        }
      });
    return finalList;
  };

  getPostData = (data) => {
    if (data) {
      let list = this.getFilterData(data);
      this.setState({
        postData: list ? list : [],
        // userPostList
      });
      console.log("finalList", list);
    } else {
      this.setState({
        noMoreState: false,
      });
    }
  };
  getFriendFilterList = () => {
    let friends = this.props.workspaceFriendList;
    var friendData = [];
    for (let i = 0; i < friends.length; i++) {
      friendData.push({
        displayName: friends[i].userName,
        userId: friends[i].userId,
        friendCardColor: "false",
      });
    }

    this.setState({ friendDataList: friendData });
  };
  getMyPosts(myPost) {
    var friendData = [];
    for (let i = 0; i < myPost.length; i++) {
      friendData.push({
        name: myPost[i].name,
        id: myPost[i].id,
        myPostColor: "false",
      });
    }
    this.setState({ myPostLists: friendData });
  }
  handleClickListGroups(groups) {
    var friendData = [];
    if (this.props.joinedGroupList !== undefined) {
      for (let i = 0; i < groups.length; i++) {
        friendData.push({
          tagDescription: groups[i].subjectName,
          userId: groups[i].subjectId,
          colorChangeGroups: "false",
        });
      }
    }

    this.setState({ groupsList: friendData });
  }
  showPlusMenu(event) {
    //  alert()
    console.log("Remove--1 showPlusMenu");
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      showPlusMenu: !this.state.showPlusMenu,
    });
  }

  //Friends,General
  createHomePost = (
    file,
    posType,
    content,
    active,
    postCategory,
    groupOrNot,
    groupId,
    articleHeading
  ) => {
    const myJSON = JSON.stringify(file);

    const paragraph = content;
    const regex = /#\w+/g;
    const found = paragraph.match(regex);
    console.log("para", found);
    const formData = new FormData();

    if (posType === "poll") {
      console.log("isAnonymous", groupId);
      formData.append("isAnonymous", groupId);
    }
    if (postCategory === "article") {
      console.group("Article Type");
      console.log("File", file);
      console.log("posType", posType);
      console.log("content", content);
      console.log("active", true);
      console.log("post Category", postCategory);
      console.log("Is Group", false);
      console.log("groupId", groupId);
      console.log("article Heading", articleHeading);
      if (file) {
        formData.append("postCategory", postCategory);
        formData.append("isGroup", false);
        formData.append("title", articleHeading);
        formData.append("description", content);
        formData.append("type", posType);
        for (let i = 0; i < file.length; i++) {
          formData.append("postFiles", file[i]);
        }
        // formData.append("type", file);
      } else {
        formData.append("postCategory", postCategory);
        formData.append("isGroup", false);
        formData.append("title", articleHeading);
        formData.append("description", content);
        formData.append("type", posType);
      }

      console.groupEnd();
    } else if (
      posType === "text" &&
      groupOrNot === "notGroup" &&
      postCategory !== "article"
    ) {
      formData.append("userId", this.props.loginDetails.uId);
      formData.append("mediaPost", content);
      formData.append("postActive", active);
      formData.append("postCategory", postCategory);
      formData.append("type", "text");
      formData.append("isGroup", false);
      // formData.append("postFiles", file)
    } else if (
      groupOrNot === "notGroup" &&
      posType !== "text" &&
      posType !== "poll" &&
      file !== undefined &&
      posType !== "survey" &&
      postCategory !== "article"
    ) {
      formData.append("userId", this.props.loginDetails.uId);
      formData.append("type", posType);
      formData.append("postActive", active);
      formData.append("postCategory", postCategory);
      formData.append("mediaPost", content);
      for (let i = 0; i < file.length; i++) {
        formData.append("postFiles", file[i]);
      }
      formData.append("isGroup", false);
    } else if (groupOrNot === "notGroup" && posType === "poll") {
      formData.append("userId", this.props.loginDetails.uId);
      file.forEach((item) => {
        formData.append("postAnswers", item);
      });
      formData.append("postActive", active);
      formData.append("postCategory", postCategory);
      formData.append("postQuestion", content);
      formData.append("isGroup", false);
    } else if (groupOrNot === "notGroup" && posType === "survey") {
      formData.append("userId", this.props.loginDetails.uId);
      formData.append("surveyQA", myJSON);
      formData.append("postActive", active);
      formData.append("postCategory", postCategory);
      formData.append("isGroup", false);
      // formData.append("answerType", content);
    } else if (
      groupOrNot === "Group" &&
      postCategory === ("normal" || "advertisement")
    ) {
      if (posType !== "text" && file !== undefined && file !== "") {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("questionName", content);
        formData.append("markAsPrivate", true);
        formData.append("Type", posType);
        formData.append("tagDescription", active);
        formData.append("subjectId", groupId);
        formData.append("groupType", false);
        found && formData("hashtag", found);
        formData.append("postCategory", postCategory);
        for (let i = 0; i < file.length; i++) {
          formData.append("messgaeFiles", file[i]);
        }
        formData.append("isGroup", true);
      } else {
        formData.append("userName", this.props.loginDetails.dName);
        formData.append("questionName", content);
        formData.append("markAsPrivate", true);
        formData.append("Type", "text");
        formData.append("tagDescription", active);
        formData.append("subjectId", groupId);
        formData.append("groupType", false);
        found && formData.append("hashtag", found);
        formData.append("postCategory", postCategory);
        formData.append("isGroup", true);
      }

      console.log("pt ok");
    } else if (groupOrNot === "Group" && postCategory === "polling") {
      formData.append("userName", this.props.loginDetails.dName);
      formData.append("markAsPrivate", true);
      formData.append("Type", "text");
      formData.append("tagDescription", active);
      formData.append("subjectId", groupId);
      formData.append("groupType", false);
      formData.append("postCategory", postCategory);
      formData.append("postQuestion", posType);
      formData.append("postAnswers", file);
      formData.append("isGroup", true);
      
    } else if (groupOrNot === "Group" && postCategory === "survey") {
      formData.append("userName", this.props.loginDetails.dName);
      formData.append("questionName", content);
      formData.append("markAsPrivate", true);
      formData.append("Type", "text");
      formData.append("tagDescription", active);
      formData.append("subjectId", groupId);
      formData.append("groupType", false);
      formData.append("surveyQA", myJSON);
      formData.append("postCategory", postCategory);
      formData.append("isGroup", true);
    } else if (groupOrNot === "Group") {
      formData.append("userName", this.props.loginDetails.dName);
      formData.append("markAsPrivate", true);
      formData.append("Type", posType);
      formData.append("tagDescription", active);
      formData.append("subjectId", groupId);
      formData.append("groupType", false);
      formData.append("postCategory", postCategory);
      formData.append("isGroup", true);
      for (let i = 0; i < file.length; i++) {
        formData.append("messgaeFiles", file[i]);
      }
      formData.append("questionName", content);
    }

    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };
    if(articleHeading){
      formData.append("postQuestionTitle",articleHeading)

    }
 
    // this.props.createNewPost(formData, config, "✓ Post has been created");
    try {
      this.processIndicator(0, "processing", true);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(
            `Progress Details ${loaded}kb of ${total}kb | ${percent}%`
          ); // just to see whats happening in the console
          // this.setState({ percentUploaded: percent });
          if (percent <= 100) {
            this.setState({ percentUploaded: percent });
            // hook to set the value of current level that needs to be passed to the progressbar
          } else {
            this.setState({ processingStatus: "completed" });
          }
        },
      };
      this.processIndicator(0, "processing", true);

      HomeService.createHomePostV2(formData, config)
        .then((response) => {
          var result = response.data.data;
          if (result === "expired") {
            this.notify();
          } else if (result) {
            this.props.createNewPostV2(result, "✓ Post has been created");
          } else {
            toast.error("Something went wrong,please try after some time !");
          }
          this.processIndicator(0, "completed", false);
        })
        .catch((e) => {
          this.processIndicator(0, "completed", false);
          toast.error("Something went wrong,please try after some time !");
        });
    } catch (e) {
      this.processIndicator(0, "completed", false);
      console.log("error", e);
    }
  };

  notify = () => {
    toast.info(
      <div>
        <p>Your limit has been reached.</p>
        <p>Please recharge your account to continue using the service.</p>
        <a href="http://localhost:3000/premium-subscription">Recharge Now</a>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "center-toast",
      }
    );
  };

  calculateProgress(data) {
    console.log("Pogress Details ", data);
    // this.setState({percentage:data})
  }

  fromPost(file, type, content, active) {
    this.setState({ blocking: true });
    this.setState({ isUploading: true });
    const paragraph = content;
    const regex = /#\w+/g;
    const found = paragraph.match(regex);
    const formData = new FormData();
    formData.append("userId", this.props.loginDetails.uId);
    // formData.append("", file);
    formData.append("type", type);
    formData.append("postActive", active);
    formData.append("mediaPost", content);
    for (let i = 0; i < file.length; i++) {
      formData.append("postFiles", file[i]);
    }
    found && formData.append("hashtag", found);
    // for (let i = 0; i < found.length; i++) {
    //   formData.append("hashTag", found[i]);
    // }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`Progress Details ${loaded}kb of ${total}kb | ${percent}%`); // just to see whats happening in the console
        this.setState({ percentUploaded: percent });
        if (percent <= 100) {
          this.setState({ percentUploaded: percent });
          // hook to set the value of current level that needs to be passed to the progressbar
        } else {
          this.setState({ processingStatus: "completed" });
        }
      },
    };

    PostService.saveImagePostV2(formData, config)
      .then((response) => {
        var result = response.data.data;
        console.log("Progress Details", this.state.postData);
        console.log("Progress Details", result.userpost);
        console.log("progress Details", [
          result.userpost,
          ...this.state.postData,
        ]);
        this.setState({ blocking: false, seeMorePostListempty: false });
        this.setState({ isUploading: false });

        toast.success(`${this.props.t("✓ Post has been created")}`);

        this.setState({
          postData: [result.userpost, ...this.state.postData],
        });
        console.log("saveImage >>", this.state.postData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // getPost() {
  //   this.setState({ blocking: true });

  //   let getPost = {
  //     userId: this.props.loginDetails.uId,
  //     page: 0,
  //   };

  //   PostService.getPost(getPost)
  //     .then((response) => {
  //       console.log("old 10 post");
  //       this.setState({ blocking: false });
  //       console.log(response.data.data.userpost);
  //       console.log("firstTimeSetup%%%", response.data.data.firstTimeSetup);
  //       if (response.data.status === 1) {
  //         this.setState({
  //           userPostList: response.data.data.userpost,
  //           firstTimeSetup: response.data.data.firstTimeSetup,
  //         });
  //         this.getMergePostList(response.data.data.userpost);
  //       } else if (response.data.status === -1) {
  //         this.setState({ userPostList: [] });
  //       }
  //       if (response.data.data.userpost.length > 0) {
  //       } else {
  //         this.setState({ noMoreState: true });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  getMergePostList(postData) {
    this.setState({
      postData: postData,
    });
  }
  onChangeCallingSeeMore = () => {
    // this.state.pageNo = this.state.pageNo + 1;
    const items = [];
    const filterKey = [];
    const filterValue = [];
    var filterType = "";
    if (this.state.payLoad.length !== 0) {
      console.log("payload : ", this.state.payLoad);
      Object.keys(this.state.payLoad).forEach((item) => {
        if (item === "filterKey") {
          filterKey.push(this.state.payLoad[item]);
        }
        if (item === "filterType") {
          filterType = this.state.payLoad[item];
        }
        if (item === "filterValue") {
          filterValue.push(this.state.payLoad[item]);
        }
      });
      if (filterKey.length !== 0) {
        items.push({
          filterKey: filterKey[0],
          filterType: filterType,
          page: this.state.pageNo,
        });
      } else if (filterValue.length !== 0) {
        items.push({
          filterValue: filterValue[0],
          filterType: filterType,
          page: this.state.pageNo,
        });
      } else {
        items.push({
          filterType: filterType,
          page: this.state.pageNo,
        });
      }
      console.log("payload : ", items[0]);
      this.pageNo(items[0]);
    } else {
      this.pageNo();
    }
  };
  pageNo(filterData) {
    var data = filterData;
    if (!this.props.homePost) {
      if (this.props.type === "Feed") {
        data = {
          filterType: "mypost",
          filterValue: ["groups", "friends", "broadCast"],
          page: this.state.pageNo,
        };
      } else {
        data = {
          filterType: "bookmark",
          page: this.state.pageNo,
        };
      }
    } else {
      if (data != null) {
        this.setState({
          payLoad: data,
        });
      } else if (data == null) {
        data = {
          page: this.state.pageNo,
          filterType: "empty",
        };
      }
    }
    //pagination true
    this.props.getPostList(data, this.state.pageNo, true);
    this.setState({
      pageNo: this.state.pageNo + 1,
    });
    // if (data == null) {
    //   var data = {
    //     page: this.state.pageNo,
    //     filterType: "empty",
    //   };
    // }
    // this.setState({ blockingUi: true });
    // PostService.getPostByFilter(data)

    //   .then((response) => {
    //     var result = response.data;
    //     this.setState({ blocking: false, blockingUi: false });
    //     console.log("new 10 post");
    //     console.log(result.data.userpost);
    //     // if (result.data.userpost.length === 0) {
    //     //   this.setState({
    //     //     seeMorePostListempty: true,
    //     //   });
    //     // }
    //     if (result.data.userpost?.length > 0) {
    //       if (response.data.data?.totalPages - 1 > this.state.pageNo) {
    //         this.setState({ noMoreState: true, pageNo: this.state.pageNo + 1 });
    //       } else {
    //         this.setState({ noMoreState: false });
    //       }
    //       if (this.state.postData?.length > 0) {
    //         var oldPostList = this.state.postData;
    //         var responseData = result.data.userpost;
    //         if (responseData) {
    //           responseData = this.getFilterData(responseData);
    //         }
    //         var newPosts = oldPostList.concat(responseData);
    //         this.setState({
    //           // userPostList: newPosts ? newPosts : [],
    //           seeMorePostListempty: false,
    //           postData: newPosts ? newPosts : [],
    //         });
    //         // this.getMergePostList(this.state.userPostList);
    //       } else {
    //         var newPosts = [result.data.userpost];
    //         this.setState({ postData: newPosts });
    //         // this.getMergePostList(this.state.userPostList);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }
  sharePost(value) {
    console.log("share post");
    var itemuserPost = value;
    console.log("USERDETAILS : uid : " + JSON.stringify(itemuserPost));
    var temppostMedia = [];
    if (itemuserPost.hasOwnProperty("fileData")) {
      itemuserPost.fileData.forEach((element) => {
        var jsonItm = {
          postId: itemuserPost.userpost.postId,
          fileName: element,
          fileType: "message",
        };
        temppostMedia.push(jsonItm);
      });

      itemuserPost.userpost.postMediaFiles = temppostMedia;
    }
    var jsonUser = {
      userProfile: {
        userId: this.props.loginDetails.uId,
        firstName: this.props.loginDetails.fName,
        lastName: this.props.loginDetails.lName,
        displayName: this.props.loginDetails.dName,
      },
    };

    itemuserPost.userpost.user = jsonUser;
    console.log(itemuserPost);
    var jsonList = this.state.postData;
    jsonList.unshift(itemuserPost.userpost);
    this.setState({
      postData: jsonList,
    });
  }
  deletePost(moderationId, postId) {
    console.log(this.state.postData);
    var postList = this.state.postData;
    postList = this.state.postData.filter(
      (item) => item.moderationId != moderationId
    );
    this.setState({
      postData: postList,
    });
  }

  openAnnoucmentScreen(announcementId) {
    // this.setState({ showAnnoucment: true });
    this.props.history.push(
      `/${this.props.workspaceType}/announcement/${announcementId}`
    );
  }

  closeAnnoucmentScreen() {
    // this.setState({ showAnnoucment: false });
    this.props.history.push(`/${this.props.workspaceType}/home`);
  }
  processIndicator = (percent, status, uploading) => {
    this.setState({
      percentUploaded: percent,
      processingStatus: status,
      isUploading: uploading,
    });
  };
}

const mapStateToProps = function (state) {
  return {
    authUser: state.loginDetailsApi.authUser,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceFriendList: state.commonApi.workspaceFriendList,
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug, //  tagDatas: state.subjects.myGroupHome,
    joinedGroupList: state.commonApi.joinedSubjectList,
    postList: state.postList.postList,
    loaderStats: state.postList.loaderStats,
    seeMorePage: state.postList.seeMorePage,
    postError: state.postList.postError,
    toastData: state.postList.toastData,
    toastDataStatus: state.postList.toastDataStatus,
    firstTimeSetupStatus: state.postList.firstTimeSetupStatus,
    announcementList: state.commonApi.announcementList,
    isDeletedPost: state.postList.isDeletedPost,
    isDeletePostStatus: state.postList.isDeletePostStatus,
    toursStages: state.commonApi.learningPopupStatus,
    userWorkSpaceList: state.loginDetailsApi.loginDetails?.userWorkSpaceList,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    createNewPost: (data, config, toastData) =>
      dispatch(createNewPost(data, config, toastData)),
    getPostList: (type, pageNo, pagination) =>
      dispatch(getPostList(type, pageNo, pagination)),
    getAllMyGroupEventsHome: (t) => dispatch(getAllMyGroupEventsHome()),
    getAllUserCommunityList: () => dispatch(getAllUserCommunityList()),
    triggerTourPopup: (slug) => dispatch(triggerTourPopup(slug)),
    getFriendsListBasedWorkSpace: () =>
      dispatch(getFriendsListBasedWorkSpace()),
    createNewPostV2: (data, toastData) =>
      dispatch(createNewPostV2(data, toastData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MyCommunity)));
