export const timeZone = [
  {
    label: "Africa",
    options: [
      { label: "Africa-Cairo", value: "Africa/Cairo", offset: "GMT+2" },
      {
        label: "Africa-Johannesburg",
        value: "Africa/Johannesburg",
        offset: "GMT+2",
      },
      { label: "Africa-Lagos", value: "Africa/Lagos", offset: "GMT+1" },
      { label: "Africa-Nairobi", value: "Africa/Nairobi", offset: "GMT+3" },
    ],
  },
  {
    label: "America",
    options: [
      { label: "America-New_York", value: "America/New_York", offset: "GMT-4" },
      {
        label: "America-Los_Angeles",
        value: "America/Los_Angeles",
        offset: "GMT-7",
      },
      {
        label: "America-Sao_Paulo",
        value: "America/Sao_Paulo",
        offset: "GMT-3",
      },
      { label: "America-Chicago", value: "America/Chicago", offset: "GMT-5" },
    ],
  },
  {
    label: "Asia",
    options: [
      { label: "Asia-Tokyo", value: "Asia/Tokyo", offset: "GMT+9" },
      { label: "Asia-Dubai", value: "Asia/Dubai", offset: "GMT+4" },
      { label: "Asia-Shanghai", value: "Asia/Shanghai", offset: "GMT+8" },
      { label: "Asia-Kolkata", value: "Asia/Kolkata", offset: "GMT+5:30" },
    ],
  },
  {
    label: "Europe",
    options: [
      { label: "Europe-London", value: "Europe/London", offset: "GMT+0" },
      { label: "Europe-Berlin", value: "Europe/Berlin", offset: "GMT+2" },
      { label: "Europe-Moscow", value: "Europe/Moscow", offset: "GMT+3" },
      { label: "Europe-Paris", value: "Europe/Paris", offset: "GMT+2" },
    ],
  },
  {
    label: "Australia",
    options: [
      {
        label: "Australia-Sydney",
        value: "Australia/Sydney",
        offset: "GMT+10",
      },
      { label: "Australia-Perth", value: "Australia/Perth", offset: "GMT+8" },
      {
        label: "Australia-Melbourne",
        value: "Australia/Melbourne",
        offset: "GMT+10",
      },
    ],
  },
];

export const language = [
  { value: "English", label: "English" },
  { value: "Tamil", label: "தமிழ்" },
  { value: "Hindi", label: "हिंदी" },

  { value: "Gujarati", label: "ગુજરાતી" },

  { value: "Kannadam", label: "ಕನ್ನಡ" },

  { value: "Odia", label: "ଓଡିଆ" },
  { value: "Telugu", label: "తెలుగు" },
  { value: "Assamese", label: "অসমীয়া" },
  { value: "Kashmiri", label: "کٲشُر" },

  { value: "Bengali", label: "বাংলা" },
  { value: "Arabic", label: "عربي" },

  { value: "French", label: "Français" },

  { value: "Spanish", label: "Española" },
  { value: "Mandarin", label: "普通话" },
];

export const fontOptions = [
  { label: "Arial", value: "Arial, sans-serif" },
  { label: "Times New Roman", value: "'Times New Roman', serif" },
  { label: "Courier New", value: "'Courier New', monospace" },
  { label: "Georgia", value: "Georgia, serif" },
  { label: "Verdana", value: "Verdana, sans-serif" },
  { label: "Tahoma", value: "Tahoma, sans-serif" },
  { label: "Trebuchet MS", value: "'Trebuchet MS', sans-serif" },
  { label: "Impact", value: "Impact, sans-serif" },
  { label: "Comic Sans MS", value: "'Comic Sans MS', cursive, sans-serif" },
  { label: "Lucida Console", value: "'Lucida Console', Monaco, monospace" },
  {
    label: "Palatino Linotype",
    value: "'Palatino Linotype', 'Book Antiqua', Palatino, serif",
  },
  // { label: "Garamond", value: "Garamond, serif" },
  { label: "Century Gothic", value: "'Century Gothic', sans-serif" },
  // {
  //   label: "Franklin Gothic Medium",
  //   value: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
  // },
  { label: "Roboto", value: "'Roboto', sans-serif" },
  { label: "Open Sans", value: "'Open Sans', sans-serif" },
  { label: "Lato", value: "'Lato', sans-serif" },
  { label: "Montserrat", value: "'Montserrat', sans-serif" },
  { label: "Poppins", value: "'Poppins', sans-serif" },
  { label: "Playfair Display", value: "'Playfair Display', serif" },
  { label: "Raleway", value: "'Raleway', sans-serif" },
  // { label: "Oswald", value: "'Oswald', sans-serif" },
  { label: "Ubuntu", value: "'Ubuntu', sans-serif" },
  { label: "Dancing Script", value: "'Dancing Script', cursive" },
  // { label: "Helvetica", value: "'Helvetica', sans-serif" },
];

export const colorPalettes = [
  {
    primary: "#222831",
    secondary: "#393E46",
    heading: "#00ADB5",
    subHeading: "#EEEEEE",
  },
  {
    primary: "#264653",
    secondary: "#2A9D8F",
    heading: "#E9C46A",
    subHeading: "#F4A261",
  },
  {
    primary: "#457B9D",
    secondary: "#1D3557",
    heading: "#A8DADC",
    subHeading: "#F1FAEE",
  },
  {
    primary: "#219EBC",
    secondary: "#023047",
    heading: "#8ECAE6",
    subHeading: "#FFB703",
  },
  {
    primary: "#D9A066",
    secondary: "#6B4226",
    heading: "#F2D7B6",
    subHeading: "#FAF3E0",
  },

  {
    primary: "#81B29A",
    secondary: "#3D405B",
    heading: "#E07A5F",
    subHeading: "#F4F1DE",
  },

  {
    primary: "#0A1128",
    secondary: "#001F54",
    heading: "#ffffff",
    subHeading: "#F0E3CA",
  },
  {
    primary: "#AB83A1",
    secondary: "#6A0572",
    heading: "#EBC7E6",
    subHeading: "#F6E8EA",
  },
  {
    primary: "#BB9457",
    secondary: "#6F1D1B",
    heading: "#E6B8A2",
    subHeading: "#FFE6A7",
  },
  
  {
    primary: "#C1A5A9",
    secondary: "#8A4F7D",
    heading: "#D8C3A5",
    subHeading: "#EAE7DC",
  },
  {
    primary: "#A0937D",
    secondary: "#3A6351",
    heading: "#D9CAB3",
    subHeading: "#EADBC8",
  },
  {
    primary: "#8D99AE",
    secondary: "#2B2D42",
    heading: "#EDF2F4",
    subHeading: "#EF233C",
  },
  {
    primary: "#393E46",
    secondary: "#1B1B1B",
    heading: "#6D9886",
    subHeading: "#F2E7D5",
  },
  {
    primary: "#90623E",
    secondary: "#4B2C20",
    heading: "#D1B092",
    subHeading: "#EED7C5",
  },
  {
    primary: "#102A43",
    secondary: "#243B53",
    heading: "#D9E2EC",
    subHeading: "#F0F4F8",
  },
  {
    primary: "#704214",
    secondary: "#1F1300",
    heading: "#EAD196",
    subHeading: "#FFF1E6",
  },
  {
    primary: "#8A4F7D",
    secondary: "#561C24",
    heading: "#C09F80",
    subHeading: "#E2C2B9",
  },
  {
    primary: "#5C8374",
    secondary: "#092327",
    heading: "#9EC8B9",
    subHeading: "#DFEEEA",
  },
    {
    primary: "#FAF0CA",
    secondary: "#0D3B66",
    heading: "#F4D35E",
    subHeading: "#EE964B",
  },
  {
    primary: "#5E548E",
    secondary: "#231942",
    heading: "#9F86C0",
    subHeading: "#E0B1CB",
  },
  {
    primary: "#3FC1C9",
    secondary: "#364F6B",
    heading: "#F5F5F5",
    subHeading: "#FC5185",
  },
 {
    primary: "#005F73",
    secondary: "#0A9396",
    heading: "#94D2BD",
    subHeading: "#E9D8A6",
  },
  {
    primary: "#007991",
    secondary: "#222E50",
    heading: "#439A86",
    subHeading: "#BCD8C1",
  },
  {
    primary: "#1F2041",
    secondary: "#4B3F72",
    heading: "#FFC857",
    subHeading: "#E6AF2E",
  },
  {
    primary: "#D8A48F",
    secondary: "#3B6064",
    heading: "#B6B58C",
    subHeading: "#EEF5DB",
  },
 
  {
    primary: "#A29BFE",
    secondary: "#3D3B8E",
    heading: "#DCD6F7",
    subHeading: "#F1EAFF",
  },
  {
    primary: "#3A2E39",
    secondary: "#1E555C",
    heading: "#E4B363",
    subHeading: "#F2E3D5",
  },
  {
    primary: "#7A306C",
    secondary: "#A44A3F",
    heading: "#E8C547",
    subHeading: "#E5E5E5",
  },
  {
    primary: "#7C5E99",
    secondary: "#382933",
    heading: "#A27EA8",
    subHeading: "#DFC2F2",
  },
  {
    primary: "#192A51",
    secondary: "#967AA1",
    heading: "#FFEECF",
    subHeading: "#FDC57B",
  },
  {
    primary: "#2E382E",
    secondary: "#33673B",
    heading: "#90A955",
    subHeading: "#ECF39E",
  },
  {
    primary: "#A9927D",
    secondary: "#1B2021",
    heading: "#D1BE9C",
    subHeading: "#FAE1DF",
  },
  {
    primary: "#1B1B1E",
    secondary: "#515052",
    heading: "#BE9063",
    subHeading: "#F8E9A1",
  },
  {
    primary: "#A9A9A9",
    secondary: "#3C3744",
    heading: "#D1CFE2",
    subHeading: "#F3F3F4",
  },
  {
    primary: "#C398A3",
    secondary: "#5B4A5D",
    heading: "#E1CFCB",
    subHeading: "#F1D4D4",
  },
  {
    primary: "#252323",
    secondary: "#70798C",
    heading: "#DAD2D8",
    subHeading: "#F5F3F4",
  },
  {
    primary: "#40798C",
    secondary: "#0B2027",
    heading: "#70A9A1",
    subHeading: "#CFD7C7",
  },
  {
    primary: "#A5B68D",
    secondary: "#B17F59",
    heading: "#C1CFA1",
    subHeading: "#EDE8DC",
  },
  
  {
    primary: "#B9B28A",
    secondary: "#504B38",
    heading: "#EBE5C2",
    subHeading: "#F8F3D9",
  },
  {
    primary: "#5C7285",
    secondary: "#818C78",
    heading: "#A7B49E",
    subHeading: "#E2E0C8",
  },
  {
    primary: "#FFDAB3",
    secondary: "#574964",
    heading: "#9F8383",
    subHeading: "#C8AAAA",
  },
  {
    primary: "#727D73",
    secondary: "#AAB99A",
    heading: "#D0DDD0",
    subHeading: "#F0F0D7",
  },
  {
    primary: "#8174A0",
    secondary: "#A888B5",
    heading: "#EFB6C8",
    subHeading: "#FFD2A0",
  },
  {
    primary: "#f3d2c1", //main
    secondary: "#f582ae",//Teritary
    heading: "#001858", //Button Text
    subHeading: "#172c66", //Paragraph
  },
  {
    primary: "#faeee7", //main
    secondary: "#c3f0ca",//Teritary
    heading: "#33272a", //Button Text
    subHeading: "#594a4e", //Paragraph
  },
  {
    primary: "#f3d2c1", //main
    secondary: "#bae8e8",//Teritary
    heading: "#272343", //Button Text
    subHeading: "#2d334a", //Paragraph
  },
  {
    primary: "#fffffe", //main
    secondary: "#3da9fc",//Teritary
    heading: "#001858", //Button Text
    subHeading: "#5f6c7b", //Paragraph
  },
  {
    primary: "#eff0f3", //main
    secondary: "#ff8e3c",//Teritary
    heading: "#0d0d0d", //Button Text
    subHeading: "#2a2a2a", //Paragraph
  },
  {
    primary: "#e3f6f5", //main
    secondary: "#ffd803",//Teritary
    heading: "#272343", //Button Text
    subHeading: "#2d334a", //Paragraph
  },
 
  
];
