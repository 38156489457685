import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import MyYuukke from "../../../api/MyYuukke";
import BlockUi from "react-block-ui";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { JITSI_URL } from "../../../config/app-config";
import { FaRupeeSign } from "react-icons/fa";
import { mailInit, phoneInit } from "../../../utils.js";
import { FiPhoneForwarded } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { withTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FaDownload } from "react-icons/fa";
import { useRef } from "react";
import { Tabs, Tab } from '@material-ui/core';
import { Button, IconButton, TextField, InputAdornment } from "@material-ui/core";
import { IoMdAddCircleOutline } from "react-icons/io";
import BookingModal from "./BookingModal.jsx";

const useStyles = makeStyles(theme => ({
  tableHeader: {
    backgroundColor: '#1a237e',
    '& .MuiTableCell-head': {
      color: '#fff',
      fontSize: '0.95rem',
      fontWeight: 600
    }
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#f5f5f5 !important',
      cursor: 'pointer'
    }
  },
  // searchField: {
  //   '& .MuiInputAdornment-root': {
  //     visibility: 'hidden', 
  //     width: 0, 
  //   },
  // },

  dialogTitle: {
    backgroundColor: '#1a237e',
    color: '#fff !important',
    padding: '20px 24px',
    fontSize: '1.5rem',
    fontWeight: 500
  },
  detailTable: {
    '& th': {
      backgroundColor: '#3f51b5 !important',
      color: '#fff !important',
      fontSize: '1rem',
      fontWeight: 500
    },
    '& td': {
      verticalAlign: 'top',
      fontSize: '0.95rem'
    }
  },
  actionButton: {
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
    }
  },
  actionButtons: {
    // padding: theme.spacing(1.5, 3),
    borderRadius: 12,
    fontWeight: 700,
    textTransform: "none",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    border: "none",
    color: "#fff",
    marginLeft: "auto",
    background: `linear-gradient(34deg, #a00030 0%, #a00030 100%)`,
    letterSpacing: 0.5,
    "&:hover": {
      transform: "translateY(-2px)",
      // boxShadow: theme.shadows[4],
    },
  },
  editButton: {
    background: `linear-gradient(34deg, #007bff 0%, #007bff 100%)`,
    // color: theme.palette.common.white,
  },
  exportButton: {
    background: `linear-gradient(34deg, #a00030 0%, #a00030 100%)`,
    color: "#fff",
    padding: theme.spacing ? theme.spacing(0.8, 1.5) : "6px 12px",
    borderRadius: 8,
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "none",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    border: "none",
    letterSpacing: 0.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "auto",
    "&:hover": {
      transform: "translateY(-1px)",
      boxShadow: theme.shadows ? theme.shadows[2] : "0 2px 4px rgba(0,0,0,0.1)",
    },
  },
  filterTabsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1.5rem 0',
    padding: '0 8px',
    position: 'relative',
    marginLeft: '-9px'
  },
  filterTabs: {
    position: 'relative',
    backgroundColor: 'transparent',
    minHeight: 'auto',
    '& .MuiTabs-scroller': {
      overflow: 'visible !important',
    },
    '& .MuiTabs-flexContainer': {
      gap: '2px',
    },
  },
  filterTab: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '0.85rem',
    padding: '10px 32px',
    borderRadius: '20px',
    transition: 'all 0.2s ease',
    color: '#000000 !important',
    background: '#fff !important',
    border: '2px solid #ddd !important',
    minHeight: 'auto',
    minWidth: 'auto',
    letterSpacing: '0.5px',

    '&.Mui-selected': {
      color: '#fff !important',
      background: '#a00030 !important',
      borderColor: '#a00030 !important',
      boxShadow: '#a00030 !important',
    },

    '&:hover': {
      background: '#f8f9fa !important',
      borderColor: '#c1c1c1 !important',
      color: '#a00030 !important',
    },
    dateFilterWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      marginLeft: '16px',
      '& .MuiFormControl-root': {
        marginRight: '16px',
        '&:last-child': {
          marginRight: 0
        }
      }
    },
    datePicker: {
      width: '180px',
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px 14px',
      },
    },
  },
}));

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '14px 16px'
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: '12px',
        overflow: 'hidden'
      }
    }
  }
});

const RequestList = ({ t, id, dashboardData, type }) => {
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const classes = useStyles();
  const requestTypes = [
    { value: "upcoming", label: "Upcoming Meetings" },
    { value: "past", label: "Past Meetings" },
    { value: "all", label: "Service Bookings" },
    { value: "clients", label: "Total clients" },
  ];

  const [state, setState] = useState({
    requestFilterType: { value: "all", label: "Service Bookings" },
    downloadList: [],
    singleRequestData: null,
    assessmentAnswer: [],
    requestDetails: false,
    requestList: [],
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    viewRequestList(type ? type : "all");
  }, [type]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const viewRequestList = (menu) => {

    if (menu === "clients") {
      const clientsData = dashboardData?.clients || [];
      const list = clientsData.map(client => ({
        userName: client.userName,
        email: client.email,
        mobileNumber: client.mobileNumber
      }));

      setState(prev => ({
        ...prev,
        requestList: list,
        loader: false,
        requestFilterType: requestTypes.find(item => item.value === menu),
      }));
      return;
    }

    let data = { id: id };
    if (menu !== "all") data.type = menu;

    updateState("loader", true);
    MyYuukke.getRequestList(data)
      .then((res) => {
        const type = requestTypes.find((item) => item.value === menu);
        const uniqueServiceNames = [...new Set(res.data.data.map(item => item.serviceName))];
        let obj = {};
        uniqueServiceNames.map((item) => {
          let getRandomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
          obj[item] = getRandomColor;
        });

        const list = res.data.data.map((item) => {
          item.startTime = moment(item.startTime).locale("en").format("LLL");
          item.color = obj[item.serviceName];
          return item;
        });


        setState(prev => ({
          ...prev,
          requestList: list,
          loader: false,
          requestFilterType: type ? type : prev.requestFilterType,
        }));
      })
      .catch((err) => updateState("loader", false));
  };



  const updateState = (name, value) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const getRequestSingleData = (data) => {
    if (data.assessmentNeed) {
      updateState("loader", true);
      MyYuukke.serviceAssessmentAnswer({ id: data.bookingId })
        .then((res) => {
          setState(prev => ({
            ...prev,
            singleRequestData: data,
            assessmentAnswer: res.data.data ?? [],
            loader: false,
            requestDetails: true,
          }));
        })
        .catch((err) => updateState("loader", false));
    } else {
      setState(prev => ({
        ...prev,
        singleRequestData: data,
        requestDetails: true,
        assessmentAnswer: [],
      }));
    }
  };

  const serviceColors = useRef({});

  const getColorForService = (serviceName) => {
    if (!serviceColors.current[serviceName]) {
      serviceColors.current[serviceName] =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
    return serviceColors.current[serviceName];
  };

  const tableRef = React.useRef(null);

  const handleExport = () => {
    if (!tableRef.current) {
      console.error("Table reference is not assigned yet.");
      return;
    }

    const columns = tableRef.current.dataManager.columns;
    const data = tableRef.current.dataManager.data;

    if (!columns || !data) {
      console.error("Columns or data are not available.");
      return;
    }

    const csvRows = [];
    const header = columns.map(col => `"${col.title}"`).join(",");
    csvRows.push(header);

    data.forEach(row => {
      const csvRow = columns.map(col => {
        if (col.field === "reqDate") {
          return `"${moment(row[col.field]).locale("en").format("MMMM DD, YYYY")}"`;
        }
        return `"${row[col.field] || ""}"`;
      }).join(",");
      csvRows.push(csvRow);
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const filteredData = state.requestList.filter(item => {
    const matchesSearch = item.userName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.serviceName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.mobileNumber?.toString().includes(searchText);

    if (!matchesSearch) return false;

    const meetingDate = moment(item.startTime);
    const isAfterStart = !startDate || meetingDate.isSameOrAfter(startDate, 'day');
    const isBeforeEnd = !endDate || meetingDate.isSameOrBefore(endDate, 'day');

    return isAfterStart && isBeforeEnd;
  });

  const columns = state.requestFilterType.value === "clients"
    ? [
      { title: "Customer Name", field: "userName", cellStyle: { fontWeight: 500 } },
      { title: "Email", field: "email" },
      { title: "Phone Number", field: "mobileNumber" }
    ]
    : [
      {
        title: "Customer Name",
        field: "userName",
        cellStyle: { fontWeight: 500 }
      },
      {
        title: "Service",
        field: "services",
        render: ({ type, serviceName }) => (
          <span
            style={{
              color: getColorForService(serviceName),
              fontWeight: "bold",
            }}
          >
            {serviceName} {type === "demo" && " (Demo)"}
          </span>
        ),
      },
      { title: "Email", field: "email" },
      {
        title: "Phone Number",
        field: "mobileNumber"
      },
      {
        title: "Status",
        field: "status",
        render: (rowData) => {
          const isPast = moment(rowData.startTime).isBefore(moment());
          const displayStatus = isPast ? 'Completed' : rowData.status;
          return (
            <span
              className={`badge rounded-pill px-2 py-2 ${displayStatus === 'Completed' ? 'bg-success text-white' : 'bg-warning text-dark'}`}
            >
              {displayStatus}
            </span>
          );
        }
      },
      {
        title: "Booked Date",
        field: "reqDate",
        render: ({ reqDate }) => moment(reqDate).locale("en").format("MMMM DD, YYYY")
      },
      { title: "Meeting Date & Time", field: "startTime" }
    ];

  return (
    <ThemeProvider theme={theme}>
      {/* {isOpen && <div> Hiiiii </div>} */}
      <BlockUi
        tag="div"
        message="Loading, please wait"
        className="wallLoadingPage w-100"
        blocking={state.loader}
        style={{ minHeight: "134px" }}
      >
        <div className="p-4" style={{ marginTop: -35, marginLeft: -23 }}>
          {/* <Button
          className={`${classes.actionButton} ${classes.exportButton}`}
          onClick={handleExport}
          style={{ marginBottom: "15px", display: "flex", alignItems: "center" }}
        >
          <FaDownload style={{ marginRight: "5px" }} />
          Export CSV
        </Button> */}
          <div
            className="d-flex flex-wrap align-items-center"
            style={{ marginBottom: "-18px", gap: "0px", paddingBottom: "0px" }}
          >
            <div className={classes.filterTabsContainer}>
              <Tabs
                value={state.requestFilterType.value}
                onChange={(e, newValue) => viewRequestList(newValue)}
                className={classes.filterTabs}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { display: 'none' } }}
              >
                {requestTypes.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    className={classes.filterTab}
                    disableRipple
                  />
                ))}
              </Tabs>
            </div>
            <Button
              className={classes.actionButtons}
              onClick={() => setIsBookingModalOpen(true)}
              style={{ marginLeft: 'auto' }}
            >
              <IoMdAddCircleOutline style={{ marginRight: 5 }} />
              Book Meeting
            </Button>

            <BookingModal
              open={isBookingModalOpen}
              onClose={() => setIsBookingModalOpen(false)}
              expertId={id}
              onBookingSuccess={() => viewRequestList(state.requestFilterType.value)}
            />
          </div>

          <div className="bg-white rounded-3 overflow-hidden shadow-sm" style={{ marginTop: 0 }}>
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={filteredData}
              options={{
                pageSize: 10,
                search: false,
                sorting: true,
                pageSizeOptions: [10, 20, 50],
                headerStyle: {
                  backgroundColor: '#1a237e',
                  color: '#FFF',
                },
                debounceInterval: 300,
                searchText: searchText,
                search: true,
                showTitle: false,
                // searchFieldStyle: {
                //   padding: '12px',
                //   borderRadius: '8px',
                //   borderColor: '#eee',
                //   margin: '0 16px 16px 0',
                //   border: '2px solid #b3b7c9',
                //   width: '100%',
                //   maxWidth: '380px',
                //   maxHeight: '50px',
                //   marginTop: '9px',
                // },
                // searchFieldProps: {
                //   className: classes.searchField,
                //   variant: 'outlined',
                //   InputProps: {
                //     disableUnderline: true,
                //     endAdornment: null, 
                //   },
                // },
                exportButton: false,
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: () => <Button className={classes.exportButton}><FaDownload style={{ marginRight: 5, fontSize: 5 }} />Download</Button>,
                  tooltip: "Download service booking details",
                  isFreeAction: true,
                  onClick: handleExport
                }
              ]}
              components={{
                Toolbar: (props) => (
                  <div style={{
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                    {/* Left side - Title */}
                    <div style={{ flex: 1 }}>
                      <h3 style={{
                        margin: 0,
                        color: '#1a237e',
                        fontSize: '1.0rem',
                        fontWeight: 600,
                        paddingLeft: '2px'
                      }}>
                        {state.requestFilterType.label}
                      </h3>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => handleSearchChange(e.target.value)}
                        style={{
                          width: "300px",
                          borderRadius: "8px",
                        }}
                        InputProps={{
                          endAdornment: searchText && (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={() => handleSearchChange("")}
                              >
                                <AiOutlineCloseCircle />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoFocus
                      />
                      <div className={classes.dateFilterWrapper}>
                        <TextField
                          type="date"
                          label="From Date"
                          InputLabelProps={{ shrink: true }}
                          value={startDate ? startDate.format('YYYY-MM-DD') : ''}
                          onChange={(e) => setStartDate(e.target.value ? moment(e.target.value) : null)}
                          variant="outlined"
                          className={classes.datePicker}
                        />
                        <TextField
                          type="date"
                          label="To Date"
                          InputLabelProps={{ shrink: true }}
                          value={endDate ? endDate.format('YYYY-MM-DD') : ''}
                          onChange={(e) => setEndDate(e.target.value ? moment(e.target.value) : null)}
                          variant="outlined"
                          className={classes.datePicker}
                        />
                      </div>
                      <Button className={classes.exportButton} onClick={handleExport}>
                        <FaDownload style={{ marginRight: 5 }} />
                        Download
                      </Button>
                      {/* <Button className={`${classes.actionButtons}`} onClick={() => setIsOpen(true)}>
                        <IoMdAddCircleOutline style={{ marginRight: 5 }} />
                        Add Booking
                      </Button> */}
                    </div>
                  </div>
                ),
              }}
              classes={{
                header: classes.tableHeader,
                row: classes.tableRow
              }}
              onRowClick={(event, rowData) => getRequestSingleData(rowData)}
            />
          </div>

          {state.requestDetails && (
            <Dialog
              fullWidth
              maxWidth="lg"
              open={true}
              onClose={() => updateState("requestDetails", false)}
            >
              <DialogTitle className={classes.dialogTitle}>
                Request Details
                <AiOutlineCloseCircle
                  onClick={() => updateState("requestDetails", false)}
                  className="float-end cursor-pointer hover-scale"
                  style={{ width: "24px", height: "24px", marginLeft: "1040px" }}
                />
              </DialogTitle>
              <DialogContent dividers="paper" className="p-4">
                <div className="mb-5">
                  <h5 className="mb-4 fw-semibold text-primary">User Details</h5>
                  <table className={`table ${classes.detailTable}`}>
                    <tbody>
                      <tr>
                        <td className="fw-medium">Service Name</td>
                        <td>{state.singleRequestData.serviceName}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Customer Name</td>
                        <td>{state.singleRequestData.userName}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Email</td>
                        <td className="d-flex align-items-center gap-3">
                          {state.singleRequestData.email}
                          <button
                            className={`ms-auto d-flex align-items-center ${classes.actionButton} bg-primary text-white px-3 py-1 rounded-pill`}
                            onClick={(e) => mailInit(e, state.singleRequestData.email)}
                          >
                            <MdOutlineMarkEmailRead className="me-2" />
                            Send Email
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Contact</td>
                        <td className="d-flex align-items-center gap-3">
                          {state.singleRequestData.mobileNumber}
                          <button
                            className={`ms-auto d-flex align-items-center ${classes.actionButton} bg-primary text-white px-3 py-1 rounded-pill`}
                            onClick={(e) => phoneInit(e, state.singleRequestData.mobileNumber)}
                          >
                            <FiPhoneForwarded className="me-2" />
                            Contact
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Description</td>
                        <td>{state.singleRequestData.bio}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Appointment Type</td>
                        <td>{state.singleRequestData.appointmentType}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Amount</td>
                        <td className="d-flex align-items-center">
                          {state.singleRequestData.amount ?? 0}
                          <FaRupeeSign className="ms-2" />
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Meeting Time</td>
                        <td>{moment(state.singleRequestData.startTime).locale("en").format("LLL")}</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Duration</td>
                        <td>{state.singleRequestData.meetingActiveDuration} mins</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Meeting Link</td>
                        <td>
                          {state.singleRequestData.url ? (
                            <a
                              href={
                                state.singleRequestData.meetingInApplication
                                  ? `${JITSI_URL}/${state.singleRequestData.url}?&isGroup=true&sp=yes#config`
                                  : state.singleRequestData.url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary text-decoration-none"
                            >
                              Join Meeting
                            </a>
                          ) : "Meeting not created"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {state.singleRequestData.assessmentNeed && (
                  <div>
                    <h5 className="mb-4 fw-semibold text-primary">Pre-Session Responses</h5>
                    <table className={`table ${classes.detailTable}`}>
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Type</th>
                          <th>Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.assessmentAnswer.map((item, i) => (
                          <tr key={i}>
                            <td>{item.question}</td>
                            <td className="text-capitalize">{item.answerType}</td>
                            <td>{t(`${item.answer}`)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </DialogContent>
            </Dialog>
          )}
        </div>
      </BlockUi>
    </ThemeProvider>
  );
};

export default withTranslation()(RequestList);