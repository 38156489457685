import { useState } from "react";
import { Modal } from "react-bootstrap";
import PhoneNumberContainer from "../../../components/PhoneNumberContainer";
import { toast } from "react-toastify";
import MyYuukke from "../../../api/MyYuukke";
import BlockUi from "react-block-ui";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function ServiceUserForm({
  serviceProviderUserId,
  updateDetails,
  closeModel,
}) {
  const [state, setState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    loader: false,
    description: "",
  });
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const uploadDetails = (e) => {
    e.preventDefault();
    if (!state.name || !state.phoneNumber) {
      toast.warning("Fill all required fields");
    } else {
      updateState("loader", true);

      let data = {
        userName: state.name,
        email: state.email,
        phoneNumber: state.phoneNumber,
        userId: serviceProviderUserId,
        description: state.description,
      };
      MyYuukke.saveServiceChatDetails(data)
        .then((response) => {
          console.log("response", response);
          localStorage.setItem("SERVICE-EMAIL", response.data?.data?.email);
          updateDetails(response.data.data);
          updateState("loader", false);
        })
        .catch((e) => {
          console.log("e", e);
          toast.error("Something went wrong !");
          updateState("loader", false);
          closeModel();
        });
    }
  };
  return (
    <Modal
      show={true}
      onHide={() => {
        closeModel();
      }}
    >
      <Modal.Header className="font-weight-bold">
        <p>Need to Your Details :</p>
        <AiOutlineCloseCircle
          onClick={() => closeModel()}
          style={{
            cursor: "pointer",
            float: "right",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <BlockUi
          tag="div"
          message="Loading, please wait"
          style={{ minHeight: "134px" }}
          className="wallLoadingPage"
          blocking={state.loader}
        >
          <form
            onSubmit={uploadDetails}
            className="w-100 p-3 d-flex flex-column font-weight-bold"
            style={{ fontSize: "12px" }}
          >
            <label className="mt-2">Name *</label>
            <input
              style={{ caretColor: "black" }}
              className="input-text-input text-dark"
              value={state.name}
              type="text"
              placeholder="Enter name"
              required={true}
              onChange={(e) => updateState("name", e.target.value)}
            />
            <label className="mt-2">Email *</label>
            <input
              style={{ caretColor: "black" }}
              className="input-text-input text-dark"
              value={state.email}
              type="email"
              placeholder="Enter email"
              required={true}
              onChange={(e) => updateState("email", e.target.value)}
            />
            <label className="mt-2">Phone Number *</label>
            <PhoneNumberContainer
              value={state.phoneNumber}
              updateData={(e) => updateState("phoneNumber", e)}
              defaultClass="custom-phone-input"
            />
            <label className="mt-2">Enter Your Query *</label>

            <textarea
              style={{ caretColor: "black", minHeight: "5rem" }}
              className="input-text-input text-dark p-2"
              value={state.description}
              type="text"
              required={true}
              onChange={(e) => updateState("description", e.target.value)}
            />
            <button type="submit" className="mt-3 font-weight-bold">
              Submit
            </button>
          </form>
        </BlockUi>
      </Modal.Body>
    </Modal>
  );
}
