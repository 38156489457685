import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  useTheme,
} from "@material-ui/core";
import { FiUser, FiMail, FiPhone } from "react-icons/fi";
import { readOnlyWorkspace } from "../../utils";
import { toast } from "react-toastify";
import ProfileService from "../../api/ProfileService";
import { withTranslation } from "react-i18next";

const ContactSupportForm = (props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    enquiry: "",
  });
  const {t}=props;

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    phone: "",
    enquiry: "",
  });

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (
      !formData.username ||
      formData.username.length < 2 ||
      formData.username.length > 50
    ) {
      tempErrors.username = "Username must be between 2 and 50 characters";
      isValid = false;
    }

    if (
      !formData.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
    ) {
      tempErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.phone) {
      tempErrors.phone = "Phone number must be between 10-15 digits";
      isValid = false;
    }

    if (
      !formData.enquiry ||
      formData.enquiry.length < 10 ||
      formData.enquiry.length > 800
    ) {
      tempErrors.enquiry = "Enquiry must be between 10 and 800 characters";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        let data = {
          name: formData.username,
          emailId: formData.email,
          phoneNumber: formData.phone,
          description: formData.enquiry,
        };
        ProfileService.saveEnquiry(data)
          .then((response) => {
            setSuccess(true);
            resetForm();
            toast.success(
              "Thank you for your enquiry, we will get back to you soon"
            );
          })
          .catch((error) => {
            toast.error("Something went wrong , please try again later");
          });
        // Simulating API call
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        // setSuccess(true);
        // resetForm();
      } catch (error) {
        toast.warning("An error occurred");
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warning("Please enter required fields");
    }
  };

  const resetForm = () => {
    setFormData({
      username: "",
      email: "",
      phone: "",
      enquiry: "",
    });
    setErrors({});
  };
  // console.log("errors", errors);
  return (
    <Container
      maxWidth="md"
      sx={{ py: 4 }}
      style={{ maxWidth: "36rem", marginTop: "4rem" }}
    >
      <Card elevation={3} style={{ padding: "2rem" }}>
        <CardContent>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              marginBottom: "30px",
            }}
            align="center"
            gutterBottom
          >
            {t("Enquiry")}
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("Your Name *")}
                  name="username"
                  variant="outlined"
                  value={formData.username}
                  onChange={handleChange}
                  error={Boolean(errors.username)}
                  helperText={errors.username}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FiUser />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("Your  Email *")}
                  variant="outlined"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FiMail />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("Your Phone Number *")}
                  name="phone"
                  variant="outlined"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FiPhone />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <div style={{ width: "100%" }}>
                  <label
                    className="text-secondary"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Let Us Know How We Can Assist! *")}
                  </label>
                  <textarea
                    value={formData.enquiry}
                    onChange={handleChange}
                    name="enquiry"
                    maxLength={800}
                    placeholder={t("Enter your enquiry here...")}
                    style={{
                      width: "100%",
                      height: "120px",
                      padding: "10px",
                      fontSize: "16px",
                      border: Boolean(errors.enquiry)
                        ? "2px solid red"
                        : "2px solid #ccc",
                      borderRadius: "8px",
                      outline: "none",
                      resize: "vertical", // Allows resizing only vertically
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      marginTop: "5px",
                      color: Boolean(errors.enquiry) ? "red" : "#888",
                    }}
                  >
                    {errors.enquiry || `${formData.enquiry.length}/${800}`}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    startIcon={loading && <CircularProgress size={20} />}
                  >
                    {loading ? t("Submitting...") : t("Submit")}
                  </Button>
                </Grid>
                <Grid item>
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Reset
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withTranslation()(ContactSupportForm);

