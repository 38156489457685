import {
  ANNOUNCEMENT_POP_UP,
  DYNAMIC_FORM_DATA,
  KEY_CLOAK_LOGIN_SUCCESS_V2,
  KEY_CLOAK_LOGIN_V2_ERROR,
  LOGIN_LOADER,
  LOGIN_USER_STATUS_ERROR,
  REGISTER_ERROR_STATUS,
  STORE_LOGIN_DETAILS,
  UPDATE_SOCIAL_PROFILE_DETAILS,
  SET_IS_SERVICE_USER,
} from "../types";
import { GET_LOGOUT_DATA } from "../types";
import { STORE_AUTH_USER } from "../types";
import { UPDATE_LOGIN_DETAILS } from "../types";
import {
  URL_UPDATE_LOGIN_DETAILS,
  UPDATE_ELIGIBILITY_DETAILS,
  SET_REFRESH_TOKEN_DETAILS,
} from "../types";
const initialState = {
  loginDetails: "",
  authUser: {},
  isServiceUser: false,
  keycloakAuthenticated: false,
  keycloakData: null,
  keycloakTokenExpired: false,
  accessToken: null,
  refreshToken: null,
  keycloakClientId: null,
  userDetails: null,
  clientId: "",
  tokenFixed: false,
  workSpaceLogin: false,
  loginUserError: "",
  needAnnouncement: "",
  loginLoader: false,
  loginError: false,
  userLoggedIn: false,
  registerErrorStatus: null,
  loginErrorMessage: null,
  dynamicFormSlug: null,
  storeSlug: null,
  chatDetails: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_LOGIN_DETAILS:
      console.log("STORE_LOGIN_DETAILS", action.payload);

      if (state.loginDetails) {
        var oldLoginDetails = state.loginDetails;
        var loginDetails = action.payload;
        if (loginDetails.userWorkSpace) {
          loginDetails.userWorkSpace = oldLoginDetails.userWorkSpace;
        }
        console.log("New isServiceUser Value:", action.payload?.isServiceUser);

        return Object.assign({}, state, {
          loginDetails: loginDetails,
          isServiceUser: action.payload?.isServiceUser || false,
          loginUserError: "",
        });
      }
      console.log("isServiceUser:", action.payload.isServiceUser);

    // return {
    //   ...state,
    //   loginDetails: action.payload,
    // };
    case UPDATE_LOGIN_DETAILS:
      console.log("UPDATE_LOGIN_DETAILS");
      console.log(action.payload);
      if (state.loginDetails) {
        var loginDetails = state.loginDetails;
        loginDetails.fTLogin = true;
        loginDetails.profile_complete = true;
        loginDetails.userWorkSpace = action.payload;
        return Object.assign({}, state, {
          loginDetails: loginDetails,
          loginUserError: "",
        });
      }
    case URL_UPDATE_LOGIN_DETAILS:
      console.log("URL_UPDATE_LOGIN_DETAILS");
      console.log(action.payload);
      if (state.loginDetails) {
        var loginDetails = state.loginDetails;
        loginDetails.userWorkSpace = action.payload;
        return Object.assign({}, state, {
          loginDetails: loginDetails,
          loginUserError: "",
        });
      } else {
        return {
          ...state,
        };
      }

    case UPDATE_ELIGIBILITY_DETAILS:
      console.log("UPDATE_ELIGIBILITY_DETAILS");
      console.log(action.payload);
      if (state.loginDetails) {
        var loginDetails = state.loginDetails;
        loginDetails.eligibility = true;
        return Object.assign({}, state, {
          loginDetails: loginDetails,
          loginUserError: "",
        });
      }

    case SET_IS_SERVICE_USER:
      return {
        ...state,
        isServiceUser: action.payload,
      };

    case STORE_AUTH_USER:
      console.log("STORE_AUTH_USER");
      console.log(action.payload);
      return {
        ...state,
        authUser: action.payload,
      };

    // keycloak data
    case "KEYCLOAK_LOGIN_SUCCESS":
      return {
        keycloakAuthenticated: true,
        keycloakData: action.payload,
        accessToken: action.payload.token,
        refreshToken: action.payload.refreshToken,
        keycloakClientId: action.payload.clientId,
        keycloakTokenExpired: false,
      };

    case SET_REFRESH_TOKEN_DETAILS:
      console.log("SET_REFRESH_TOKEN_DETAILS");
      console.log(action.payload);
      if (action.payload !== "") {
        return {
          ...state,
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          tokenFixed: true,
        };
      } else {
        return {
          ...state,
          accessToken: "",
          refreshToken: "",
          tokenFixed: false,
        };
      }

    case "SET_USER_DETAILS":
      //  debugger;
      console.log(action.payload);
      console.log("SET_USER_DETAILS", action.loginDetails);
      let chatDetails = {
        uId: action.loginDetails?.uId,
        dName: action.loginDetails?.dName,
        profile_uuid: action.loginDetails?.profile_uuid,
        timeZone: action.loginDetails?.timeZone,
        email: action.loginDetails?.email,
        active: action.loginDetails?.active,
        isServiceUser: action.loginDetails?.isServiceUser,
        userType: action.loginDetails?.isServiceUser ? "service" : "normal",
      };
      return {
        ...state,
        userDetails: action.payload,
        chatDetails: chatDetails,
        isServiceUser: action.payload?.isServiceUser || false,
        loginDetails: action.loginDetails,
        loginUserError: "",
        // needAnnouncement: !action.loginDetails.newUser
        //   ? action.loginDetails?.modelType
        //   : "",
        loginError: false,
        userLoggedIn: true,
        loginLoader: false,
      };

    case "REFERRAL_LOGIN_DETAILS":
      //  debugger;
      console.log("REFERRAL_LOGIN_DETAILS", action.payload);
      if (action.payload) {
        return {
          ...state,
          loginDetails: action.payload,
        };
      } else {
        return {
          ...state,
        };
      }

    case "SET_TOKEN_FIXED":
      return {
        ...state,
        tokenFixed: false,
      };
    case "WORK_SPACE_LOGIN_SUCCESS":
      console.log("WORK_SPACE_LOGIN_SUCCESS", action.payload);

      if (state.loginDetails) {
        var loginDetails = state.loginDetails;
        loginDetails.switchUserWorkSpace =
          Object.keys(action.payload.switchUserWorkSpace).length !== 0
            ? action.payload.switchUserWorkSpace
            : loginDetails.switchUserWorkSpace;
        loginDetails.userWorkSpace =
          Object.keys(action.payload.switchUserWorkSpace).length !== 0
            ? action.payload.switchUserWorkSpace
            : loginDetails.userWorkSpace;
        loginDetails.biography = action.payload.biography;
        loginDetails.dob = action.payload.dob;
        return Object.assign({}, state, {
          loginDetails: loginDetails,
        });
      }
    case "UPDATE_WORKSPACE_LOGO":
      console.log("UPDATE_WORKSPACE_LOGO", action.payload);
      if (action.payload) {
        console.log("UPDATE_WORKSPACE_LOGO", action.payload);
        var loginDetails = state.loginDetails;
        loginDetails.userWorkSpace.logo = action.payload;
        return {
          ...state,
          loginDetails: loginDetails,
        };
      } else {
        return {
          ...state,
        };
      }
    case "UPDATE_PROFILE_DETAILS":
      console.log("UPDATE_PROFILE_DETAILS", action.payload);
      var data = state.loginDetails;
      data["countryId"] = action.payload?.countryId
        ? action.payload?.countryId
        : "";
      data["phoneNumber"] = action.payload?.phoneNumber
        ? action.payload?.phoneNumber
        : "";
      data["location"] = action.payload?.location
        ? action.payload?.location
        : "";
      data["dob"] = action.payload?.dob ? action.payload?.dob : "";
      data["profession_Id"] = action.payload?.professionId
        ? action.payload?.professionId
        : "";
      console.log("UPDATE_PROFILE_DETAILS", data);

      return {
        ...state,
        loginDetails: data,
      };
    case UPDATE_SOCIAL_PROFILE_DETAILS:
      console.log("UPDATE_PROFILE_DETAILS", action.payload);
      let details = state.loginDetails;
      details["instagram"] = action.payload?.instagram
        ? action.payload.instagram
        : details.instagram;
      details["linkedin"] = action.payload?.linkedin
        ? action.payload.linkedin
        : details.linkedin;
      details["biography"] = action.payload?.biography
        ? action.payload.biography
        : details.biography;
      details["twitter"] = action.payload?.twitter
        ? action.payload.twitter
        : details.twitter;
      details["location"] = action.payload?.location
        ? action.payload.location
        : details.location;

      return {
        ...state,
        loginDetails: details,
      };
    case GET_LOGOUT_DATA:
      console.log("GET_LOGOUT_DATA_LOGIN_API");
      console.log(initialState);
      return {
        ...initialState,
        authUser: { loginType: state.authUser.loginType, type: false },
      };

    case LOGIN_USER_STATUS_ERROR:
      console.log("LOGIN_USER_STATUS_ERROR", action.payload);
      return {
        ...state,
        loginUserError: action.payload,
        loginError: true,
        loginErrorMessage: action.message,
      };
    case ANNOUNCEMENT_POP_UP:
      console.log("ANNOUNCEMENT_POP_UP", action.payload);
      return {
        ...state,
        needAnnouncement: action.payload,
      };
    case KEY_CLOAK_LOGIN_SUCCESS_V2:
      return {
        ...state,
        keycloakAuthenticated: true,
        keycloakData: action.payload,
        accessToken: action.payload?.access_token,
        refreshToken: action.payload?.refresh_token,
        keycloakClientId: action.payload?.clientId,
        keycloakTokenExpired: false,
        // loginLoader: false,
        loginError: false,
        // userLoggedIn: true,
      };

    case LOGIN_LOADER:
      return {
        ...state,
        loginLoader: action.payload,
      };
    case KEY_CLOAK_LOGIN_V2_ERROR:
      return {
        ...state,
        loginLoader: false,
        loginError: action.payload,
      };

    case REGISTER_ERROR_STATUS:
      console.log("REGISTER_ERROR_STATUS", action.payload);
      return {
        ...state,
        registerErrorStatus: action.payload,
      };
    case DYNAMIC_FORM_DATA:
      console.log("DYNAMIC_FORM_DATA", action.payload);
      if (action.isUpdate) {
        return {
          ...state,
          dynamicFormSlug: action.payload,
          storeSlug: action.payload,
        };
      } else {
        return {
          ...state,
          dynamicFormSlug: action.payload,
        };
      }

    default:
      return state;
  }
}
