
export const APP_NAME = 'Yuukke';

export const API_URL = "https://bmlv2.betalearnings.com/bcav2/"

export const BASE_URL = "https://bmlv2.betalearnings.com/";

export const STREAM_URL = "https://streaming.betalearnings.com/";


export const profiles = "moodle,keycloak";

export const TEAMS_SSO_URL = "https://testapi.peersquareschools.com/"

export const CHAT_URL = "https://chat.yuukke.betalearnings.com/"
export const JITSI_URL = "https://meet.yuukke.in/"


export const HTTP_HEADERS = {
    'Content-Type': 'application/json',
}
