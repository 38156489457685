import React, { useEffect, useState } from "react";
import { API_URL } from "../../config/app-config.js";
import dummyimage from "../../img/default_avatar.jpg";
import yuukke from "../../images/SocialMediaGif/yuukke-white.png";
import "../Community/calendar.css";
import MyYuukke from "../../api/MyYuukke.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { GoBriefcase } from "react-icons/go";
import PhoneNumberContainer from "../../components/PhoneNumberContainer.jsx";
import Swal from "sweetalert2";
import faceBook from "../../images/SocialMediaGif/Facebook.png";
import instagram from "../../images/SocialMediaGif/instagram.png";
import linkedIn from "../../images/SocialMediaGif/Linkedin.png";
import CheckBox from "../../components/CheckBox.jsx";
import { withRouter } from "react-router";
import Select from "react-select";
import StarRating from "../../components/Rating.jsx";

// let socialMedia=[
//   {name:"faceBook",image:faceBook},{name:"instagram",image:instagram},{name:"linkedIn",image:linkedIn}
//     ]

const ServiceRecommend = (props) => {
  const [state, setState] = useState({
    bookingPage: false,

    activeIndexData: null,
    selectedSlot: null,
    slotActiveIndex: null,
    loader: true,
    userDetails: null,
    userSlots: [],
    bookingCount: null,
    detailsPage: false,
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
    bio: "",

    socialMedia: [],
    serviceDetails: null,
    language: [],
    profession: "",
    permissionToUse: true,
    companyName: "",
    bookingId: 0,
    qualityOptions: [
      {
        label: "Excellent",
        value: "Excellent",
      },
      {
        label: "Very Good",
        value: "Very Good",
      },
      {
        label: "Good",
        value: "Good",
      },
      {
        label: "Poor",
        value: "Poor",
      },
    ],
    quality: {
      label: "Good",
      value: "Good",
    },
    referYourFriends: false,
    receiveNotifications: false,
    starRating: 0,
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const bookingId = query.get("id");
  useEffect(() => {
    var main = document.getElementById("base");
    main.style.backgroundImage = `linear-gradient(205deg, #000940 0%, #a00030 100%)`;
    if (!bookingId) {
      invalid();
      return;
    }

    // const dcrypt = decryptData(bookingId);
    // const data = dcrypt.split("&");
    // // console.log("dcrypt", data);
    // if (data.length > 1) {
    //   getSlotDetails(data);
    // } else {
    //   invalid();
    // }
    getSlotDetails(bookingId);
  }, []);
  const invalid = () => {
    setState((previous) => ({
      ...previous,
      userDetails: null,
      loader: false,
    }));
    toast.error("Invalid link !,please check link");
  };

  const getSlotDetails = (data) => {
    console.log("data", data);
    MyYuukke.getBookingReview(data)
      .then((response) => {
        console.log("response", response);
        let result = response.data.data.userDetails;
        let socialMedia = [];
        if (result.faceBook) {
          socialMedia.push({
            link: result.faceBook,
            icon: faceBook,
          });
        }
        if (result.instagram) {
          socialMedia.push({
            link: result.instagram,
            icon: instagram,
          });
        }
        if (result.linkedIn) {
          socialMedia.push({
            link: result.linkedIn,
            icon: linkedIn,
          });
        }

        setState((previous) => ({
          ...previous,
          userDetails: response.data.data.userDetails,
          loader: false,
          socialMedia: socialMedia,
          bookingId: data,
        }));
        // updateState("userDetails", response.data.data.userDetails);
      })
      .catch((e) => {
        toast.error("Slot not present , please check url");
        updateState("loader", false);
        console.log("Error", e);
      });
  };

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const uploadDetails = (e) => {
    e.preventDefault();
    updateState("loader", true);
    let data = {
      type: "review",
      userName: state.name,
      //  formUserId: state.formUserId,
      receiveNotification: state.receiveNotifications,
      qualityOfService: state.quality.label,
      satisfaction: state.starRating,
      referFriend: state.referYourFriends,
      bookingId: state.bookingId,
    };
    if (state.email) {
      data.email = state.email;
    }
    if (state.description) {
      data.description = state.description;
    }

    MyYuukke.saveServiceReviewForm(data)
      .then((response) => {
        console.log("resp", response);
        Swal.fire({
          icon: "success",
          title: "Thank you for your FeedBack.",
          showConfirmButton: true,
        })
          .then((res) => {
            props.history.push("/login");
          })
          .catch((e) => {
            props.history.push("/login");
          });
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong !,Please try latter");
        updateState("loader", false);
      });
  };

  return (
    <div className="calender-vcard flex-wrap position-relative justify-content-center">
      {state.loader ? (
        <div className="d-flex justify-content-center p-5 align-items-center w-100">
          <div class="spinner-border text-white" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : state.userDetails ? (
        <div className="login-options-opt flex-row m-5 justify-content-center align-items-start flex-wrap">
          <section
            className="position-relative login-options-opt justify-content-centre align-items-center"
            style={{
              minWidth: window.matchMedia("(max-width:739px)").matches
                ? "auto"
                : "30rem",
              gap: "0",
            }}
          >
            <img
              style={{ width: "7rem", height: "7rem" }}
              className="rounded-circle  mt-5 "
              src={
                state.userDetails?.showProfileImage
                  ? API_URL +
                    "api/getUserPhoto/" +
                    state.userDetails.userId +
                    "/" +
                    "name"
                  : yuukke
              }
              alt="user"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dummyimage;
              }}
            />

            <div className="d-flex flex-column p-3 w-100 justify-content-centre align-items-center">
              <h3 className="font-weight-bold mt-4 text-white text-capitalize">
                {state.userDetails.userName}
              </h3>
              <ul className="d-flex g-1 flex-wrap l-none justify-content-centre">
                {state.socialMedia.map((item, i) => (
                  <li
                    className="mt-2"
                    style={{ height: "2rem", width: "2rem" }}
                    key={i}
                  >
                    <a href={item.link}>
                      <img src={item.icon} className="h-100 w-100" alt="img" />
                    </a>
                  </li>
                ))}
              </ul>
              <p className="f-14 text-white align-center font-weight-bold">
                <GoBriefcase className="mr-2 svg-icons text-white  " />
                {state.userDetails?.profession?.professionName}
              </p>
            </div>
            <p className="f-14 align-center font-weight-bold  mt-auto text-white ml-3">
              Powered by{" "}
              <a
                href="https://yuukke.com/"
                className="text-white  ml-1"
                style={{ textDecoration: "underline" }}
              >
                Yuukke
              </a>
            </p>
          </section>
          <section className="d-flex flex-column p-3 card text-dark">
            <h4 className="font-weight-bold text-black mb-3 mt-2">RATE US</h4>
            <p className="service-form-heading text-secondary">
              We’d love to hear your feedback ! Your input helps us improve and
              ensure to meet your needs effectively.{" "}
            </p>
            <form
              onSubmit={uploadDetails}
              className="w-100 p-3 d-flex flex-column font-weight-bold "
              style={{ fontSize: "12px" }}
            >
              <label className="mt-2 mr-auto label-v2">Your Name *</label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.name}
                type="text"
                placeholder="Enter name"
                required={true}
                onChange={(e) => updateState("name", e.target.value)}
              />
              <label className="mt-2 mr-auto label-v2">Phone Number</label>
              <PhoneNumberContainer
                value={state.phoneNumber}
                updateData={(e) => updateState("phoneNumber", e)}
                defaultClass="custom-phone-input"
              />

              <label className="mt-2 mr-auto label-v2">Email</label>
              <input
                style={{ caretColor: "black" }}
                className="input-text-input text-dark"
                value={state.email}
                type="email"
                placeholder="Enter email"
                required={true}
                onChange={(e) => updateState("email", e.target.value)}
              />
              <label className="mt-2 mr-auto label-v2">
                Overall Satisfaction
              </label>
              <StarRating
                value={state.starRating}
                updateState={(e) => updateState("starRating", e)}
              />

              <label className="mt-2 mr-auto label-v2">
                Quality of Service
              </label>
              <Select
                options={state.qualityOptions}
                value={state.quality}
                onChange={(e) => {
                  updateState("quality", e);
                }}
                required
              />

              <CheckBox
                value={state.referYourFriends}
                onChange={(e) => updateState("referYourFriends", e)}
                label={
                  "Would you refer our service to your friends, family or colleagues?"
                }
              />
              <CheckBox
                value={state.receiveNotifications}
                onChange={(e) => updateState("receiveNotifications", e)}
                label={
                  "Would you like to receive email newsletter about our products?"
                }
                // color={"text-secondary"}
              />
              <label className="mt-4 mr-auto label-v2">
                Your comments or suggestions on the service:
              </label>
              <textarea
                style={{ caretColor: "black", minHeight: "5rem" }}
                className="input-text-input text-dark p-2"
                value={state.description}
                type="text"
                required={true}
                onChange={(e) => updateState("description", e.target.value)}
              />

              <br />
              <button className="  font-weight-bold w-fit-content mt-5 m-auto ">
                Submit
              </button>
            </form>
          </section>
        </div>
      ) : (
        <div className="text-white text-center w-100 mt-5 font-bold">
          Some thing went wrong , please visit{" "}
          <p className="text-secondary">Yuukke</p>
        </div>
      )}
    </div>
  );
};

export default withRouter(ServiceRecommend);
