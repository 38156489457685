import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import MSLogin from "../screens/ms-tab/MSLogin.jsx";
import Tab from "../screens/ms-tab/Tab.js";
import ClosePopup from "../screens/ms-tab/ClosePopup";
import ConsentPopup from "../screens/ms-tab/ConsentPopup";
import Classroom from "../screens/Classroom/Classroom";
import Community from "../screens/Community/Community";
import Square from "../screens/Square/Square";
import Redirect from "../screens/Home/Redirect";
import CommunityFileOption from "../screens/Home/HomeUpdated/CommunityFileOption";
import CoverCommunityFileOption from "../screens/Home/HomeUpdated/CoverCommunityFileOption";
import RedirectPage from "../app/RedirectPage";
import AuthRoute from "../app/AuthRoute.jsx";

// import Login from "../screens/Login/Login";
import ConsentModal from "../websocket/ConsentModal";
import Settings from "../screens/Home/Settings/Settings";
import PageConstruction from "../components/PageConstruction";
import SuggestedFriendsList from "../screens/Square/Peer/SuggestedFriendsList";
import GroupDescription from "../screens/MyGroupsandMyEvents/GroupDescription/GroupDescription";
import Badges from "../screens/Square/Home/Badges";
import KeyCloakLogin from "../screens/Login/KeyCloakLogin";
import Http from "../api/Http";
import AddAnnouncemment from "../screens/Announcement/AddAnnouncement";
import Groups from "../screens/Community/Groups";
import AskQuestion from "../screens/Community/AskQuestion";
import Users from "../screens/User/Users";
import UserUpload from "../screens/User/UserUpload";
import GetJobRequest from "../components/Header/GetJobRequest";
import ProfessionContainer from "../screens/Home/ProfileSetUp/ProfessionContainer";
import EducationContainer from "../screens/Home/ProfileSetUp/EducationContainer";
import SkillsContainer from "../screens/Home/ProfileSetUp/SkillsContainer";
import ProfileContainer from "../screens/Home/ProfileSetUp/ProfileContainer";
import FirstTimeEducation from "../screens/Home/HomeUpdated/FirstTimeEducation";
import FirstSetupProfile from "../screens/Home/HomeUpdated/FirstSetupProfile";
import CommunitySkillsContainer from "../screens/Home/HomeUpdated/CommunitySkillContainer";
import MyRequestContainer from "../screens/MyRequest/MyRequestContainer";
import KYCSetupContainer from "../screens/FirstTimeProfile/KYCSetupContainer";
import SubscriberForm from "../screens/Home/SubscriberForm";
import PrivacySettings from "../screens/Visibility/PrivacySettings";
import FriendDescription from "../screens/FriendsDetails/FriendDescription";
import MyRequestDescription from "../screens/MyRequest/MyRequestDescription";
import ValueMatchContainer from "../screens/Home/ProfileSetUp/ValueMatchContainer";
import OtherRequestList from "../screens/MyRequest/OtherRequestList";
import AnnouncementBoxContainer from "../screens/Announcement/AnnouncementBoxContainer";
import ApplyChallengeDescription from "../screens/LearningApp/ApplyChallengeDescription";
import ChallengeApplyForm from "../screens/LearningApp/ChallengeApplyForm";
import MentorUpdateForm from "../screens/Mentor/MentorUpdateForm";
import Meeting from "../screens/Meeting/Meeting";
import ResultsList from "../screens/MyGroupsandMyEvents/GroupDescription/ResultsList";
import LearningHubContainer from "../screens/Community/LearningHubContainer";
import CareerLabsContainer from "../screens/Community/CareerLabsContainer";
import { connect } from "react-redux";
import AskQuestionContainer from "../screens/Community/Questions/AskQuestionContainer";
import PreferencesContainer from "../screens/Home/ProfileSetUp/PreferencesContainer";
import SellerRegistration from "../screens/SellerRegistration";
import MentorRegistration from "../screens/MentorRegistration";
import Calender from "../screens/Calender/Calender";
import Article from "../screens/Article/Article";
import EventForm from "../screens/Calender/EventForm";
import ForumContainer from "../screens/Community/ForumCommunity/ForumContainer";
import ForumDescription from "../screens/Community/ForumCommunity/ForumDescription";
import UserList from "../screens/UserList/UserList";
import NewResource from "../screens/Community/NewResource";
import NewCareerLab from "../screens/Community/NewCareerLab";
import ResourcePostContainer from "../screens/LearningApp/ResourcePostContainer";
import AdvertisementChat from "../screens/Home/AdvertisementChat/AdvertisementChatContainer";
import HomePostCard from "../screens/Home/HomeUpdated/PostCardDescription";
import LoginUserWorkSpace from "../screens/FirstTimeProfile/LoginUserWorkSpace";
import ProfileCard from "../components/ProfileCard";
import StepperFormOptions from "../screens/Wizard/StepperFormOptions";
import MyRequestForm from "../screens/MyRequest/MyRequestForm";
import HashTagContainer from "../screens/Home/HashTag/HashTagContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import WorkSpaceUpdate from "../screens/Home/HomeUpdated/WorkSpaceUpdate";
import DirectoryList from "../screens/Directory/DirectoryList";
import DirectoryDescription from "../screens/Directory/DirectoryDescription";
import DirectoryForm from "../screens/Directory/DirectoryForm";
import AnnouncementForm from "../screens/Square/Home/AnnouncementForm";
import MentorDescription from "../screens/Mentor/MentorDescription";
import AnnouncementContainer from "../screens/Square/Home/AnnouncementContainer";
import SingleResourcePost from "../screens/MyGroupsandMyEvents/GroupDescription/SingleResourcePost";
import CalenderEventDetails from "../screens/Calender/CalenderEventDetails";
import MentorFilterCard from "../screens/Mentor/MentorModule.jsx/MentorFilterCard";
import MentorProfileUpdate from "../components/MentorProfileUpdate";
import SpecialMentor from "../screens/Forms/SpecialMentor.jsx";
// import AskHelpDescriptionV2 from "../screens/AskHelp/AskHelpDescriptionV2.jsx";
import DirectoryRegistration from "../screens/Forms/DirectoryRegistration.jsx";
import YuukkeLaunchPad from "../screens/Forms/YuukkeLaunchPad.jsx";
import YuukkeEventForm from "../screens/Forms/YuukkeEventForm.jsx";
import EventPage from "../screens/Calender/EventPage.jsx";
import AuthContainer from "../screens/Login/LoginV2/AuthContainer.jsx";
import MailVerification from "../screens/Login/LoginV2/MailVerification.jsx";
import EventContainer from "../screens/Calender/EventContainer.jsx";
import EventCards from "../screens/Calender/EventVideos.jsx";
import ErrorUser from "../screens/Login/ErrorUser.jsx";
import CalendarVcard from "../screens/FirstTimeProfile/CalendarVcard.jsx";
import PremiumSubscriptionActivation from "../screens/PremiumBadges/PremiumSubscriptionActivation.jsx";
import CalendarContainer from "../screens/Calender/CalendarContainer.jsx";
import LanguageContainer from "../screens/Square/Home/LanguageContainer.jsx";
import ServiceDirectory from "../screens/Forms/ServiceDirectory.jsx";
import CommonIdEncoder from "../screens/Forms/CommonIdEncoder.jsx";
import ServiceLandingPage from "../screens/Forms/ServiceLandingPage.jsx";
import ServiceReviewForm from "../screens/Forms/ServiceReviewForm.jsx";
import ServiceRecommend from "../screens/Forms/ServiceRecommendForm.jsx";
import WorkSpaceLandingPage from "../screens/Forms/WorkSpaceLandingPage.jsx";
import CalendarVcardV2 from "../screens/FirstTimeProfile/CalendarVcardV2.jsx";
import ServiceUsersHome from "../screens/Calender/ProfileCalendar/ServiceUsersHome.jsx";

let env_check = window.location.host === "connect.yuukke.com";

function NavigationRouter(props) {
  const location = useLocation();

  useEffect(() => {
    if (env_check) {
      const TRACKING_ID = "G-DZ66X6F4EC";
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: location.pathname,
      });
    }
    // ReactGA.event({
    //   category: "Navigate",
    //   action: "Visit page",
    //   label: location.pathname,
    // });
    // }
    // if (env_check) {
    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);
    // ReactGA.event({
    //   category: "Navigate",
    //   action: "Click",
    //   label: location.pathname,
    // });
  }, [location]);
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <Square />
        </Route>
        <Route path="/articles/:articleName" exact>
          <Article />
        </Route>
        <Route path="/meeting-ref/:slug" exact>
          <EventPage />
        </Route>
        <Route path="/serviceHomePage" exact>
          <ServiceUsersHome />
        </Route>
        <Route path="/available-calender" exact>
          <CalendarVcard />
        </Route>
        <Route path="/yuukke-service" exact>
          <ServiceLandingPage />
        </Route>
        <Route path="/yuukke-service/:slug" exact>
          <CalendarVcardV2 />
        </Route>
        <Route path="/service-review" exact>
          <ServiceReviewForm />
        </Route>
        <Route path="/service-recommend/:slug" exact>
          <ServiceRecommend />
        </Route>

        <Route path="/mentor-profile/:slug" exact>
          <MentorProfileUpdate />
        </Route>
        <Route path="/yuukke-form/:slug" exact>
          <YuukkeEventForm />
        </Route>
        <Route path="/yuukke-mentor-form" exact>
          <SpecialMentor />
        </Route>
        <Route path="/yuukke-launch-pad" exact>
          <YuukkeLaunchPad />
        </Route>
        <Route path="/directoryRegistration" exact>
          <DirectoryRegistration />
        </Route>

        <Route path="/service-directory" exact>
          <ServiceDirectory />
        </Route>
        <Route path="/workspace-landing" exact>
          <WorkSpaceLandingPage />
        </Route>

        <Route path="/yuukke-id-encoder" exact>
          <CommonIdEncoder />
        </Route>
        <Route path="/service-directory/:id" exact>
          <ServiceDirectory />
        </Route>
        <Route path="/eventRegistration/:name" exact>
          <CalenderEventDetails />
        </Route>
        <Route path="/userProfile/:uid" exact>
          <ProfileCard />
        </Route>

        <Route path="/login" exact>
          {/* // have to update to dynamic one */}
          {/* {Http.isProfileExists("keycloak") ? <KeyCloakLogin /> : <Login />} */}
          {Http.isProfileExists("keycloak") ? (
            <KeyCloakLogin />
          ) : (
            <AuthContainer />
          )}
        </Route>

        <Route exact path="/mslogin" component={MSLogin}></Route>
        <Route exact path="/tab" component={Tab} />
        <Route exact path="/auth-start" component={ConsentPopup} />
        <Route exact path="/auth-end" component={ClosePopup} />

        <Route
          path="/yuukke-email-verification"
          exact
          component={MailVerification}
        />
        <Route
          path="/:type/market-place-update"
          exact
          component={StepperFormOptions}
        />
        <AuthRoute path="/redirect" exact>
          <Redirect />
        </AuthRoute>
        <AuthRoute path="/redirectpage" exact>
          <RedirectPage />
        </AuthRoute>

        <AuthRoute path="/community" exact>
          {/* <Header /> */}
          <Community tabIndex={1} />
        </AuthRoute>
        <AuthRoute path="/community-skills-container" exact>
          {/* <Header /> */}
          <CommunitySkillsContainer />
        </AuthRoute>

        {/* deepak */}
        {/* mynetwork/groups */}

        <AuthRoute path="/:type/mynetwork" exact>
          {/* <Header /> */}
          <Community tabIndex={1} />
        </AuthRoute>

        <AuthRoute path="/:type/groups" exact>
          {/* <Header /> */}
          <Groups viewMyGroups={false} openTab="myGroups" />
        </AuthRoute>
        <AuthRoute path="/:type/event-videos/:id" exact>
          <EventCards />
        </AuthRoute>
        <AuthRoute path="/:type/mygroups" exact>
          {/* <Header /> */}
          <Groups viewMyGroups={false} openTab="myGroups" />
        </AuthRoute>

        <AuthRoute path="/:type/groupsList" exact>
          {/* <Header /> */}
          <Groups viewMyGroups={false} openTab="groups" />
        </AuthRoute>

        <AuthRoute path="/:type/forumCommunity" exact>
          <Groups viewMyGroups={false} openTab="forumCommunity" />
        </AuthRoute>

        <AuthRoute path="/learningApp" exact>
          <Groups viewMyGroups={false} openTab="learningApp" />
        </AuthRoute>

        <AuthRoute path="/challenges" exact>
          <Groups viewMyGroups={false} openTab="challenges" />
        </AuthRoute>

        {/* <AuthRoute path="/group-description" exact>
           <GroupsQuestions />
        </AuthRoute> */}
        <AuthRoute path="/ask-question/:id/:name/:groupId" exact>
          <AskQuestionContainer />
        </AuthRoute>
        <AuthRoute path="/photo-set-up" exact>
          <KYCSetupContainer />
        </AuthRoute>
        <AuthRoute path="/workspace" exact>
          <LoginUserWorkSpace />
        </AuthRoute>

        <AuthRoute path="/:type/learning-hub" exact>
          {/* <Header /> */}
          <LearningHubContainer />
        </AuthRoute>
        <AuthRoute path="/:type/career-lab" exact>
          {/* <Header /> */}
          <CareerLabsContainer />
        </AuthRoute>
        <AuthRoute path="/get-job-request" exact>
          {/* <Header /> */}
          <GetJobRequest />
        </AuthRoute>

        {/* home/wall */}

        <AuthRoute path="/wall" exact>
          {/* <Header /> */}
          <Square tablocation={2} />
        </AuthRoute>
        {/* course */}
        <AuthRoute path="/classroom" exact>
          {/* <Header /> */}
          <Classroom />
        </AuthRoute>
        {/* events */}

        <AuthRoute path="/:type/calender" exact>
          <Calender />
        </AuthRoute>

        {/* deepak */}
        <AuthRoute path="/:type/home" exact>
          {/* <Header /> */}
          <Square tablocation={0} />
        </AuthRoute>

        <AuthRoute path="/:type/serviceHome" exact>
          {/* <Header /> */}
          <Square tablocation={0} />
        </AuthRoute>

        <AuthRoute path="/:type/yuukke-home/:id" exact>
          {/* <Header /> */}
          <Square tablocation={0} keyData="squarepost" />
        </AuthRoute>
        <AuthRoute path="/:type/my-network/:id" exact>
          {/* <Header /> */}
          <Community tabIndex={1} invite={true} />
        </AuthRoute>

        <AuthRoute path="/meeting" exact>
          <Meeting />
        </AuthRoute>
        <AuthRoute path="/community/:tid" exact>
          <Community />
        </AuthRoute>
        <AuthRoute path="/community/questions/:qid" exact>
          <Community questions={true} dashboard={false} />
        </AuthRoute>
        <AuthRoute path="/community/question/:qid" exact>
          <Community questions={true} dashboard={true} />
        </AuthRoute>
        <AuthRoute path="/ask-a-question" exact>
          <Community showquestions={true} />
        </AuthRoute>
        <AuthRoute path="/user-upload" exact>
          <UserUpload />
        </AuthRoute>
        <AuthRoute path="/users" exact>
          <Users />
        </AuthRoute>

        <AuthRoute path="/home-yuukke" exact>
          <Square peerState={true} />
        </AuthRoute>
        <AuthRoute path="/:type/badges" exact>
          <Badges />
        </AuthRoute>
        <AuthRoute path="/my-profile" exact>
          <ProfileContainer />
        </AuthRoute>
        <AuthRoute path="/my-profile/:menu" exact>
          <ProfileContainer />
        </AuthRoute>
        <AuthRoute path="/my-profile-friends" exact>
          <ProfileContainer backfriendList={true} />
        </AuthRoute>
        <AuthRoute path="/my-profession" exact>
          <ProfessionContainer />
        </AuthRoute>
        <AuthRoute path="/my-education" exact>
          <EducationContainer />
        </AuthRoute>
        <AuthRoute path="/my-preference" exact>
          <PreferencesContainer />
        </AuthRoute>
        <AuthRoute path="/premium-subscription" exact>
          <PremiumSubscriptionActivation />
        </AuthRoute>
        {/* <AuthRoute path="/my-business-profile" exact>
          <BusinessProfileContainer />
        </AuthRoute> */}
        <AuthRoute path="/value-match" exact>
          <ValueMatchContainer />
        </AuthRoute>
        <AuthRoute path="/my-skills" exact>
          <SkillsContainer />
        </AuthRoute>
        <AuthRoute path="/my-profile-setup" exact>
          <ProfileContainer />
        </AuthRoute>
        <AuthRoute path="/suggested-friends-list" exact>
          <SuggestedFriendsList />
        </AuthRoute>
        <AuthRoute path="/update-profile" exact>
          <PageConstruction name="Profile" />
        </AuthRoute>
        <AuthRoute path="/:type/directory-details/:directoryId" exact>
          <DirectoryDescription />
        </AuthRoute>
        <AuthRoute path="/settings" exact>
          <Settings />
        </AuthRoute>
        <AuthRoute path="/add-announcement" exact>
          <AddAnnouncemment />
        </AuthRoute>
        <AuthRoute path="/consentPage" exact>
          <ConsentModal />
        </AuthRoute>
        <AuthRoute path="/:type/createGroup" exact>
          <AskQuestion events={false} />
        </AuthRoute>
        <AuthRoute path="/createEvent" exact>
          <AskQuestion events={true} />
        </AuthRoute>

        <AuthRoute path="/:type/request-dis/:id/:type" exact>
          <MyRequestDescription />
        </AuthRoute>
        <AuthRoute path="/:type/my-requests" exact>
          <MyRequestContainer otherRequest={false} />
          {/* <MyRequestList /> */}
        </AuthRoute>
        <AuthRoute path="/:type/other-requests-list" exact>
          <MyRequestContainer otherRequestListCheck={true} />
          {/* <MyRequestList /> */}
        </AuthRoute>
        <AuthRoute path="/challenge-description/:id" exact>
          <ApplyChallengeDescription />
        </AuthRoute>
        <AuthRoute path="/other-requests" exact>
          <OtherRequestList otherRequest={true} />
        </AuthRoute>
        <AuthRoute path="/square/announcement/:id" exact>
          <Square announcement={true} />
        </AuthRoute>
        <AuthRoute path="/set-up-page" exact>
          <CommunityFileOption />
        </AuthRoute>
        <AuthRoute path="/:type/friendDescription/:userId" exact>
          <FriendDescription />
        </AuthRoute>
        <AuthRoute path="/:type/friendDescription/friendsPic/:userId" exact>
          <FriendDescription friendsPic={true} />
        </AuthRoute>
        <AuthRoute path="/set-up-cover" exact>
          <CoverCommunityFileOption />
        </AuthRoute>
        <AuthRoute path="/set-up-education" exact>
          <FirstTimeEducation />
        </AuthRoute>
        <AuthRoute path="/set-up-profile" exact>
          <FirstSetupProfile />
        </AuthRoute>
        <AuthRoute path="/updateGroup/:subjectId" exact>
          <AskQuestion events={false} />
        </AuthRoute>
        <AuthRoute path="/updateEvent/:subjectId" exact>
          <AskQuestion events={true} />
        </AuthRoute>
        <AuthRoute path="/:type/subscriber-form" exact>
          <SubscriberForm></SubscriberForm>
        </AuthRoute>
        <AuthRoute path="/:type/announcement-form/:id/update-form" exact>
          <AnnouncementForm />
        </AuthRoute>
        <AuthRoute path="/contest-results" exact>
          <div
            className="resultsCardChallenge"
            style={{ marginTop: "95px", marginLeft: "20px" }}
          >
            <ResultsList></ResultsList>
          </div>
        </AuthRoute>
        <AuthRoute path="/:type/announcement/:id" exact>
          <AnnouncementBoxContainer announcement={true} />
        </AuthRoute>
        <AuthRoute path="/:type/mentors" exact>
          <MentorFilterCard />
        </AuthRoute>
        <AuthRoute path="/:type/mentor-update/:id" exact>
          <MentorUpdateForm />
        </AuthRoute>

        {/* Old Des */}

        {/* <AuthRoute path="/myGroupDescription/:subjectId/:subjectName" exact>
          <MyGroupsDetailsPage fromWhichCard="mygroupscard" />
        </AuthRoute>
        <AuthRoute path="/groupDescription/:subjectId/:subjectName" exact>
          <MyGroupsDetailsPage fromWhichCard="groupscard" />
        </AuthRoute>  
        <AuthRoute path="/learningAppDesription/:subjectId/:subjectName" exact>
          <MyGroupsDetailsPage fromWhichCard="learningAppDesription" />
        </AuthRoute>
        <AuthRoute path="/challengeDescription/:subjectId/:subjectName" exact>
          <MyGroupsDetailsPage fromWhichCard="challengeDescription" />
        </AuthRoute> */}

        {/* New Des */}

        <AuthRoute
          path="/:type/myGroupDescription/:subjectId/:subjectName"
          exact
        >
          <GroupDescription fromWhichCard="mygroupscard" />
        </AuthRoute>
        <AuthRoute path="/:type/groupDescription/:subjectId/:subjectName" exact>
          <GroupDescription fromWhichCard="groupscard" />
        </AuthRoute>
        <AuthRoute
          path="/:type/learningAppDesription/:subjectId/:subjectName"
          exact
        >
          <GroupDescription fromWhichCard="learningAppDesription" />
        </AuthRoute>
        <AuthRoute path="/:type/special-resource/:subjectId/:subjectName" exact>
          <GroupDescription fromWhichCard="special-resource" />
        </AuthRoute>
        <AuthRoute path="/:type/mentor-description/:id" exact>
          <MentorDescription />
        </AuthRoute>
        <AuthRoute
          path="/:type/challengeDescription/:subjectId/:subjectName"
          exact
        >
          <GroupDescription
            fromWhichCard="challengeDescription"
            participantPage={false}
          />
        </AuthRoute>
        <AuthRoute
          path="/:type/challengeDescription/:subjectId/:subjectName/participantList"
          exact
        >
          <GroupDescription
            fromWhichCard="challengeDescription"
            participantPage={true}
          />
        </AuthRoute>
        <AuthRoute path="/communityDescription/:subjectId/questionList" exact>
          <GroupDescription fromWhichCard="mygroupscard" />
        </AuthRoute>
        <AuthRoute
          path="/:type/challengeApplyForm/:subjectId/:challengeId/:apply/:date/:careerType"
          exact
        >
          <ChallengeApplyForm />
        </AuthRoute>
        <AuthRoute path="/:type/privacySettings" exact>
          <PrivacySettings />
        </AuthRoute>
        <AuthRoute path="/:type/seller-registration" exact>
          <SellerRegistration />
        </AuthRoute>

        <AuthRoute path="/:type/mentor-registration" exact>
          <MentorRegistration />
        </AuthRoute>
        <AuthRoute path="/:type/event-form/:subjectId/:subName" exact>
          <EventForm />
        </AuthRoute>

        <AuthRoute path="/:type/forum-community/:id/:name">
          <ForumContainer />
        </AuthRoute>
        <AuthRoute path="/:type/forum-description/:id/:name/:subId">
          <ForumDescription />
        </AuthRoute>
        <AuthRoute path="/:type/userList" exact>
          <UserList />
        </AuthRoute>
        <AuthRoute path="/:type/newResource/:id" exact>
          <NewResource />
        </AuthRoute>
        <AuthRoute path="/:type/newCareer/:id" exact>
          <NewCareerLab />
        </AuthRoute>
        <AuthRoute
          path="/:type/resourcePost/:id/:type/:resourceType/:subId"
          exact
        >
          <ResourcePostContainer />
        </AuthRoute>
        <AuthRoute path="/:type/advertisement/:id/:type" exact>
          <AdvertisementChat />
        </AuthRoute>
        <AuthRoute path="/:type/ask-help-form/:id" exact>
          <MyRequestForm />
        </AuthRoute>
        <AuthRoute path="/:type/userPost/:id/:type" exact>
          <HomePostCard />
        </AuthRoute>
        {/* <AuthRoute path="/:type/advice/:id">
          <AskHelpDescriptionV2 />
        </AuthRoute> */}
        <AuthRoute path="/:type/:position/:hash" exact>
          <HashTagContainer />
        </AuthRoute>
        <AuthRoute path="/:type/update-form" exact>
          <WorkSpaceUpdate />
        </AuthRoute>
        <AuthRoute path="/:type/directory" exact>
          <DirectoryList />
        </AuthRoute>

        <AuthRoute path="/:type/resource-post/:id/:resourceType" exact>
          <SingleResourcePost />
        </AuthRoute>

        <AuthRoute path="/:type/business/user-directory/:slug" exact>
          <DirectoryForm />
        </AuthRoute>
        <AuthRoute path="/:type/announcement-list" exact>
          <AnnouncementContainer />
        </AuthRoute>
        <AuthRoute path="/:type/events" exact>
          <EventContainer />
        </AuthRoute>
        <AuthRoute path="/:type/calendar" exact>
          <CalendarContainer />
        </AuthRoute>
        <AuthRoute path="/:type/user-language" exact>
          <LanguageContainer />
        </AuthRoute>
        <AuthRoute path="/:type" exact>
          <Square tablocation={0} />
        </AuthRoute>
        <Route path="*">
          <ErrorUser />
        </Route>
      </Switch>
      <ToastContainer />
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    userDetails: state.loginDetailsApi,
    friendsList: state.commonApi.friendslist,
  };
};

export default connect(mapStateToProps)(NavigationRouter);
