import React, { useEffect, useState } from "react";
import logo from "../../../images/icons/customer-review.png";
import {
  IoIosAddCircleOutline,
  IoMdAddCircle,
  IoMdArrowRoundBack,
} from "react-icons/io";
import Select from "react-select";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import MyYuukke from "../../../api/MyYuukke";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import { useDispatch, useSelector } from "react-redux";
import { getUserService } from "../../../redux/actions/calendarAction";
import {
  FaEdit,
  FaArrowLeft,
  FaRegClock,
  FaRegShareSquare,
  FaEye,
} from "react-icons/fa";
import {
  MdDeleteOutline,
  MdDownloading,
  MdOutlineRateReview,
  MdOutlineRecommend,
} from "react-icons/md";
import Swal from "sweetalert2";
import { HiOutlineEye } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AssessmentForm from "../../Mentor/AssessmentForm";
import { Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import SocialShare from "../../../components/SocialShare";
import { shareFormUrl, shareUrl } from "../../../utils";
import CheckBox from "../../../components/CheckBox";
import moment from "moment";
import RequestList from "./RequestList";
import { withTranslation } from "react-i18next";
import ServiceRecommendation from "./ServiceRecommendation";
import ServiceReview from "./ServiceReview";
import { API_URL, BASE_URL } from "../../../config/app-config";

const Services = (props) => {
  const appointmentTypes = [
    {
      value: "one_to_one_consultation",
      label: "One-to-One Consultation",
      prize: 0,
      select: false,
    },
    {
      value: "primary_discussion",
      label: "Primary Discussion",
      prize: 0,
      select: false,
    },
    {
      value: "follow_up_session",
      label: "Follow-Up Session",
      prize: 0,
      select: false,
    },
    { value: "package", label: "Package", prize: 0, select: false },
    { value: "others", label: "Others", prize: 0, select: false },
  ];
  const activeOptions = [
    { value: 15, label: "15 Minute Meeting" },
    { value: 30, label: "30 Minute Meeting" },
    { value: 45, label: "45 Minute Meeting" },
    // { value: 60, label: "60 Minute Meeting" },
  ];
  const { t } = props;
  const { userServiceList } = useSelector((state) => state.event);
  const [state, setState] = useState({
    serviceList: [],
    activeType: null,
    activeMenu: 0,
    title: "",
    prize: 0,
    activeMinutes: {
      value: 15,
      label: "15 Minute Meeting",
    },
    // userServiceList: userServiceList,
    userServiceList: [],

    serviceId: null,
    allMenu: true,
    menuType: "",
    assessmentListUpdated: [],
    assessmentData: [],
    removeId: [],
    subMenu: "",
    shareCalendar: false,
    assessmentNeed: false,

    requestList: [],
    downloadList: [],
    singleRequestData: null,
    assessmentAnswer: [],
    shareUrl: "",
    qrUrl: "",
    description: "",
    pagePreview: false,
    previewUrl: "",
  });
  useEffect(() => {
    if (userServiceList) {
      // if (!props?.userPrefDetails?.demoSlotNeed) {
      let filterList = userServiceList.filter(
        (item) => item.type !== "clarity"
      );
      updateState("userServiceList", filterList);
      // } else {
      //   updateState("userServiceList", userServiceList);
      // }
    }
  }, [userServiceList]);
  useEffect(() => {
    console.log("neededTypeRedirect", props.neededTypeRedirect);
    if (props.neededTypeRedirect) {
      updateState("menuType", props.neededTypeRedirect);
      props.clearNeededType();
    }
  }, [props.neededTypeRedirect]);

  const dispatch = useDispatch();
  const { userSlots } = useSelector((state) => state.event);
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  useEffect(() => {
    dispatch(getUserService());
  }, []);
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const createService = (e) => {
    e.preventDefault();
    if (!state.title) {
      toast.warning("Enter your service name");
    } else if (state.prize === "") {
      toast.warning("Enter your service amount");
    } else {
      const formData = new FormData();

      formData.append("meetingActiveDuration", state.activeMinutes.value);
      formData.append("amount", state.prize);
      formData.append("appointmentType", state.activeType.label);
      formData.append("appointmentTypeValue", state.activeType.value);
      formData.append("calendarId", userSlots?.preference.id);
      formData.append("serviceName", state.title);
      formData.append("assessmentNeed", state.assessmentNeed);
      if (state.description) {
        formData.append("description", state.description);
      }
      if (state.assessmentData.length > 0) {
        const data = state.assessmentData.map((item) => {
          if (item.questionId) {
            return {
              question: item.question,
              answer:
                item.answerType === "select"
                  ? item.answerIds
                  : item.textTypeAnswer,
              answerType: item.answerType,
              questionId: item.questionId,
            };
          } else {
            return {
              question: item.question,
              answer:
                item.answerType === "select"
                  ? item.answerIds
                  : item.textTypeAnswer,
              answerType: item.answerType,
            };
          }
        });
        const obj = { questions: data };

        formData.append("selfAssessmentData", JSON.stringify(obj));
      }

      if (state.serviceId !== null) {
        formData.append("id", state.serviceId);
      }
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      MyYuukke.saveService(formData, config)
        .then((response) => {
          toast.success("Service created successfully");
          let list = [];
          if (state.serviceId) {
            list = userServiceList.map((item) => {
              if (item.id === state.serviceId) {
                item.serviceName = state.title;
                item.amount = state.prize;
                item.description = state.description ?? "";
                item.duration = state.activeMinutes.value;
                return item;
              }
              return item;
            });
            console.log("update-list", list);
          }

          setState((previous) => ({
            ...previous,
            loader: false,
            menuType: "",
            userServiceList: state.serviceId
              ? list
              : [response.data.data, ...userServiceList],
            allMenu: true,
            // assessmentListUpdated: [],
            assessmentData: [],
            // activeType: appointmentTypes[0],
          }));

          dispatch(
            getUserService(
              "update",
              state.serviceId ? list : [response.data.data, ...userServiceList]
            )
          );
          props.addSuccess(5);
        })
        .catch((e) => {
          toast.error("Something went wrong");
          console.log("error", e);
          setState((previous) => ({
            ...previous,
            loader: false,
            menuType: "",
          }));
        });
    }
  };
  const filterItem = (type, data) => {
    if (type === "all") {
      setState((previous) => ({
        ...previous,
        userServiceList: userServiceList,
        allMenu: true,
      }));
    } else {
      const list = userServiceList.filter(
        (item) => item.appointmentValue === data.value
      );
      setState((previous) => ({
        ...previous,
        userServiceList: list,
        activeType: data,
        allMenu: false,
      }));
    }
  };
  const makeChanges = (type, item) => {
    if (type === "edit") {
      let data = {
        id: item.id,
      };
      MyYuukke.getSingleService(data)
        .then((response) => {
          let result = response.data.data;
          setState((previous) => ({
            ...previous,
            serviceId: item.id,
            title: result.data.serviceName,
            prize: result.data.amount,
            description: result.data?.description ?? "",
            activeMinutes: {
              value: result.data.duration,
              label: `${result.data.duration} minutes`,
            },
            activeType: appointmentTypes.find(
              (data) => data.value === result.data.appointmentValue
            ),
            // assessmentListUpdated: result.questions,
            assessmentData: result.questions,
            assessmentNeed:
              result.data.assessmentNeed === null
                ? false
                : result.data.assessmentNeed,
            menuType: "update-service",
          }));
        })
        .catch((e) => {
          toast.error("Something went wrong");

          console.log("error", e);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Delete Service!",
        text: "Are you sure to delete the service",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          let data = {
            id: item.id,
          };
          MyYuukke.deleteService(data)
            .then((response) => {
              toast.success("Deleted successfully");
              let list = userServiceList.filter(
                (value) => value.id !== item.id
              );
              console.log("removed-items", list);
              dispatch(getUserService("update", list));
              setState((previous) => ({
                ...previous,
                loader: false,
                menuType: "",
                allMenu: true,
                userServiceList: list,
              }));
            })
            .catch((e) => {
              toast.error("Something went wrong");

              console.log("error", e);
            });
        }
      });
    }
  };
  const newService = () => {
    setState((previous) => ({
      ...previous,
      menuType: "new-service",
      serviceId: null,
      title: "",
      description: "",
      prize: 0,
      activeType: state.activeType ? state.activeType : appointmentTypes[0],
      assessmentListUpdated: [],
      assessmentData: [],
    }));
  };

  const removedQuestionId = (data) => {
    updateState([...state.removeId, data]);
  };
  const viewBookingSlots = () => {
    // updateState("loader", true);
    updateState("menuType", "request");
    // viewRequestList("upcoming");
  };
  const viewRequestList = (menu) => {
    // upcomming ,past
    let data = {
      id: userSlots?.preference.id,
    };
    if (menu !== "all") {
      data.type = menu;
    }
    MyYuukke.getRequestList(data)
      .then((res) => {
        console.log("res", res);

        const list = res.data.data.map((item) => {
          item.createdDate = item.startTime;
          item.startTime = moment(item.startTime).locale("en").format("LLL");
          return item;
        });
        // const downloadList = res.data.data.map((item) => {
        //   return {
        //     ServiceName: item.serviceName,
        //     UserName: item.userName,
        //     Biography: item.bio,
        //     Email: item.email,
        //     ContactNumber: item.phoneNumber,
        //     time: moment(item.startTime).locale("en").format("LLL"),
        //     createdDate: item.startTime,
        //   };
        // });

        setState((previous) => ({
          ...previous,
          menuType: "request",
          requestList: list,
          loader: false,
          // downloadList: downloadList,
        }));
      })
      .catch((err) => {
        updateState("loader", false);
      });
  };
  const calendarShare = (status = false, id) => {
    if (!props?.userPrefDetails?.accountNumber) {
      Swal.fire({
        icon: "warning",
        title: "Please Fill Your Account Details !",
        text: "We need your account details to process the credit of the service fee to your account.",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((res) => {
        props.onBack(6);
      });
      return;
    }

    setState((previous) => ({
      ...previous,
      shareUrl: !id
        ? shareUrl(userSlots?.preference.id + "&id")
        : shareUrl(userSlots?.preference.id + "&id&" + id),
      shareCalendar: status,
      // qrUrl: encodeURIComponent(
      //   !id
      //     ? shareUrl(userSlots?.preference.id + "&id")
      //     : shareUrl(userSlots?.preference.id + "&id&" + id)
      // ),
      qrUrl: encodeURIComponent(
        `${BASE_URL}yuukke-service/${userSlots?.preference.slug}`
      ),
    }));
  };

  const pagePreview = () => {
    console.log("PagePreview CLick Action");
    let id = null;
    setState((previous) => ({
      ...previous,
      shareUrl: !id
        ? shareUrl(userSlots?.preference.id + "&id")
        : shareUrl(userSlots?.preference.id + "&id&" + id),
      pagePreview: true,
      previewUrl: `${BASE_URL.replace(
        "connect.yuukke.com",
        "service.yuukke.com"
      ).replace(
        "bmlv2.betalearnings.com",
        "servicev2.betalearnings.com"
      )}yuukke-service/${userSlots?.preference.slug}`,
      //  previewUrl:state.shareUrl.replace("connect.yuukke.com", "service.yuukke.com"),
      // previewUrl: !id
      // ? shareUrl(userSlots?.preference.id + "&id").replace("connect.yuukke.com", "service.yuukke.com")
      // : shareUrl(userSlots?.preference.id + "&id&" + id).replace("connect.yuukke.com", "service.yuukke.com"),

      qrUrl: encodeURIComponent(
        !id
          ? shareUrl(userSlots?.preference.id + "&id")
          : shareUrl(userSlots?.preference.id + "&id&" + id)
      ),
    }));

    console.log("SharedURL >>>>> " + state.previewUrl);
  };

  const closePagePreview = () => {
    setState((previous) => ({
      ...previous,
      pagePreview: false,
    }));
  };

  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob(); // Convert response to Blob

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "service-profile.png"; // Set download file name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Cleanup
    } catch (error) {
      console.error("Error downloading the QR code:", error);
      toast.error("Error downloading the QR code");
    }
  };
  const toCamelCase = (str) => {
    if (!str || typeof str !== "string") return ""; // Handle null, undefined, or non-string values

    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" "); // Keep spaces between words
  };
  console.log("state", state);
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <div style={{ borderBottom: "1px solid #80808063" }}>
        <h4 className="p-3 mt-2 mb-2 d-flex  heading-h4-v2 flex-wrap g-1">
          {" "}
          {/* {(!state.newService && !props.initialServicePage) && (
            <IoArrowBackCircleOutline
              onClick={() => props.onBack()}
              className="h-auto  mr-3 svg-icons"
              style={{
                width: "2rem",
              }}
            />
          )} */}
          {state.menuType === "request"
            ? t("Service Request Users")
            : state.menuType === "recommend"
            ? t("Service Recommendation User List")
            : state.menuType === "review"
            ? t("Service Review User List")
            : t("Services")}
          {state.menuType === "review" ||
          state.menuType === "request" ||
          state.menuType === "recommend" ? (
            <>
              {/* <button
                className="ml-auto mr-3"
                onClick={() => updateState("menuType", "request")}
              >
                {" "}
                <FiDownloadCloud className="text-white svg-icons mr-2 c-pointer" />
                Download User's List
              </button> */}

              <IoArrowBackCircleOutline
                onClick={() => updateState("menuType", "")}
                className="h-auto ml-auto  svg-icons"
                style={{
                  width: "2rem",
                }}
              />
            </>
          ) : (
            <>
              {state.menuType === "" && (
                <>
                  {/* <button
                    className="mr-3 ml-auto"
                    onClick={() => viewBookingSlots()}
                  >
                    {" "}
                    <HiOutlineEye className="text-white svg-icons mr-2 c-pointer" />
                    {t("View Service Request Users")}
                  </button> */}
                  {/* <button
                    className="ml-auto  mr-3"
                    onClick={() => pagePreview()}
                    // onClick={() => updateState("shareCalendar", true)}
                  >
                    {" "}
                    <FaEye className="svg-icons text-white mr-2" />
                    {t("Page Preview")}
                  </button> */}

                  <button
                    className="ml-auto  mr-3"
                    onClick={() => calendarShare(true, null)}
                    // onClick={() => updateState("shareCalendar", true)}
                  >
                    {" "}
                    <FaRegShareSquare className="svg-icons text-white mr-2" />
                    {t("Share")}
                  </button>
                  <button onClick={() => props.onBack(7)}>
                    {/* <button onClick={() => props.onBack(0)}> */}{" "}
                    <FaArrowLeft className="text-white svg-icons mr-2 c-pointer" />
                    {t("Back")}
                  </button>
                </>
              )}
            </>
          )}
        </h4>
        {state.menuType === "" && (
          <div
            className="d-flex m-3"
            style={{
              borderRadius: "7px",
              overflow: "hidden",
              border: "1px solid gray",
            }}
          >
            <section className="cal-border-c">
              <p>{t("Appointment Types")}</p>{" "}
            </section>
            <ul className="l-none d-flex flex-wrap g-1 mr-auto align-items-centre p-3 ">
              <li
                style={{
                  background: state.allMenu ? "gainsboro" : "",
                }}
                className="small-box  position-relative"
                onClick={() => filterItem("all")}
              >
                {state.allMenu && (
                  <span
                    className="badge badge-info position-absolute"
                    style={{ top: "-7px", right: "-8px" }}
                  >
                    {state.userServiceList.length}
                  </span>
                )}
                {t("All")}
              </li>
              {appointmentTypes.map((item, i) => (
                <li
                  style={{
                    background:
                      !state.allMenu && item.value === state.activeType?.value
                        ? "gainsboro"
                        : "",
                  }}
                  className="small-box  position-relative"
                  key={i}
                  onClick={() => filterItem("other", item)}
                >
                  {!state.allMenu && item.value === state.activeType?.value && (
                    <span
                      className="badge badge-info position-absolute"
                      style={{ top: "-7px", right: "-8px" }}
                    >
                      {state.userServiceList.length}
                    </span>
                  )}
                  {t(`${item.label}`)}
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* <img
            src={logo}
            alt="img"
            className="mt-5 mb-1"
            style={{ width: "10rem" }}
          />
          <p className="f-12 text-secondary ">Create a {state.activeType}</p> */}
      </div>
      <div style={{ minHeight: "35rem" }}>
        {state.menuType === "review" ? (
          <>
            <ServiceReview />
          </>
        ) : state.menuType === "request" ? (
          <>
            <RequestList
              id={userSlots?.preference.id}
              // requestList={state.requestList}
              // viewRequestList={viewRequestList}
            />
          </>
        ) : state.menuType === "recommend" ? (
          <ServiceRecommendation />
        ) : state.menuType === "new-service" ||
          state.menuType === "update-service" ? (
          <>
            <div className="d-flex">
              <h5 className="p-2 mt-5">
                {" "}
                <IoMdArrowRoundBack
                  onClick={() =>
                    setState((previous) => ({
                      ...previous,
                      menuType: "",
                      serviceId: null,
                    }))
                  }
                  className="mr-2 svg-icons text-secondary"
                />{" "}
                {t("What Service ")}?
              </h5>
              <section className="ml-auto mb-2 mt-auto mr-2">
                <CheckBox
                  value={state.assessmentNeed}
                  label={`Need Pre Questions Form`}
                  onChange={(e) => updateState("assessmentNeed", e)}
                  questionMark={true}
                />
              </section>
              {state.assessmentNeed && (
                <button
                  className="mt-auto mr-2"
                  onClick={() => updateState("subMenu", "assessment")}
                >
                  {state.assessmentData.length > 0 ? (
                    <FaEdit className="text-white svg-icons mr-2 c-pointer" />
                  ) : (
                    <IoMdAddCircle className="text-white svg-icons mr-2 c-pointer" />
                  )}
                  {state.assessmentData.length > 0
                    ? t("Update Pre Questions")
                    : t("Add Pre Questions")}
                </button>
              )}
            </div>
            <form
              onSubmit={createService}
              className="card askAQuestionInputGroup cal-grid  p-4"
            >
              <section className="h-100">
                <label className="mt-3 text-dark label-v2">
                  {t("Service Type")}
                </label>
                <p className="f-12 text-primary">{state.activeType.label}</p>
              </section>
              <section>
                <label className="label-v2 mt-3 text-dark">
                  {t("Service Name")}
                </label>
                <input
                  style={{ height: "50px" }}
                  type="text"
                  name="serviceName"
                  placeholder="Mentoring"
                  value={state.title}
                  onChange={(e) => updateState("title", e.target.value)}
                  required
                />
              </section>

              <section>
                {" "}
                <label className="label-v2 mt-3 text-dark">
                  {t("Amount")} ₹
                </label>
                <input
                  style={{ height: "50px" }}
                  type="number"
                  placeholder="0"
                  value={state.prize}
                  onChange={(e) => updateState("prize", e.target.value)}
                />
              </section>
              <section>
                <label className="label-v2 mt-3 text-dark">
                  {t("Duration")} (mins)
                </label>{" "}
                <Select
                  menuPlacement="top"
                  options={activeOptions}
                  value={state.activeMinutes}
                  onChange={(e) => {
                    updateState("activeMinutes", e);
                  }}
                />
              </section>
              <section className="cal-full w-100">
                <label className="label-v2 mt-3 text-dark">
                  {t("Tell About Your Service in Short Description")}
                </label>
                <textarea
                  className="post-form-control post-pe-4 post-bg-light"
                  rows="1"
                  placeholder={t("About your service ...")}
                  type="text"
                  onChange={(e) => updateState("description", e.target.value)}
                  value={state.description}
                  name="description"
                  style={{ minHeight: "8rem" }}
                  required
                ></textarea>
              </section>
              <button className="w-100 mt-4 mb-3" type="submit">
                {state.serviceId ? "Update" : "Create"} Your Service
              </button>
            </form>
            {state.subMenu === "assessment" && (
              <Modal show="true" onHide={() => updateState("subMenu", "")}>
                <Modal.Header>
                  {t("PRE QUESTION'S FORM")}
                  <AiOutlineCloseCircle
                    onClick={() => updateState("subMenu", "")}
                    style={{
                      cursor: "pointer",
                      float: "right",
                      marginBottom: "8px",
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                    }}
                  />
                </Modal.Header>
                <Modal.Body className="d-flex flex-column">
                  <AssessmentForm
                    editState={state.assessmentData.length}
                    assessmentListUpdated={state.assessmentData}
                    surveyQuestion={(data) =>
                      updateState("assessmentData", data)
                    }
                    removedQuestionId={removedQuestionId}
                  />

                  {state.assessmentData.length > 0 && (
                    <button
                      onClick={() => {
                        const isValid = state.assessmentData.every((item) => {
                          if (item.answerType === "select") {
                            if (!item.answerIds || item.answerIds.length < 2) {
                              toast.warn(
                                "Please add at least two options for the select type."
                              );
                              return false; // Validation failed
                            }
                            return true; // Validation passed
                          } else {
                            if (
                              !item.textTypeAnswer ||
                              item.textTypeAnswer.trim() === ""
                            ) {
                              toast.warn(
                                "Please add a valid answer for the text type."
                              );
                              return false; // Validation failed
                            }
                            return true; // Validation passed
                          }
                        });

                        if (isValid) {
                          updateState("subMenu", "");
                          // updateState("assessmentListUpdated", state.assessmentData);
                        }
                      }}
                      disabled={!state.assessmentData.length} // Disable button if no data
                    >
                      {state.menuType === "update-service"
                        ? t("Update Pre Questions")
                        : t("Add My Service")}
                    </button>
                  )}
                </Modal.Body>
              </Modal>
            )}
            {state.subMenu !== "assessment" &&
              state.assessmentNeed &&
              state.assessmentData.length > 0 && (
                <div className="card p-4 m-3">
                  <h5
                    className="mt-1 mb-3 font-weight-bold p-3"
                    style={{ background: "#80808047", borderRadius: "4px" }}
                  >
                    {t("PRE QUESTIONS FORM")}
                  </h5>
                  {state.assessmentData.map((item, index) =>
                    item.answerType === "select" ||
                    item.answerType === "select" ? (
                      <div className="mb-2">
                        <div className="d-flex font-weight-bold">
                          <label className="label-v2 mt-3 text-dark">
                            {index + 1} . {item.question}
                          </label>
                        </div>
                        <div
                          className="d-flex flex-column align-items-start mb-2 mt-2 ml-4"
                          style={{ gap: "1rem" }}
                        >
                          {item.answerIds.map((data) => (
                            <div
                              className="d-flex"
                              style={{ gap: "1rem", cursor: "pointer" }}
                              onClick={() => {}}
                            >
                              <input
                                key={data.answerId}
                                type="radio"
                                checked={false}
                                // value={data.answerId}
                              />{" "}
                              <p>{data.answer}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="mb-2">
                        <div className="d-flex font-weight-bold ">
                          <label className="label-v2 mt-3 text-dark">
                            {index + 1} . {item.question}
                          </label>
                        </div>
                        <div className="d-flex flex-column align-items-start ml-4">
                          <TextField
                            fullWidth
                            margin="normal"
                            variant="filled"
                            placeholder={item.textTypeAnswer || item.answer}
                            onChange={(e) => {}}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
          </>
        ) : (
          <>
            <div>
              <button
                className="mr-3 ml-3"
                onClick={() => updateState("menuType", "recommend")}
              >
                {" "}
                <MdOutlineRecommend className="text-white svg-icons mr-2 c-pointer" />
                {t("View Recommendation List")}
              </button>
              <button
                className="mr-3"
                onClick={() => updateState("menuType", "review")}
              >
                {" "}
                <MdOutlineRateReview className="text-white svg-icons mr-2 c-pointer" />
                {t("View Review List")}
              </button>
              <button className="m-3" onClick={() => newService()}>
                <IoIosAddCircleOutline className="svg-icons mr-2 text-white  " />{" "}
                {t("Add More Service")}
              </button>
            </div>
            {state.userServiceList.length > 0 ? (
              <ul className="d-flex flex-column g-2 l-none p-3">
                {state.userServiceList.map((item) => (
                  <li className="card  p-4 ">
                    <div className="d-flex flex-wrap g-2">
                      <section>
                        <h4
                          className="font-weight-bold m-0"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* {item.serviceName}{" "} */}
                          {toCamelCase(item.serviceName)}

                          {/* {item.type === "clarity" && " - (Default Service)"} */}
                        </h4>
                        <p
                          className=" text-secondary mt-1"
                          style={{ fontSize: "12px" }}
                        >
                          {moment(item.updatedDate).locale("en").format("lll")}
                        </p>
                      </section>
                      <section className="ml-auto">
                        <FaRegShareSquare
                          className="svg-icons mr-2 c-pointer"
                          onClick={() => calendarShare(true, item.id)}
                        />
                        {item.type !== "clarity" && (
                          <FaEdit
                            className=" svg-icons mr-2 c-pointer"
                            onClick={() => makeChanges("edit", item)}
                          />
                        )}
                        {item.type !== "clarity" && (
                          <MdDeleteOutline
                            className="mr-2 svg-icons "
                            onClick={() => makeChanges("delete", item)}
                          />
                        )}
                      </section>
                    </div>
                    <p className="mt-2">
                      {item.appointmentType}{" "}
                      <span className="font-weight-bold">{`₹${item.amount}`}</span>
                    </p>
                    <p className="d-flex items-center   mt-3">
                      <FaRegClock className="mr-2 svg-icons c-pointer" />{" "}
                      {item.duration} mins
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="d-flex align-items-center justify-content-center flex-column">
                <img
                  src={logo}
                  alt="img"
                  className="mt-5 mb-1"
                  style={{ width: "10rem" }}
                />
                <p className="mb-5">No service present ...</p>
              </div>
            )}
          </>
        )}
        {state.shareCalendar && (
          <Modal show="true" onHide={() => calendarShare(false, null)}>
            <Modal.Header className="font-weight-bold">
              <p>{t("Your Service Link")} </p>
              <AiOutlineCloseCircle
                onClick={() => calendarShare(false, null)}
                className="ml-auto"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginBottom: "8px",
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <h6 className="mt-3 font-weight-bold">
                Download Your Service Page QR Code
              </h6>
              <div>
                <img
                  src={`${API_URL}auth/v2/qrcode/image?url=${state.qrUrl}`}
                  alt=""
                />
                <button
                  onClick={() =>
                    downloadImage(
                      `${API_URL}auth/v2/qrcode/image?url=${state.qrUrl}`
                    )
                  }
                  style={{
                    marginTop: "10px",
                    padding: "10px 15px",
                    cursor: "pointer",
                  }}
                >
                  <MdDownloading className="svg-icons text-white mr-2" />
                  Download
                </button>{" "}
              </div>

              <h6 className="mt-3 font-weight-bold">
                Share Your Service To Others
              </h6>
              <div
                className="d-flex align-items-center mb-2 mt-2 p-4"
                style={{
                  gap: "1rem",
                  background: "lightslategray",
                  borderRadius: "21px",
                }}
              >
                <SocialShare
                  closeNotNeed={true}
                  // url={state.shareUrl}
                  url={`${BASE_URL}yuukke-service/${userSlots?.preference.slug}`}
                  // url={shareUrl(userSlots?.preference.id + "&id")}
                  title={"Check my available schedule"}
                  closeModel={() => updateState("userCalendarUrl", null)}
                />
              </div>
              <h6 className="mt-3 font-weight-bold">
                Get Recommendation From Others
              </h6>
              <div
                className="d-flex align-items-center mb-2 mt-2 p-4"
                style={{
                  gap: "1rem",
                  background: "lightslategray",
                  borderRadius: "21px",
                }}
              >
                <SocialShare
                  closeNotNeed={true}
                  url={`${BASE_URL}service-recommend/${userSlots?.preference.slug}`}
                  // url={shareFormUrl(
                  //   loginDetails?.userId + "&id",
                  //   "service-recommend"
                  // )}
                  // url={shareUrl(userSlots?.preference.id + "&id")}
                  title={"Check my available schedule"}
                  closeModel={() => updateState("userCalendarUrl", null)}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}

        {state.pagePreview && (
          <Modal show="true" onHide={() => closePagePreview()}>
            <Modal.Header className="font-weight-bold">
              <p>{t("Dedicated Page Preview")} </p>
              <AiOutlineCloseCircle
                onClick={() => closePagePreview()}
                className="ml-auto"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginBottom: "8px",
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <h6 style={{ display: "none" }}>{state.previewUrl}</h6>
              <iframe
                src={state.previewUrl}
                //src={`${BASE_URL}yuukke-service/${userSlots?.preference.slug}`}
                // src={`${BASE_URL}yuukke-service/${userSlots?.preference.slug}`}

                //src="https://bmlv2.betalearnings.com/available-calender/?&id=U2FsdGVkX1_BCc0DY4ySDkniv5s56nfScFDI5DfhiQ4"
                title="Preview"
                width="100%"
                height="500px"
                className="border"
              ></iframe>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </BlockUi>
  );
};

export default withTranslation()(Services);
