import React, { useEffect, useState } from "react";
import MyYuukke from "../../../api/MyYuukke";
import BlockUi from "react-block-ui";
import { useDispatch, useSelector } from "react-redux";
import { getUserSlots } from "../../../redux/actions/calendarAction";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import CheckBox from "../../../components/CheckBox";
import ImagePreview from "../../../components/ImagePreview";
import StepperFileOption from "../../Wizard/StepperFileOption";
import { urlPatternValidation } from "../../../utils";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import TextEditorQuestion from "../../../components/TextEditorQuestion";
import { fontOptions, timeZone } from "../../../sampleData/Options";
import ChooseFileComponent from "../../../components/ChooseFileComponent";
import ColorPicker from "../../../components/ColorPicker";
import ColorPickerV2 from "../../../components/ColorPickerV2";
import { Typeahead } from "react-bootstrap-typeahead";

let updatingFinish = false;
function CalendarPlans({ t, onBack }) {
  const [state, setState] = useState({
    activeMenu: 0,
    type: null,
    meetingInYuukke: false,
    paymentNeed: false,
    meetingLink: "",
    bookingCount: true,
    profilePhoto: true,
    freeCalendar: true,
    meetingPrize: "",
    businessLogo: null,
    emailReminder: true,
    whatsappReminder: false,
    apiLogo: false,
    editBusinessLogo: null,
    loader: false,
    paymentPage: false,
    subscribeId: 0,
    prize: 0,
    paymentStatus: "",
    subscriptionId: null,
    serviceName: "",
    serviceImage: "",
    editServiceLogo: null,
    apiGalleryImages: [],
    removedId: [],
    selectedGalleryImage: [],
    serviceApiLogo: false,
    serviceType: [],
    packageName: "",
    successPercent: 35,
    menuState: 0,
    serviceList: [],
    otherServiceName: "",
    pinCode: "",
    removedServiceCategoryId: [],
    categoryIds: [],
    description: "",
    subCategory: [],
    subCategoryList: [],
    timeZone: [],
    language: "",
    editDescription: "",
    countryList: [],
    country: "",
    phoneNumberShow: true,
    emailShow: true,
    minBookingCount: 2,
    demoSlotNeed: true,
    selectedServiceVideo: [],
    apiServiceVideo: [],
    removedServiceVideoId: [],
    selectedAnnounceMent: [],
    apiAnnounceMentImages: [],
    removedAnnounceMentId: [],
    editAnnounceMentContent: "",
    announceMentContent: "",
    theme: "#a00030",
    needCustomTheme: false,
    fontFamily: { label: "Poppins", value: "'Poppins', sans-serif" },
    changeMade: false,
    primaryColor: "",
    secondaryColor: "",
    headingColor: "",
    subHeadingColor: "",
    uiTheme: { value: 0, label: "Default Theme" },
    sliderTextOne: "",
    sliderTextTwo: "",
  });
  const uiTheme = [
    { value: 0, label: "Default Theme" },
    { value: 1, label: "New Theme" },
  ];
  const dispatch = useDispatch();
  const countryList = useSelector((state) => state.commonApi.countryName);

  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const { categoryV2, subCategoryV2, calendarServices, userSlots } =
    useSelector((state) => state.event);
  const { languagelist } = useSelector((state) => state.commonApi);
  console.log("languagelist", languagelist);
  useEffect(() => {
    if (countryList.length > 0) {
      const list = countryList.map((item) => {
        return { value: item.id, label: item.nicename, id: item.id };
      });
      updateState("countryList", list);
      const country = list.find(
        (item) => item.value === userSlots?.preference?.countryId
      );
      console.log("country", country);
      if (country) {
        updateState("country", country);
      }
    }
  }, [countryList]);
  useEffect(() => {
    if (userSlots?.preference) {
      console.log("slotDetails", userSlots);
      let slotDetails = userSlots?.preference;
      // let categoryIds = slotDetails.categorys.map((item) => item.categoryId);
      // let serviceType = calendarSpecializationV2.filter((item) =>
      //   categoryIds.includes(item.value)
      // );
      let timeZoneData = timeZone.reduce((result, item) => {
        for (let i = 0; i < item.options.length; i++) {
          if (slotDetails.timeZone == item.options[i].value) {
            result = {
              label: item.options[i].label,
              value: item.options[i].value,
            };
            break;
          }
        }
        return result;
      }, {});
      let lan =
        slotDetails.language &&
        slotDetails.language.map((item) => {
          return { label: item.name, value: item.id };
        });
      let serviceIds = [];
      let cat = slotDetails.categorys.map((item) => {
        serviceIds.push(item.serviceId);
        return {
          label: item.categoryName,
          value: item.categoryId,
          categoryId: item.serviceId,
        };
      });
      let serviceData = [];
      if (serviceIds.length > 0) {
        serviceData = categoryV2.filter((item) =>
          serviceIds.includes(item.value)
        );
      }
      console.log("serviceIds", serviceIds);
      console.log("serviceData", serviceData);
      let selectedTheme =
        slotDetails?.uiTheme &&
        uiTheme.find((item) => item.value === slotDetails?.uiTheme);
      setState((previous) => ({
        ...previous,
        timeZone: timeZoneData ?? [],
        subCategory: cat ?? [],
        language: lan ?? [],

        // categoryIds: categoryIds ? categoryIds : [],
        serviceType: serviceData,
        meetingInYuukke:
          slotDetails.meetingInApplication === null
            ? true
            : slotDetails.meetingInApplication,
        meetingLink:
          slotDetails.meetingUrl === null ? "" : slotDetails.meetingUrl,
        bookingCount:
          slotDetails.showBookingCount === null
            ? true
            : slotDetails.showBookingCount,
        profilePhoto:
          slotDetails.showProfileImage === null
            ? true
            : slotDetails.showProfileImage,
        // freeCalendar: slotDetails.noFees === null ? "" : slotDetails.noFees,
        // meetingPrize: slotDetails.fees === null ? "" : slotDetails.fees,
        editBusinessLogo: slotDetails.businessLogo,
        apiLogo: true,
        // editServiceLogo: slotDetails.serviceImage,
        apiGalleryImages: slotDetails.serviceGalleryImages
          ? slotDetails.serviceGalleryImages
          : [],
        serviceApiLogo: true,
        serviceName: slotDetails.serviceName,
        pinCode: slotDetails.pinCode,
        editDescription: slotDetails.description ?? "",
        description: slotDetails.description ?? "",
        phoneNumberShow: slotDetails.phoneNumberShow,
        emailShow: slotDetails.emailShow,
        minBookingCount: slotDetails.minBookingCount,
        demoSlotNeed: slotDetails.demoSlotNeed,
        apiServiceVideo: slotDetails.sereviceVideo,
        apiAnnounceMentImages: slotDetails.announcement,
        editAnnounceMentContent: slotDetails.announcementContent ?? "",
        theme: slotDetails.theme ?? "#a00030",
        needCustomTheme: slotDetails.needCustomTheme ?? false,
        announceMentContent: slotDetails.announcementContent ?? "",
        fontFamily: slotDetails?.fontFamily
          ? findFont(slotDetails?.fontFamily)
          : state.fontFamily,
        updateState: true,
        primaryColor: slotDetails.primaryColor ?? "#a00030",
        secondaryColor: slotDetails.secondaryColor ?? "#B7B7B7",
        headingColor: slotDetails.headingColor ?? "#000000",
        subHeadingColor: slotDetails.subHeadingColor ?? "#EEEEEE",
        uiTheme: selectedTheme ? selectedTheme : state.uiTheme,
        sliderTextTwo: slotDetails.sliderTextTwo ?? "",
        sliderTextOne: slotDetails.sliderTextOne ?? "",
      }));
    }
  }, [userSlots]);

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const findFont = (family) => {
    const font = fontOptions.find((item) => item.value === family);
    console.log("font", font);
    if (font) {
      updateFont(font.value);
    }
    return font ? font : { label: "Poppins", value: "'Poppins', sans-serif" };
  };
  const updateFont = (font) => {
    console.log("font", font);
    document.documentElement.style.setProperty("--test-font", font);
  };
  const updatePreferenceDetails = (meetingLink, type) => {
    const formData = new FormData();
    formData.append("meetingInApplication", state.meetingInYuukke);
    formData.append("showBookingCount", state.bookingCount);
    formData.append("showProfileImage", state.profilePhoto);
    formData.append("emailShow", state.emailShow);
    formData.append("phoneNumberShow", state.phoneNumberShow);
    formData.append("minBookingCount", state.minBookingCount ?? 2);

    // formData.append("noFees", state.freeCalendar);
    if (state.pinCode) {
      formData.append("pinCode", state.pinCode);
    }
    // if (!state.freeCalendar) {
    //   formData.append("fees", state.meetingPrize);
    // } else {
    //   formData.append("fees", 0);
    // }
    if (state.businessLogo) {
      formData.append("businessLogov2", state.businessLogo);
    }
    formData.append("serviceName", state.serviceName);

    // if (state.serviceImage) {
    //   formData.append("serviceImage", state.serviceImage);
    // }
    // formData.append("shareService", true);
    if (meetingLink) {
      formData.append("meetingUrl", meetingLink);
    } else {
      formData.append("meetingUrl", "");
    }

    if (state.selectedGalleryImage?.length > 0) {
      for (let i = 0; i < state.selectedGalleryImage.length; i++) {
        formData.append("serviceGalleryImage", state.selectedGalleryImage[i]);
      }
    }
    if (state.removedId.length > 0) {
      formData.append("removedGalleryImageId", state.removedId);
    }

    if (state.country) {
      formData.append("countryId", state.country.id);
      formData.append("countryName", state.country.label);
    }
    formData.append("description", state.description);
    formData.append("timeZone", state.timeZone.value);
    const lang = state.language.map((item) => {
      return { language: item.label, id: item.value };
    });

    formData.append("language", JSON.stringify({ data: lang }));
    const cate = state.subCategory.map((item) => {
      return { primaryId: item.categoryId, categoryId: item.value };
    });

    formData.append("categoryV2", JSON.stringify({ data: cate }));

    if (state.selectedAnnounceMent.length > 0) {
      for (let i = 0; i < state.selectedAnnounceMent.length; i++) {
        formData.append("serviceAnnouncement", state.selectedAnnounceMent[i]);
      }
    }
    if (state.selectedServiceVideo.length > 0) {
      for (let i = 0; i < state.selectedServiceVideo.length; i++) {
        formData.append("serviceVideo", state.selectedServiceVideo[i]);
      }
    }

    if (state.removedServiceVideoId.length > 0) {
      formData.append("removedServiceVideoId", state.removedServiceVideoId);
    }
    if (state.removedAnnounceMentId.length > 0) {
      formData.append(
        "removedServiceAnnouncementImageId",
        state.removedAnnounceMentId
      );
    }
    if (state.demoSlotNeed != null) {
      formData.append("demoSession", state.demoSlotNeed);
    }
    if (state.theme !== null) {
      formData.append("theme", state.theme);
    }
    if (state.primaryColor) {
      formData.append("primaryColor", state.primaryColor);
    }
    if (state.secondaryColor) {
      formData.append("secondaryColor", state.secondaryColor);
    }
    if (state.headingColor) {
      formData.append("headingColor", state.headingColor);
    }
    if (state.subHeadingColor) {
      formData.append("subHeadingColor", state.subHeadingColor);
    }
    if (state.needCustomTheme != null) {
      formData.append("customTheme", state.needCustomTheme);
    }
    if (state.announceMentContent != null) {
      formData.append("announcementContent", state.announceMentContent);
    }
    if (state.fontFamily != null) {
      formData.append("fontFamily", state.fontFamily?.value);
    }
    if (state.uiTheme !== null) {
      formData.append("uiTheme", state.uiTheme?.value);
    }

    if (state.sliderTextOne) {
      formData.append("sliderTextOne", state.sliderTextOne);
    }
    if (state.sliderTextTwo) {
      formData.append("sliderTextTwo", state.sliderTextTwo);
    }
    // const id = state.serviceType.map((item) => item.value);
    // formData.append("serviceCategoryId", id);
    // let ids = [7, 8];
    // formData.append("removedServiceCategoryId", ids);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    MyYuukke.updatePreference(formData, config)
      .then((response) => {
        console.log("pref", response.data.data);
        // updateState("loader", false);
        setState((previous) => ({
          ...previous,
          loader: false,
          changeMade: false,
        }));
        dispatch(getUserSlots());
        if (type !== "update") {
          onBack("calendar", true);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong !");
        console.log("e", err);
        setState((previous) => ({
          ...previous,
          loader: false,
          changeMade: false,
        }));
        // updateState("loader", false);
      });
  };

  const updatePreference = (e, type) => {
    e.preventDefault();
    if (!urlPatternValidation(state.meetingLink) && !state.meetingInYuukke) {
      toast.warning("Enter valid url meeting url");
    } else if (!state.freeCalendar && !state.meetingPrize) {
      toast.warning("Enter your slot prize");
    }
    // else if (!state.serviceImage && !state.editServiceLogo) {
    //   toast.warning("Add service image");
    // }
    else if (!state.serviceName) {
      toast.warning("Enter service name");
    }
    // else if (
    //   state.apiGalleryImages.length === 0 &&
    //   state.selectedGalleryImage.length === 0
    // ) {
    //   toast.warning("Add your gallery images");
    // }
    else if (!state.serviceType.length) {
      toast.warning("Select your service related serviceType");
    } else if (!state.description) {
      toast.warning("Add your service details");
    } else {
      updateState("loader", true);
      updatePreferenceDetails(state.meetingLink, type);
    }
  };

  const removeApiGalleryImage = (index, type) => {
    if (type === "apiImage") {
      const list = state.apiGalleryImages.splice(index, 1);
      console.log("list", list);
      updateState("removedId", state.removedId.concat(list));
    } else {
      const list = state.selectedGalleryImage.splice(index, 1);
      console.log("list", list);
      setState((previous) => ({
        ...previous,
      }));
    }
  };
  const removeServiceVideo = (index, type) => {
    if (type === "apiVideo") {
      const list = state.apiServiceVideo.splice(index, 1);
      console.log("list", list);
      updateState(
        "removedServiceVideoId",
        state.removedServiceVideoId.concat(list)
      );
    } else {
      const list = state.selectedServiceVideo.splice(index, 1);
      console.log("list", list);
      setState((previous) => ({
        ...previous,
      }));
    }
  };
  const removeAnnounceMentImage = (index, type) => {
    if (type === "apiImage") {
      const list = state.apiAnnounceMentImages.splice(index, 1);
      console.log("list", list);
      updateState(
        "removedAnnounceMentId",
        state.removedAnnounceMentId.concat(list)
      );
    } else {
      const list = state.selectedAnnounceMent.splice(index, 1);
      console.log("list", list);
      setState((previous) => ({
        ...previous,
      }));
    }
  };
  const getSubcategory = (data) => {
    console.log("data", data);
    updateState("serviceType", data);
    let ids = data.map((item) => item.value);
    let findSubCategory = subCategoryV2.reduce((result, data) => {
      if (ids.includes(data.categoryId)) {
        let list = data.subCategoryList.map((item) => {
          return {
            label: item.subCategoryName,
            value: item.subCategoryId,
            categoryId: data.categoryId,
          };
        });
        result = [...result, ...list];
      }
      return result;
    }, []);
    console.log("subCategoryV2", subCategoryV2);
    updateState("subCategoryList", findSubCategory);
  };

  const onUploadSuccess = async (file) => {
    console.log("FILES", file);

    if (file.length > 1) {
      toast.warning("Please select single image");
    } else {
      updateState("selectedServiceVideo", [
        ...state.selectedServiceVideo,
        file[0],
      ]);
    }
  };
  // const [selectedFont, setSelectedFont] = useState("'Poppins', sans-serif");
  // useEffect(() => {
  //   // Apply font to the CSS variable
  //   document.documentElement.style.setProperty("--app-font", selectedFont);
  //   // Save to localStorage so it persists
  //   localStorage.setItem("selectedFont", selectedFont);
  // }, [selectedFont]);

  console.log("pref", state);
  // console.log("selectedFont", selectedFont);
  const changeMade = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
      changeMade: true,
    }));
  };
  const changeColor = (color) => {
    console.log("color", color);
    setState((previous) => ({
      ...previous,
      primaryColor: color.primary,
      secondaryColor: color.secondary,
      headingColor: color.heading,
      subHeadingColor: color.subHeading,
    }));
  };
  return (
    <>
      <BlockUi
        tag="div"
        message="Loading, please wait"
        style={{ minHeight: "134px" }}
        className="wallLoadingPage"
        blocking={state.loader}
      >
        <h4
          className="p-3 mt-2 mb-2   heading-h4-v2"
          style={{ borderBottom: "1px solid #80808063" }}
        >
          <IoArrowBackCircleOutline
            onClick={() => onBack()}
            className="h-auto  mr-3 svg-icons"
            style={{
              width: "2rem",
            }}
          />
          {t("Service Profile Details")}
        </h4>
        <form
          onSubmit={updatePreference}
          className="card askAQuestionInputGroup cal-grid  p-4 relative"
        >
          {/* {state.changeMade && (
            <button
              className="cal-update-changes"
              onClick={(e) => updatePreference(e, "update")}
            >
              Update Service Changes
            </button>
          )} */}
          <section>
            <label className="label-v2 mt-3">
              {/* {t("Business Name / Service Name")} * */}
              {t("Your Business Name")} *
            </label>
            <input
              style={{ height: "50px" }}
              type="text"
              name="serviceName"
              placeholder="Mentoring"
              value={state.serviceName}
              onChange={(e) => changeMade("serviceName", e.target.value)}
              required
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Service Type")} *</label>
            <Select
              options={categoryV2}
              value={state.serviceType}
              onChange={getSubcategory}
              required
              isMulti
              className="z-1002"
            />
          </section>
          <section>
            {/* <label className="label-v2 mt-3">{t("Category")} *</label> */}
            <label className="label-v2 mt-3">Category of Your Service</label>
            <Select
              options={state.subCategoryList}
              value={state.subCategory}
              onChange={(e) => {
                changeMade("subCategory", e);
              }}
              required
              isMulti
              className="z-1001"
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Timezone")} *</label>
            <Select
              options={timeZone}
              value={state.timeZone}
              onChange={(e) => {
                changeMade("timeZone", e);
              }}
              required
              className="z-1001"
            />
          </section>
          <section>
            <label className="label-v2 mt-3">
              {t("Languages You are Comfortable Communicating")} *
            </label>

            {/* <label className="label-v2 mt-3">{t("Language")} *</label> */}
            <Select
              options={languagelist}
              value={state.language}
              onChange={(e) => {
                changeMade("language", e);
              }}
              required
              isMulti
              className="z-1000"
            />
          </section>
          <section>
            <label className="label-v2 mt-3">{t("Postal Code")} *</label>
            <input
              style={{ height: "50px" }}
              type="number"
              name="pinCode"
              placeholder=""
              value={state.pinCode}
              onChange={(e) => changeMade("pinCode", e.target.value)}
              required
            />
          </section>
          <section>
            <label className="label-v2 mt-3">
              Which country are you from ? *
            </label>
            <Select
              options={state.countryList}
              value={state.country}
              onChange={(e) => {
                changeMade("country", e);
              }}
              required
              className="z-995"
            />
          </section>
          <section>
            <label className="label-v2 mt-3">
              {t("Show my booking count when the number exceeds")} *
            </label>
            <input
              style={{ height: "50px" }}
              type="number"
              name="minBookingCount"
              placeholder=""
              value={state.minBookingCount}
              onChange={(e) => changeMade("minBookingCount", e.target.value)}
              required
            />
          </section>
          {/* <section className="cal-full w-100"> */}
          <section>
            <label className="label-v2 mt-3 text-font  ">
              Choose Your Web Page Font
            </label>
            <Select
              options={fontOptions}
              onChange={(option) => {
                updateFont(option.value);
                changeMade("fontFamily", option);
              }}
              value={state.fontFamily}
              placeholder="Choose a font"

              // required
            />
          </section>
          <section>
            <label className="label-v2 mt-3">Choose Your Web Page</label>
            <Select
              options={uiTheme}
              onChange={(option) => {
                changeMade("uiTheme", option);
              }}
              value={state.uiTheme}
              placeholder="Choose UI Theme"

              // required
            />
          </section>
          <section>
            <label className="label-v2 mt-3">
              {t("Caption One Display in Site")} *
            </label>
            <input
              style={{ height: "50px" }}
              type="text"
              name="sliderTextOne"
              placeholder=""
              value={state.sliderTextOne}
              onChange={(e) => changeMade("sliderTextOne", e.target.value)}
              required
            />
          </section>
          {state.uiTheme.value === 1 && (
            <section>
              <label className="label-v2 mt-3">
                {t("Caption Two Display in Site")}
              </label>
              <input
                style={{ height: "50px" }}
                type="text"
                name="sliderTextTwo"
                placeholder=""
                value={state.sliderTextTwo}
                onChange={(e) => changeMade("sliderTextTwo", e.target.value)}
              />
            </section>
          )}
          <section className="editableTextWrapper formFieldGroup cal-full w-100">
            <label className="label-v2">Few Words About Your Service *</label>
            {/* <textarea
              className="post-form-control post-pe-4 post-bg-light"
              rows="1"
              placeholder={t("About your service ...")}
              type="text"
              onChange={(e) => updateState("description", e.target.value)}
              value={state.description}
              name="description"
              style={{ minHeight: "8rem" }}
              required
            ></textarea> */}
            <TextEditorQuestion
              editorStateValue={(e) => changeMade("description", e)}
              editorValue={state.editDescription}
            />
          </section>
          <section className="editableTextWrapper formFieldGroup cal-full w-100">
            <label className="label-v2">Add Your Announcement Content</label>
            {/* <textarea
              className="post-form-control post-pe-4 post-bg-light"
              rows="1"
              placeholder={t("About your service ...")}
              type="text"
              onChange={(e) => updateState("description", e.target.value)}
              value={state.description}
              name="description"
              style={{ minHeight: "8rem" }}
              required
            ></textarea> */}
            <TextEditorQuestion
              editorStateValue={(e) => changeMade("announceMentContent", e)}
              editorValue={state.editAnnounceMentContent}
            />
          </section>
          <section>
            <CheckBox
              value={state.emailShow}
              label={`Do you want to show your email ?`}
              onChange={(e) => changeMade("emailShow", e)}
              questionMark={false}
            />
          </section>
          <section>
            <CheckBox
              value={state.phoneNumberShow}
              label={`Do you want to show your Phone Number`}
              onChange={(e) => changeMade("phoneNumberShow", e)}
              questionMark={false}
            />
          </section>
          <section>
            <CheckBox
              value={state.demoSlotNeed}
              label={`Would you like to offer a FREE DEMO session ?`}
              onChange={(e) => changeMade("demoSlotNeed", e)}
              questionMark={false}
            />
          </section>

          <section>
            <CheckBox
              value={state.bookingCount}
              label={`Do you want to show your Booking Count ?`}
              onChange={(e) => changeMade("bookingCount", e)}
              questionMark={false}
            />
          </section>
          <section className="h-100">
            <CheckBox
              value={state.profilePhoto}
              label="Do you want show your profile photo ?"
              onChange={(e) => changeMade("profilePhoto", e)}
              questionMark={false}
            />
          </section>

          <section>
            <CheckBox
              value={state.meetingInYuukke}
              label="Would you like to have a Yuukke Meeting ?"
              onChange={(e) => changeMade("meetingInYuukke", e)}
              questionMark={false}
            />
            {/* {!state.meetingInYuukke && (
              <div className="">
                <label className="label-v2 mt-3 ml-2">
                  {t("Add Meeting Link")} *
                </label>

                <input
                  style={{ marginLeft: "0.5rem", height: "50px" }}
                  type="text"
                  name="meetingLink"
                  placeholder="http://googlemeet.com/"
                  value={state.meetingLink}
                  onChange={(e) => updateState("meetingLink", e.target.value)}
                />
              </div>
            )} */}
          </section>
          {!state.meetingInYuukke && (
            <section>
              <label className="label-v2 mt-3 ml-2">
                {t("Add Your Meeting Link")} *
              </label>

              <input
                style={{ marginLeft: "0.5rem", height: "50px" }}
                type="text"
                name="meetingLink"
                placeholder="http://googlemeet.com/"
                value={state.meetingLink}
                onChange={(e) => changeMade("meetingLink", e.target.value)}
              />
            </section>
          )}
          {/* <section>
            <label className="label-v2 mt-3">{t("Service Image")} *</label>

            {state.editServiceLogo || state.serviceImage ? (
              <ImagePreview
                apiLogo={state.serviceApiLogo}
                file={
                  state.serviceApiLogo
                    ? state.editServiceLogo
                    : state.serviceImage
                }
                removeFiles={() => {
                  setState((previous) => ({
                    ...previous,
                    editServiceLogo: null,
                    serviceImage: null,
                  }));
                }}
              />
            ) : (
              <StepperFileOption
                onUploadSuccess={(file) => {
                  setState((previous) => ({
                    ...previous,
                    serviceImage: file[0],
                    serviceApiLogo: false,
                  }));
                }}
                acceptedType=".jpg,.jpeg,.png,.svg"
              />
            )}
          </section> */}
          <section>
            <CheckBox
              value={state.needCustomTheme}
              label={`Do you want custom theme ?`}
              onChange={(e) => changeMade("needCustomTheme", e)}
              questionMark={false}
            />
          </section>
          {state.needCustomTheme && (
            <section>
              <label className="label-v2 mt-3">
                {t("Choose Your Landing Page Theme")}
              </label>
              {/* <ColorPicker
                data={state.theme}
                onChange={(e) => updateState("theme", e)}
              /> */}
              <ColorPickerV2
                data={state.theme}
                onChange={changeColor}
                primary={state.primaryColor}
                secondary={state.secondaryColor}
                heading={state.headingColor}
                subHeading={state.subHeadingColor}
              />
            </section>
          )}

          <section>
            <label className="label-v2 mt-3">
              {t("Upload Your Business Logo Here")}
            </label>

            {state.editBusinessLogo || state.businessLogo ? (
              <ImagePreview
                apiLogo={state.apiLogo}
                file={
                  state.apiLogo ? state.editBusinessLogo : state.businessLogo
                }
                removeFiles={() => {
                  setState((previous) => ({
                    ...previous,
                    editBusinessLogo: null,
                    businessLogo: null,
                    changeMade: true,
                  }));
                }}
              />
            ) : (
              <StepperFileOption
                onUploadSuccess={(file) => {
                  setState((previous) => ({
                    ...previous,
                    businessLogo: file[0],
                    apiLogo: false,
                    changeMade: true,
                  }));
                }}
                acceptedType=".jpg,.jpeg,.png,.svg"
              />
            )}
          </section>
          <section>
            <label className="label-v2 mt-3">
              {t("Upload Your Gallery Images (You can add multiple images)")}
            </label>
            <StepperFileOption
              onUploadSuccess={(file) => {
                setState((previous) => ({
                  ...previous,
                  changeMade: true,
                  selectedGalleryImage: [
                    ...state.selectedGalleryImage,
                    ...file,
                  ],
                }));
              }}
              acceptedType=".jpg,.jpeg,.png,.svg"
            />{" "}
            {state.apiGalleryImages && (
              <div className="d-flex flex-wrap g-1">
                {state.apiGalleryImages &&
                  state.apiGalleryImages.map((item, i) => (
                    <ImagePreview
                      key={i}
                      apiLogo={true}
                      file={item}
                      removeFiles={(index) =>
                        removeApiGalleryImage(index, "apiImage")
                      }
                    />
                  ))}
                {state.selectedGalleryImage &&
                  state.selectedGalleryImage.map((item, i) => (
                    <ImagePreview
                      key={i}
                      apiLogo={false}
                      file={item}
                      removeFiles={(index) =>
                        removeApiGalleryImage(index, "selectedImage")
                      }
                    />
                  ))}
              </div>
            )}
          </section>
          <section>
            <label className="label-v2 mt-3">
              {t("Add Your Announcements Here (You can add multiple images)")}
            </label>
            <StepperFileOption
              onUploadSuccess={(file) => {
                setState((previous) => ({
                  ...previous,
                  changeMade: true,
                  selectedAnnounceMent: [
                    ...state.selectedAnnounceMent,
                    ...file,
                  ],
                }));
              }}
              acceptedType=".jpg,.jpeg,.png,.svg"
            />{" "}
            <div className="d-flex flex-wrap g-1">
              {state.apiAnnounceMentImages &&
                state.apiAnnounceMentImages.map((item, i) => (
                  <ImagePreview
                    key={i}
                    apiLogo={true}
                    file={item}
                    removeFiles={(index) =>
                      removeAnnounceMentImage(index, "apiImage")
                    }
                  />
                ))}
              {state.selectedAnnounceMent &&
                state.selectedAnnounceMent.map((item, i) => (
                  <ImagePreview
                    key={i}
                    apiLogo={false}
                    file={item}
                    removeFiles={(index) =>
                      removeAnnounceMentImage(index, "selectedImage")
                    }
                  />
                ))}
            </div>
          </section>
          <section className="h-100">
            <label className="label-v2" style={{ marginTop: "0.8rem " }}>
              {t("Upload Your Video (You can add multiple videos)")}
            </label>
            <ChooseFileComponent
              onUploadSuccess={onUploadSuccess}
              acceptedType=".mp4,.m4v"
            ></ChooseFileComponent>
            <div className="d-flex flex-wrap g-1">
              {state.apiServiceVideo &&
                state.apiServiceVideo.map((item, i) => (
                  <ImagePreview
                    key={i}
                    apiLogo={true}
                    file={item}
                    fileType={"video"}
                    removeFiles={(index) =>
                      removeServiceVideo(index, "apiVideo")
                    }
                  />
                ))}
              {state.selectedServiceVideo &&
                state.selectedServiceVideo.map((item, i) => (
                  <ImagePreview
                    key={i}
                    apiLogo={false}
                    file={item}
                    fileType={"video"}
                    removeFiles={(index) =>
                      removeServiceVideo(index, "selectedVideo")
                    }
                  />
                ))}
            </div>
          </section>

          <button className="w-100 font-weight-bold mt-3" type="submit">
            {t("Update your Service Profile")}
          </button>
        </form>
      </BlockUi>
    </>
  );
}

export default withTranslation()(CalendarPlans);
