import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import MyYuukke from "../../../api/MyYuukke";
import { useDispatch } from "react-redux";
import { getUserSlots } from "../../../redux/actions/calendarAction";
import Select from "react-select";
import { isValidUPI } from "../../../utils";

const BankDetails = ({ onBack, userSlots }) => {
  const [state, setState] = useState({
    bankId: userSlots?.accountNumber ?? "",
    options: [
      {
        label: "Account Number",
        value: "accountNumber",
      },
      {
        label: "PayPal Id",
        value: "paypalId",
      },
      {
        label: "UPI ID",
        value: "upiId",
      },
    ],
    type: {
      label: "Account Number",
      value: "accountNumber",
    },
    accountType: [
      {
        label: "Saving Account",
        value: "saving",
      },
      {
        label: "Current Account",
        value: "current",
      },
    ],
    accountValue: {
      label: "Current Account",
      value: "current",
    },
    panCard: userSlots?.panCard ?? "",
    ifseCode: userSlots?.ifseCode ?? "",
    branchName: userSlots?.branchName ?? "",
  });
  useEffect(() => {
    if (userSlots?.bankDetailsType) {
      const data = state.options.find(
        (res) => res.value === userSlots?.bankDetailsType
      );
      if (data) {
        setState((previous) => ({
          ...previous,
          type: data,
        }));
      }
    }

    if (userSlots?.accountType) {
      const data = state.accountType.find(
        (res) => res.value === userSlots?.accountType
      );
      if (data) {
        setState((previous) => ({
          ...previous,
          accountValue: data,
        }));
      }
    }
  }, [userSlots]);
  const dispatch = useDispatch();
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const updateDetails = (e) => {
    e.preventDefault();
    if (state.type.value === "upiId" && !isValidUPI(state.bankId)) {
      toast.error("Invalid UPI ID");
      return;
    } else {
      let data = {
        accountNumber: state.bankId,
        type: state.type.value,
        panCard: state.panCard,
      };
      data.ifseCode = state.ifseCode ?? "";

      data.accountType = state?.accountValue?.value ?? "";

      if (state.branchName) {
        data.branchName = state.branchName;
      }

      MyYuukke.updateDetails(data)
        .then((res) => {
          dispatch(getUserSlots());
          toast.success("Updated successfully");
          onBack("bank", true);
        })
        .catch((e) => {
          toast.error("Something went wrong !");
        });
    }
  };
  console.log("state", state);
  console.log("state", userSlots);

  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <h4
        className="p-3 mt-2 mb-2   heading-h4-v2"
        style={{ borderBottom: "1px solid #80808063" }}
      >
        <IoArrowBackCircleOutline
          onClick={() => onBack()}
          className="h-auto  mr-3 svg-icons"
          style={{
            width: "2rem",
          }}
        />
        Bank Account Details
      </h4>
      <form
        onSubmit={updateDetails}
        className="card askAQuestionInputGroup cal-grid  p-4"
      >
        <section>
          <label className="label-v2 mt-3">
            Select Your Payment Method Type *
          </label>
          <Select
            options={state.options}
            value={state.type}
            onChange={(e) => {
              setState({
                ...state,
                type: e,
                bankId: "",
                ifseCode: "",
              });
              // updateState("type", e);
              // updateState(
              //   "bankId",
              //   e.value === userSlots?.bankDetailsType &&
              //     userSlots?.accountNumber
              //     ? userSlots?.accountNumber
              //     : ""
              // );
            }}
            required
            className="z-1000"
          />
        </section>

        <section>
          <label className="label-v2 mt-3">
            Enter your {state.type.label} *
          </label>
          <input
            style={{ height: "50px" }}
            type="text"
            // type={state?.type?.value === "accountNumber" ? "number" : "text"}
            // type={userSlots?.timeZone === "Asia/Kolkata" ? "number" : "text"}
            name="bankId"
            placeholder=""
            value={state.bankId}
            onChange={(e) => updateState("bankId", e.target.value)}
            required
          />
        </section>
        {state?.type?.value === "accountNumber" && (
          <section>
            <label className="label-v2 mt-3">Account Type *</label>
            <Select
              options={state.accountType}
              value={state.accountValue}
              onChange={(e) => {
                updateState("accountValue", e);
              }}
              required
            />
          </section>
        )}
        {state?.type?.value === "accountNumber" && (
          <section>
            <label className="label-v2 mt-3">IFSC CODE *</label>
            <input
              style={{ height: "50px" }}
              type="text"
              name="IFSE CODE"
              placeholder=""
              value={state.ifseCode}
              onChange={(e) => updateState("ifseCode", e.target.value)}
              required
            />
          </section>
        )}
        {state?.type?.value === "accountNumber" && (
          <section>
            <label className="label-v2 mt-3">Branch Name *</label>
            <input
              style={{ height: "50px" }}
              type="text"
              name="IFSE CODE"
              placeholder=""
              value={state.branchName}
              onChange={(e) => updateState("branchName", e.target.value)}
              required
            />
          </section>
        )}

        <section>
          <label className="label-v2 mt-3">Pan Card *</label>
          <input
            style={{ height: "50px" }}
            type="text"
            name="panCard"
            placeholder=""
            value={state.panCard}
            onChange={(e) => updateState("panCard", e.target.value)}
            required
          />
        </section>

        <button className="  font-weight-bold mt-3" type="submit">
          Update Details
        </button>
      </form>
    </BlockUi>
  );
};

export default BankDetails;
