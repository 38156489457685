import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../config/app-config";
import dummyimage from "../../img/default_avatar.jpg";
import yuukke from "../../images/SocialMediaGif/yuukke-white.png";
import "../Community/calendar.css";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import {
  IoArrowBack,
  IoArrowBackCircleOutline,
  IoCalendarOutline,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaRupeeSign,
} from "react-icons/fa";
import { decryptData, generateMonthDates } from "../../utils";
import moment from "moment";
import MyYuukke from "../../api/MyYuukke";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { GoBriefcase } from "react-icons/go";
import PhoneNumberContainer from "../../components/PhoneNumberContainer";
import Swal from "sweetalert2";
import ProfileService from "../../api/ProfileService";
import { CgUnavailable } from "react-icons/cg";
import { FiCheckCircle, FiGrid } from "react-icons/fi";
import RazorPay from "../../components/RazorPay.jsx";
import AssessmentForm from "../Calender/ProfileCalendar/AssessmentForm.jsx";
import faceBook from "../../images/SocialMediaGif/Facebook.png";
import instagram from "../../images/SocialMediaGif/instagram.png";
import linkedIn from "../../images/SocialMediaGif/Linkedin.png";
import CalendarTabs from "../Forms/CalendarTabs.jsx";
import VcardChatBox from "../Calender/ProfileCalendar/VcardChatBox.jsx";
import ServiceUserForm from "../Calender/ProfileCalendar/ServiceUserForm.jsx";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../../chatPanel/src/config/config.js";

// let socialMedia=[
//   {name:"faceBook",image:faceBook},{name:"instagram",image:instagram},{name:"linkedIn",image:linkedIn}
//     ]

const CalendarVcard = () => {
  const [state, setState] = useState({
    bookingPage: false,
    slots: [],
    availableSlot: [],
    activeIndexData: null,
    selectedSlot: null,
    slotActiveIndex: null,
    loader: true,
    eventDetails: null,
    userSlots: [],
    userDetails: null,
    bookingCount: null,
    detailsPage: false,
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
    bio: "",
    type: "",
    singleDate: new Date(),
    bookedSlot: [],
    paymentPage: false,
    paymentId: null,
    dayId: null,
    serviceList: [],
    serviceLists: [],
    serviceId: null,
    serviceAmount: 0,
    minutes: 0,
    appointmentValue: "",
    assessmentForm: false,
    assessmentQuestions: [],
    assessmentNeed: false,
    assessmentAnswer: [],
    socialMedia: [],
    serviceDetails: null,
    language: [],
    clarityService: null,
    chatBoxOpen: false,
    serviceProviderUserId: null,
    serviceChatDetails: null,
    openServiceChatForm: false,
  });

  const isScrollingRef = useRef(false);
  const [showServiceDropdown, setShowServiceDropdown] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexSafe = Math.min(currentIndex, state.serviceList.length - 1);
  const socket = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  console.log(
    "Current Index:",
    currentIndex,
    "Service List Length:",
    state.serviceList.length
  );
  const [maxScroll, setMaxScroll] = useState(0);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const calendarData = query.get("id");
  const isMarketPlace = query.get("type");
  useEffect(() => {
    var main = document.getElementById("base");
    main.style.backgroundImage = `linear-gradient(205deg, #000940 0%, #a00030 100%)`;
    if (!calendarData) {
      invalid();
      return;
    }
    if (isMarketPlace === "market-place") {
      const serviceId = query.get("service");
      const marketPayload = [calendarData, "id", serviceId];
      console.log("market-place", marketPayload);

      getSlotDetails(marketPayload);
    } else {
      const dcrypt = decryptData(calendarData);
      const data = dcrypt.split("&");
      // console.log("dcrypt", data);
      if (data.length > 1) {
        getSlotDetails(data);
      } else {
        invalid();
      }
    }
  }, []);
  const invalid = () => {
    setState((previous) => ({
      ...previous,
      eventDetails: null,
      loader: false,
    }));
    toast.error("Invalid link !,please check link");
  };
  const getAvailableDateSlot = (date, availableSlot, bookedSlot) => {
    console.log("date", date);
    console.log("availableSlot", availableSlot);
    console.log("bookedSlot", bookedSlot);

    if (bookedSlot?.length === 0) {
      const allAvailable = availableSlot.map((item) => {
        item.slotAvailable = true;
        return item;
      });
      return allAvailable;
    }

    const convertToDate = (timeStr) => {
      const [hours, minutes] = timeStr.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      console.log("date", date);
      return date;
    };
    let filterCurrentDateSlots = availableSlot;
    if (date === moment(new Date()).format("YYYY/MM/DD")) {
      filterCurrentDateSlots = availableSlot.filter(
        (slot) => convertToDate(slot.from) >= new Date()
      );
      console.log("current date slot-update", filterCurrentDateSlots);
    }

    const findDateBasedSlot = bookedSlot
      .filter((item) => date === moment(item.startTime).format("YYYY/MM/DD"))
      .map((item) => {
        item.timeSlot = JSON.parse(item.timeSlot);
        item.timeSlot = item.timeSlot[0].from;
        return item.timeSlot;
      });

    console.log("findDateBasedSlot ", filterCurrentDateSlots);
    const filteredList = filterCurrentDateSlots.map((item) => {
      item.slotAvailable = true;
      if (findDateBasedSlot.includes(item.from)) {
        item.slotAvailable = false;
      }
      return item;
    });
    console.log("filterdList", filteredList);

    return filteredList ? filteredList : [];
  };

  const getSlotDetails = (data) => {
    console.log("data", data);
    MyYuukke.getSlotDetailsById(data[0])
      .then((response) => {
        let result = response.data.data.eventData;
        result.eventName = response.data.data.eventName;
        // result.meetingActiveDuration = response.data.data.meetingDuration;
        console.log("res", response);
        // const bio =
        //   "In my journey as a women entrepreneur, I have built Womoneysta, a women-first financial enablement platform. With a passion for financial wellness, I am dedicated to coaching women and families on improving their financial relationships and making better investment decisions through innovative tools and trackers.";

        if (result) {
          const userSlots = response.data.data.slots;

          const serviceList = response.data.data.service || [];

          // console.log(
          //   "Service Lists In Data:",
          //   JSON.stringify(serviceList, null, 2)
          // );

          // if (data.length === 3) {
          //   // Single day
          //   const availableSlot = userSlots.find(
          //     (item) => item.day === data[1]
          //   );

          //   console.log("availableSlot", availableSlot);
          //   let list = availableSlot?.timeSlots;
          //   // if (list?.length === 1) {
          //   //   list = generateTimeSlots(
          //   //     list[0].from,
          //   //     list[0].to,
          //   //     response.data.data.meetingDuration
          //   //   );
          //   // }
          //   console.log("list", list);
          //   const obj = {
          //     result: result,
          //     userSlots: userSlots,
          //     response: response.data.data,
          //     monthDates: [],
          //     // availableSlot: list ? list : [],
          //     activeIndexData: { value: data[2] },
          //     data: data,
          //     bio: bio,
          //     id: availableSlot?.id,
          //   };
          //   updateData(obj);
          // } else {
          // Weekly
          const getDayList = userSlots.reduce((result, item) => {
            if (item.timeSlots.length > 0) {
              result.push(item.day);
            }
            return result;
          }, []);
          const monthDates = generateMonthDates(getDayList);
          console.log("monthDates", monthDates);

          const indexOfDate = monthDates.findIndex(
            (item) => item.available === true
          );

          let activeIndexData = monthDates[indexOfDate];
          activeIndexData.index = indexOfDate;
          let getDaySlots = userSlots.find(
            (item) =>
              item.day.toLowerCase() === activeIndexData.day.toLowerCase()
          );
          // const updateTimes = userSlots.map((item) => {
          //   if (item.timeSlots.length === 1) {
          //     item.timeSlots = generateTimeSlots(
          //       item.timeSlots[0].from,
          //       item.timeSlots[0].to,
          //       response.data.data.meetingDuration
          //     );
          //   }
          //   return item;
          // });

          // console.log("userSlots updateTimes", updateTimes);
          console.log("getDaySlots", getDaySlots);
          const obj = {
            result: result,
            // userSlots: updateTimes,
            response: response.data.data,
            monthDates: monthDates,
            // availableSlot: getDaySlots ? getDaySlots.timeSlots : [],
            activeIndexData: activeIndexData,
            data: data,
            serviceLists: serviceList,
            // bio: result.description,
            id: getDaySlots ? getDaySlots.id : null,
            userSlots: userSlots,
          };
          updateData(obj);
          // }

          setState((prevState) => ({
            ...prevState,
            serviceLists: serviceList,
          }));

          // updateState("slots", monthDates);
        } else {
          toast.error("Slot not present , please check url");
          updateState("loader", false);
        }
      })
      .catch((e) => {
        toast.error("Slot not present , please check url");
        updateState("loader", false);
        console.log("Error", e);
      });
  };

  const updateData = (obj) => {
    const bookedSlotCopy = JSON.parse(JSON.stringify(obj.response.bookedSlot));
    console.log("obj", obj);
    // const list = getAvailableDateSlot(
    //   obj.activeIndexData.value,
    //   obj.availableSlot,
    //   obj.response.bookedSlot
    // );
    // console.log("list", list);
    let socialMedia = [];
    if (obj.response.userDetails.faceBook) {
      socialMedia.push({
        link: obj.response.userDetails.faceBook,
        icon: faceBook,
      });
    }
    if (obj.response.userDetails.instagram) {
      socialMedia.push({
        link: obj.response.userDetails.instagram,
        icon: instagram,
      });
    }
    if (obj.response.userDetails.linkedIn) {
      socialMedia.push({
        link: obj.response.userDetails.linkedIn,
        icon: linkedIn,
      });
    }
    const findClarityService =
      obj.response?.service &&
      obj.response.service.find((item) => item.type === "clarity");
    console.log("findClarityService", findClarityService);
    setState((previous) => ({
      ...previous,
      socialMedia: socialMedia,
      eventDetails: obj.result,
      loader: false,
      // userSlots: obj.userSlots,
      userSlots: obj.response.slots,
      userDetails: obj.response.userDetails,
      // bio: obj.response.userDetails.bio ?? obj.bio,
      slots: obj.monthDates,
      bookingCount: obj.response.bookingCount,
      // availableSlot:
      //   list?.length > 0 ? list : obj.availableSlot ? obj.availableSlot : [],
      activeIndexData: obj.activeIndexData,
      // type: obj.data.length === 3 ? "day" : "week",
      type: obj.data.length === 3 ? "Single Service" : "Multi Service",
      // singleDate: obj.data?.length === 3 ? obj.data[2] : null,
      bookedSlot: bookedSlotCopy,
      dayId: obj.id,
      serviceList: getService(obj),
      language: obj.response.language ?? [],
      clarityService: findClarityService,
      serviceProvider: {
        userId: obj.response.userDetails.userId,
        userName: obj.response.userDetails.userName,
      },
      serviceProviderUserId: obj.response.userDetails.userId,

      // serviceList: obj.response.service ?? [],
    }));
    socket.current = io(SOCKET_URL, {});

    let serviceChatEmail = localStorage.getItem("SERVICE-EMAIL");

    if (serviceChatEmail && obj.response.userDetails.userId) {
      let payload = {
        email: serviceChatEmail,
        userId: obj.response.userDetails.userId,
      };
      MyYuukke.getServiceChatDetails(payload)
        .then((response) => {
          console.log("response", response);
          if (response.data.message !== "fail") {
            updateState("serviceChatDetails", response.data.data);
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
    if (obj?.result?.fontFamily) {
      document.documentElement.style.setProperty(
        "--app-font",
        obj?.result?.fontFamily
      );
    }
  };

  useEffect(() => {
    const updateScrollState = () => {
      if (listRef.current) {
        setScrollPosition(listRef.current.scrollLeft);
        setMaxScroll(listRef.current.scrollWidth - listRef.current.clientWidth);
      }
    };

    if (listRef.current) {
      listRef.current.addEventListener("scroll", updateScrollState);
      updateScrollState(); // Initialize values
    }

    return () => {
      listRef.current?.removeEventListener("scroll", updateScrollState);
    };
  }, [state.serviceList]);

  useEffect(() => {
    if (state.serviceChatDetails && state.serviceChatDetails?.id) {
      let userInfo = {
        uId: state.serviceChatDetails?.id,
        dName: state.serviceChatDetails?.name,
        profile_uuid: "",
        email: state.serviceChatDetails?.email,
        active: true,
        userType: "guest",
      };
      console.log("userInfo", userInfo);

      socket.current.emit("serviceUser", userInfo);
    }
  }, [state.serviceChatDetails]);

  const getService = (obj) => {
    let filterList = [];
    if (!obj.response?.eventData?.demoSessionAvailable) {
      filterList = obj.response.service.filter(
        (item) => item.type !== "clarity"
      );
    } else {
      filterList = obj.response.service;
    }

    if (obj.data.length === 3 && filterList) {
      let list = filterList.find((item) => item.id === Number(obj.data[2]));
      console.log("filter data", list);
      let result = list ? [list] : filterList;
      return result;
    }
    return filterList ?? [];
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const listRef = useRef(null);

  const handleScroll = (direction) => {
    if (listRef.current) {
      const width = listRef.current.children[0].offsetWidth + 16;
      listRef.current.scrollBy({
        left: direction === "left" ? -width : width,
        behavior: "smooth",
      });
    }
  };

  const [cardWidth, setCardWidth] = useState(300); // Default card width

  useEffect(() => {
    if (listRef.current) {
      const firstCard = listRef.current.firstChild;
      if (firstCard) {
        setCardWidth(firstCard.offsetWidth);
      }
    }
  }, [state.serviceList]);

  useEffect(() => {
    const list = listRef.current;
    if (!list) return;

    const handleScroll = () => {
      const cardWidth = 300; // Width of each card
      const gap = 16; // Gap between cards
      const scrollLeft = list.scrollLeft;
      const newIndex = Math.round(scrollLeft / (cardWidth + gap));
      setCurrentIndex(Math.min(newIndex, state.serviceList.length - 1));
    };

    list.addEventListener("scroll", handleScroll);
    return () => list.removeEventListener("scroll", handleScroll);
  }, [state.serviceList.length]);

  const handleScrolling = (direction) => {
    const list = listRef.current;
    if (!list || isScrollingRef.current) return;

    const cardWidth = 300; // card width
    const gap = 16; // gap between cards

    let newIndex = currentIndex + direction;
    if (newIndex < 0) newIndex = 0;
    if (newIndex >= state.serviceList.length)
      newIndex = state.serviceList.length - 1;

    // Set flag to prevent additional updates during scroll animation
    isScrollingRef.current = true;

    // Update the index immediately if needed
    setCurrentIndex(newIndex);

    // Smooth scroll to the exact card position
    list.scrollTo({
      left: newIndex * (cardWidth + gap),
      behavior: "smooth",
    });

    // Reset the flag after the animation completes
    setTimeout(() => {
      isScrollingRef.current = false;
    }, 800);
  };

  const serviceType = state.selectedService === "clarity" ? "Demo" : "Service";

  const viewAvailableSlot = (index, data, available) => {
    if (available) {
      const findSlots = state.userSlots.find(
        (item) => item.day === data.day.toUpperCase()
      );
      console.log("findSlots", findSlots);
      console.log("data", data);
      const bookedSlotCopy = JSON.parse(JSON.stringify(state.bookedSlot));

      const list = getAvailableDateSlot(
        data.value,
        findSlots.timeSlots,
        bookedSlotCopy
      );
      console.log("list", list);
      data.index = index;
      if (findSlots) {
        setState((previous) => ({
          ...previous,
          availableSlot: list ? list : findSlots.timeSlots,
          activeIndexData: data,
          slotActiveIndex: null,
          selectedSlot: null,
          dayId: findSlots?.id,
        }));
      }
    }
  };
  const saveCalendar = (link, bookingId) => {
    const combinedDateTime = `${state.activeIndexData.value} ${state.selectedSlot.from}`;
    const startDate = moment(combinedDateTime).format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );
    const endDate = moment(combinedDateTime)
      .add(state.minutes ? state.minutes : 30, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    let data = {
      meetingInApplication: state.eventDetails.meetingInApplication,
      taskName:
        state.serviceDetails?.type === "clarity"
          ? `You have Clarity Meeting with ${state.name}`
          : `You have meeting with ${state.name}`,
      taskDescription: `Meeting booked by below time`,
      startDate: startDate,
      endDate: endDate,
      subjectId: 0,
      taskType: "slot_booking",
      startTime: "",
      endTime: "",
      color: "#A00030",
      moderatorList: [state.userDetails.userId],
      publicEvent: false,
      emailConfigure: false,
      meetingType: 0,
      url: link,
      slotBookingId: bookingId,
      userId: state.userDetails.userId,
      workSpaceType: state.eventDetails.workSpaceType,
      providerName: state.userDetails.userName,
      clientName: state.name,
      type: state.type || "Service",
      amount: state.serviceAmount,
      formatTime: moment(startDate)
        .locale("en")
        .format("MMMM Do YYYY, h:mm:ss a"),
      serviceName: state?.serviceDetails?.serviceName ??"Demo Service",

      email: state.email,
    };

    MyYuukke.saveCalendarSchedule(data)
      .then((response) => {
        console.log("resp", response);
        Swal.fire({
          icon: "success",
          title:
            "Thank you for scheduling your appointment. You will receive notifications on your email and phone shortly.",
          showConfirmButton: true,
        })
          .then((res) => {
            window.location.href = "https://yuukke.com/";
          })
          .catch((e) => {
            window.location.href = "https://yuukke.com/";
          });
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong !");
        updateState("loader", false);
      });
  };

  const uploadDetails = (e) => {
    e.preventDefault();
    if (!state.name || !state.phoneNumber) {
      toast.warning("Fill all required fields");
    } else {
      if (!state.serviceAmount) {
        saveBooking(null);
      } else {
        updateState("paymentPage", true);
      }
    }
  };
  const saveBooking = (id) => {
    updateState("loader", true);
    const combinedDateTime = `${state.activeIndexData.value} ${state.selectedSlot.from}`;
    const startDate = moment(combinedDateTime).format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );
    const endDate = moment(combinedDateTime)
      .add(state.minutes ? state.minutes : 30, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    let slot = [
      {
        from: state.selectedSlot.from,
        to: state.selectedSlot.to,
      },
    ];

    let data = {
      email: state.email,
      userName: state.name,
      mobileNo: state.phoneNumber,
      bio: state.description,
      timeSlot: slot,
      dateSlot: [state.activeIndexData.value],
      id: state.eventDetails.id,
      startTime: startDate,
      endTime: endDate,
      type: state.type || "Service",
      dayId: state.dayId,
      serviceId: state.serviceId,
      userId: state.userDetails?.userId,
    };

    if (id) {
      data.updatePayment = true;
      data.calendarUserId = state.eventDetails.userId;
      data.paymentId = id;
    }

    if (state.assessmentAnswer.length > 0) {
      data.selfAssessment = state.assessmentAnswer;
    }

    MyYuukke.saveUserBookingSlots(data)
      .then((response) => {
        if (response.data.message === "fail") {
          toast.error("Something went wrong ,please check url !");
        } else {
          let result = response.data.data.bookingslot;
          if (result.id) {
            if (state.eventDetails.meetingInApplication) {
              const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
                state.userDetails.userId
              }-${Date.now()}`;
              const meetingData = {
                meetingName: `${state.userDetails.userName} available meeting`,
                meetingDescription: `Schedule Meeting`,
                meetingLink: instantMeetingLink,
                meetingType: "Scheduled",
                meetingTime: new Date().toISOString(),
              };
              const formData = {
                senderName: state.userDetails.userName,
                senderId: state.userDetails.userId,
                receiverId: undefined,
                receiverName: undefined,
                receiverList: [state.userDetails.userId],
                message: {
                  meeting: meetingData,
                },
                isMeeting: true,
              };
              ProfileService.instantMeetingAction(formData)
                .then((response) => {
                  saveCalendar(instantMeetingLink, result.id);
                })
                .catch((error) => {
                  console.log(error);
                  toast.error("Saving Error");
                  updateState("loader", false);
                });
            } else {
              saveCalendar(state.eventDetails.meetingUrl, result.id);
            }
          }
        }
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Something went wrong !");
        updateState("loader", false);
      });
  };
  // const createMeeting = () => {
  //   if (!state.meetingTitle) {
  //     toast.warning("Enter meeting title");
  //   } else if (
  //     !urlPatternValidation(state.meetingLink) &&
  //     !state.meetingInYuukke
  //   ) {
  //     toast.warning("Enter valid url");
  //   } else if (state.meetingInYuukke) {
  //     updateState("btnLoader", true);
  //     const combinedDateTimeString = `${state.meetingDetails.dateSlots[0]} ${state.meetingDetails.timeSlots[0].from}`;
  //     const combinedDateTime = moment(
  //       combinedDateTimeString,
  //       "YYYY-MM-DD HH:mm:ss"
  //     );

  //     const instantMeetingLink = `Yuukke-${Math.random() * 100}-${
  //       loginDetails.uId
  //     }-${Date.now()}`;
  //     const meetingData = {
  //       meetingName: state.meetingTitle,
  //       meetingDescription: `Schedule Meeting with ${state.meetingDetails.userName}`,
  //       meetingLink: instantMeetingLink,
  //       meetingType: "Scheduled",
  //       meetingTime: combinedDateTime.toISOString(),
  //     };
  //     const formData = {
  //       senderName: loginDetails.dName,
  //       senderId: loginDetails.uId,
  //       receiverId: undefined,
  //       receiverName: undefined,
  //       receiverList: [loginDetails.uId],
  //       message: {
  //         meeting: meetingData,
  //       },
  //       isMeeting: true,
  //     };
  //     console.log("formData", formData);
  //     ProfileService.instantMeetingAction(formData)
  //       .then((response) => {
  //         var result = response.data.data;
  //         console.log("meetind details>>", result);
  //         saveCalendar(instantMeetingLink);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast.error("Saving Error");
  //       });
  //     updateState("btnLoader", false);
  //   } else {
  //     saveCalendar(state.meetingLink);
  //   }
  // };
  const [serviceError, setServiceError] = useState(false);

  const confirmDetails = () => {
    if (state.serviceDetails?.type === "clarity" && !state.selectedService) {
      toast.warning("Select service name");
      setServiceError(true);
      return;
    }
    setServiceError(false);
    if (!state.selectedSlot) {
      toast.warning("Please select time");
    } else if (state.assessmentNeed && state.assessmentQuestions?.length > 0) {
      updateState("assessmentForm", true);
    } else {
      updateState("detailsPage", true);
    }
  };

  const updatePaymentStatus = (status, id) => {
    if (status === "dismiss") {
      Swal.fire({
        icon: "error",
        title: "Payment updating failed!",
        text: "If your money debited in bank account ,please contact our team  +91 04446314646",
        showConfirmButton: true,
      }).then(() => {
        setState((previous) => ({
          ...previous,
          paymentPage: false,
        }));
      });
    } else {
      setState((previous) => ({
        ...previous,
        paymentPage: false,
        paymentId: id,
      }));
      saveBooking(id);
    }
  };
  console.log("Initial Clarity State : " + showServiceDropdown);

  const selectService = (data, serviceSelectOption) => {
    const findSlots = state.userSlots.find(
      (item) => item.day === state.activeIndexData.day.toUpperCase()
    );

    const bookedSlotCopy = JSON.parse(JSON.stringify(state.bookedSlot));
    const list = getAvailableDateSlot(
      state.activeIndexData.value,
      findSlots.timeSlots,
      bookedSlotCopy
    );

    setState((previous) => ({
      ...previous,
      bookingPage: true,
      serviceAmount: data.amount,
      serviceId: data.id,
      minutes: data.duration,
      availableSlot: list ?? [],
      appointmentValue: data.appointmentValue,
      assessmentNeed: data.assessmentNeed,
      assessmentAnswer: [],
      assessmentForm: false,
      serviceDetails: data,
      type: serviceType,
    }));

    if (serviceSelectOption === "clarity") {
      setShowServiceDropdown(true);
    } else {
      setShowServiceDropdown(false);
    }
    console.log("Service Select Option:", serviceSelectOption);
    console.log("Show Service Dropdown:", showServiceDropdown);

    if (serviceSelectOption === "clarity") {
      console.log("Inside Clarity");
      setShowServiceDropdown(true);
    }
  };

  console.log(" Clarity State : " + showServiceDropdown);

  const toCamelCase = (str) => {
    if (!str || typeof str !== "string") return ""; // Handle null, undefined, or non-string values

    return str
      .toLowerCase()
      .split(" ")
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" "); // Keep spaces between words
  };
  const viewChatList = () => {
    if (state.serviceChatDetails) {
      updateState("chatBoxOpen", true);
    } else {
      updateState("openServiceChatForm", true);
    }
  };

  const updateServiceFormDetails = (data) => {
    setState((previous) => ({
      ...previous,
      serviceChatDetails: data,
      chatBoxOpen: true,
      openServiceChatForm: false,
    }));
  };
  console.log("state", state);
  return (
    <div
      className="calender-vcard flex-wrap position-relative justify-content-center"
      style={{
        background:
          state.eventDetails?.customTheme && state.eventDetails?.theme
            ? state.eventDetails.theme
            : "",
      }}
    >
      {state.paymentPage && (
        <RazorPay
          email={state.email}
          name={state.name}
          userId={null}
          contactNumber={state.phoneNumber}
          subscriptionId={state.subscribeId}
          amount={state.serviceAmount}
          type={"slot_booking"}
          updatePaymentStatus={updatePaymentStatus}
        />
      )}
      {state.loader ? (
        <div className="d-flex justify-content-center p-5 align-items-center w-100">
          <div class="spinner-border text-white" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : state.bookingPage ? (
        <div className="p-3 d-flex g-1 flex-wrap w-100 flex-wrap justify-content-center calendar-wrapper-an">
          <section
            className="cal-small-box box-rd p-0"
            style={{
              maxWidth: window.matchMedia("(max-width:739px)").matches
                ? "auto"
                : "24rem",
              overflow: "hidden",
            }}
          >
            <div className="p-3" style={{ background: "#ffc0cbb0" }}>
              <span
                className="c-pointer"
                onClick={() => {
                  updateState("bookingPage", false);
                  setShowServiceDropdown(false);
                  setScrollPosition(0);
                  setCurrentIndex(0);
                }}
              >
                <IoArrowBack className="svg-icons ml-2" /> Back
              </span>
              <div className="d-flex flex-wrap align-items-center flex-column g-2 p-2 justify-content-center">
                <img
                  style={{ width: "8rem", height: "8rem" }}
                  className="rounded-circle"
                  src={
                    API_URL +
                    "api/getUserPhoto/" +
                    state.userDetails.userId +
                    "/" +
                    "name"
                  }
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                />
                <h5 className="font-weight-bold text-capitalize">
                  {state.userDetails.userName}
                </h5>
              </div>
            </div>

            <div
              className="d-flex align-items-center flex-column"
              style={{
                border: "1px solid gray",
                minWidth: window.matchMedia("(max-width:739px)").matches
                  ? "auto"
                  : "24rem",
              }}
            >
              <section className="d-flex align-items-center">
                <p
                  className="cal-small-box   f-1 d-flex justify-content-center justify-content-center"
                  style={{
                    borderRight: "1px solid gray",
                    borderRadius: 0,
                  }}
                >
                  <span className="gray-box-1">
                    <FaRupeeSign className="mr-1" />

                    {state.serviceAmount}
                  </span>
                </p>
                <p
                  className="f-1 text-align-center f-1 text-center"
                  style={{ width: "17rem" }}
                >
                  {" "}
                  <IoCalendarOutline className=" svg-icons mr-2" />
                  <span className="f-14 font-weight-bold">
                    {state.minutes} mins meeting
                  </span>
                </p>
              </section>

              <section
                className="p-3 w-100 text-center  "
                style={{ borderTop: "1px solid gray" }}
              >
                <h6 className=" font-weight-bold">TimeZone</h6>
                <span className="text-secondary f-14 ">
                  {state.eventDetails.timeZone}
                </span>
              </section>
              <section
                className="p-3 w-100 text-center"
                style={{ borderTop: "1px solid gray" }}
              >
                <h6 className=" font-weight-bold">
                  I'm good for below languages
                </h6>

                {state.language.map((item, i) => (
                  <span className="ml-2 f-14 text-secondary" key={i}>
                    {item.language} {state.language.length - 1 !== i && ","}
                  </span>
                ))}
              </section>

              <section
                className="p-3 w-100 text-center"
                style={{ borderTop: "1px solid gray" }}
              >
                <h6 className="font-weight-bold">Service Name</h6>
                <p className="text-secondary f-14">
                  {state.serviceDetails.serviceName}
                </p>

                {state.serviceDetails.description && (
                  <>
                    <h6 className="font-weight-bold">Description</h6>
                    <p className="text-secondary f-14">
                      {state.serviceDetails.description}
                    </p>
                  </>
                )}

                {/* {state.serviceDetails.appointmentValue && (
                  <div className="d-flex">
                    <h6
                      className="m-0 font-weight-bold text-start"
                      style={{ width: "8rem", flexShrink: 0 }}
                    >
                      Appointment :
                    </h6>
                    <p className="text-secondary f-14 ml-2">
                      {state.serviceDetails.appointmentValue}
                    </p>
                  </div>
                )} */}
              </section>
            </div>

            <p
              className="p-4 f-13 text-secondary text-justify"
              dangerouslySetInnerHTML={{
                __html: state.eventDetails?.description,
              }}
            />
          </section>

          <section
            className="cal-small-box box-rd"
            style={{
              width: window.matchMedia("(max-width:739px)").matches
                ? "21rem"
                : "41.8rem",
            }}
          >
            {state.detailsPage ? (
              <form
                onSubmit={uploadDetails}
                className="w-100 p-3 d-flex flex-column font-weight-bold"
                style={{ fontSize: "12px" }}
              >
                <h5 className="font-weight-bold d-flex">
                  Enter your details for further communication ..
                  <IoArrowBackCircleOutline
                    className="ml-auto c-pointer"
                    onClick={() => updateState("detailsPage", false)}
                  />
                </h5>
                <label className="mt-2">Name *</label>
                <input
                  style={{ caretColor: "black" }}
                  className="input-text-input text-dark"
                  value={state.name}
                  type="text"
                  placeholder="Enter name"
                  required={true}
                  onChange={(e) => updateState("name", e.target.value)}
                />
                <label className="mt-2">Email *</label>
                <input
                  style={{ caretColor: "black" }}
                  className="input-text-input text-dark"
                  value={state.email}
                  type="email"
                  placeholder="Enter email"
                  required={true}
                  onChange={(e) => updateState("email", e.target.value)}
                />
                <label className="mt-2">Phone Number *</label>
                <PhoneNumberContainer
                  value={state.phoneNumber}
                  updateData={(e) => updateState("phoneNumber", e)}
                  defaultClass="custom-phone-input"
                />

                <label className="mt-2">
                  Please share anything that will help prepare for our meeting
                </label>
                <textarea
                  style={{ caretColor: "black", minHeight: "5rem" }}
                  className="input-text-input text-dark p-2"
                  value={state.description}
                  type="text"
                  required={true}
                  onChange={(e) => updateState("description", e.target.value)}
                />
                <button className="mt-3 font-weight-bold">
                  Request Slot on{" "}
                  {moment(`2024-01-01T${state.selectedSlot.from}:00`)
                    // .add(
                    //   state.eventDetails.meetingActiveDuration
                    //     ? state.eventDetails.meetingActiveDuration
                    //     : 30,
                    //   "minutes"
                    // )
                    .locale("en")
                    .format("LT")}
                  ,{" "}
                  {moment(state.activeIndexData.value)
                    .locale("en")
                    .format("LL")}
                </button>
              </form>
            ) : (
              <>
                <ul className="l-none d-flex flex-wrap g-1">
                  <li
                    className="info-box text-white"
                    style={{ background: "#a00030" }}
                  >
                    <FiCheckCircle className="text-white" />
                    Selected Slot
                  </li>
                  <li className="info-box" style={{ background: "#5f9ea07a" }}>
                    <FiGrid />
                    Booked Slots
                  </li>
                  <li className="info-box" style={{ background: "gainsboro" }}>
                    <CgUnavailable />
                    Not Available
                  </li>
                </ul>

                <h6 className="font-weight-bold mt-4 mb-3">
                  {state.type === "day"
                    ? "Available date"
                    : "When should we meet?"}
                </h6>

                <div className="d-flex align-items-center">
                  <FaArrowAltCircleLeft
                    className="svg-icons p-1 mr-1"
                    style={{ flexShrink: 0 }}
                    onClick={() => handleScroll("left")}
                  />

                  <ul className="cal-scroll mb-4" ref={listRef}>
                    {state.slots.map((item, i) => (
                      <li
                        key={i}
                        className={`gray-box-1 flex-column bg-none`}
                        style={{
                          minWidth: "6rem",
                          background: !item.available
                            ? "gainsboro"
                            : i === state.activeIndexData?.index
                            ? "#a00030"
                            : "white",
                          color:
                            i === state.activeIndexData?.index
                              ? "white"
                              : "black",
                          cursor: !item.available ? "not-allowed" : "pointer",
                          border:
                            i === state.activeIndexData?.index
                              ? "none"
                              : "1px solid gray",
                        }}
                        onClick={() =>
                          viewAvailableSlot(i, item, item.available)
                        }
                      >
                        <p className="font-weight-bold f-12">{item.day}</p>
                        <p
                          className="f-12"
                          style={{
                            color:
                              i === state.activeIndexData?.index
                                ? "white"
                                : "gray",
                          }}
                        >
                          {item.date}
                        </p>
                      </li>
                    ))}
                  </ul>

                  <FaArrowAltCircleRight
                    className="svg-icons p-1 ml-1"
                    style={{ flexShrink: 0 }}
                    onClick={() => handleScroll("right")}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="font-weight-bold mt-4 mb-3">
                    Select time of day
                  </h6>

                  {showServiceDropdown && (
                    <div className="w-50">
                      <select
                        className="form-control"
                        value={state.selectedService}
                        onChange={(e) => {
                          const selectedService = e.target.value;
                          const selectedServiceObj = state.serviceLists.find(
                            (service) => service.serviceName === selectedService
                          );

                          setState((prevState) => ({
                            ...prevState,
                            selectedService,
                            serviceId: selectedServiceObj
                              ? selectedServiceObj.id
                              : null,
                            type: "demo",
                          }));
                          setServiceError(false); // Remove error when a valid selection is made
                        }}
                      >
                        <option value="">Select a service</option>
                        {state.serviceLists?.map((service, index) => (
                          <option key={index} value={service.serviceName}>
                            {service.serviceName}
                          </option>
                        ))}
                      </select>

                      {/* Show error message when no service is selected */}
                      {serviceError && (
                        <p className="text-danger mt-1">
                          Please select a service
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <ul
                  className="d-flex p-0 g-1 flex-wrap"
                  style={{ marginLeft: "1.7rem", marginRight: "1.7rem" }}
                >
                  {state.availableSlot.map((item, i) => (
                    <li
                      key={i}
                      className="gray-box-1 flex-column bg-none"
                      style={{
                        minWidth: "6rem",
                        background: !item.slotAvailable
                          ? "#5f9ea07a"
                          : i === state.slotActiveIndex
                          ? "#a00030"
                          : "white",
                        color: i === state.slotActiveIndex ? "white" : "black",
                        border: !item.slotAvailable
                          ? "none"
                          : i === state.slotActiveIndex
                          ? "none"
                          : "1px solid gray",
                        cursor: !item.slotAvailable ? "not-allowed" : "pointer",
                      }}
                      onClick={() => {
                        if (item.slotAvailable) {
                          setState((previous) => ({
                            ...previous,
                            slotActiveIndex: i,
                            selectedSlot: item,
                          }));
                        }
                      }}
                    >
                      <p className="font-weight-bold f-12">
                        {moment(`2024-01-01T${item.from}:00`)
                          .locale("en")
                          .format("LT")}{" "}
                        to{" "}
                        {moment(`2024-01-01T${item.to}:00`)
                          .locale("en")
                          .format("LT")}
                      </p>
                    </li>
                  ))}
                  {state.availableSlot.length === 0 && (
                    <p>No slots available!</p>
                  )}
                </ul>

                <button className="w-100" onClick={() => confirmDetails()}>
                  Confirm Details
                </button>
              </>
            )}
          </section>
          {state.assessmentForm && (
            <AssessmentForm
              questions={state.assessmentQuestions}
              updateAssessment={(data) => {
                setState((previous) => ({
                  ...previous,
                  assessmentAnswer: data,
                  assessmentForm: false,
                  assessmentNeed: false,
                  detailsPage: true,
                }));
              }}
            />
          )}
        </div>
      ) : !state.bookingPage && state.eventDetails && state.userDetails ? (
        <div className="login-options-opt flex-row  m-5 justify-content-center align-items-start flex-wrap">
          <section

          // style={{
          //   minWidth: window.matchMedia("(max-width:739px)").matches
          //     ? "auto"
          //     : "30rem",
          //   gap: "0",
          // }}
          >
            <div
              className="d-flex align-items-center"
              style={{ minHeight: "75px" }}
            >
              <img
                src={
                  state.eventDetails?.businessLogo
                    ? `${API_URL}auth/image/${state.eventDetails?.businessLogo}`
                    : dummyimage
                }
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
                className="calendar-logo mr-3"
              />
              {/* <h4 className="heading-h4-v2" style={{ maxWidth: "30rem" }}> */}
              <h4
                style={{
                  fontWeight: "600",
                  // maxWidth: "30rem",
                  minWidth: window.matchMedia("(max-width:739px)").matches
                    ? "auto"
                    : "30rem",
                  textTransform: "capitalize",
                }}
                className="three-line"
              >
                {toCamelCase(state.eventDetails?.serviceName ?? "")}
                {/* {state.eventDetails?.serviceName} */}
              </h4>
            </div>
            <div className="login-options-opt mt-0">
              <div
                className="position-relative d-flex flex-column justify-content-centre align-items-center"
                style={{
                  minWidth: window.matchMedia("(max-width:739px)").matches
                    ? "auto"
                    : "30rem",
                  gap: "0",
                }}
              >
                <div style={{ width: "7rem", height: "7rem" }}>
                  <img
                    style={{ borderRadius: "10px" }}
                    src={
                      state.eventDetails?.showProfileImage
                        ? API_URL +
                          "api/getUserPhoto/" +
                          state.userDetails.userId +
                          "/" +
                          "name"
                        : yuukke
                    }
                    alt="user"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = dummyimage;
                    }}
                  />
                </div>

                {/* <a
              className="calender-shadow-box font-weight-bold"
              href="https://yuukke.com/"
            >
              <img
                src={
                  state.eventDetails?.businessLogo
                    ? `${API_URL}auth/image/${state.eventDetails?.businessLogo}`
                    : yuukke
                }
                style={{
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = yuukke;
                }}
              />
              <p>Yuukke</p>
            </a> */}
                <div className="d-flex flex-column p-3 w-100 justify-content-centre align-items-center">
                  <h3 className="font-weight-bold mt-1 text-white text-capitalize">
                    {state.userDetails.userName}
                  </h3>
                  <ul className="d-flex g-1 flex-wrap l-none justify-content-centre">
                    {state.socialMedia.map((item, i) => (
                      <li
                        className="mt-2"
                        style={{
                          height: "2rem",
                          width: "2rem",
                        }}
                        key={i}
                      >
                        <a href={item.link}>
                          <img
                            src={item.icon}
                            className="h-100 w-100"
                            alt="img"
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                  <p className="f-14 text-white align-center font-weight-bold">
                    <GoBriefcase className="mr-2 svg-icons text-white  " />
                    {state.userDetails?.profession?.professionName}
                  </p>
                  {state.bookingCount > 2 &&
                    state.eventDetails?.showBookingCount &&
                    state.bookingCount >=
                      state.eventDetails?.minBookingCount && (
                      <div className="cal-small-box text-dark">
                        <p className="font-weight-bold">{state.bookingCount}</p>
                        <p>{state.bookingCount > 2 ? "Bookings" : "Booking"}</p>
                      </div>
                    )}
                </div>
                {state.eventDetails && (
                  <CalendarTabs
                    eventDetails={state.eventDetails}
                    contact={state.userDetails}
                    clarityService={state.clarityService}
                    viewClarityService={(data) =>
                      selectService(data, "clarity")
                    } // Pass "clarity" as serviceSelectOption
                  />
                )}
                {/* <div style={{ textAlign: "start" }}>
                  <h4 className=" mt-5 mb-2  heading-h4-v2 mr-auto">
                    Service Details
                  </h4>
                  <p
                    className="f-14 text-white text-justify"
                    dangerouslySetInnerHTML={{
                      __html: state.eventDetails?.description,
                    }}
                  />
                </div> */}
              </div>
              <p className="f-14 align-center font-weight-bold  mt-auto text-white ml-3">
                Powered by{" "}
                <a
                  href="https://yuukke.com/"
                  className="text-white  ml-1"
                  style={{ textDecoration: "underline" }}
                >
                  Yuukke
                </a>
              </p>
            </div>
          </section>
          <section
            className="d-flex flex-column p-3 position-relative"
            style={{
              flex: 1,
              maxWidth: "400px",
              width: "90%",
              margin: "4rem auto 0 auto",
              paddingTop: "5rem",
              position: "relative",
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4
                className="m-0"
                style={{ fontWeight: "600", textAlign: "start" }}
              >
                Book Your Services
              </h4>
            </div>

            {/* Service List Container */}
            <div style={{ position: "relative" }}>
              <ul
                className="l-none booking-grid"
                ref={listRef}
                style={{
                  display: "flex",
                  overflowX: "auto",
                  flexWrap: "nowrap",
                  gap: "1rem",
                  paddingBottom: "0.5rem",
                  color: "black",
                  scrollBehavior: "smooth",
                  scrollbarWidth: "none",
                  scrollSnapType: "x mandatory",
                }}
              >
                {state.serviceList.map((item, i) => (
                  <li
                    onClick={() => selectService(item)}
                    key={i}
                    className="w-100 h-100 cal-small-box box-rd text-capitalize d-flex flex-column"
                    style={{
                      minWidth: "300px",
                      flexShrink: 0,
                      cursor: "pointer",
                      transition: "transform 0.2s",
                      scrollSnapAlign: "center",
                    }}
                  >
                    <h4
                      className="m-0 three-line cal-heading"
                      style={{
                        fontWeight: "600",
                        textTransform: "capitalize",
                        maxWidth: "21rem",
                      }}
                    >
                      {toCamelCase(item.serviceName ?? "")}
                    </h4>
                    <h6 className="text-secondary cal-sub-heading mt-2">
                      {item.appointmentValue}
                    </h6>
                    <section className="gray-box mt-auto">
                      <div className="d-flex align-items-center justify-content-center">
                        <IoCalendarOutline className="text-secondary svg-icons" />
                        <p className="d-flex flex-column ml-2">
                          <span className="cal-sub-heading font-weight-bold">
                            {item.duration} mins
                          </span>
                          <span className="cal-sub-heading text-secondary">
                            Video Meeting
                          </span>
                        </p>
                      </div>
                      <span className="gray-box font-weight-bold p-2">
                        <FaRupeeSign className="mr-1" />
                        {item.amount}
                      </span>
                    </section>
                  </li>
                ))}
                {state.serviceList.length === 0 && (
                  <div className="text-muted">No services found</div>
                )}
              </ul>

              {/* Scroll Left Button - shown only if there is content to the left */}
              {state.serviceList.length > 1 && currentIndex > 0 && (
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    left: "-25px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={() => handleScrolling(-1)}
                >
                  <MdNavigateBefore
                    style={{
                      color: "white",
                      fontSize: "32px",
                    }}
                  />
                </button>
              )}

              {/* Scroll Right Button - shown only if there is content to the right */}
              {state.serviceList.length > 1 &&
                currentIndex < state.serviceList.length - 1 && (
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      padding: "0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      right: "-25px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={() => handleScrolling(1)}
                  >
                    <MdNavigateNext
                      style={{
                        color: "white",
                        fontSize: "32px",
                      }}
                    />
                  </button>
                )}
            </div>

            {/* Counter Display */}
            {state.serviceList.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginTop: "1.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    color: "#333",
                  }}
                >
                  <span
                    style={{
                      padding: "0.25rem 0.5rem",
                      backgroundColor: "rgb(113 21 21)",
                      color: "white",
                      borderRadius: "12px",
                      minWidth: "30px",
                      textAlign: "center",
                    }}
                  >
                    {currentIndexSafe + 1}
                  </span>
                  <span style={{ color: "#666" }}>/</span>
                  <span style={{ color: "white" }}>
                    {state.serviceList.length}
                  </span>
                </div>
              </div>
            )}
          </section>
          {/* <div className="cal-small-box box-rd text-capitalize">
              <h4 style={{ fontWeight: "600" }}>
                Book call with {state.userDetails.userName}
              </h4>
              <p
                className="mt-3 text-secondary mb-5"
                style={{ fontWeight: "300" }}
              >
                Discovery call to understand your financial pain points
              </p>
                 <section
                  className="gray-box"
                  onClick={() => updateState("bookingPage", true)}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <IoCalendarOutline className="text-secondary svg-icons" />
                    <p className="d-flex flex-column ml-2">
                      <span className="f-14 font-weight-bold">
                        {state.eventDetails?.meetingActiveDuration} mins
                      </span>
                      <span className="f-12 text-secondary">Video Meeting</span>
                    </p>
                  </div>
                  <span className="gray-box font-weight-bold p-2">
                    View <FaArrowRight className="ml-2" />
                  </span>
                </section>
            
            </div> */}

          {/* <h4 className=" mt-5 mb-2  heading-h4-v2 mr-auto">
              Service Details
            </h4>
            <p
              className="f-14 text-white text-justify"
              dangerouslySetInnerHTML={{
                __html: state.eventDetails?.description,
              }}
            /> */}
          {/* </section> */}
        </div>
      ) : (
        <div className="text-white text-center w-100 mt-5 font-bold">
          Something went wrong , please visit{" "}
          <p className="text-secondary">Yuukke</p>
        </div>
      )}
      {state.serviceChatDetails && state.serviceProviderUserId && (
        <div
          className="service-chat-btn c-pointer"
          onClick={() => viewChatList()}
        >
          <IoChatboxEllipsesOutline className="svg-icons" /> Chat
        </div>
      )}
      {state.openServiceChatForm && (
        <ServiceUserForm
          serviceProviderUserId={state.serviceProviderUserId}
          // updateDetails={(data) => updateState("serviceChatDetails", data)}
          updateDetails={updateServiceFormDetails}
          closeModel={() => updateState("openServiceChatForm", false)}
        />
      )}
      {state.chatBoxOpen &&
        state.serviceChatDetails &&
        state.serviceProviderUserId && (
          <VcardChatBox
            socket={socket}
            serviceProvider={state.serviceProvider}
            handleChatListBoxClose={() => updateState("chatBoxOpen", false)}
            serviceUser={state.serviceChatDetails}
          />
        )}
    </div>
  );
};

export default CalendarVcard;
