import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../config/app-config";
import useRazorpay from "react-razorpay";
import MyYuukke from "../api/MyYuukke";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ProfileService from "../api/ProfileService";

const Payment = ({
  email = "notgiven@gmail.com",
  contactNumber = "",
  name = "Other User",
  userId = null,
  amount = 100,
  type = "un_known_premium",
  subscriptionId = null,
  updatePaymentStatus,
  initId = null,
}) => {
  //   const [amount, setAmount] = useState("");
  const [Razorpay] = useRazorpay();

  // useEffect(() => {
  //   const paymentDetails = async () => {
  //     const orderResponse = await axios.get(
  //       `${API_URL}auth/payment/get_payment_details/${"pay_OXb5HSRo5n3RT4"}`
  //     );
  //     console.log("order data", orderResponse.data);
  //   };
  //   paymentDetails();
  // }, []);
  const updateErrorStatus = (e, message) => {
    console.log("e", e);
    let orderId = sessionStorage.getItem("order_id");
    console.log("orderId", orderId);

    if (orderId) {
      let data = {
        amount: amount,
        orderId: orderId,
        type: type,
        subscriptionId: subscriptionId === null ? null : subscriptionId,
        email: email,
        status: e,
        errorStatus: message,
      };

      MyYuukke.updateErrorStatus(data)
        .then((res) => {
          console.log("response", res);
        })
        .catch((e) => {});
      sessionStorage.removeItem("order_id");
    }
  };
  const savePaymentInforMation = (response) => {
    let data = {
      paymentId: response.razorpay_payment_id,
      amount: amount,
      razorpaySignature: response.razorpay_signature,
      orderId: response.razorpay_order_id,
      type: type,
      status: "success",
      subscriptionId: subscriptionId === null ? null : subscriptionId,
      email: email,
    };

    if (userId) {
      data.userId = userId;
      MyYuukke.savePaymentInforMation(data)
        .then((res) => {
          console.log("response", res);
          updatePaymentStatus("success", res.data?.data?.id);
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Payment updating failed!",
            text: "If your money debited in bank account ,please contact our team  +91 04446314646",
            showConfirmButton: true,
          });
          updatePaymentStatus("dismiss");
          updateErrorStatus("error", "API-ERROR");
        });
    } else {
      MyYuukke.savePaymentInforMationWithoutAuth(data)
        .then((res) => {
          console.log("response", res);
          updatePaymentStatus("success", res.data?.data?.id);
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Payment updating failed!",
            text: "If your money debited in bank account ,please contact our team  +91 04446314646",
            showConfirmButton: true,
          });
          updatePaymentStatus("dismiss");
          updateErrorStatus("error", "API-ERROR");
        });
    }
  };
  const handlePayment = async () => {
    try {
      // const orderResponse = await axios.get(
      //   `${API_URL}auth/payment/${amount * 100}`
      // );
      let data = {
        emailId: email,
        userId: userId,
        amount: amount * 100,
        realAmount: amount,
        type: type,
      };
      if (initId) {
        data.initId = initId;
      }
      if (subscriptionId) {
        data.subscriptionId = subscriptionId;
      }

      ProfileService.getOrderInIt(data)
        .then((orderResponse) => {
          const result = orderResponse.data.data;
          console.log("result", result);
          if (orderResponse.data.message === "success") {
            const { id: order_id, currency } = result;
            sessionStorage.setItem("order_id", order_id);
            console.log("orderResponse", orderResponse.data);
            const key = "rzp_test_Gnu8neTnUU656M"; //test
            // const key = "rzp_live_lclCyKLWqjYCIJ";
            const options = {
              key: key,
              amount: (amount * 100).toString(),
              currency: currency,
              name: "Yuukke Global Venture",
              description: "Transaction in yuukke",
              image:
                "https://corporate.yuukke.com/bcav2/auth/image/be6f008b-2fb9-4027-9634-642b614c3607",
              order_id: order_id,
              handler: function (response) {
                console.log("response", response);
                savePaymentInforMation(response);
                // console.log("response order_id", order_id);
                // console.log("response", response.razorpay_payment_id);
              },
              prefill: {
                name: name,
                email: email,
                contact: contactNumber,
              },
              notes: {
                address: "Yuukke Global Venture",
              },
              theme: {
                color: "#a00030",
              },
              modal: {
                ondismiss: function (e) {
                  // Handle modal close here
                  console.log("User closed the Razorpay modal", e);
                  updateErrorStatus("error", "USER-CLOSED-THE-PAGE");
                  updatePaymentStatus("dismiss");
                  // Perform any actions you need when the modal is closed
                },
              },
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              // updatePaymentStatus("error");
              console.log("error-response", response.error);
              updateErrorStatus("error", "PAYMENT-FAILED-IN-RAZORPAY");
            });
            // rzp1.on("payment.dismiss", function () {
            //   updatePaymentStatus("dismiss");
            //   console.log("User closed the Razorpay modal");
            // });

            rzp1.open();
          } else {
            throw new Error(orderResponse.data);
          }
        })
        .catch((e) => {
          toast.error("Something went wrong,please try latter ");
        });
    } catch (e) {
      toast.error("Something went wrong,please try latter!");
      console.log("payment-method error", e);
    }
  };

  useEffect(() => {
    // if (openPayment) {
    handlePayment();

    // }
  }, []);
  return (
    <>
      {/* <input
        type="text"
        placeholder="Enter Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <button onClick={handlePayment}>Pay Now</button> */}
    </>
  );
};

export default Payment;
