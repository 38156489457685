import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  IconButton,
  useTheme,
  styled,
} from "@material-ui/core";
import { FaArrowUp } from "react-icons/fa";
import FooterV2 from "../../components/FooterV2";
import food from "../../images/icons/fast-food.png";
import digital from "../../images/icons/digital-transformation.png";
import industry from "../../images/icons/industrial-park.png";
import place from "../../images/icons/place.png";
import school from "../../images/icons/school.png";
import shield from "../../images/icons/shield.png";
import stats from "../../images/icons/stats.png";
import videocam from "../../images/icons/videocamera.png";
import world from "../../images/icons/world.png";
import customer from "../../images/icons/customer-review.png";

const fadeInKeyframes = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const Section = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
}));

const NavDot = styled(Box)(({ active }) => ({
  width: 12,
  height: 12,
  borderRadius: "50%",
  backgroundColor: active ? "#1976d2" : "#ccc",
  margin: "8px 0",
  cursor: "pointer",
  transition: "all 0.3s ease",
}));

const LocationCard = styled(Card)(({ theme }) => ({
  height: "100%",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-8px)",
  },
}));

const WorkSpaceLandingPage = () => {
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const sections = ["hero", "about", "locations", "values"];

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
    const windowHeight = window.innerHeight;
    const index = Math.floor(window.scrollY / windowHeight);
    setActiveSection(index);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getHeroOpacity = () => {
    return Math.max(0, Math.min(1, 1 - scrollPosition / 300));
  };

  const locations = [
    {
      name: "New York",
      address: "123 Broadway, NY 10001",
      image: "https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9",
    },
    {
      name: "London",
      address: "456 Oxford St, London W1C",
      image: "https://images.unsplash.com/photo-1513635269975-59663e0ac1ad",
    },
    {
      name: "Tokyo",
      address: "789 Shibuya, Tokyo 150-0002",
      image: "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf",
    },
  ];
  const countries = [
    { flag: "🇮🇳", name: "India" },
    { flag: "🇱🇰", name: "Sri Lanka" },
    { flag: "🇳🇵", name: "Nepal" },
    { flag: "🇵🇰", name: "Pakistan" },
    { flag: "🇦🇪", name: "UAE" },
    { flag: "🇯🇴", name: "Jordan" },
    { flag: "🇱🇧", name: "Lebanon" },
    { flag: "🇳🇬", name: "Nigeria" },
    { flag: "🇰🇪", name: "Kenya" },
    { flag: "🇮🇪", name: "Ireland" },
    { flag: "🏴", name: "Scotland" },
    { flag: "🇯🇵", name: "Japan" },
    { flag: "🇩🇪", name: "Germany" },
    { flag: "🇵🇭", name: "Philippines" },
    { flag: "🇲🇾", name: "Malaysia" },
    { flag: "🇸🇬", name: "Singapore" },
    { flag: "🇻🇳", name: "Vietnam" },
    { flag: "🇫🇯", name: "Fiji" },
    { flag: "🇦🇺", name: "Australia" },
    { flag: "🇳🇿", name: "New Zealand" },
    { flag: "🇹🇳", name: "Tunisia" },
    { flag: "🇲🇹", name: "Malta" },
    { flag: "🇱🇺", name: "Luxembourg" },
    { flag: "🇪🇪", name: "Estonia" },
  ];

  const values = [
    {
      title: "Empowerment",
      description:
        "Belief in women's potential and commitment to providing resources for their success.",
    },
    {
      title: "Community",
      description:
        "Creating a sense of belonging, Collaboration, Support and shared purpose.",
    },
    {
      title: "Inclusivity",
      description:
        "Commitment to diversity and creating a welcoming space for all women, regardless of background.",
    },

    {
      title: "Courage",
      description:
        "Encouragement of women to step outside their comfort zones, take risks, and embrace challenges as opportunities for learning and development.",
    },
    {
      title: "Impact",
      description:
        "Focus on creating meaningful change, not just for individual women, but also for their families, communities, and society as a whole.",
    },
  ];

  const focusedGroups = [
    {
      title: "SheImpact",
      icon: shield,
    },
    {
      title: "Digital Transformation",
      icon: digital,
    },
    {
      title: "SheSafe",
      icon: world,
    },
    {
      title: "Vocal For Local",
      icon: place,
    },
    {
      title: "She Wellness",
      icon: school,
    },
    {
      title: "Food & Beverage",
      icon: food,
    },
    {
      title: "Business Growth",
      icon: customer,
    },
    {
      title: "Manufacturing",
      icon: industry,
    },
    {
      title: "Capacity Building",
      icon: stats,
    },
    {
      title: "Fashion Entertainment & Media",
      icon: videocam,
    },
  ];
  const scrollToSection = (index) => {
    const element = document.getElementById(sections[index]);
    element?.scrollIntoView({ behavior: "smooth" });
    setActiveSection(index);
  };

  return (
    <>
      <Box sx={{ position: "relative", background: "white" }}>
        <style>{fadeInKeyframes}</style>

        <Box
          sx={{
            position: "fixed",
            right: 20,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1000,
          }}
        >
          {sections.map((_, index) => (
            <NavDot
              key={index}
              active={activeSection === index}
              onClick={() => scrollToSection(index)}
            />
          ))}
        </Box>

        <Box
          id="hero"
          sx={{
            // opacity: getHeroOpacity(),
            transition: "opacity 0.3s ease",
            animation: "fadeIn 1s ease-in",
          }}
        >
          <Section
            sx={{
              background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(https://corporate.yuukke.com/bcav2/auth/image/4e9fc828-aba4-4c86-aa13-c71d035d4a51)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
            }}
          >
            <Container>
              <Typography
                variant="h1"
                component="h1"
                gutterBottom
                style={{ color: "white", fontWeight: "bold" }}
                className="mobil-text"
              >
                She Ignites Business Council
              </Typography>
              <Typography variant="h4" gutterBottom className="mobil-text">
                Every Women equipped,confident, and celebrated
              </Typography>
              <Button
                variant="contained"
                style={{ background: "white", marginTop: "1rem" }}
                size="large"
                sx={{ mt: 4 }}
              >
                Get Started
              </Button>
            </Container>
          </Section>
        </Box>

        <Section id="about">
          <Container>
            <Grid
              container
              spacing={4}
              alignItems="center"
              className="p-4 mb-4"
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h2" gutterBottom>
                  About Us
                </Typography>
                <Typography variant="body1" paragraph>
                  A Global Business Support Marketplace dedicated to Empower
                  every ambitions women everyware.
                </Typography>
                <Typography variant="body1">
                  Our vision is to leverage technology by connecting half of the
                  world , to break down barriers by giving women a safe digital
                  space where everything what she needs to grow and thrive is
                  easily accessible.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src="https://corporate.yuukke.com/bcav2/auth/image/0f880f7e-090e-48d6-8e05-a3ae6cd5953e"
                  alt="Team working"
                  sx={{ width: "100%", borderRadius: 2 }}
                />
              </Grid>
            </Grid>
            <div
              className="work-loc-wrap p-4"
              style={{
                borderRadius: "10rem",
              }}
            >
              <h5 className="workspace-heading mb-5">Our Global Presence</h5>

              <ul className="work-country-list p-0">
                {window.matchMedia("(max-width:739px)").matches ? (
                  <>
                    {countries.slice(0, 5).map((country, index) => (
                      <li key={index}>
                        {country.flag}{" "}
                        <span className="ml-1"> {country.name}</span>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    {countries.map((country, index) => (
                      <li key={index}>
                        {country.flag}{" "}
                        <span className="ml-1"> {country.name}</span>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </Container>
        </Section>

        <Section
          id="locations"
          // className="work-loc-wrap"
          // style={{
          //   borderRadius: "10rem",
          // }}
        >
          <Container>
            <h5 className="workspace-heading mb-5">Focused Groups</h5>

            <div className="d-flex align-items-center justify-content-center mt-5">
              <ul
                className="d-flex flex-column l-none mt-5 mb-5"
                style={{ gap: "4rem" }}
              >
                {focusedGroups.map((item, i) => (
                  <li
                    key={i}
                    className="d-flex align-items-center justify-content-center position-relative workspace-f-group"
                  >
                    <div
                      className={`mt-3 workspace-info ${
                        i % 2 ? "right" : "left"
                      }`}
                      // style={{
                      //   marginLeft: i % 2 ? "16rem" : "0",
                      //   marginRight: i % 2 ? "0" : "16rem",
                      // }}
                    >
                      <img
                        src={item.icon}
                        style={{ height: "3rem" }}
                        className="w-auto"
                        alt=""
                      />
                      <p>{item.title}</p>
                    </div>
                    <section>{i + 1}</section>
                    {focusedGroups.length - 1 !== i && (
                      <div
                        style={{
                          position: "absolute",
                          top: "calc(70% + 20px)",
                          // left: "calc(24% - 1px)",
                          // height: "calc(100% - 25px)",
                          height: "100%",
                          borderLeft: "3px solid #a00030",
                          zIndex: 0,
                        }}
                      ></div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </Container>
        </Section>

        <Section
          id="values"
          style={{
            background: "#dcdcdc7d",
            marginBottom: "-3rem",
            marginTop: "2rem",
          }}
        >
          <Container>
            <h5 className="workspace-heading mb-5">Our Core Values</h5>
            <ul className="workspace-grid l-none">
              {values.map((value, index) => (
                <Card
                  key={index}
                  sx={{
                    height: "100%",
                    textAlign: "center",
                    p: 3,
                  }}
                  style={{
                    borderLeft: "10px solid brown",
                  }}
                  className="d-flex align-items-center justify-content-center p-3 flex-column"
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="font-weight-bold mb-3"
                  >
                    {value.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {value.description}
                  </Typography>
                </Card>
              ))}
            </ul>
          </Container>
        </Section>
        <FooterV2 />

        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
          }}
        >
          <IconButton
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            sx={{ backgroundColor: "white", boxShadow: 2 }}
          >
            <FaArrowUp />
          </IconButton>
        </Box>

        {/* <Box
          sx={{
            position: "fixed",
            bottom: 20,
            left: 20,
            zIndex: 1000,
          }}
        >
          <IconButton
            href="https://www.instagram.com/yuukkeglobal/"
            sx={{ mx: 1 }}
          >
            <IoLogoInstagram />
          </IconButton>
          <IconButton
            className="ml-3"
            href="https://www.linkedin.com/company/yuukke/?original_referer=https%3A%2F%2Fyuukke.com%2F"
            sx={{ mx: 1 }}
          >
            <FaLinkedin />
          </IconButton>
        </Box> */}
      </Box>
    </>
  );
};

export default WorkSpaceLandingPage;
