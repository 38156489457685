import React from "react";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Avatar, CircularProgress, Grid } from "@material-ui/core";
import Share from "@material-ui/icons/Share";
import Report from "@material-ui/icons/Report";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import PostService from "../../../api/PostService_old";
import AuthService from "../../../session/AuthService_old";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import i18n from "../../../i18n";
import { API_URL } from "../../../config/app-config";
import { FaComment } from "react-icons/fa";
import "./myPost.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import dummygif from "../../../img/dummygif.gif";
import { withRouter } from "react-router";
import { AiFillEye } from "react-icons/ai";
import { withTranslation } from "react-i18next";
import { Button, Form, Modal, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import dummyimage from "../../../img/default_avatar.jpg";
import ForumService from "../../../api/ForumService";
import * as APPCONFIG from "../../../config/app-config.js";
import VideoPostCard from "./VideoPostCard";
import Popover from "react-bootstrap/Popover";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Chip from "@material-ui/core/Chip";
import { AiOutlineSend } from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import {
  MdBookmarks,
  MdDeleteOutline,
  MdOutlineAttachFile,
} from "react-icons/md";
import { MdOutlineReport } from "react-icons/md";
import {
  getAllUserPoints,
  getNavigationType,
  getPostTagList,
  needPhotVerification,
} from "../../../redux/actions/commonApiActions";
import "react-image-lightbox/style.css";
import FbGridImages from "./ImageView/Images";
import TextEditor from "../../../components/TextEditorHomePost";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SurveyAnswersList from "./surveyAnswerList";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MyYuukke from "../../../api/MyYuukke";
import PieChart from "../../../components/PieChart";
import {
  getAdvertisementData,
  getPieChartList,
} from "../../../redux/actions/squareHomeActions";
import { OverlayTrigger } from "react-bootstrap";
import loveFill from "../../../img/love.png";
import agreeFill from "../../../img/agree.png";
import supportFill from "../../../img/support.png";
import careFill from "../../../img/care.png";
import celebrateFill from "../../../img/celebrate.png";
import {
  createPostLike,
  deletePost,
  commentsCountUpdate,
  sharePost,
  subscribePost,
  updatePostData,
  removePost,
  postNotPresent,
  likeAndCommentUpdate,
} from "../../../redux/actions/PostCardActions";
import HomeService from "../../../api/HomeService";
import { BiLike } from "react-icons/bi";
import {
  htmlValidation,
  removeMentionTags,
  validateSpanTag,
  removeHtmlTags,
  extractMentionsFromUpdate,
  removeHTML,
  extractNumberFromHref,
  giveUserAction,
  getVideoStatus,
  premiumUser,
  getProductContent,
  convertString,
  getImageAndVideo,
} from "../../../utils";
import SinglePost from "./SinglePost";
import MentionsInput from "../../../components/UserMention/Mention";
import MessagesFileOption from "../../Community/Subjects/MessagesFileOption";
import LikePopUp from "../../../components/LikePopUp";
import ReportModel from "../../../components/ReportModel";
import YoutubeVideoModel from "../../../components/YoutubeVideoModel";
import PointAnimation from "../../../components/PointAnimation";
import ProductCard from "../../../components/ProductCard.jsx";
import VideoContainer from "../../../components/VideoContainer.jsx";
import { BsCheck2All } from "react-icons/bs";
let time;
// var userView = true
const options = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

class PostCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commentsText: "",
      likePopoverStatus: false,
      viewGrafModel: false,
      replayList: "",
      replayListIndexStatus: false,
      newReplayStatus: false,
      commentsEdit: false,
      commentsId: 0,
      replayFullList: [],
      replayListStatus: false,
      FriendsList: [],
      FriendsListSearch: [],
      allSubjects: [],
      tagDatas: [],
      selectGroupName: "",
      selectFriendsName: [],
      isOpen: false,
      postId: "",
      isLike: false,
      base64String: [],
      sample: "",
      isvideoOpen: false,
      isImageview: true,
      choosedGroupName: "",
      commendsCount: 0,
      commentsEditText: "",
      isLikeOptionSelected: false,
      isEnbleComment: false,
      selectedLikeValue: "",
      choosedFriendName: "",
      reportPostState: false,
      reportPostId: "",
      reportPostType: "",
      FirstTwocomment: true,
      fileData: [],
      fileVideoData: [],
      photoIndex: 0,
      videoIndex: 0,
      startIndex: 0,
      postType: "",
      openCommentBoxes: false,
      shareOptionPopup: false,
      shareOptionPopupList: false,
      professionList: [
        {
          id: 1,
          name: "Student",
        },
        {
          id: 2,
          name: "Working professional",
        },
        {
          id: 3,
          name: "Practicing profession (Auditoer,lawyer and etc)",
        },
        {
          id: 4,
          name: "Entrepreneur",
        },
        {
          id: 5,
          name: "Homemaker",
        },
        {
          id: 6,
          name: "Freelancer",
        },
      ],
      shareOptionGroupList: false,
      showItems: 2,
      pollQuestion: "Sample Question",
      samplePollAnswer: [
        {
          id: 1,
          option: "Answer 1",
          count: 4,
          isSelected: true,
        },
        {
          id: 2,
          option: "Answer 2",
          count: 4,
          isSelected: false,
        },
        {
          id: 3,
          option: "Answer 3",
          count: 4,
          isSelected: false,
        },
        {
          id: 4,
          option: "Answer 4",
          count: 4,
          isSelected: false,
        },
      ],
      voteCount: 16,
      polling: null,
      survey: null,
      article: null,
      postCategory: "",
      surveySelectcheckedIndex: [],
      surveyAnswers: [],
      textAnswers: [],
      showSurveyquestions: false,
      showSurveyResults: false,
      dialogOpen: false,
      surveyQuestions: null,
      surveyQuestionsLoader: true,
      surveyAnswersLoader: true,
      showSurveyAnswer: false,
      surveyResultsAnswers: [],
      questionDescribed: null,
      content: "",
      editOption: false,
      textBoxStatus: false,
      replyUpdateStatus: false,
      visibleStatus: false,
      userView: false,
      editorValue: "",
      needEdit: false,
      sharedContentText: "",
      sharePostPopup: false,
      sharedType: "",
      articleModel: false,
      articleHeading: "",
      imageFile: null,
      removeImageFile: false,
      likeCountPopUpStatus: false,
      reportCommentState: false,
      reportReplyState: false,
      reportReplyId: "",
      menuStatewithDelete: false,
      videos: [
        "https://youtu.be/7K5BeoPRhqk",
        "https://www.youtube.com/watch?v=S478Qomc1pA&list=RDS478Qomc1pA&start_radio=1",
      ],
      likesLength: 0,
      likeStatusState: false,
    };
    // this.handleInputChange = this.handleInputChnge.bind(this)
    this.handleShowSurveyResults = this.handleShowSurveyResults.bind(this);
    this.handleTextType = this.handleTextType.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleReportPopupClose = this.handleReportPopupClose.bind(this);
    this.popUpReport = this.popUpReport.bind(this);
    this.commentDelete = this.commentDelete.bind(this);
    this.mergeUserCommentList = this.mergeUserCommentList.bind(this);
    this.getFinalCommentList = this.getFinalCommentList.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.checkContent = this.checkContent.bind(this);
    this.isYoutube = this.isYoutube.bind(this);
    this.checkImageURL = this.checkImageURL.bind(this);
    this.checkVideoURL = this.checkVideoURL.bind(this);
    // this.ToggleButton = this.ToggleButton.bind(this);
    this.commentListBox = this.commentListBox.bind(this);
    this.commentHit = this.commentHit.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.reportPost = this.reportPost.bind(this);
    this.reportPostClick = this.reportPostClick.bind(this);
    this.popUpCommentReport = this.popUpCommentReport.bind(this);
    this.commentEdit = this.commentEdit.bind(this);
    this.popUpPostDelete = this.popUpPostDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.reportComment = this.reportComment.bind(this);
    this.ViewAllComments = this.ViewAllComments.bind(this);
    this.ViewAllCommentsHide = this.ViewAllCommentsHide.bind(this);
    this.getProfileBase64String = this.getProfileBase64String.bind(this);
    this.check = this.check.bind(this);
    this.handleClickComment = this.handleClickComment.bind(this);
    this.handleReactplayer = this.handleReactplayer.bind(this);
    this.handleFormEnterKey = this.handleFormEnterKey.bind(this);
    this.handleFormEnterKeyEdit = this.handleFormEnterKeyEdit.bind(this);
    // this.socialPopover = this.socialPopover.bind(this);
    this.shareOptionPopup = this.shareOptionPopup.bind(this);
    this.optionTypesFriends = this.optionTypesFriends.bind(this);
    this.handleoptionTypes = this.handleoptionTypes.bind(this);
    this.optionTypesGroup = this.optionTypesGroup.bind(this);
    this.searchFriends = this.searchFriends.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.handlePolling = this.handlePolling.bind(this);
    this.handleSurvey = this.handleSurvey.bind(this);
    this.handleSurveySelect = this.handleSurveySelect.bind(this);
    this.myRef = React.createRef();
    this.commentRef = React.createRef();
    this.handleshowSurveyAnswers = this.handleshowSurveyAnswers.bind(this);
    this.handleModalclose = this.handleModalclose.bind(this);
    this.ofSetRef = React.createRef();
    this.sharePostComment = this.sharePostComment.bind(this);
    this.articleModel = this.articleModel.bind(this);
    this.audioPlayer = React.createRef();
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    // console.log(this.props.data);

    this.setState({
      // FriendsList: this.props.FriendsList,
      FriendsListSearch: this.props.FriendsList,
    });

    if (this.props.tagDatas && this.props.tagDatas !== undefined) {
      if (this.props.tagDatas.length > 0) {
        this.setState({ allSubjects: this.props.tagDatas });
      }
    }

    this.setState(
      {
        postValue: this.props.data,
      },
      () => {
        this.load(
          this.state.postValue?.postId ? this.state.postValue.postId : 0,
          this.state.postValue?.postType
            ? this.state.postValue.postType
            : "normal"
        );
      }
    );

    this.getMessageFiles(
      this.props.data.postMediaFiles,
      this.props.data.type,
      this.props.data.userId
    );
    if (this.props.data.postCategory === "polling") {
      this.setState({
        polling: this.props.data.polling,
      });
    }
    if (this.props.data.postCategory === "survey") {
      this.setState({
        survey: this.props.data.surveyList,
      });
    }
    if (this.props.data.postCategory === "article") {
      this.setState({
        article: this.props.data.article,
      });
    }
    this.setState({
      postCategory: this.props.data.postCategory,
    });

    if (this.props.postDescriptionPage) {
      this.handleClickComment();
    }

    // const selectedIndexList = this.props.data?.surveyList.survey
    // .filter(data => data.answerType === 'Select')
    //
    // const selectedIndex = this.props?.data?.surveyList?.survey?.postSurveyAnswers.filter(data => data.isSelected===true)
    // this.setState({
    //   surveySelectcheckedIndex:selectedIndex.
    // })
  }

  componentWillReceiveProps(nextProps) {
    // console.log("commentsLentghh", this.props.data);

    if (this.props.data && this.props.data !== nextProps.data) {
      this.setState({
        polling: nextProps.data?.polling,
        survey: nextProps.data?.surveyList,
        article: nextProps.data?.article ? nextProps.data.article : null,
        postCategory: nextProps.data?.postCategory,
        openCommentBoxes: this.props.postDescriptionPage ? true : false,
        questionDescribed: "",
        content: "",
      });
      this.setState(
        {
          postValue: nextProps?.data ? nextProps?.data : [],
        },
        () => {
          this.load(this.state.postValue.postId, this.state.postValue.postType);
        }
      );
    }
    if (this.props.postLikeStatus !== nextProps?.postLikeStatus) {
      this.setState(
        {
          postValue: nextProps?.data ? nextProps?.data : [],
        },
        () => {
          this.load(this.state.postValue.postId, this.state.postValue.postType);
        }
      );
    }

    // if (this.state.survey === null) {
    //   this.setState({
    //     survey: nextProps.data.surveyList,
    //   });
    // }

    // postLikeStatus

    if (this.props.data.postMediaFiles !== nextProps.data.postMediaFiles) {
      this.getMessageFiles(
        nextProps.data.postMediaFiles,
        nextProps.data.type,
        nextProps.data.userId
      );
    }
  }
  callbackFunction = (entries) => {
    const [entry] = entries;

    if (
      // this.state.postValue?.userId !== this.props.loginDetails.uId &&
      entry.isIntersecting &&
      !this.state.userView
    ) {
      console.log("View Api Call___________", this.state.postValue.postId);
      let data = {
        type: this.props.data.postCategory,
        postId: this.state.postValue.postId,
        typeData: this.state.postValue.postType,
      };
      MyYuukke.post_view(data)
        .then((response) => {
          if (response.data.data.allLikes && !this.props.isGroup) {
            let data = {
              postId: this.state.postValue.postId,
              commendsCount: response.data.data.commendsCount,
              allLikes: response.data.data.allLikes,
            };
            this.props.likeAndCommentUpdate(data);
          }
        })
        .catch((e) => console.log(e));

      this.setState({
        userView: true,
      });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleMenuClick(event, userId) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({ menuState: event.target });
      if (
        userId === this.props.loginDetails.uId ||
        this.props.loginUserType === 12 ||
        this.props.loginUserRole !== 0
      ) {
        console.log("Menu-state true");
        this.setState({ menuStatewithDelete: true });
      } else {
        console.log("Menu-state false");

        this.setState({ menuStatewithoutDelete: true });
      }
    }
  }

  handleReactplayer() {
    this.setState({
      isImageview: true,
      isvideoOpen: false,
    });
  }

  handleMenuClose() {
    this.setState({
      menuState: false,
      menuStatewithDelete: false,
      menuStatewithoutDelete: false,
    });
  }

  CheckPollIDisMatching(pollArray, key) {
    if (pollArray != undefined && pollArray.length > 0) {
      var result = pollArray.filter((poll) => {
        return poll.pollId === key;
      });

      if (
        result != null &&
        result != undefined &&
        result.length > 0 &&
        "cnt" in result[0]
      ) {
        return result[0].cnt;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  handleModalclose() {
    this.setState({
      showSurveyResults: false,
    });
  }

  handleShowSurveyResults() {
    const params = {
      page: 0,
      modertionId: this.props.data.moderationId,
      postId: this.props.data.postId,
      type: this.props.data.postType,
    };

    this.setState({
      showSurveyResults: true,
    });
    PostService.getSurveyUserListByPostId(params).then((res) => {
      console.log("Survey Results", res.data.data.userList);
      if (res?.data?.data?.userList) {
        console.log("Survey Results", res.data.data);
        this.setState({
          surveyQuestions: res?.data?.data?.userList,
          surveyQuestionsLoader: false,
        });
      }
    });
  }

  handlePolling(pollingOptions = [], pollId) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      let newState = Object.assign({}, this.state);
      pollingOptions.filter((data) => {
        if (data.postAnswersId === pollId.postAnswersId) {
          console.log("Selected Polling Id:", data);
          console.log("Selected Polling Id:", newState);
          const params = {
            postId: this.props.data.moderationId,
            answerId: data.postAnswersId,
            questionId: data.postQuestionId,
          };
          PostService.saveUserPoll(params).then((res) => {
            console.log("Selected Polling Id (api call):", res.data.data);
            this.setState({
              polling: res.data.data?.polling ?? null,
            });
          });
        }
      });
    }
  }

  handleTextType(questionId, answerId, type, textAnswer) {
    this.setState({ sample: textAnswer });
    let surveySelectList = {};
    surveySelectList.questionId = questionId;
    surveySelectList.answerType = type;
    surveySelectList.answersId = answerId;
    surveySelectList.answer = textAnswer;
    console.log("Event Target Selected", surveySelectList);
    const index = this.state.textAnswers.findIndex(
      (data) => data.questionId === questionId
    );
    if (index === -1) {
      console.log("Event Target Selected index exist", index);
      this.setState({
        textAnswers: [...this.state.textAnswers, surveySelectList],
      });
    } else {
      console.log("Event Target Selected index Not Exist", index);
      const newState = this.state.textAnswers.map((data, idx) =>
        index === idx
          ? {
              ...data,
              answerId: answerId,
              answer: textAnswer,
            }
          : data
      );
      console.log("Event Target Selected", newState);
      this.setState({
        textAnswers: [...newState],
      });
    }
  }

  handleSurveySelect(questionId, answerId, type) {
    console.log("Event Target Selected:", questionId);
    console.log("Event Target Selected:", answerId);
    let surveySelectList = {};
    surveySelectList.questionId = questionId;
    surveySelectList.answerType = type;

    if (type === "Select") {
      surveySelectList.answersId = [answerId];
      console.log("Event Target Selected:", surveySelectList);
      const index = this.state.surveyAnswers.findIndex(
        (data) => data.questionId === questionId
      );
      if (index === -1) {
        console.log("Event Target Selected", index);
        this.setState({
          surveyAnswers: [...this.state.surveyAnswers, surveySelectList],
        });
      } else {
        console.log("Event Target Selected", index);
        const newState = this.state.surveyAnswers.map((data, idx) =>
          index === idx
            ? {
                ...data,
                answersId: [answerId],
              }
            : data
        );
        console.log("Event Target Selected", newState);
        this.setState({
          surveyAnswers: [...newState],
        });
      }
    }
    // return
  }
  findData = (id) => {
    let check =
      (this.state.textAnswers &&
        this.state.textAnswers.find((item) => item.answerId == id)?.answer) ||
      "";
    return check ? check : "";
  };
  handleSurvey(e) {
    // console.log(data)
    e.preventDefault();
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      const data = {
        postId: this.props.data.moderationId,
        survey: [...this.state.surveyAnswers, ...this.state.textAnswers],
      };
      console.log("Survey Data :", data);
      console.log("Event Target Selected:", e.target.value);
      PostService.saveUserSurvey(data).then((res) => {
        console.log("Response Survey", res.data?.data);
        this.setState({
          survey: res.data.data?.surveyList ?? null,
          sample: "",
          surveyAnswers: [],
          textAnswers: [],
        });
        this.props.updateSurveyCompletion(this.props.data.moderationId);
      });
    }
    // return
  }

  handleshowSurveyAnswers() {
    this.setState({
      showSurveyAnswer: !this.state.showSurveyAnswer,
    });
  }
  componentDidUpdate(nextProps) {
    const observer = new IntersectionObserver(this.callbackFunction, options);
    if (this.ofSetRef.current) observer.observe(this.ofSetRef.current);
    return () => {
      if (this.ofSetRef.current) observer.unobserve(this.ofSetRef.current);
    };
  }
  render() {
    const { t } = this.props;
    const {
      shareOptionPopup,
      shareOptionPopupList,
      shareOptionGroupList,
      commendsCount,
    } = this.state;

    const {
      type,

      allLikes,
      userBookMark,
    } = this.props.data;
    var myHTML =
      this.state.postCategory == "article"
        ? this.state.article?.description
          ? this.state.article.description
          : ""
        : "";
    // var strippedHtml = myHTML.replace(/<[^>]+>/g, "");
    const trimmedString1 = myHTML.split("</p>", 1);
    const trimmedString = trimmedString1[0] ? trimmedString1[0] : "" + "</p>";
    return (
      <>
        {this.state.postValue && (
          <>
            <div
              key={this.state.postValue.postId}
              ref={this.ofSetRef}
              id={this.state.postValue.postId}
              className="about-card"
              style={{ marginTop: "1.5rem" }}
            >
              <div className="about-card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="post-avatar post-avatar-story postCardImg-me-2 mb-auto">
                      {" "}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.showFriendsProfile(this.state.postValue.userId)
                        }
                        className="post-avatar-img rounded-circle"
                        src={
                          this.state.postValue.dispalyName === "Anonymous User"
                            ? dummyimage
                            : API_URL +
                              "api/getUserPhoto/" +
                              this.state.postValue.userId +
                              "/" +
                              this.state.postValue.userName
                        }
                        alt="user"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                      />{" "}
                    </div>
                    <div>
                      <div className="post-nav post-nav-divider">
                        <h6
                          className="post-nav-item post-card-title post-mb-0"
                          style={{
                            color: "#14191e",
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.showFriendsProfile(this.state.postValue.userId)
                          }
                        >
                          {" "}
                          {this.state.postValue.userName}{" "}
                          {premiumUser(this.state.postValue?.premiumData) && (
                            <img
                              src={premiumUser(
                                this.state.postValue?.premiumData
                              )}
                              alt=""
                              className="ml-1"
                              style={{ width: "20px" }}
                            />
                          )}
                        </h6>
                        <span
                          className="post-small mr-2 ml-2"
                          style={{ marginTop: "6px" }}
                        >
                          {" "}
                          {moment(this.state.postValue.createdDate)
                            .locale("en")
                            .format("ll")}
                          {/* {moment(this.state.postValue.createdDate).format(
                            "DD MMM, h:mm a"
                          )} */}
                        </span>
                        {!this.props.isGroup && (
                          <div>
                            {this.state.postValue?.isSharePost ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Shared")}
                              </span>
                            ) : this.state.postCategory === "product" ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Product")}
                              </span>
                            ) : !this.state.postValue?.isSharePost &&
                              this.state.postValue.postActive == false &&
                              !this.state.postValue.pstType ==
                                "groupMessage" ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Post")}
                              </span>
                            ) : !this.state.postValue?.isSharePost &&
                              this.state.postValue.postType ==
                                "groupMessage" ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {this.state.postValue.subjectName}
                              </span>
                            ) : !this.state.postValue?.isSharePost &&
                              this.state.postValue.pstType == "groupMessage" ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {this.state.postValue.subjectName}
                              </span>
                            ) : // </div>
                            !this.state.postValue?.isSharePost &&
                              this.state.postValue.postType == "postList" &&
                              this.state.postValue.postActive == false ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Post")}
                              </span>
                            ) : !this.state.postValue?.isSharePost &&
                              this.state.postValue.postType == "postList" &&
                              this.state.postValue.postActive == true ? (
                              <span class="badge badge-pill post-badge-secondary">
                                {this.state.postValue.postCategory}
                              </span>
                            ) : (
                              <span class="badge badge-pill post-badge-secondary">
                                {t("Post")}
                              </span>
                            )}
                          </div>
                        )}
                        {/* <div></div> */}
                      </div>
                      {this.state.professionList.map((option, index) => (
                        <>
                          {this.state.postValue.professionId == option.id && (
                            <p className="post-mb-0 post-small" key={index}>
                              {option.name}
                            </p>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      cursor: "pointer",
                      alignItems: "baseline",
                      gap: "0.6rem",
                    }}
                  >
                    {this.state.survey &&
                      this.props.loginDetails.uId ===
                        this.props.data.userId && (
                        // this.state?.survey?.isCompleted &&
                        <div
                          style={{
                            border: "2px solid rgb(220, 53, 69)",
                            padding: "4px 8px",
                            borderRadius: "100px",
                            background: "rgb(220, 53, 69,0.1)",
                            fontSize: "10px",
                          }}
                        >
                          <p onClick={() => this.handleShowSurveyResults()}>
                            {t("View Results")}
                          </p>
                        </div>
                      )}

                    {this.state.survey &&
                      this.props.loginDetails.uId !== this.props.data.userId &&
                      this.state.survey?.isCompleted && (
                        <div
                          style={{
                            border: "2px solid rgb(220, 53, 69)",
                            padding: "4px 8px",
                            borderRadius: "100px",
                            background: "rgb(220, 53, 69,0.1)",
                            fontSize: "10px",
                          }}
                        >
                          <p>{t("Survey Submitted")}</p>
                        </div>
                      )}

                    <div className="post-avatar">
                      <MoreHorizIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          this.handleMenuClick(e, this.state.postValue.userId);
                        }}
                      />
                      {/* with owner */}
                      {this.state.menuStatewithDelete && (
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.menuState}
                          keepMounted
                          open={this.state.menuState}
                          onClose={this.handleMenuClose}
                        >
                          {(this.state.postValue.userId ===
                            this.props.loginDetails.uId ||
                            this.props.loginDetails.uType === 12 ||
                            this.props.loginUserRole === 1 ||
                            this.props.loginUserRole === 2) && (
                            <div
                              className="menuCard"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.deletePostCard(
                                  this.state.postValue.postId,
                                  this.state.postValue.postType,
                                  this.state.postValue.moderationId
                                )
                              }
                            >
                              <Delete className="menuItem" />{" "}
                              <MenuItem>
                                <p style={{ color: "black" }}>{t("Delete")}</p>
                              </MenuItem>
                            </div>
                          )}
                          {this.props.data.postCategory !== "article" &&
                            this.props.data.postCategory !== "polling" &&
                            this.props.data.postCategory !== "survey" &&
                            this.state.postValue.userId !==
                              this.props.loginDetails.uId && (
                              <div
                                className="menuCard"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.reportPostClick(
                                    this.state.postValue.postId,
                                    this.state.postValue.type
                                  )
                                }
                              >
                                <Report className="menuItem" />{" "}
                                <MenuItem>
                                  <p style={{ color: "black" }}>
                                    {t("Report")}
                                  </p>
                                </MenuItem>
                              </div>
                            )}
                          {/* {this.state.postValue?.isSharePost === false && */}
                          {(this.state.postCategory === "normal" ||
                            this.state.postCategory === "article" ||
                            this.state.postCategory === "advertisement" ||
                            this.state.postCategory === "resource") && (
                            // this.state.postValue.userId ===
                            //   this.props.loginDetails.uId

                            //   &&
                            <div
                              className="menuCard"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.editClick(
                                  this.state.postValue.postId,
                                  this.state.postValue.type
                                )
                              }
                            >
                              <Edit className="menuItem" />{" "}
                              <MenuItem>
                                <p style={{ color: "black" }}>{t("Edit")}</p>
                              </MenuItem>
                            </div>
                          )}
                          {this.state.postValue.userId ===
                            this.props.loginDetails.uId && (
                            <div
                              className="menuCard"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.openViewModel(this.state.postValue.userId)
                              }
                            >
                              <AiFillEye
                                style={{ width: "25px", height: "25px" }}
                                className="menuItem"
                              />{" "}
                              <MenuItem>
                                <p style={{ color: "black" }}>
                                  {t("View Count")}
                                </p>
                              </MenuItem>
                            </div>
                          )}
                          {/* This is only handle for Front End moderator */}
                          {this.state.postValue.userId !==
                            this.props.loginDetails.uId &&
                            (this.props.loginDetails.uType === 12 ||
                              this.props.loginUserRole === 1 ||
                              this.props.loginUserRole === 2) && (
                              <>
                                {this.state.postValue.userId !==
                                  this.props.loginDetails.uId &&
                                  this.state.postCategory !== "product" &&
                                  this.state.postValue.postSubscribe && (
                                    <>
                                      {this.state.postValue.postSubscribe
                                        .isSubscribe ? (
                                        <div
                                          className="menuCard"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.subscribe(
                                              false,
                                              this.state.postValue.postSubscribe
                                                .subscribeId
                                            )
                                          }
                                        >
                                          <NotificationsNoneIcon className="menuItem bg-color" />{" "}
                                          <MenuItem>
                                            <p class="bg-color">
                                              {t("Un Subscribe")}
                                            </p>
                                          </MenuItem>
                                        </div>
                                      ) : (
                                        <div
                                          className="menuCard"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.subscribe(
                                              true,
                                              this.state.postValue.postSubscribe
                                                .subscribeId
                                            )
                                          }
                                        >
                                          <NotificationsNoneIcon className="menuItem" />{" "}
                                          <MenuItem>
                                            <p style={{ color: "black" }}>
                                              {t("Subscribe")}
                                            </p>
                                          </MenuItem>
                                        </div>
                                      )}
                                    </>
                                  )}
                                {this.state.postValue.userId !==
                                  this.props.loginDetails.uId && (
                                  <div
                                    className="menuCard"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addBookMark(
                                        this.state.postValue.postId,
                                        this.state.postValue.postType,
                                        userBookMark
                                      )
                                    }
                                  >
                                    <MdBookmarks
                                      style={{ width: "25px", height: "25px" }}
                                      className={
                                        userBookMark
                                          ? "bg-color menuItem"
                                          : "menuItem"
                                      }
                                    />{" "}
                                    <MenuItem>
                                      <p
                                        className={
                                          userBookMark
                                            ? "bg-color "
                                            : "text-dark"
                                        }
                                      >
                                        {userBookMark
                                          ? t("Remove Bookmark")
                                          : t("Add Bookmark")}
                                      </p>
                                    </MenuItem>
                                  </div>
                                )}
                              </>
                            )}
                          {/* Moderator handle end */}
                        </Menu>
                      )}

                      {/* without owner */}
                      {this.state.menuStatewithoutDelete && (
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.menuState}
                          keepMounted
                          open={this.state.menuState}
                          onClose={this.handleMenuClose}
                        >
                          {this.props.data.postCategory !== "article" &&
                            this.props.data.postCategory !== "polling" &&
                            this.props.data.postCategory !== "survey" &&
                            this.state.postValue.userId !==
                              this.props.loginDetails.uId && (
                              <div
                                className="menuCard"
                                onClick={() =>
                                  this.reportPostClick(
                                    this.state.postValue.postId,
                                    this.state.postValue.type
                                  )
                                }
                              >
                                <Report className="menuItem" />{" "}
                                <MenuItem>
                                  <p style={{ color: "black" }}>
                                    {t("Report")}
                                  </p>
                                </MenuItem>
                              </div>
                            )}
                          {this.state.postValue.userId !==
                            this.props.loginDetails.uId &&
                            this.state.postValue.postSubscribe && (
                              <>
                                {this.state.postValue.postSubscribe
                                  .isSubscribe ? (
                                  <div
                                    className="menuCard"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.subscribe(
                                        false,
                                        this.state.postValue.postSubscribe
                                          .subscribeId
                                      )
                                    }
                                  >
                                    <NotificationsNoneIcon className="menuItem bg-color" />{" "}
                                    <MenuItem>
                                      <p class="bg-color">{t("unsubscribe")}</p>
                                    </MenuItem>
                                  </div>
                                ) : (
                                  <div
                                    className="menuCard"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.subscribe(
                                        true,
                                        this.state.postValue.postSubscribe
                                          .subscribeId
                                      )
                                    }
                                  >
                                    <NotificationsNoneIcon className="menuItem" />{" "}
                                    <MenuItem>
                                      <p style={{ color: "black" }}>
                                        {t("Subscribe")}
                                      </p>
                                    </MenuItem>
                                  </div>
                                )}
                              </>
                            )}
                          {this.state.postValue.userId !==
                            this.props.loginDetails.uId && (
                            <div
                              className="menuCard"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.addBookMark(
                                  this.state.postValue.postId,
                                  this.state.postValue.postType,
                                  userBookMark
                                )
                              }
                            >
                              <MdBookmarks
                                style={{ width: "25px", height: "25px" }}
                                className={
                                  userBookMark
                                    ? "bg-color menuItem"
                                    : "menuItem"
                                }
                              />{" "}
                              <MenuItem>
                                <p
                                  className={
                                    userBookMark ? "bg-color " : "text-dark"
                                  }
                                >
                                  {userBookMark
                                    ? t("Remove Bookmark")
                                    : t("Add Bookmark")}
                                </p>
                              </MenuItem>
                            </div>
                          )}
                        </Menu>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="post-card-body">
                {this.state.editOption && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: "1rem",
                        flexDirection: "column",
                        marginBottom: "13px",
                      }}
                    >
                      <TextEditor
                        friendslist={this.props.FriendsList}
                        ref={this.myRef}
                        editorStateValue={this.editorStateValues}
                        editorValue={this.state.questionDescribed}
                        textBoxStatus={this.state.textBoxStatus}
                        textBoxStatusBack={this.textBoxStatusBack}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={this.editOptionClose}
                        className="replayButton mr-3"
                        style={{ marginLeft: "10px" }}
                      >
                        {t("Close")}
                      </button>
                      <button
                        onClick={this.updatePost}
                        className="replayButtonPost"
                      >
                        {t("Update")}
                      </button>
                    </div>
                  </>
                )}
                {this.state.article &&
                  this.state.postCategory === "article" &&
                  this.state.article !== null && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                      }}
                    >
                      <h5>
                        {this.state.article?.title
                          ? this.state.article.title
                          : "Article"}
                      </h5>
                      <p className="about-mb-2" onClick={this.handleHashTag}>
                        {htmlValidation(trimmedString ? trimmedString : "")}
                      </p>
                      {/* <div
                        className="about-mb-2"
                        dangerouslySetInnerHTML={{
                          __html: trimmedString,
                        }}
                      /> */}

                      {/* {trimmedString}.... */}
                      <Link
                        to={{
                          pathname: `/articles/${this.state.article?.slug}`,
                        }}
                      >
                        <p
                          style={{
                            fontSize: "15px",
                            color: "#A00030",
                          }}
                        >
                          {t("Read More")}
                        </p>
                      </Link>
                    </div>
                  )}
                {this.state.postValue?.isSharePost === false &&
                  !this.state.editOption &&
                  type == "text" &&
                  this.state.postValue?.content && (
                    <>
                      <p onClick={this.handleHashTag}>
                        <p
                          className="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: this.state.postValue.content.replace(
                              /<p><\/p>/g,
                              "<br/>"
                            ),
                          }}
                        />
                        {/* {htmlValidation(this.state.postValue.content)} */}
                      </p>
                    </>
                    // <div
                    //   className="about-mb-2"
                    //   // dangerouslySetInnerHTML={{
                    //   //   // __html: this.state.postValue.content.replaceAll("#" ", `<span style=color:yellow></span>`),
                    //   //   __html: this.state.postValue.content
                    //   //       ?
                    //   //         this.state.postValue.content.replace(/((?:^|)(?:#[a-zA-Z0-9!@#_:;"',.<>?/|=()+{}[\$%\^\&*\d-]+))/ig, (m, g1) => {
                    //   //           return `<span style=color:#A00030>${g1}</span>`
                    //   //         })
                    //   //       :
                    //   //         ''
                    //   //     }}
                    //   // style={{whiteSpace: 'break-spaces'}}
                    //   dangerouslySetInnerHTML={{
                    //     // __html: this.state.postValue.content.replaceAll("#" ", `<span style=color:yellow></span>`),
                    //     __html: this.state.postValue.content,
                    //     // ?
                    //     //   this.state.postValue.content.replaceAll(" ","&nbsp;").replace(/((?:^|)(?:#[a-zA-Z_\d-]+))/ig, (m, g1) => {
                    //     //     return `<span style=color:#A00030>${g1}</span>`
                    //     //   })
                    //     // :
                    //     //   ''
                    //   }}
                    // />

                    // style={{
                    //   // color:"green"
                    // }}

                    // > {this.state.postValue.content.replaceAll('(?i)<td[^>]*>','')}</p>

                    // this.state.postValue.content.substring(this.state.postValue.content.indexOf(">")+1,this.state.postValue.content.lastIndexOf("<")).split(" ").length > 0 && (
                    //   this.state.postValue.content.substring(this.state.postValue.content.indexOf(">")+1,this.state.postValue.content.lastIndexOf("<")).split(" ")
                    //   .map(data => (
                    //     data.startsWith("#") ? (<span style={{color:"blue", paddingInline:"0.2rem"}}>{data}</span>)  : (data)
                    //   ))
                    // )

                    //  </p>
                  )}

                {/* Shared Post */}
                {
                  // !this.props.postDescriptionPage &&
                  !this.state.editOption &&
                    this.state.postValue?.isSharePost === true && (
                      <>
                        {this.state.postValue?.content && (
                          <>
                            <p onClick={this.handleHashTag}>
                              <p
                                // className="about-mb-2"
                                dangerouslySetInnerHTML={{
                                  __html: this.state.postValue.content.replace(
                                    /<p><\/p>/g,
                                    "<br/>"
                                  ),
                                }}
                              />
                              {/* {htmlValidation(this.state.postValue.content)} */}
                            </p>
                          </>
                        )}
                        <div className="mb-4">
                          <SinglePost
                            professionList={this.state.professionList}
                            workspaceType={this.props.workspaceType}
                            postId={this.state.postValue?.shareDetails?.postId}
                            postType={
                              this.state.postValue?.shareDetails?.typeData
                            }
                            modelPost={false}
                          />
                        </div>
                      </>
                    )
                }
                {this.state.postValue?.isSharePost === false &&
                  !this.state.editOption &&
                  this.state.postValue?.fileContent &&
                  (type == "image" ||
                    type == "video" ||
                    type == "file" ||
                    type == "pdf" ||
                    type == "audio") && (
                    // <p className="about-mb-2" onClick={this.handleHashTag}>
                    //   {this.state.postValue?.fileContent &&
                    //     htmlValidation(this.state.postValue.fileContent)}
                    // </p>
                    <p className="about-mb-2" onClick={this.handleHashTag}>
                      <p
                        // className="about-mb-2"
                        dangerouslySetInnerHTML={{
                          __html: this.state.postValue.fileContent.replace(
                            /<p><\/p>/g,
                            "<br/>"
                          ),
                        }}
                      />
                    </p>

                    // this.state.postValue.fileContent.substring(this.state.postValue.fileContent.indexOf(">")+1,this.state.postValue.fileContent.lastIndexOf("<")).split(" ").length > 0 && (
                    //   this.state.postValue.fileContent.substring(this.state.postValue.fileContent.indexOf(">")+1,this.state.postValue.fileContent.lastIndexOf("<")).split(" ")
                    //   .map(data => (
                    //     data.startsWith("#") ? (<span style={{color:"blue", paddingInline:"0.2rem"}}>{data}</span>)  : (data)
                    //   ))
                    // )

                    //  </p>
                  )}

                {!this.state.editOption &&
                  Array.isArray(this.state.postValue?.youtubeUrls) &&
                  this.state.postValue?.youtubeUrls.length !== 0 && (
                    <div
                      className="d-flex flex-column mb-2 mt-2"
                      style={{ gap: "1rem" }}
                    >
                      {this.state.postValue?.youtubeUrls
                        .slice(0, 1)
                        .map((item) => (
                          <>
                            <YoutubeVideoModel youtubeId={item} />{" "}
                          </>
                        ))}
                    </div>
                  )}
                {!this.state.editOption &&
                  // !this.props.postDescriptionPage &&
                  !this.state.postValue?.isSharePost &&
                  type === "image" && (
                    <>
                      {/* {this.state.fileImageData !== undefined &&
                        this.state.fileImageData.length == 1 && (
                          <div class="postCardImageSingle">
                            <FbGridImages
                              images={this.state.fileImageData}
                              onClickImage={this.subscribeAdvertisement}
                            />
                          </div>
                        )} */}
                      {/* {this.state.fileImageData !== undefined &&
                        this.state.fileImageData.length > 1 && ( */}
                      <div
                        class="postCardImage mb-3"
                        // onClickImage={this.subscribeAdvertisement}
                      >
                        <FbGridImages
                          images={this.state.fileImageData}
                          onClickImage={() => {}}
                        />
                      </div>
                      {/* )} */}
                    </>
                  )}
                {/* !this.props.postDescriptionPage && */}
                {!this.state.editOption &&
                  !this.state.postValue?.isSharePost &&
                  type === "video" && (
                    <div className="d-flex flex-column" style={{ gap: "2rem" }}>
                      {/* {this.state.fileVideoData.map((photo) => (
                        <VideoPostCard
                          fileData={photo.file}
                          imageData={photo.image}
                          videoStatus={photo.steamStatus}
                        />
                      ))} */}
                      <VideoContainer data={this.state.fileVideoData} />
                    </div>
                  )}

                {!this.state.editOption &&
                  !this.state.postValue?.isSharePost &&
                  type == "pdf" && (
                    <div>
                      {this.state.fileData &&
                        this.state.fileData.map((photo) => (
                          <div style={{ margin: "8px" }}>
                            <a
                              // download={photo.originalFileName}
                              style={{ textDecoration: "none", color: "black" }}
                              href={
                                photo.fileName == undefined ||
                                photo.fileName == ""
                                  ? dummygif
                                  : photo.fileName
                              }
                              target="_blank"
                            >
                              <DescriptionOutlinedIcon />
                              {photo.originalFileName}
                            </a>
                          </div>
                        ))}
                    </div>
                  )}

                {/* polling */}
                {this.state.polling &&
                  this.state.postCategory === "polling" && (
                    <div>
                      <div>
                        {this.state.polling?.postQuestionTitle && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.polling?.postQuestionTitle,
                            }}
                            className="mb-2 mt-2 "
                            style={{ fontSize: "14px" }}
                          />
                        )}
                        <p
                          className="about-mb-2 mt-4 font-weight-bold"
                          onClick={this.handleHashTag}
                          style={{ textTransform: "capitalize" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.polling?.postQuestion,
                            }}
                          />
                        </p>
                        {/* <p>{this.state.polling?.postQuestion}</p> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          alignItems: "space-between",
                          paddingBlock: "1rem",
                        }}
                      >
                        {Array.isArray(this.state.polling?.answer) &&
                          this.state.polling?.answer
                            .sort((a, b) =>
                              a.postAnswersId > b.postAnswersId ? 1 : -1
                            )
                            .map((data, index) => (
                              <div>
                                <button
                                  key={index}
                                  className={`position-relative polling-box  `}
                                  style={{
                                    // border: data.isSelected
                                    //   ? "1px solid #A00030"
                                    //   : "1px solid lightgray",
                                    // background: data.isSelected
                                    //   ? "mistyrose"
                                    //   : "white",
                                    boxShadow: this.state.polling?.isSelected
                                      ? "none"
                                      : "#000000e8 5px 5px",
                                  }}
                                  onClick={() =>
                                    this.handlePolling(
                                      this.state.polling?.answer,
                                      data
                                    )
                                  }
                                >
                                  {this.state.polling?.isSelected && (
                                    <div
                                      class="background-part"
                                      style={{
                                        width: data.isSelected
                                          ? data?.percentage + 1 + "%"
                                          : data?.percentage + "%",
                                      }}
                                    ></div>
                                  )}

                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.postAnswers}
                                  </p>
                                  {this.state.polling?.isSelected && (
                                    <p className="d-flex font-bold ">
                                      <span className="ml-2">
                                        {+data?.percentage > 0
                                          ? data.percentage.toFixed(1)
                                          : 0}
                                        %
                                      </span>
                                      {data.isSelected && (
                                        <span>
                                          <BsCheck2All
                                            className="ml-2 h-auto"
                                            style={{ width: "22px" }}
                                          />
                                        </span>
                                      )}
                                    </p>
                                  )}
                                  {/* <p className="poll-count-position">
                                     {data.count}
                                   </p> */}
                                </button>
                              </div>
                            ))}
                      </div>
                      <span className="miniText font-weight-bold">
                        {this.state.polling?.totalCount}
                        {this.state.polling?.totalCount > 1
                          ? " Votes"
                          : " Vote"}
                        {/* 1 votes */}
                      </span>
                    </div>
                  )}

                {/* survey */}
                {this.state.survey && this.state.postCategory === "survey" && (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        {!this.state.survey?.isCompleted
                          ? t("Please Take a Survey")
                          : t("Survey Results")}
                      </p>
                      {this.state.survey?.survey.map((surveyData, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "0.6rem",
                          }}
                        >
                          <p className="d-flex">
                            {index + 1}.
                            <div
                              className="ml-2"
                              dangerouslySetInnerHTML={{
                                __html: surveyData.surveyQuestions,
                              }}
                            />
                            {/* {surveyData.surveyQuestions} */}
                          </p>
                          {surveyData.answerType === "Select" ? (
                            <>
                              {/* <RadioGroup aria-label="gender" name={surveyData.surveyQuestions}  onChange={(e) => this.handleSurveySelect(e)}>
                               {
                                 surveyData.postSurveyAnswers.map( data => 
                                  <FormControlLabel value={data.surveyAnswers} data-id={data.postSurveyAnswersId} control={<Radio color="primary" />} label={data.surveyAnswers} />
                                ) 
                               }
                              </RadioGroup> */}
                              {surveyData?.postSurveyAnswers.map(
                                (data, index) => (
                                  <label
                                    key={index}
                                    style={{
                                      display: "flex",
                                      gap: "0.6rem",
                                    }}
                                  >
                                    {data.isSelected ? (
                                      <fieldset disabled={data.isSelected}>
                                        <input
                                          type="radio"
                                          disabled={
                                            this.state.survey.isCompleted
                                          }
                                          checked={data.isSelected}
                                          name={surveyData.surveyQuestions}
                                          value={data.surveyAnswers}
                                          onChange={() =>
                                            this.handleSurveySelect(
                                              surveyData.postSurveyQuestionsId,
                                              data.postSurveyAnswersId,
                                              "Select"
                                            )
                                          }
                                        />
                                      </fieldset>
                                    ) : (
                                      <input
                                        type="radio"
                                        disabled={this.state.survey.isCompleted}
                                        name={surveyData.surveyQuestions}
                                        value={data.surveyAnswers}
                                        onChange={() =>
                                          this.handleSurveySelect(
                                            surveyData.postSurveyQuestionsId,
                                            data.postSurveyAnswersId,
                                            "Select"
                                          )
                                        }
                                      />
                                    )}
                                    {data.surveyAnswers}
                                  </label>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              <div style={{ width: "99%" }}>
                                <TextField
                                  id="servey text"
                                  // value={this.state.sample}
                                  value={
                                    this.state.sample
                                      ? this.findData(
                                          surveyData.postSurveyAnswers?.[0]
                                            ?.postSurveyAnswersId
                                        )
                                      : ""
                                  }
                                  style={{ margin: 8 }}
                                  placeholder={
                                    !this.state.survey.isCompleted
                                      ? surveyData.postSurveyAnswers?.[0]
                                          ?.surveyAnswers
                                      : surveyData.postSurveyAnswers?.[0]
                                          ?.answer
                                  }
                                  // helperText="value"
                                  fullWidth
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) =>
                                    this.handleTextType(
                                      surveyData.postSurveyQuestionsId,
                                      surveyData.postSurveyAnswers?.[0]
                                        .postSurveyAnswersId,
                                      "Text",
                                      e.target.value
                                    )
                                  }
                                  variant="filled"
                                  disabled={this.state.survey.isCompleted}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                      {!this.state.survey.isCompleted && (
                        <Button
                          variant="primary"
                          onClick={(e) => this.handleSurvey(e)}
                        >
                          {" "}
                          {t("Submit")}{" "}
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
                {/* Market place card */}
                {this.state.postCategory === "product" && (
                  <div className="mt-3">
                    <ProductCard data={this.state.postValue?.productDetails} />
                  </div>
                )}

                {/* article */}
                <div className="position-relative">
                  {allLikes !== null && (
                    <>
                      <p
                        className="like-count"
                        onClick={this.likeCountPopUp}
                        style={{ cursor: "pointer" }}
                      >
                        {allLikes?.hasOwnProperty("1") && (
                          <Tooltip
                            title={"Love  " + allLikes["1"]}
                            placement="top"
                          >
                            <img
                              className="home-like-img-icon"
                              src={loveFill}
                              style={{
                                height: "55px",
                                width: "auto",
                              }}
                            />
                          </Tooltip>
                        )}
                        {allLikes?.hasOwnProperty("2") && (
                          <Tooltip
                            title={"Care  " + allLikes["2"]}
                            placement="top"
                          >
                            <img
                              className="home-like-img-icon"
                              src={careFill}
                            />
                          </Tooltip>
                        )}
                        {allLikes?.hasOwnProperty("3") && (
                          <Tooltip
                            title={"Celebrate  " + allLikes["3"]}
                            placement="top"
                          >
                            <img
                              className="home-like-img-icon"
                              src={celebrateFill}
                              style={{ marginBottom: "8px" }}
                            />
                          </Tooltip>
                        )}
                        {allLikes?.hasOwnProperty("4") && (
                          <Tooltip
                            title={"Support  " + allLikes["4"]}
                            placement="top"
                          >
                            <img
                              className="home-like-img-icon"
                              src={supportFill}
                            />
                          </Tooltip>
                        )}
                        {allLikes?.hasOwnProperty("5") && (
                          <Tooltip
                            title={"Agree  " + allLikes["5"]}
                            placement="top"
                          >
                            <span>
                              <img
                                className="home-like-img-icon"
                                src={agreeFill}
                              />
                            </span>
                          </Tooltip>
                        )}

                        {this.state.likeStatusState ? (
                          <>
                            {this.state.likesLength === 1 ? (
                              <></>
                            ) : (
                              <>
                                {t("You and ")}
                                {this.state.likesLength > 0
                                  ? this.state.likesLength - 1
                                  : 0}

                                {this.state.likesLength === 2
                                  ? t(" other")
                                  : t(" others")}
                              </>
                            )}
                          </>
                        ) : this.state.likesLength !== 0 ? (
                          <>
                            {" "}
                            {this.state.likesLength}
                            {this.state.likesLength > 1
                              ? t(" others")
                              : t(" other")}
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                      <hr class="m-0 " />
                    </>
                  )}
                  {this.props.likePointIndex === this.props.index && (
                    <span className="like-alert">
                      <PointAnimation />
                    </span>
                  )}
                </div>
                {/* {this.props.postDescriptionPage && <hr class="m-0 " />} */}

                <ul className="nav comment-nav-stack comment-py-3 comment-small mt-2">
                  {this.state.postCategory !== "advertisement" &&
                    this.state.postCategory !== "polling" && (
                      <>
                        {this.props.data.postCategory !== "product" && (
                          <li
                            className={`${
                              !this.state.postValue?.userlikeType &&
                              "nav-item-post-card"
                            } position-relative`}
                          >
                            {/* <div className="position-relative"> */}
                            <OverlayTrigger
                              // show={this.state.likePopoverStatus}
                              trigger="click"
                              placement="top"
                              overlay={this.likePopover()}
                              rootClose={true}
                            >
                              <a
                                className="comment-nav-link"
                                // className={
                                //   this.state.likeStatusState
                                //     ? "comment-nav-link active"
                                //     : "comment-nav-link"
                                // }
                                onClick={() =>
                                  this.userLikeType(
                                    "like",
                                    this.state.postValue.postType
                                  )
                                }
                                // onClick={() =>
                                //   this.handleClickDefaultLike(
                                //     'like',
                                //     this.state.postValue.postType,
                                //   )
                                // }
                                style={{
                                  float: "left",
                                  cursor: "pointer",
                                  textTransform: "capitalize",
                                }}
                              >
                                {this.state.postValue?.userlikeType == "1" ? (
                                  <img
                                    className="home-img-icon mb-3 "
                                    src={loveFill}
                                    style={{
                                      height: "55px",
                                      width: "auto",
                                    }}
                                  />
                                ) : this.state.postValue?.userlikeType ==
                                  "2" ? (
                                  <img
                                    className="home-img-icon mb-3  "
                                    src={careFill}
                                  />
                                ) : this.state.postValue?.userlikeType ==
                                  "3" ? (
                                  <img
                                    className="home-img-icon mb-3  "
                                    src={celebrateFill}
                                  />
                                ) : this.state.postValue?.userlikeType ==
                                  "4" ? (
                                  <img
                                    className="home-img-icon mb-3 "
                                    src={supportFill}
                                  />
                                ) : this.state.postValue?.userlikeType ==
                                  "5" ? (
                                  <img
                                    className="home-img-icon mb-3 "
                                    src={agreeFill}
                                  />
                                ) : (
                                  <BiLike
                                    style={{
                                      width: "18px",
                                      height: "20px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  // <img
                                  //   class="home-img-icon-like mb-1"
                                  //   src={defaultUnFill}
                                  // />
                                )}
                                {this.state.likeStatusState ? (
                                  <span class="ml-1">
                                    {t(
                                      `${
                                        this.state.postValue.userlikeType == 1
                                          ? "Love"
                                          : this.state.postValue.userlikeType ==
                                            2
                                          ? "Care"
                                          : this.state.postValue.userlikeType ==
                                            3
                                          ? "Celebrate"
                                          : this.state.postValue.userlikeType ==
                                            4
                                          ? "Support"
                                          : this.state.postValue.userlikeType ==
                                            5
                                          ? "Agree"
                                          : "Like"
                                      }`
                                    )}
                                  </span>
                                ) : (
                                  <span class="ml-1">{t("Like")}</span>
                                )}{" "}
                                {/* ({this.state.likesLength}) */}
                                {/* <AiFillLike
                              style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "0.25rem",
                              }}
                            ></AiFillLike>{" "}
                            
                            {this.state.likeStatusState
                              ? t("Liked")
                              : this.state.likesLength > 1
                              ? t("Likes")
                              : t("Like")}{" "}
                            ({this.state.likesLength}) */}
                              </a>
                            </OverlayTrigger>
                            {/* </div> */}
                          </li>
                        )}
                        <li
                          className="nav-item-post-card"
                          onClick={this.handleClickComment}
                        >
                          <a
                            className="comment-nav-link"
                            style={{ float: "left" }}
                          >
                            {" "}
                            <FaComment
                              style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "0.25rem",
                              }}
                            />{" "}
                            {commendsCount > 1 ? t("Comments") : t("Comment")} (
                            {commendsCount})
                          </a>
                        </li>
                      </>
                    )}
                  {this.props.data.postCategory !== "polling" &&
                    // this.props.data.postCategory !== "product" &&
                    this.props.data.postCategory !== "survey" &&
                    this.props.data?.status !== "private" && (
                      // this.props.data.userId !==
                      //   this.props.loginDetails.uId &&
                      <div
                        style={{
                          display: "inline-flex",

                          position: "relative",
                        }}
                      >
                        <li
                          className="nav-item-post-card"
                          onClick={this.shareOptionPopup}
                        >
                          {/* <OverlayTrigger
                                trigger="click"
                                placement="top"
                                overlay={this.socialPopover()}
                                rootClose
                              > */}
                          <a
                            className={"comment-nav-link"}
                            style={{ float: "left" }}
                          >
                            {" "}
                            <Share
                              style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "0.25rem",
                              }}
                            ></Share>{" "}
                            {t("Share")}{" "}
                          </a>
                          {/* </OverlayTrigger> */}
                        </li>
                        <div>
                          {shareOptionPopup && (
                            <div
                              className="dropdownshareCard"
                              ref={this.wrapperRef}
                            >
                              {this.props.defaultWorkSpaceId === 0 && (
                                <>
                                  <h6
                                    style={{
                                      // fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t(
                                      `Share this ${
                                        this.props.data.postCategory ===
                                        "product"
                                          ? "products"
                                          : "post"
                                      }  in social media..`
                                    )}
                                  </h6>
                                  <hr className="m-0 pb-2" />
                                  <div
                                    className="d-flex align-items-center mb-2 mt-2"
                                    style={{ gap: "1rem" }}
                                  >
                                    <WhatsappShareButton
                                      url={this.shareUrl()}
                                      title={removeHTML(
                                        this.state.postCategory === "article"
                                          ? this.state.article?.title
                                          : this.props.data.postCategory ===
                                            "product"
                                          ? getProductContent(
                                              this.props.data.productDetails
                                            )
                                          : type === "text"
                                          ? this.state.postValue?.content
                                          : this.state.postValue?.fileContent
                                      )}
                                      separator="  "
                                      metaTags={this.generateMetaTags(
                                        this.state.postValue
                                      )}
                                    >
                                      <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <TwitterShareButton
                                      url={this.shareUrl()}
                                      title={removeHTML(
                                        this.state.postCategory === "article"
                                          ? this.state.article?.title
                                          : this.props.data.postCategory ===
                                            "product"
                                          ? getProductContent(
                                              this.props.data.productDetails
                                            )
                                          : type === "text"
                                          ? this.state.postValue?.content
                                          : this.state.postValue?.fileContent
                                      )}
                                      separator="  "
                                      metaTags={this.generateMetaTags(
                                        this.state.postValue
                                      )}
                                    >
                                      <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                    <FacebookShareButton
                                      url={this.shareUrl()}
                                      title={removeHTML(
                                        this.state.postCategory === "article"
                                          ? this.state.article?.title
                                          : this.props.data.postCategory ===
                                            "product"
                                          ? getProductContent(
                                              this.props.data.productDetails
                                            )
                                          : type === "text"
                                          ? this.state.postValue?.content
                                          : this.state.postValue?.fileContent
                                      )}
                                      separator="  "
                                      metaTags={this.generateMetaTags(
                                        this.state.postValue
                                      )}
                                    >
                                      <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <LinkedinShareButton
                                      url={this.shareUrl()}
                                      title={removeHTML(
                                        this.state.postCategory === "article"
                                          ? this.state.article?.title
                                          : this.props.data.postCategory ===
                                            "product"
                                          ? getProductContent(
                                              this.props.data.productDetails
                                            )
                                          : type === "text"
                                          ? this.state.postValue?.content
                                          : this.state.postValue?.fileContent
                                      )}
                                      separator="  "
                                      metaTags={this.generateMetaTags(
                                        this.state.postValue
                                      )}
                                    >
                                      <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                    <TelegramShareButton
                                      url={this.shareUrl()}
                                      title={removeHTML(
                                        this.state.postCategory === "article"
                                          ? this.state.article?.title
                                          : this.props.data.postCategory ===
                                            "product"
                                          ? getProductContent(
                                              this.props.data.productDetails
                                            )
                                          : type === "text"
                                          ? this.state.postValue?.content
                                          : this.state.postValue?.fileContent
                                      )}
                                      separator="  "
                                      metaTags={this.generateMetaTags(
                                        this.state.postValue
                                      )}
                                    >
                                      <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                  </div>{" "}
                                </>
                              )}
                              {this.state.postCategory !== "advertisement" &&
                                this.props.data.postCategory !== "product" && (
                                  <>
                                    <h6
                                      className="mt-2"
                                      style={{
                                        // fontSize: "13px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {t("Share your post in yuukke..")}
                                    </h6>
                                    <hr className="m-0 pb-2" />
                                    <p
                                      className="dropdownshareList"
                                      onClick={(e) => this.optionType(e, 1)}
                                    >
                                      {t("Share Public")}
                                    </p>
                                    {this.props.data.postCategory !==
                                      "article" && (
                                      <>
                                        <p
                                          className="dropdownshareList"
                                          onClick={(e) => this.optionType(e, 2)}
                                        >
                                          {t("Share Privately")}
                                        </p>
                                        <p
                                          className="dropdownshareList"
                                          onClick={(e) => this.optionType(e, 3)}
                                        >
                                          {t("Share with Community")}
                                        </p>{" "}
                                      </>
                                    )}
                                  </>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  {this.state.postCategory === "advertisement" &&
                    this.state.postCategory !== "polling" && (
                      <li className="nav-item-post-card">
                        <a
                          className={"comment-nav-link"}
                          onClick={this.subscribeAdvertisement}
                          // style={{ float: "left" }}
                        >
                          {" "}
                          <FaComment
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "0.25rem",
                            }}
                          />
                          {this.state.postValue?.userId !==
                          this.props.loginDetails.uId
                            ? t("Chat with seller")
                            : t("View post related chat")}
                        </a>
                      </li>
                    )}
                </ul>

                {this.props.postDescriptionPage && <hr class="mb-3 mt-0" />}

                {this.state.openCommentBoxes && (
                  <div className="d-flex mb-3">
                    <div className="post-avatar post-avatar-xs post-me-2">
                      <a href="#!">
                        {" "}
                        <img
                          className="post-avatar-img rounded-circle"
                          src={
                            API_URL +
                            "api/getUserPhoto/" +
                            this.props.loginDetails.uId +
                            "/" +
                            this.props.loginDetails.displayName
                          }
                          alt="user"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = dummyimage;
                          }}
                        />{" "}
                      </a>
                    </div>
                    <form className="post-position-relative post-w-100">
                      {!this.state.commentsEdit && (
                        <div class="row mt-2">
                          <div class="col-9 col-sm-6 col-md-8 col-lg-10">
                            <MentionsInput
                              placeholder={"Add a comment ..."}
                              editorStateValue={this.commentValues}
                              friendList={this.props.memberList}
                              type={"friends"}
                              needEdit={this.state.needEdit}
                              changeNeedHelpState={this.changeNeedHelpState}
                              editorValue={""}
                            />
                            {/* <TextEditorComments
                              editorStateValue={this.commentValues}
                              friendList={this.props.FriendsList}
                              editorValue={this.state.editorValue}
                              needEdit={this.state.needEdit}
                              changeNeedHelpState={this.changeNeedHelpState}
                            /> */}
                            {/* <textarea
                              className="post-form-control post-pe-4 post-bg-light"
                              rows="1"
                              placeholder={t("Add a comment...")}
                              maxLength="255"
                              type="text"
                              onKeyDown={this.handleFormEnterKey}
                              onChange={this.handleForm}
                              value={this.state.commentsText}
                              name="commentsText"
                              style={{ overflow: "hidden" }}
                            ></textarea> */}
                          </div>
                          <div class="col-3 col-sm-6 col-md-4 col-lg-2">
                            <AiOutlineSend
                              style={{
                                width: "29px",
                                height: "51px",
                                cursor: "pointer",
                                color: "#676a79",
                              }}
                              onClick={() =>
                                this.commentHit(
                                  this.state.postValue.postId,
                                  this.state.postValue.postType
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                      {this.state.commentsEdit && (
                        <div class="row mt-2">
                          <div class="col-9 col-sm-6 col-md-8 col-lg-10">
                            <MentionsInput
                              placeholder={"Update comment.."}
                              editorStateValue={this.commentEditValues}
                              editorValue={this.state.commentsEditText}
                              friendList={this.props.memberList}
                              type={"friends"}
                              needEdit={this.state.needEdit}
                              changeNeedHelpState={this.changeNeedHelpState}
                            />
                            {/* <TextEditorComments
                              editorStateValue={this.commentEditValues}
                              editorValue={this.state.commentsEditText}
                              friendList={this.props.FriendsList}
                              needEdit={this.state.needEdit}
                              changeNeedHelpState={this.changeNeedHelpState}
                            /> */}
                            {/* <textarea
                              className="post-form-control post-pe-4 post-bg-light"
                              rows="1"
                              placeholder={t("Edit a comment...")}
                              maxLength="255"
                              type="text"
                              onChange={this.handleForm}
                              onKeyDown={this.handleFormEnterKeyEdit}
                              value={this.state.commentsEditText}
                              name="commentsEditText"
                              style={{ overflow: "auto" }}
                              //  style="overflow:auto"
                            ></textarea> */}
                          </div>
                          <div class="col-3 col-sm-6 col-md-4 col-lg-2">
                            <FiRefreshCw
                              style={{
                                width: "29px",
                                height: "41px",
                                cursor: "pointer",
                                color: "#676a79",
                              }}
                              onClick={() =>
                                this.commentEditHit(
                                  this.state.commentId,
                                  this.state.postValue.postId,
                                  this.state.postValue.postType
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                )}

                {this.state.openCommentBoxes && (
                  <div className="comment-overflow">
                    {this.state.commentList &&
                      this.state.commentList
                        // .slice(0, 2)
                        .map((comments, index) => (
                          <>
                            <ul
                              className="comment-wrap list-unstyled"
                              key={index}
                            >
                              <li className="comment-item">
                                <div className="d-flex position-relative">
                                  <div className="post-avatar post-avatar-xs">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.showFriendsProfile(comments.userId)
                                      }
                                      className="post-avatar-img rounded-circle"
                                      src={
                                        API_URL +
                                        "api/getUserPhoto/" +
                                        comments.userId +
                                        "/" +
                                        comments.userName
                                      }
                                      alt="user"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = dummyimage;
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="ms-2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    <div
                                      className="bg-light rounded-start-top-0 rounded"
                                      style={{ padding: "0.6rem" }}
                                    >
                                      <div className="post-nav post-nav-divider">
                                        <h6
                                          className="post-nav-item post-card-title post-mb-0"
                                          style={{
                                            color: "#14191e",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.showFriendsProfile(
                                              comments.userId
                                            )
                                          }
                                        >
                                          {" "}
                                          {comments.userName}{" "}
                                        </h6>
                                        <span className="post-nav-item post-small">
                                          {" "}
                                          {moment(comments.createdDate)
                                            .locale("en")
                                            .format("lll")}
                                        </span>
                                        <div className="action-bar">
                                          <div
                                            style={{ display: "inline-flex" }}
                                          >
                                            {(comments.userId ==
                                              this.props.loginDetails.uId ||
                                              this.props.loginUserType == 12 ||
                                              this.props.loginUserRole !==
                                                0) && (
                                              <div
                                                style={{
                                                  display: "inline-flex",
                                                  float: "right",
                                                }}
                                              >
                                                <span class="action-item">
                                                  <div
                                                    onClick={() =>
                                                      this.commentEdit(
                                                        comments.id,
                                                        comments.commends,
                                                        this.state.postValue
                                                          .postType
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <FiEdit2
                                                      style={{
                                                        width: "20px",
                                                        height: "18px",
                                                        cursor: "pointer",
                                                        color: "#676a79",
                                                      }}
                                                    />
                                                  </div>
                                                </span>
                                                <span class="action-item">
                                                  <div
                                                    onClick={(e) =>
                                                      this.deleteComment(
                                                        e,
                                                        comments.id,

                                                        this.state.postValue
                                                          .postId,
                                                        this.state.postValue
                                                          .postType
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <MdDeleteOutline
                                                      style={{
                                                        width: "22px",
                                                        height: "22px",
                                                        cursor: "pointer",
                                                        color: "#676a79",
                                                      }}
                                                    />{" "}
                                                  </div>
                                                </span>
                                              </div>
                                            )}
                                            {comments.userId !=
                                              this.props.loginDetails.uId && (
                                              <span class="action-item">
                                                <div
                                                  onClick={() =>
                                                    this.reportCommentClick(
                                                      comments.id
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <MdOutlineReport
                                                    style={{
                                                      width: "22px",
                                                      height: "22px",
                                                      cursor: "pointer",
                                                      color: "#676a79",
                                                    }}
                                                  />{" "}
                                                </div>
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <p
                                        class="small mb-0"
                                        style={{ wordBreak: "break-word" }}
                                      >
                                        <p onClick={this.handleHashTag}>
                                          {htmlValidation(comments.commends)}
                                        </p>
                                        {/* <p
                                          onClick={this.handleComments}
                                          className="about-mb-2"
                                          dangerouslySetInnerHTML={{
                                            __html: comments.commends,
                                          }}
                                        /> */}
                                        {/* {htmlValidation(comments.commends)} */}
                                        {/* {comments.commends} */}
                                      </p>
                                      <div className="d-flex justify-content-between">
                                        <div className="ml-1">
                                          {/* <button
                                            onClick={() =>
                                              this.replayComment(
                                                comments.id,
                                                index
                                              )
                                            }
                                            className="replayButtonPost"
                                          >
                                            Reply
                                          </button> */}
                                          <p
                                            onClick={() =>
                                              this.replayComment(
                                                comments.id,
                                                index
                                              )
                                            }
                                            className="replayComment"
                                          >
                                            {t("Reply")}
                                          </p>
                                        </div>

                                        {comments.replayCount > 0 && (
                                          <div
                                            className="mr-1"
                                            style={{ display: "flex" }}
                                            onClick={() =>
                                              this.getReplayList(
                                                comments.id,
                                                index
                                              )
                                            }
                                          >
                                            {/* <p
                                              style={{
                                                // textDecoration: "underline",
                                                cursor: "pointer",

                                                color: "#A00030",
                                              }}
                                            >
                                              {comments.replayCount}
                                              {"_"}
                                            </p>{" "} */}
                                            <p
                                              style={{
                                                color: "blue",
                                                cursor: "pointer",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {t("View")} {comments.replayCount}{" "}
                                              {comments.replayCount > 1
                                                ? t("Replies")
                                                : t("Reply")}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            {this.state.commentsId === comments.id &&
                              // this.state.replayIndex === index && (

                              this.state.newReplayStatus && (
                                <div style={{ paddingBottom: "32px" }}>
                                  <div class="mb-1 d-flex align-items-center">
                                    <div
                                      style={{
                                        width: "78%",
                                        marginLeft: "3.7rem",
                                      }}
                                    >
                                      <MentionsInput
                                        placeholder={"Add Reply ..."}
                                        editorStateValue={this.replyValues}
                                        editorValue={this.state.editorValue}
                                        friendList={this.props.memberList}
                                        type={"friends"}
                                        needEdit={this.state.needEdit}
                                        changeNeedHelpState={
                                          this.changeNeedHelpState
                                        }
                                      />
                                      {/* <TextEditorComments
                                        editorStateValue={this.replyValues}
                                        friendList={this.props.FriendsList}
                                        editorValue={this.state.editorValue}
                                        needEdit={this.state.needEdit}
                                        changeNeedHelpState={
                                          this.changeNeedHelpState
                                        }
                                      /> */}
                                      {/* <textarea
                                        placeholder={t("Add a reply.....")}
                                        maxLength="255"
                                        type="text"
                                        onKeyDown={
                                          this.handleFormEnterKeyReplay
                                        }
                                        onChange={this.handleForm}
                                        value={this.state.replayList}
                                        name="replayList"
                                        style={{ overflow: "hidden" }}
                                      ></textarea> */}
                                    </div>
                                    <div className="ml-2">
                                      {this.state.replyUpdateStatus && (
                                        <button
                                          onClick={this.updateReplay}
                                          type="submit"
                                          class="reply-btn"
                                        >
                                          {" "}
                                          {t("Update")}{" "}
                                        </button>
                                      )}
                                      {!this.state.replyUpdateStatus && (
                                        <button
                                          onClick={this.saveReplay}
                                          type="submit"
                                          class="reply-btn"
                                        >
                                          {" "}
                                          {t("Reply")}{" "}
                                        </button>
                                      )}
                                    </div>
                                    {/* <div className="waring-box">
                                      <p>Note: Only 250 characters allow</p>
                                    </div> */}
                                    {/* <div
                                      class="col-11 col-sm-11 col-md-11 col-lg-11"
                                      style={{ paddingLeft: "0.4rem" }}
                                    >
                                      <textarea
                                        className="post-replay-control post-pe-4 post-bg-color"
                                        rows="1"
                                        placeholder={t("Add a replay.....")}
                                        maxLength="255"
                                        type="text"
                                        // onKeyDown={this.handleFormEnterKeyReplay}
                                        onChange={this.handleForm}
                                        value={this.state.replayList}
                                        name="replayList"
                                        style={{ overflow: "hidden" }}
                                      ></textarea>
                                    </div> */}
                                  </div>
                                  {/* <button
                                    onClick={this.saveReplay}
                                    className="replayButton"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Create
                                  </button>
                                  <button
                                    onClick={this.clearReplay}
                                    className="replayButtonPost"
                                  >
                                    Close
                                  </button> */}
                                </div>
                              )}
                            {this.state.replayFullList &&
                              this.state.replayListStatus &&
                              //  this.state.replayListIndexStatus &&
                              this.state.commentsId === comments.id &&
                              this.state.replayFullList.map((reply, index) => (
                                <ul
                                  className="comment-wrap list-replay"
                                  key={index}
                                >
                                  <li className="comment-item">
                                    <div className="d-flex position-relative">
                                      <div className="post-avatar post-avatar-xs">
                                        <img
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.showFriendsProfile(
                                              reply.userId
                                            )
                                          }
                                          className="post-avatar-img rounded-circle"
                                          src={
                                            API_URL +
                                            "api/getUserPhoto/" +
                                            reply.userId +
                                            "/" +
                                            reply.userName
                                          }
                                          alt="user"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = dummyimage;
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="ms-2"
                                        style={{ marginLeft: "12px" }}
                                      >
                                        <div className="bg-light rounded-start-top-0 p-reply rounded">
                                          <div className="post-nav post-nav-divider">
                                            <h6
                                              className="post-nav-item post-card-title post-mb-0"
                                              style={{ color: "#14191e" }}
                                            >
                                              {" "}
                                              {reply.userName}{" "}
                                            </h6>
                                            <span className="post-nav-item post-small">
                                              {" "}
                                              {moment(reply.createdDate)
                                                .locale("en")
                                                .format("lll")}
                                            </span>
                                            {reply.userId ===
                                              this.props.loginDetails.uId && (
                                              <div
                                                style={{
                                                  display: "inline-flex",
                                                  float: "right",
                                                }}
                                              >
                                                <span class="action-item">
                                                  <div
                                                    onClick={() =>
                                                      this.commentReplyEdit(
                                                        reply.id,
                                                        comments.id,
                                                        reply.commends,
                                                        this.state.postValue
                                                          .postType
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <FiEdit2
                                                      style={{
                                                        width: "20px",
                                                        height: "18px",
                                                        cursor: "pointer",
                                                        color: "#676a79",
                                                      }}
                                                    />
                                                  </div>
                                                </span>
                                                <span class="action-item">
                                                  <div
                                                    onClick={() =>
                                                      this.deleteReply(
                                                        reply.id,
                                                        comments.id
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <MdDeleteOutline
                                                      style={{
                                                        width: "19px",
                                                        height: "19px",
                                                        cursor: "pointer",
                                                        color: "#676a79",
                                                        marginLeft: "8px",
                                                      }}
                                                    />{" "}
                                                  </div>
                                                </span>
                                              </div>
                                            )}
                                            {reply.userId !==
                                              this.props.loginDetails.uId && (
                                              <div
                                                style={{
                                                  display: "inline-flex",
                                                  float: "right",
                                                }}
                                              >
                                                <span class="action-item">
                                                  <div
                                                    onClick={() =>
                                                      this.reportReply(
                                                        reply.id,
                                                        comments.id
                                                      )
                                                    }
                                                  >
                                                    <MdOutlineReport
                                                      style={{
                                                        width: "22px",
                                                        height: "22px",
                                                        cursor: "pointer",
                                                        color: "#676a79",
                                                      }}
                                                    />{" "}
                                                  </div>
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                          <p
                                            class="small mb-0"
                                            style={{
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <p onClick={this.handleHashTag}>
                                              {htmlValidation(reply.commends)}
                                            </p>
                                            {/* <p
                                              onClick={this.handleComments}
                                              // className="about-mb-2"
                                              dangerouslySetInnerHTML={{
                                                __html: reply.commends,
                                              }}
                                            /> */}
                                            {/* {reply.commends} */}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              ))}
                          </>
                        ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {this.state.reportPostState && (
          <ReportModel
            id={this.state.postValue.postId}
            typeData={
              this.state.postValue.postType === "postList"
                ? "postList"
                : "groupMessage"
            }
            reportType="Post"
            closePopup={this.handleReportPopupClose}
            removePost={this.removePost}
          />
        )}
        {this.state.postDeletePopUp && this.popUpPostDelete()}
        {/* {this.state.reportCommentState && this.popUpCommentReport()} */}
        {this.state.reportCommentState && (
          <ReportModel
            id={this.state.reportCommentId}
            typeData={
              this.state.postValue.postType === "postList"
                ? "postCommend"
                : "groupCommend"
            }
            reportType="Comment"
            closePopup={this.handleReportCommentPopupClose}
            removePost={this.removePost}
          />
        )}
        {this.state.reportReplyState && (
          <ReportModel
            id={this.state.reportReplyId}
            typeData={
              this.state.postValue.postType === "postList"
                ? "postCommentReply"
                : "groupCommentReply"
            }
            reportType="Reply"
            closePopup={this.reportReply}
            removePost={this.removePost}
          />
        )}
        {shareOptionPopupList && this.optionTypesFriends()}
        {shareOptionGroupList && this.optionTypesGroup()}
        {this.state.sharePostPopup && this.sharePostComment()}
        {this.state.articleModel && this.articleModel()}
        {this.state.showSurveyResults && (
          <>
            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.showSurveyResults}
              onClose={() => this.handleModalclose()}
            >
              <DialogTitle>{t("Survey Results")}</DialogTitle>
              <DialogContent dividers="paper">
                {this.state.surveyQuestionsLoader ? (
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <SurveyAnswersList data={this.state.surveyQuestions} />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleModalclose()}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {this.state.viewGrafModel && <PieChart onClose={this.onCloseModel} />}
        {this.state.likeCountPopUpStatus && (
          <LikePopUp
            modelClose={this.likeCountPopUp}
            postId={this.state.postValue.postId}
            postType={this.state.postValue.postType}
            availableType={allLikes}
          />
        )}
      </>
    );
  }

  handleComments = (event) => {
    let htmlElement = event.target;
    const htmlString = htmlElement?.outerHTML;
    if (validateSpanTag(htmlString)) {
      console.log("F-Dis");
      this.moveToUserProfile(event, htmlString);
    }
  };
  moveToUserProfile = (event, htmlString) => {
    const id = extractNumberFromHref(htmlString);
    event.preventDefault();
    event.stopPropagation();
    this.showFriendsProfile(id);
    // event.preventDefault();
    // event.stopPropagation();
    // const regex =
    //   /<span style="color:#D92267;cursor: pointer;">@(\w+)<\/span>/i;
    // const match = htmlString.match(regex);
    // const value = match ? match[1] : null;
    // this.props.history.push(`/${this.props.workspaceType}/${"user"}/${value}`);
    // return;
    // var regex = />([^<]*)<\/a>/;
    // var text = regex.exec(htmlString)[1];
    // var value = text.slice(1);
    // if (value) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   console.log("value", value);
    //   this.props.history.push(`/${this.props.workspaceType}/hash-tag/${value}`);
    //   ;
    // }
  };
  handleHashTag = (event) => {
    const clickedText = event.target.textContent;
    const regex = /^#[^\s]+$/;
    //Hash Tag validation
    if (regex.test(clickedText)) {
      const newStr = clickedText.replace(/#/g, "");
      this.props.history.push(
        `/${this.props.workspaceType}/${"hash"}/${newStr}`
      );
      return;
    }

    //User Profile validation
    let htmlElement = event.target;
    const htmlString = htmlElement?.outerHTML;

    if (validateSpanTag(htmlString)) {
      this.moveToUserProfile(event, htmlString);
    } else if (htmlString && convertString(htmlString).includes("@All")) {
      event.preventDefault();
      event.stopPropagation();
    }

    // if (isContainId(htmlString)) {
    //   this.moveToUserProfile(event, htmlString);
    // }
  };

  openViewModel = (id) => {
    this.props.getPieChartList(
      this.state.postValue.postId,
      this.state.postValue.postType
    );
    this.setState({
      viewGrafModel: !this.state.viewGrafModel,
      menuStatewithDelete: !this.state.menuStatewithDelete,
    });
  };
  onCloseModel = () => {
    this.setState({
      viewGrafModel: !this.state.viewGrafModel,
    });
  };

  subscribe = (boolean, id) => {
    this.setState({
      menuState: false,
    });
    let type = {
      typeData: this.state.postValue.postType,
      postId: this.state.postValue.postId,
      isSubscribe: boolean,
    };

    PostService.post_subscribe(type)
      .then((response) => {
        if (response.data.status === -2) {
          this.postNotPresent();
        } else {
          if (this.props.isGroup || this.props.postDescriptionPage) {
            //Only  update the post is group and shared post
            this.props.subscribeUpdate(
              response.data.data.subscribe,
              "subscribe"
            );
          } else {
            this.props.subscribePost(
              response.data.data.subscribe,
              "subscribe",
              boolean,
              this.state.postValue.moderationId
            );
            // this.props.subscribeUpdate(
            //   response.data.data.subscribe,
            //   "subscribe"
            // );
          }

          if (this.state.postCategory !== "advertisement") {
            toast.success(
              boolean
                ? `${this.props.t(
                    "Subscribed successfully ,now you get this post activity notifications"
                  )}`
                : `${this.props.t("Unsubscribed successfully")}`
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  commentReplyEdit = (replyId, replyCommentId, commends) => {
    this.setState({
      newReplayStatus: true,
      commentsId: replyCommentId,
      replyId: replyId,
      replyUpdateStatus: true,
      replayList: commends,
      replayListStatus: false,
      editorValue: removeHtmlTags(commends),
      // editorValue: commends,
      needEdit: true,
    });
  };
  updateReplay = () => {
    if (this.state.replayList === "") {
      toast.warning(`${this.props.t("Please add your reply")}`);
    } else {
      let editComment = {
        replyId: this.state.replyId,
        comment: removeMentionTags(this.state.replayList),
        // comment: extractMentionsFromHTML(this.state.replayList),
        // comment: this.state.replayList,
        commentId: this.state.commentsId,
        postId: this.props.data.postId,
        postLikeAndCommendType: "commentReply",
        type: this.state.postValue.postType,
        userId: this.props.loginDetails.uId,
        userName: this.props.loginDetails.userName,
      };

      PostService.updatePostCommandReplay(editComment)
        .then((response) => {
          if (response.data.status === -2) {
            this.postNotPresent();
          } else {
            this.setState({
              newReplayStatus: false,
              replayListStatus: true,
              editorValue: "",
              needEdit: true,
            });

            this.setState({
              replayFullList: response.data.data.replay,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  replayComment = (id, index) => {
    if (this.state.replayIndex === index) {
      this.setState({
        replayList: "",
        commentsId: id,
        newReplayStatus: false,
        replayIndex: -1,
        replayListStatus: false,
        replyUpdateStatus: false,
      });
    } else {
      this.setState({
        replayList: "",
        commentsId: id,
        newReplayStatus: true,
        replayIndex: index,
        replayListStatus: false,
        replyUpdateStatus: false,
      });
    }
  };
  deleteReply = (replyId, comId) => {
    let deleteCommends = {
      commentId: replyId,
      type: this.state.postValue.postType,
    };

    PostService.getPostCommandReplayDelete(deleteCommends)
      .then((response) => {
        if (response.data.data.commentReply === "deleted") {
          this.updateReplyStatus(replyId, comId);
          // var postList = this.state.replayFullList;
          // postList = this.state.replayFullList.filter((item) => item.id != id);
          // let commentList = this.state.commentList;
          // commentList.forEach((element) => {
          //   if (element.id === comId) {
          //     element.replayCount = element.replayCount - 1;
          //   }
          // });
          // console.log("commentList old", this.state.commentList);
          // this.setState({
          //   replayFullList: postList,
          // });
          toast.success(`${this.props.t("Reply Deleted Successfully")}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  clearReplay = () => {
    this.setState({
      replayList: "",
      commentsId: 0,
      replayListStatus: false,
    });
  };
  getReplayList = (id, index) => {
    if (this.state.replayListIndex === index) {
      this.setState({
        replayList: "",
        commentsId: id,
        replayListStatus: false,
        replayListIndex: -1,
        replayListIndexStatus: false,
        replyUpdateStatus: false,
      });
      console.log("fun_1", index);
    } else {
      console.log("fun_2", index);

      this.setState({
        replayList: "",
        commentsId: id,
        replayListStatus: true,
        replayListIndex: index,
        replayListIndexStatus: true,
        replyUpdateStatus: false,
      });

      let deleteCommends = {
        commentId: id,
        postId: this.state.postValue.postId,
        type: this.state.postValue.postType,
        postLikeAndCommendType: "commend",
      };

      PostService.getPostCommandReplayWithoutPagination(deleteCommends)
        .then((response) => {
          var result = response.data.data.commendList;
          this.setState({
            replayFullList: result,
            commentsId: id,
            replayListStatus: true,
            replayList: "",
            newReplayStatus: false,
          });
          //  this.setState({FirstTwocomment: true})
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  saveReplay = (id) => {
    if (
      this.state.replayList !== undefined &&
      this.state.replayList.trim() == ""
    ) {
      toast.warn(`${this.props.t("Please add your comments")}`);
    } else {
      let deleteCommends = {
        // comment: extractMentionsFromHTML(this.state.replayList),
        comment: removeMentionTags(this.state.replayList),
        commentId: this.state.commentsId,
        postId: this.state.postValue.postId,
        postLikeAndCommendType: "commentReply",
        type: this.state.postValue.postType,
        userId: this.props.loginDetails.uId,
        userName: this.props.loginDetails.userName,
      };

      PostService.savePostCommandReplay(deleteCommends)
        .then((response) => {
          if (response.data.status === -2) {
            this.postNotPresent();
          } else {
            let oldList = this.state.replayFullList;
            let commentList = this.state.commentList;

            if (response.data.data.replay) {
              // newReply = oldList.concat(response.data.data.replay);
              commentList.forEach((element) => {
                if (element.id === this.state.commentsId) {
                  element.replayCount = element.replayCount + 1;
                }
              });
              console.log("commentList new", commentList);
            }
            this.setState({
              commentsId: this.state.commentsId,
              replayFullList: response.data.data.replay,
              replayListStatus: true,
              replayList: "",
              newReplayStatus: false,
              editorValue: "",
              needEdit: true,
            });
          }
          //  this.setState({FirstTwocomment: true})
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  editOptionClose = () => {
    this.setState({
      editOption: false,
    });
  };
  updatePost = (e) => {
    //update Group post
    e.preventDefault();
    e.stopPropagation();

    if (
      this.state.content.replace(/([&nbsp;<p></p>\"'])/g, "").trimStart() == ""
    ) {
      toast.warn(`${this.props.t("Please add your comment")}`);
    } else {
      var share = {
        postId: this.props.data.moderationId,
        type: this.state.postValue.postType,
        content: extractMentionsFromUpdate(this.state.content),
      };
      console.log(
        "extractMentionsFromHTML",
        extractMentionsFromUpdate(this.state.content)
      );
      PostService.updateHomeAndGroupPost(share)

        .then((response) => {
          let result = response.data.data;
          if (this.props.isGroup) {
            console.log("ress", result);
            if (result) {
              toast.success(`${this.props.t("Post Updated Successfully")}`);
              this.props.updatePost(result);
            } else {
              toast.error(`${this.props.t("Error in saving post")}`);
            }
          } else {
            //post
            this.props.updatePostData(result, "Post Updated Successfully");
            // }
          }

          this.setState({
            textBoxStatus: false,
            questionDescribed: "",
            editOption: false,
            content: "",
          });
          // var result = response.data;
        })
        .catch((error) => {
          console.log(error);
          toast.error(`${this.props.t("Error in saving post")}`);
          this.setState({
            textBoxStatus: false,
            questionDescribed: "",
            editOption: false,
            content: "",
          });
        });
    }
  };
  textBoxStatusBack = () => {
    this.setState({
      textBoxStatus: false,
    });
  };
  editClick = () => {
    if (this.state.postCategory === "article") {
      console.log("content", this.state.postValue?.article?.description);
      this.setState({
        textBoxStatus: true,
        articleModel: true,
        questionDescribed: this.state.postValue?.article?.description,
        articleHeading: this.state.postValue?.article?.title,
        content: this.state.postValue?.article?.description,
        menuStatewithDelete: !this.state.menuStatewithDelete,
      });
    } else if (
      this.state.postValue.content !== null &&
      this.state.postValue.content !== ""
    ) {
      this.setState({
        textBoxStatus: true,
        questionDescribed: this.state.postValue.content,
        editOption: true,
        content: this.state.postValue.content,
        menuStatewithDelete: !this.state.menuStatewithDelete,
      });
    } else if (
      this.state.postValue.fileContent !== null &&
      this.state.postValue.fileContent !== ""
    ) {
      this.setState({
        textBoxStatus: true,
        questionDescribed: this.state.postValue.fileContent,
        editOption: true,
        content: this.state.postValue.fileContent,
        menuStatewithDelete: !this.state.menuStatewithDelete,
      });
    } else {
      this.setState({
        textBoxStatus: true,
        questionDescribed: "",
        editOption: true,
        content: "",
        menuStatewithDelete: !this.state.menuStatewithDelete,
      });
    }
  };
  commentValues = (values) => {
    // var htmlString = values;
    // var plainString = htmlString.replace(/<[^>]+>/g, "");
    // if (plainString.length === 1) {
    //   console.log("vvv1");
    //   this.setState({
    //     commentsText: values,
    //   });
    // } else {
    //   console.log("vvv2");
    this.setState({
      commentsText: values,
    });

    //   console.log("Values", this.state.commentsText);
    // }
  };
  changeNeedHelpState = () => {
    this.setState({
      needEdit: false,
    });
  };
  replyValues = (values) => {
    // var htmlString = values;
    // var plainString = htmlString.replace(/<[^>]+>/g, "");
    // if (plainString.length === 1) {
    //   this.setState({
    //     replayList: values,
    //   });
    // } else {
    this.setState({
      replayList: values,
    });
    // }
  };
  commentEditValues = (values) => {
    // var htmlString = values;
    // var plainString = htmlString.replace(/<[^>]+>/g, "");
    // if (plainString.length === 1) {
    //   this.setState({
    //     commentsEditText: values,
    //   });
    // } else {
    this.setState({
      commentsEditText: values,
    });
    // }
  };
  editorStateValues = (values) => {
    console.log("editorStateValues");
    console.log(values);

    var htmlString = values;
    var plainString = htmlString.replace(/<[^>]+>/g, "");
    if (plainString.length === 1) {
      this.setState({
        content: values,
        plainStringContent: "",
        descriptionCount: 0,
      });
    } else {
      this.setState({
        content: values,
        plainStringContent: plainString,
        descriptionCount: plainString.length,
      });
    }
  };
  deleteComment(e, commentId, pid, type) {
    this.commentDelete(e, commentId, type);
    // this.setState({
    //   // commentDeletePopUp: true,
    //   commentId: id,
    //   postId: pid,
    //   postType: type,
    // });
  }
  commentDelete(e, commentId, postType) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    let deleteCommends = {
      id: commentId,
      type: postType,
    };

    PostService.deleteCommends(deleteCommends)
      .then((response) => {
        var postList = this.state.commentList;
        postList = this.state.commentList.filter(
          (item) => item.id != commentId
        );
        toast.success(`${this.props.t("Comment Deleted Successfully")}`);
        console.log("commentList new", postList);

        this.setState({
          commendsCount: postList.length,
          commentsEdit: false,
          commentsText: "",
          commentList: postList,
          commentsEditText: "",
          commendsLength: this.state.commendsLength - 1,
        });

        if (this.props.isGroup) {
          this.props.commendsUpdate(
            this.state.postValue.postId,
            postList.length
          );
        } else {
          this.props.commentsCountUpdate(
            this.state.postValue.postId,
            postList.length
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  commentListBox() {
    const { t } = this.props;
    return (
      <div>
        <>
          {this.state.commentsEdit && (
            <div className="writeComment flexCenter">
              <div class="postuserImg">
                <img
                  className="rounded-circle"
                  src={
                    this.props.loginDetails.profile_uuid
                      ? `${APPCONFIG.API_URL}auth/image/${this.props.loginDetails.profile_uuid}`
                      : dummyimage
                  }
                  alt="user"
                />
              </div>
              <div className="commentInput">
                <input
                  // maxLength="35"
                  type="text"
                  placeholder="Edit a comment.."
                  onChange={this.handleForm}
                  value={this.state.commentsEditText}
                  name="commentsEditText"
                  style={{ width: "88%" }}
                />
                <button
                  className="sendbtn"
                  onClick={() =>
                    this.commentEditHit(
                      this.state.commentId,
                      this.state.postValue.postId,
                      this.state.postValue.postType
                    )
                  }
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </>
        {/* {this.state.commentDeletePopUp && this.popUpPostDelete()} */}
      </div>
    );
  }
  addBookMark = (postId, typeData, userBookMark) => {
    this.setState({
      menuState: false,
    });
    let data = {
      postId: postId,
      typeData: typeData,
      isBookmark: userBookMark ? false : true,
    };
    PostService.post_bookmark(data)
      .then((response) => {
        if (response.data.status === -2) {
          this.postNotPresent();
        } else {
          toast.success(
            userBookMark
              ? `${this.props.t("Bookmarked removed successfully")}`
              : `${this.props.t("Bookmarked added successfully")}`
          );
          if (this.props.isGroup || this.props.postDescriptionPage) {
            this.props.subscribeUpdate(
              postId,
              "bookmark",
              userBookMark ? false : true,
              this.state.postValue.moderationId
            );
          } else {
            this.props.subscribePost(
              postId,
              "bookmark",
              userBookMark ? false : true,
              this.state.postValue.moderationId,
              this.props.isBookMarkType
              // this.props.type === "BookMark" && !userBookMark
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleClickDefaultLike = async (likeType) => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
      document.body.click();
      this.setState({
        likePopoverStatus: !this.state.likePopoverStatus,
      });
    } else {
      let userlike = this.state.postValue.userlike;

      // this.setState({
      //   // likeType: likeType,
      //   likePopoverStatus: !this.state.likePopoverStatus,
      //   likeStatusState: !this.state.likeStatusState,
      //   likesLength: this.state.likeStatusState
      //     ? this.state.postValue.likesCount - 1
      //     : this.state.postValue.likesCount + 1,
      // });
      const likeStatus = await this.getLikeStatus(likeType, userlike);
      const oldLikeStatus =
        this.state.postValue?.userlikeType === null &&
        !this.state.postValue?.userlike;
      const likeCount = oldLikeStatus
        ? this.state.postValue.likesCount + 1
        : !likeStatus
        ? this.state.postValue.likesCount - 1
        : this.state.postValue.likesCount;
      // console.log("likeStatusState", likeStatus);
      // console.log("postValue", this.state.postValue);
      // console.log("likeCount", likeCount);
      await this.setState((prevState) => ({
        postValue: {
          ...prevState.postValue,
          userlikeType:
            this.state.postValue?.userlikeType === likeType ? null : likeType,
          userlike:
            this.state.postValue?.userlikeType === likeType ? false : true,
          likesCount: likeCount,
        },
        likePopoverStatus: !this.state.likePopoverStatus,
        likeStatusState: likeStatus,
        likesLength: likeCount,
      }));

      this.likesButton(
        this.state.postValue.postId,
        likeType,
        this.state.postValue.postType,
        userlike,
        likeStatus,
        oldLikeStatus
      );
      document.body.click();
    }
  };
  getLikeStatus = (likeType, userlike) => {
    return this.state.postValue.userlikeType === likeType
      ? userlike
        ? false
        : true
      : true;
  };
  likesButton = (pid, likeType, type, userlike, likeStatus, oldLikeStatus) => {
    // console.log("userLike : payload : ", this.state);
    let data = {
      userId: this.props.loginDetails.uId,
      postId: pid,
      postLikeAndCommendType: "like",
      likes: 1,
      type: type,
      likeReactionType: likeType,
      userLike: likeStatus,
    };
    //Group like
    if (this.props.isGroup || this.props.postDescriptionPage) {
      HomeService.createpostLike(data)
        .then((response) => {
          if (response.data.status === -2) {
            this.postNotPresent();
          } else {
            var result = response.data;
            // this.setState({ likesLength: likeCounts });
            // this.likeStatus();
            this.props.likestatusUpdate(
              result,
              this.state.postValue.userlikeType === likeType
                ? userlike
                  ? false
                  : true
                : true,
              this.props.index,
              likeStatus
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //post like
      // console.log("postValue", this.state.postValue);
      this.props.createPostLike(data, this.props.index, oldLikeStatus);
      // if (
      //   this.state.postValue.userlikeType === likeType
      //     ? userlike
      //       ? false
      //       : true
      //     : true
      // ) {
      //   this.audioPlayer.current.play();
      //  }
    }
  };

  userLikeType = () => {
    this.setState({
      likePopoverStatus: !this.state.likePopoverStatus,
    });
  };
  likePopover() {
    return (
      <Popover id="popover-basic">
        {/* <Popover.Title as="h3">Share your SocialMedia</Popover.Title> */}
        <Popover.Content>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <img
              class="home-img-icon-like "
              src={loveFill}
              onClick={() => this.handleClickDefaultLike(1)}
              style={{
                height: "60px",
              }}
            />
            <img
              class="home-img-icon-like "
              src={agreeFill}
              style={{
                height: "54px",
              }}
              onClick={() => this.handleClickDefaultLike(5)}
            />
            <img
              class="home-img-icon-like "
              src={supportFill}
              style={{
                height: "51px",
                width: "46px",
              }}
              onClick={() => this.handleClickDefaultLike(4)}
            />

            <img
              class="home-img-icon-like"
              src={careFill}
              style={{
                height: "55px",
                width: "43px",
              }}
              onClick={() => this.handleClickDefaultLike(2)}
            />
            <img
              class="home-img-icon-like"
              style={{
                height: "49px",
                width: "50px",
              }}
              src={celebrateFill}
              onClick={() => this.handleClickDefaultLike(3)}
            />
            {/* ) : (
              <img
                class="home-img-icon"
                src={supportUnFill}
                onClick={() => this.handleClickDefaultLike("support")}
                onMouseOver={(e) => (e.currentTarget.src = supportFill)}
                onMouseOut={(e) => (e.currentTarget.src = supportUnFill)}
              />
            )} */}

            {/* )} */}
          </div>

          {/* </FacebookShareButton>{' '} */}
        </Popover.Content>
      </Popover>
    );
  }
  // socialPopover() {
  //   const content = this.state.postValue.content
  //     ? this.state.postValue.content
  //       ? this.state.postValue.content
  //       : ""
  //     : this.state.postValue.fileContent
  //     ? this.state.postValue.fileContent
  //     : "";
  //     const shareUrl = `${APPCONFIG.BASE_URL}${this.props.workspaceType}/userPost/${this.state.postValue.postId}/${this.state.postValue.postType}/yuukke_share_post`;
  //    const title = "My awesome post";
  //   const description =
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

  //   return (
  //     <Popover id="popover-basic">
  //       <Popover.Title as="h3">Share your SocialMedia</Popover.Title>
  //       <Popover.Content>
  //         <FacebookShareButton
  //           url={shareUrl}
  //           title={removeHTML(content)}
  //           separator="- "
  //           metaTags={metaTags}
  //           //hashtag="#camperstribe"
  //         >
  //           <FacebookIcon size={32} round />
  //         </FacebookShareButton>{" "}
  //         <TwitterShareButton
  //           url={shareUrl}
  //           title={removeHTML(content)}
  //           separator="- "
  //           metaTags={metaTags}
  //         >
  //           <TwitterIcon size={32} round />
  //         </TwitterShareButton>{" "}
  //         <WhatsappShareButton
  //           url={shareUrl}
  //           title={removeHTML(content)}
  //           separator="- "
  //           metaTags={metaTags}
  //         >
  //           <WhatsappIcon size={32} round />
  //         </WhatsappShareButton>{" "}
  //         <TelegramShareButton
  //           url={shareUrl}
  //           title={removeHTML(content)}
  //           separator="- "
  //           metaTags={metaTags}
  //         >
  //           <TelegramIcon size={32} round />
  //         </TelegramShareButton>
  //       </Popover.Content>
  //     </Popover>
  //   );
  // }

  handleClose = () => {
    this.setState({ postDeletePopUp: false });
  };

  handleReportPopupClose() {
    this.setState({ reportPostState: false });
  }

  load = (postId, type) => {
    // this.ToggleButton(type, postId);

    // console.log("state value changed", this.state.postValue);
    // var commendsData = [];
    // var latestCommends = this.state.postValue.postLikeCommends;
    // for (var i = 0; i < latestCommends.length; i++) {
    //   commendsData.push({
    //     commend: latestCommends[i].commend,
    //     coverPhoto: latestCommends[i].coverPhoto,
    //     createdDate: latestCommends[i].createdDate,
    //     id: latestCommends[i].id,
    //     postId: latestCommends[i].postId,
    //     profilePhoto: latestCommends[i].profilePhoto,
    //     updatedDate: latestCommends[i].updatedDate,
    //     userId: latestCommends[i].userId,
    //     userName: latestCommends[i].userName,
    //   });
    // }
    //  this.setState({ commentList: commendsData });
    // this.setState({ commentList: this.state.postValue.postLikeCommends });
    // this.setState({ commendsLength: this.state.postValue.commendsCount });
    this.setState({
      commendsCount: this.state.postValue.commendsCount,
      likesLength: this.state.postValue.likesCount,
      likeStatusState: this.state.postValue.userlike,
      // openCommentBoxes:false,
    });

    // console.log("POST VALUE -----..........>>>>>>>");
    // console.log(this.state.postValue);

    this.checkContent(this.state.postValue.content);

    if (
      this.state.postValue.type === "image" ||
      this.state.postValue.type === "video" ||
      this.state.postValue.type === "audio"
    ) {
      //    this.getBase64String(this.state.postValue.postFiles);
    }
    // this.findLength();
    //this.setState({ postProfilePhoto: this.getProfileBase64String(this.state.postValue.userId) })
  };

  handleFormEnterKey(e) {
    // alert(this.state.commentsText)
    if (
      this.state.commentsText !== undefined &&
      this.state.commentsText.trim() !== ""
    ) {
      if (e.keyCode === 13) {
        this.commentHit(
          this.state.postValue.postId,
          this.state.postValue.postType
        );
      }
    }
    // if(this.state.commentsText == ""){
    // //  toast.warn("Comment should not be empty");
    // }
  }

  handleFormEnterKeyEdit(e) {
    if (
      this.state.commentsEditText !== undefined &&
      this.state.commentsEditText.trim() !== ""
    ) {
      if (e.keyCode === 13) {
        this.commentEditHit(
          this.state.commentId,
          this.state.postValue.postId,
          this.state.postValue.postType
        );
      }
    }
    // else{
    // ///  toast.warn("Comment should not be empty");
    // }
  }
  handleFormEnterKeyReplay = (e) => {
    if (
      this.state.replayList !== undefined &&
      this.state.replayList.trim() !== ""
    ) {
      if (e.keyCode === 13) {
        this.saveReplay(this.state.commentId);
      }
    }
  };
  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleClickComment() {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      await this.setState({
        openCommentBoxes: !this.state.openCommentBoxes,
        commentList: [],
        replayFullList: [],
        replayListStatus: false,
      });
      if (this.state.openCommentBoxes) {
        this.getCommentsList();
        if (this.state.postValue.postType == "groupMessage") {
          this.props.getPostTagList("groups", this.state.postValue.subjectId);
        } else {
          this.props.getPostTagList("friends");
        }
      }
    }
  }
  getCommentsList = () => {
    let getcommendbypost = {
      postId: this.state.postValue.postId,
      postLikeAndCommendType: "commend",
      type: this.state.postValue.postType,
    };
    PostService.getCommendByIndividualPostId(getcommendbypost)
      .then((response) => {
        var result = response.data.data.postCommendsList;
        this.setState({
          commentList: result,
          commentsEdit: false,
          // commentListMain: result.data.postCommendsList,
        });
        console.log("comments", result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // ToggleButton(type, pid) {
  //   let getcommendbypost = {
  //     postId: pid,
  //     postLikeAndCommendType: "commend",
  //     type: type,
  //   };
  //   // let getLikeByPost = {
  //   //   postId: pid,
  //   //   postLikeAndCommendType: "like",
  //   //   type: type
  //   // };

  //   PostService.getcommendbypost(getcommendbypost)
  //     .then((response) => {
  //       var result = response.data;

  //       this.setState({
  //         commentList: result.data.postCommendsList,
  //         commentListMain: result.data.postCommendsList,
  //       });
  //       console.log("COMMENT LIST TOGGLE");
  //       console.log(result.data.postCommendsList);
  //       // this.mergeUserCommentList(this.state.commentList);
  //       //   this.getFinalCommentList(result.data.postCommendsList);
  //       this.setState({ commendsLength: result.data.postCommendsList.length });
  //       this.setState({
  //         likesLength: result.data.postLikesList.length,
  //         likeStatusState: result.data.userLike,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // PostService.getLikebypost(getLikeByPost)
  //   //   .then((response) => {
  //   //     var result = response.data;

  //   //     this.setState({ commentList: result.data });
  //   //     console.log("COMMENT LIST TOGGLE");
  //   //     console.log(this.state.commentList);
  //   //     // this.mergeUserCommentList(this.state.commentList);

  //   //     //his.getFinalCommentList(result.data);
  //   //     this.setState({ likesLength: result.data.postCommendsList.length, likeStatusState: result.data.userLike });
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  //   this.setState({ comment: !this.state.comment });
  // }

  ViewAllComments(boolean) {
    this.setState({ FirstTwocomment: boolean });
    this.ToggleButton(
      this.state.postValue.postType,
      this.state.postValue.postId,
      this.state.comment
    );
  }

  ViewAllCommentsHide(boolean) {
    this.setState({ FirstTwocomment: boolean });
    this.setState({ openCommentBoxes: !this.state.openCommentBoxes });
  }

  getFinalCommentList(result) {
    const receiver_List = [];

    this.setState({
      commentListMain: result,
      FirstTwocomment: true,
    });

    console.log(this.state.commentListMain);
  }

  mergeUserCommentList(commentsData) {
    let friends = this.props.friends;

    let friendsMap = {};

    friendsMap[this.props.login.uId] = this.props.loginDetails;

    if (friends) {
      for (let i = 0; i < friends.length; i++) {
        friendsMap[friends[i].userProfile.userId] = friends[i];
      }
    }

    // Generate Posts with User Data
    for (let i = 0; i < commentsData.length; i++) {
      var user = friendsMap[commentsData[i].userId];
      if (user) {
        commentsData[i]["user"] = user;
      } else {
        delete commentsData[i];
      }
    }

    this.setState({
      commentListMain: commentsData.reverse(),
      FirstTwocomment: true,
    });
    console.log(this.state.commentListMain);
  }

  checkContent(url) {
    var type = "text";
    if (url) {
      if (url.startsWith("http")) {
        if (this.checkImageURL(url)) {
          type = "image";
        } else if (this.isYoutube(url) || this.checkVideoURL(url)) {
          type = "video";
        }
      }
    }
    this.setState({
      type: type,
    });
  }

  checkImageURL(url) {
    url = url.toLowerCase();
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  checkVideoURL(url) {
    url = url.toLowerCase();
    return url.match(/\.(mp4|webm|ogg)$/) != null;
  }

  isYoutube(getURL) {
    if (typeof getURL !== "string") return false;
    var newA = document.createElement("A");
    newA.href = getURL;
    var host = newA.hostname;
    var srch = newA.search;
    var path = newA.pathname;

    if (host.search(/youtube\.com|youtu\.be/) === -1) return false;
    if (host.search(/youtu\.be/) !== -1) return path.slice(1);
    if (path.search(/embed/) !== -1)
      return /embed\/([A-z0-9]+)(\&|$)/.exec(path)[1];
    var getId = /v=([A-z0-9]+)(\&|$)/.exec(srch);
    if (host.search(/youtube\.com/) !== -1) return !getId ? "" : getId[1];
  }

  handleMouseEnter() {
    time = setTimeout(
      function () {
        this.setState({
          isLike: true,
        });
      }.bind(this),
      1000
    );
  }

  handleMouseLeave() {
    clearTimeout(time);
    setTimeout(
      function () {
        this.setState({
          isLike: false,
        });
      }.bind(this),
      1000
    );
  }

  // likesButton(pid,likeType,type) {
  //   let createpostLike = {
  //     userId: this.props.loginDetails.uId,
  //     postId: pid,
  //     postLikeAndCommendType: 'like',
  //     likes: 1,
  //     type: type,
  //     reactionType:likeType
  //   }

  //   PostService.createpostLike(createpostLike)
  //     .then((response) => {
  //       var result = response.data
  //       var likeCounts = result.data.likeCount
  //       this.setState({ likesLength: likeCounts })
  //       this.likeStatus()
  //       this.props.likestatusUpdate(result)
  //       this.MixpanelCallinLike(pid)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  likeStatus = async () => {
    await this.setState({ likeStatusState: !this.state.likeStatusState });
  };

  commentHit = (pid, type) => {
    if (
      this.state.commentsText !== undefined &&
      this.state.commentsText.trim() !== ""
    ) {
      let createpostCommands = {
        postId: pid,
        postLikeAndCommendType: "commend",
        // comment: extractMentionsFromHTML(this.state.commentsText),
        comment: removeMentionTags(this.state.commentsText),
        type: type,
      };
      this.setState({
        commentsText: "",
      });

      PostService.createpostCommands(createpostCommands)
        .then((response) => {
          if (response.data.status === -2) {
            this.postNotPresent();
          } else {
            var result = response.data.data.commend;

            var newComment = [];
            let oldList = this.state.commentList;
            if (result) {
              newComment = [result, ...oldList];
            }

            this.setState({
              commentList: newComment,
              commendsCount: newComment.length,
              editorValue: "",
              needEdit: true,
            });
            if (this.props.isGroup || this.props.postDescriptionPage) {
              this.props.commendsUpdate(
                this.state.postValue.postId,
                newComment.length
              );
            } else {
              this.props.commentsCountUpdate(
                this.state.postValue.postId,
                newComment.length
              );
            }
          }

          // this.props.commendsUpdate(result, pid);
          //this.ToggleButton(this.props.data.postType, this.props.data.postId);
          // this.setState({ commendsLength: this.state.commendsLength + 1 });
        })
        .catch((error) => {
          console.log(error);
        });
      //  toast.warn("Comment should not be empty");
    } else {
      // toast.warn("Comment should not be empty");
    }
  };

  getBase64String(postFile) {
    let getBase64String = {
      resourceString: postFile,
    };

    PostService.getBase64String(getBase64String)
      .then((response) => {
        var value = response.data.data.base64String;
        var base64String = "";
        if (this.state.postValue.type === "image") {
          base64String = `data:image;base64,${value}`;
        } else if (this.state.postValue.type === "video") {
          base64String = `data:video/mp4;base64,${value}`;
          console.log("video");
        } else if (this.state.postValue.type === "audio") {
          base64String = `data:audio/wav;base64,${value}`;
          console.log("audio");
        }

        this.setState({ base64String: base64String });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProfileBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value) {
        return response.data.data.userPhoto;
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            return response.data.data.userPhoto;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  check() {
    console.log("/postvalue", this.state.postValue);
  }

  reportPost(e) {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      if (!this.state.description) {
        toast.warn(`${this.props.t("Report description should not be empty")}`);
      } else {
        let reportPost = {
          userId: this.props.loginDetails.uId,
          postId: this.state.reportPostId,
          postType: this.state.reportPostType,
          description: this.state.description,
          type: "post",
        };
        PostService.reportPost(reportPost)
          .then((response) => {
            var result = response.data;

            console.log("sharePost");
            console.log(result.data);
            toast.success(`${this.props.t("Report Sent Successfully!")}`);
            this.handleReportPopupClose();
            this.setState({ description: "" });
          })
          .catch((error) => {
            console.log(error);
            //   toast.error("Error in saving ")
          });
      }
    }
  }
  popUpPostDelete() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.handleClose}>
          <Modal.Header>{t("Delete Post")}</Modal.Header>

          <Form
            id="profileForm"
            noValidate
            validated={this.state.validated}
            onSubmit={this.deletePost}
          >
            <Modal.Body>
              <Form.Group className="formInput">
                <Form.Label style={{ marginTop: "8px", marginBottm: "8px" }}>
                  {t("Are you sure to delete the Post?")}
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.deletePostCard}>
                {t("Close")}
              </Button>
              <Button type="submit" variant="primary">
                {t("Delete Post")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  deletePostCard = () => {
    this.setState({
      postDeletePopUp: !this.state.postDeletePopUp,
      menuStatewithDelete: false,
    });
  };
  deletePost = (e) => {
    // alert()
    e.preventDefault();
    e.stopPropagation();
    let deletePost = {
      userId: this.props.loginDetails.uId,
      postId: this.state.postValue.postId,
      type: this.state.postValue.postType,
    };
    if (this.props.isGroup) {
      PostService.deletePost(deletePost)
        .then((response) => {
          var result = response.data;
          console.log("sharePost");
          console.log(result.data);
          toast.success(`${this.props.t("Post Deleted Successfully!")}`);
          this.props.ondeleteList(
            this.state.postValue.moderationId,
            this.state.postValue.postId
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (this.props.postDescriptionPage) {
        this.props.deletePost(deletePost, "Post Deleted Successfully!");
        this.props.history.push(`/${this.props.workspaceType}/home`);
      } else {
        this.props.deletePost(deletePost, "Post Deleted Successfully!");
      }
    }

    this.setState({
      postDeletePopUp: false,
    });
  };

  commentEdit(cid, commends) {
    console.log("Remove", removeHtmlTags(commends));
    this.setState({
      needEdit: true,
      commentsEdit: true,
      commentsEditText: removeHtmlTags(commends),
      commentId: cid,
    });
  }

  commentEditHit(cid, pid, type) {
    if (
      this.state.commentsEditText === undefined ||
      this.state.commentsEditText.trim() === ""
    ) {
      //  toast.warn("Comment should not be empty");
    } else {
      let editComment = {
        id: cid,
        // comment: extractMentionsFromHTML(this.state.commentsEditText),
        comment: removeMentionTags(this.state.commentsEditText),
        type: type,
      };

      PostService.editComment(editComment)
        .then((response) => {
          if (response.data.status === -2) {
            this.postNotPresent();
          } else {
            let newComment = [];
            let reverseList = [];
            var result = response.data.data.commend;
            var postList = this.state.commentList;
            postList = this.state.commentList.filter((item) => item.id != cid);
            if (result) {
              newComment = postList.concat(result);
              reverseList = newComment.reverse();
            }
            // let commentList = this.state.commentList;
            // commentList.forEach((element) => {
            //   if (element.id === comId) {
            //     element.replayCount = element.replayCount - 1;
            //   }
            // });
            this.setState({
              // commentList: result.data,
              commentsEditText: "",
              commentsEdit: false,
              comment: false,
              commentList: reverseList,
              needEdit: true,
            });
          }

          //this.getFinalCommentList(this.state.commentList);
          //this.ToggleButton(this.props.data.postType, this.props.data.postId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  reportPostClick(rpid, rtype) {
    this.setState({
      reportPostState: true,
      reportPostId: rpid,
      reportPostType: rtype,
    });
    this.handleMenuClose();
  }

  shareOptionPopup() {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      document.addEventListener("mousedown", this.handleClickOutside);
      this.setState({
        shareOptionPopup: !this.state.shareOptionPopup,
      });
    }
  }

  optionType = (e, id) => {
    if (id === 1) {
      this.setState({
        sharePostPopup: true,
        sharedType: "public",
        // shareOptionPopupList: true,
        // shareOptionGroupList:true,
      });
      // this.sharePostVT(e, "public");
    } else if (id === 2) {
      this.setState({
        shareOptionPopup: false,
        shareOptionPopupList: true,
        sharedType: "private",
      });
    } else if (id === 3) {
      this.setState({
        shareOptionPopup: false,
        shareOptionGroupList: true,
        sharedType: "group",
      });
    }
    e.preventDefault();
    e.stopPropagation();
  };

  sharePostVT = (e, type, friendId) => {
    e.preventDefault();
    e.stopPropagation();
    var share = {};
    const { postValue } = this.state;
    console.log(" props data : ", this.props);
    if (this.state.sharedType == "public") {
      share = {
        postId: postValue?.isSharePost
          ? postValue?.shareDetails?.postId
          : postValue?.postId,
        shareType: "public",
        type: postValue?.isSharePost
          ? postValue?.shareDetails.typeData
          : postValue?.postType,
        // type: this.state.postValue.postType,
        content: this.state.sharedContentText,
      };
      PostService.sharePostVT(share)
        .then((response) => {
          this.setState({
            shareOptionPopupList: false,
            shareOptionGroupList: false,
            shareOptionPopup: false,
            sharePostPopup: false,
            sharedContentText: "",
          });
          if (response.data.status === -2) {
            this.postNotPresent();
          } else {
            var result = response.data.data;

            if (result.userpost) {
              console.log("result data >>>", result.userpost);
              // toast.success(`${this.props.t("Post Shared Successfully")}`);
              if (this.props.isGroup) {
                //Group
                toast.success(`${this.props.t("Post Shared Successfully")}`);
                // this.props.sharePostOnWay(result.userpost);
              } else {
                //home post
                // toast.success(`${this.props.t("Post Shared Successfully")}`);
                this.props.sharePost(
                  result.userpost,
                  "Post Shared Successfully"
                );
              }
            } else {
              toast.error(`${this.props.t("Error in saving post")}`);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          //  toast.error("Error in saving ")

          toast.error(`${this.props.t("Error in saving post")}`);
        });
      // this.MixpanelCallinShare(type);
    } else {
      if (friendId) {
        if (this.state.sharedType == "private") {
          share = {
            // postId: this.state.postValue.postId,
            postId: postValue?.isSharePost
              ? postValue?.shareDetails?.postId
              : postValue?.postId,
            type: postValue?.isSharePost
              ? postValue?.shareDetails.typeData
              : postValue?.postType,
            shareType: "individual",
            friendsId: friendId,
            // type: this.state.postValue.postType,
            content: this.state.sharedContentText,
          };
        } else {
          share = {
            // postId: this.state.postValue?.isSharePost
            //   ? this.state.postValue?.shareDetails?.postId
            //   : this.state.postValue.postId,
            postId: postValue?.isSharePost
              ? postValue?.shareDetails?.postId
              : postValue?.postId,
            type: postValue?.isSharePost
              ? postValue?.shareDetails.typeData
              : postValue?.postType,
            shareType: "groups",
            groupId: friendId,
            // type: this.state.postValue.postType,
            content: this.state.sharedContentText,
          };
        }

        PostService.sharePostVT(share)
          .then((response) => {
            this.setState({
              shareOptionPopupList: false,
              shareOptionGroupList: false,
              shareOptionPopup: false,
              sharePostPopup: false,
              sharedContentText: "",
            });
            if (response.data.status === -1) {
              this.postNotPresent();
            } else {
              var result = response.data.data;
              console.log("result data >>>", result);
              if (result) {
                if (this.props.isGroup) {
                  //Group
                  toast.success(`${this.props.t("Post Shared Successfully")}`);
                  if (friendId === this.props.communityId) {
                    this.props.sharePostOnWay(result.userpost);
                  }
                } else {
                  //home post
                  this.props.sharePost(
                    result.userpost,
                    "Post Shared Successfully"
                  );
                }
              } else {
                toast.error(`${this.props.t("Error in saving post")}`);
              }
            }
          })
          .catch((error) => {
            // toast.error("Error in saving ")
            console.log(error);
            this.setState({
              shareOptionPopupList: false,
              shareOptionGroupList: false,
              shareOptionPopup: false,
              sharePostPopup: false,
              sharedContentText: "",
            });
          });
      } else if (!friendId) {
        toast.warn(
          this.state.sharedType == "private"
            ? `${this.props.t("Select any one of the friend")}`
            : `${this.props.t("Select any one of the group")}`
        );
      }
    }
  };

  handleoptionTypes() {
    this.setState({
      shareOptionPopup: false,
      shareOptionPopupList: false,
      shareOptionGroupList: false,
      selectFriendsName: "",
      choosedGroupName: "",
      choosedFriendName: "",
      FriendsList: [],
      tagDatas: [],
      sharedContentText: "",
      selectGroupName: "",
    });
  }

  searchSubjects = (event, value) => {
    this.setState({
      [event.target.name]: event.target.value,
      onSelect: false,
    });
    let data = [];
    for (var i = 0; i < this.state.allSubjects.length; i++) {
      var subject = this.state.allSubjects[i];
      if (subject.subjectName.toUpperCase().includes(value.toUpperCase())) {
        data.push(subject);
      }
    }
    this.setState({ tagDatas: data });
  };

  searchFriends(event, value) {
    let data = [];
    this.setState({
      [event.target.name]: event.target.value,
      onSelect: false,
    });
    if (value.length > 0) {
      var empty = [];
      for (var i = 0; i < this.state.FriendsListSearch.length; i++) {
        var friend = this.state.FriendsListSearch[i];
        if (friend.userName.toUpperCase().includes(value.toUpperCase())) {
          data.push(friend);
        }

        this.setState({ FriendsList: data });
      }
    } else {
      this.setState({ FriendsList: this.props.FriendsList });
    }

    console.log("FriendsList in search", this.state.FriendsList);
  }

  selectFriends = (uId, name) => {
    this.setState({
      selectedFriendId: uId,
      choosedFriendName: name,
      selectFriendsName: name,
      onSelect: true,
    });
  };
  removeFriend = () => {
    this.setState({
      selectedFriendId: null,
      choosedFriendName: "",
      selectFriendsName: "",
      onSelect: false,
    });
  };
  selectGroup = (id, name) => {
    this.setState({
      choosedGroupName: name,
      selectedGroupId: id,
      onSelect: true,
      selectGroupName: name,
    });
  };
  removeGroup = (id, name) => {
    this.setState({
      choosedGroupName: "",
      selectedGroupId: null,
      onSelect: false,
      selectGroupName: "",
    });
  };

  optionTypesGroup() {
    const { t } = this.props;
    const { postValue } = this.state;
    return (
      <div>
        <Modal show="true" onHide={this.handleoptionTypes}>
          <Modal.Header>
            {t("SHARE IN YOUR COMMUNITY")}
            <AiOutlineCloseCircle
              onClick={this.handleoptionTypes}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                onSubmit={(e) =>
                  this.sharePostVT(e, "groups", this.state.selectedGroupId)
                }
                //  validated={this.state.validated}
                //  onSubmit={this.reportComment}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-3">
                      <div>{t("Add your comment")} </div>
                      <div className="flex-grow-1 ml-5">
                        <textarea
                          name="sharedContentText"
                          className="share-popup"
                          value={this.state.sharedContentText}
                          onChange={this.handleForm}
                          maxLength="2500"
                          placeholder="Share your thought ..."
                        ></textarea>
                      </div>
                      <br />
                    </div>

                    <div className="d-flex justify-content-between mb-3">
                      <p>{t("Community Name *")}</p>

                      <div className="homePostInputWrapper">
                        <TextField
                          id=""
                          label=""
                          style={{ width: "100%", height: "20px" }}
                          variant="outlined"
                          value={this.state.selectGroupName}
                          name="selectGroupName"
                          onChange={(e) =>
                            this.searchSubjects(e, e.target.value)
                          }
                          placeholder={t("Search community..")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {!this.state.onSelect &&
                          this.state.tagDatas.length > 0 && (
                            <div className="homepostgroupCard">
                              {
                                <div style={{ marginBottom: "20px" }}>
                                  {this.state.tagDatas &&
                                    this.state.tagDatas.length > 0 &&
                                    this.state.tagDatas.map(
                                      (subject, index) => (
                                        <div>
                                          <div
                                            onClick={() =>
                                              this.selectGroup(
                                                subject.subjectId,
                                                subject.subjectName
                                              )
                                            }
                                          >
                                            <p
                                              style={{
                                                color: "#000000",
                                                padding: "8px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {subject.subjectName}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              }
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="mt-1">
                      <SinglePost
                        professionList={this.state.professionList}
                        workspaceType={this.props.workspaceType}
                        // postId={this.state.postValue?.postId}
                        // postType={this.state.postValue?.postType}
                        postId={
                          postValue?.isSharePost
                            ? postValue?.shareDetails.postId
                            : postValue?.postId
                        }
                        postType={
                          postValue?.isSharePost
                            ? postValue?.shareDetails.typeData
                            : postValue?.postType
                        }
                        modelPost={true}
                      />
                    </div>
                  </Card.Body>

                  {this.state.choosedGroupName !== "" && (
                    <>
                      <hr />
                      <div className="postCardCloud mt-2 ml-2 mb-2">
                        <Grid container spacing={2}>
                          <Grid item lg={3} md={3} sm={6} xs={6}>
                            <Chip
                              style={{
                                overflow: "hidden",
                                Whitespace: "nowrap",
                                fontSize: "12px",
                                textOverflow: "ellipsis",
                                textTransform: "capitalize",
                                maxWidth: "138px",
                              }}
                              avatar={
                                <Avatar>
                                  {this.state.choosedGroupName
                                    .charAt(0)
                                    .toUpperCase()}
                                </Avatar>
                              }
                              label={this.state.choosedGroupName}
                              onDelete={() => this.removeGroup()}
                            />
                          </Grid>
                        </Grid>{" "}
                      </div>
                    </>
                  )}
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleoptionTypes}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  optionTypesFriends() {
    const { t } = this.props;
    const { postValue } = this.state;
    return (
      <div>
        <Modal show="true" onHide={this.handleoptionTypes}>
          <Modal.Header>
            {t("SHARE WITH YOUR FRIENDS")}
            <AiOutlineCloseCircle
              onClick={this.handleoptionTypes}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                onSubmit={(e) =>
                  this.sharePostVT(e, "private", this.state.selectedFriendId)
                }
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-3">
                      <div>{t("Add your comment")} </div>
                      <div className="flex-grow-1 ml-5">
                        <textarea
                          name="sharedContentText"
                          className="share-popup"
                          value={this.state.sharedContentText}
                          onChange={this.handleForm}
                          maxLength="2500"
                          placeholder="Share your thought ..."
                        ></textarea>
                      </div>
                      <br />
                    </div>

                    <div className="d-flex justify-content-between mb-3">
                      <p>{t("Friend Name *")}</p>

                      <div className="homePostInputWrapper">
                        <TextField
                          id=""
                          label=""
                          style={{ width: "100%", height: "20px" }}
                          variant="outlined"
                          value={this.state.selectFriendsName}
                          name="selectFriendsName"
                          onChange={(e) =>
                            this.searchFriends(e, e.target.value)
                          }
                          placeholder={t("Search Friend..")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {!this.state.onSelect &&
                          this.state.FriendsList.length > 0 && (
                            <div className="homepostgroupCard">
                              {
                                <div style={{ marginBottom: "20px" }}>
                                  {this.state.FriendsList &&
                                    this.state.FriendsList.length > 0 &&
                                    this.state.FriendsList.map(
                                      (subject, index) => (
                                        <div>
                                          <div
                                            onClick={() =>
                                              this.selectFriends(
                                                subject.userId,
                                                subject.userName
                                              )
                                            }
                                          >
                                            <p
                                              style={{
                                                color: "#000000",
                                                padding: "8px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {subject.userName}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              }
                            </div>
                          )}
                      </div>
                    </div>
                    <div>
                      <SinglePost
                        professionList={this.state.professionList}
                        workspaceType={this.props.workspaceType}
                        postId={
                          postValue?.isSharePost
                            ? postValue?.shareDetails.postId
                            : postValue?.postId
                        }
                        postType={
                          postValue?.isSharePost
                            ? postValue?.shareDetails.typeData
                            : postValue?.postType
                        }
                        // postId={this.state.postValue?.postId}
                        // postType={this.state.postValue?.postType}
                        modelPost={true}
                      />
                    </div>
                  </Card.Body>

                  {this.state.choosedFriendName !== "" && (
                    <>
                      <hr />
                      <div className="postCardCloud mb-2 ml-3">
                        <Grid container spacing={2}>
                          <Grid item lg={3} md={3} sm={6} xs={6}>
                            <Chip
                              style={{
                                overflow: "hidden",
                                Whitespace: "nowrap",
                                fontSize: "12px",
                                textOverflow: "ellipsis",
                                textTransform: "capitalize",
                                maxWidth: "138px",
                              }}
                              avatar={
                                <Avatar>
                                  {this.state.choosedFriendName
                                    .charAt(0)
                                    .toUpperCase()}
                                </Avatar>
                              }
                              label={this.state.choosedFriendName}
                              onDelete={() => this.removeFriend()}
                            />
                            {/* <Chip
                              className="navMenuTextoverFlowGray"
                              style={{
                                overflow: "hidden",
                                Whitespace: "nowrap",
                                fontSize: "12px",
                                cursor: "pointer",
                                textOverflow: "ellipsis",
                              }}
                              label={this.state.choosedFriendName}
                            ></Chip> */}
                          </Grid>
                        </Grid>{" "}
                      </div>
                    </>
                  )}
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleoptionTypes}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>

                  {/* {this.state.postCardType == "0" && ( */}

                  {/* )} */}
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  showFriendsProfile = (userId) => {
    // if (userId !== this.props.loginDetails.uId) {
    if (this.state.postValue.dispalyName !== "Anonymous User") {
      this.props.getNavigationType(1);
      this.props.history.push(
        `/${this.props.workspaceType}/friendDescription/${userId}`,
        { from: window.location.pathname }
      );
    }

    // }
  };

  handleClickOutside(event) {
    console.log(this.wrapperRef);
    console.log(event.target);
    if (
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        shareOptionPopup: false,
      });
    }
  }
  hideShareContent = (e) => {
    this.setState({
      sharePostPopup: !this.state.sharePostPopup,
      sharedContentText: "",
    });
  };

  articleModelClose = () => {
    this.setState({
      articleModel: !this.state.articleModel,
    });
  };
  handleChange = (event) => {
    this.setState({
      articleHeading: event.target.value,
    });
  };
  articleModel() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.articleModelClose}>
          <Modal.Header>
            {t("EDIT YOUR ARTICLE")}{" "}
            <AiOutlineCloseCircle
              onClick={this.articleModelClose}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.editArticle}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div style={{ paddingBlock: "1rem" }}>
                      <TextField
                        placeholder="Enter Title"
                        variant="outlined"
                        fullWidth
                        label="Title"
                        value={this.state.articleHeading}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="postEditor">
                      <TextEditor
                        friendslist={[]}
                        ref={this.myRef}
                        editorStateValue={this.editorStateValues}
                        editorValue={this.state.questionDescribed}
                        textBoxStatus={this.state.textBoxStatus}
                        textBoxStatusBack={this.textBoxStatusBack}
                      />
                    </div>

                    {!this.state.imageFile &&
                      !this.state.removeImageFile &&
                      this.state.fileImageData &&
                      this.state.fileImageData.map((item, index) => (
                        <div key={index}>
                          <Chip
                            icon={<MdOutlineAttachFile />}
                            className="fileChip"
                            label="image"
                            size="small"
                            onDelete={() => this.removeImageFile()}
                            variant="outlined"
                          />
                          {
                            <img
                              style={{
                                margin: "8px",
                                width: "80px",
                                height: "80px",
                              }}
                              src={item}
                              alt="img"
                            ></img>
                          }
                        </div>
                      ))}

                    <MessagesFileOption
                      fileTypeKey="image"
                      fileOption={false}
                      fileOptionEnable={this.fileOptionEnable}
                      fromQuestionMedia={this.getQuestionMediaFiles}
                    ></MessagesFileOption>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.articleModelClose}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Update Article")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  removeImageFile = () => {
    this.setState({
      removeImageFile: !this.state.removeImageFile,
    });
  };
  editArticle = (e) => {
    e.preventDefault();
    if (!this.state.articleHeading || !this.state.content) {
      toast.warning(`${this.props.t("Fill all the fields")}`);
    } else {
      const formData = new FormData();
      formData.append("postId", this.state.postValue?.postId);
      formData.append("typeData", this.state.postValue?.postType);
      formData.append("title", this.state.articleHeading);
      formData.append("description", this.state.content);
      if (this.state.imageFile) {
        formData.append("postFiles", this.state.imageFile[0]);
        formData.append("type", "image");
      } else if (this.state.removeImageFile) {
        formData.append("type", "text");
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      PostService.articlePostUpdate(formData, config)
        .then((response) => {
          var result = response.data.data;
          console.log("result data >>>", result);
          if (result) {
            //home post
            this.articleModelClose();
            this.props.updatePostData(result, "Article Updated Successfully");
          } else {
            toast.error(`${this.props.t("Error in saving post")}`);
          }
        })
        .catch((error) => {
          // toast.error("Error in saving ")
          console.log(error);
          this.setState({
            shareOptionPopupList: false,
            shareOptionGroupList: false,
            shareOptionPopup: false,
          });
        });
    }
  };
  fileOptionEnable = () => {
    this.setState({ imageFile: null });
  };
  getQuestionMediaFiles = (files, type) => {
    this.setState({
      imageFile: files,
    });
  };
  sharePostComment() {
    const { t } = this.props;
    const { postValue } = this.state;
    return (
      <div>
        <Modal show="true" onHide={this.hideShareContent}>
          <Modal.Header>
            {t("ADD YOUR SHARE THOUGHT")}{" "}
            <AiOutlineCloseCircle
              onClick={this.hideShareContent}
              style={{
                cursor: "pointer",
                float: "right",
                marginBottom: "8px",
                width: "20px",
                height: "20px",
                color: "#000000",
              }}
            />
          </Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.sharePostVT}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="row ">
                      <div className="col-xs-12 col-md-12">
                        <textarea
                          name="sharedContentText"
                          className="share-popup"
                          value={this.state.sharedContentText}
                          onChange={this.handleForm}
                          maxLength="2500"
                          placeholder="Share your thought ..."
                        ></textarea>

                        <br />
                      </div>
                    </div>
                    <div>
                      <SinglePost
                        professionList={this.state.professionList}
                        workspaceType={this.props.workspaceType}
                        postId={
                          postValue?.isSharePost
                            ? postValue?.shareDetails.postId
                            : postValue?.postId
                        }
                        postType={
                          postValue?.isSharePost
                            ? postValue?.shareDetails.typeData
                            : postValue?.postType
                        }
                        modelPost={true}
                      />
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.hideShareContent}>
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Share the post")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  popUpReport() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.handleReportPopupClose}>
          <Modal.Header>{t("ADD DESCRIPTION FOR REPORT")}</Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.reportPost}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="row ">
                      <div className="col-xs-12 col-md-12">
                        <Form.Group className="formInput">
                          <Form.Label></Form.Label>
                          <textarea
                            name="description"
                            rows="3"
                            cols="30"
                            value={this.state.description}
                            onChange={this.handleForm}
                            maxLength="250"
                          ></textarea>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Enter Description")}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleReportPopupClose}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  popUpCommentReport() {
    const { t } = this.props;
    return (
      <div>
        <Modal show="true" onHide={this.handleReportCommentPopupClose}>
          <Modal.Header>{t("ADD DESCRIPTION FOR COMMENT REPORT")}</Modal.Header>
          <div
            className="tab-pane-view"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div className="profileTabContent">
              <Form
                id="profileForm"
                noValidate
                validated={this.state.validated}
                onSubmit={this.reportComment}
              >
                <Card className="profileCard">
                  <Card.Body>
                    <div className="row ">
                      <div className="col-xs-12 col-md-12">
                        <Form.Group className="formInput">
                          <Form.Label></Form.Label>
                          <textarea
                            name="reportCommentDescription"
                            style={{ width: "100%" }}
                            rows="4"
                            cols="50"
                            value={this.state.reportCommentDescription}
                            onChange={this.handleForm}
                          ></textarea>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Enter Description")}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.handleReportCommentPopupClose}
                    >
                      {t("Close")}
                    </Button>
                    <Button type="submit" variant="primary">
                      {t("Submit")}
                    </Button>
                  </Modal.Footer>
                </Card>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  reportCommentClick = (rcid) => {
    this.setState({
      reportCommentState: true,
      reportCommentId: rcid,
    });
  };

  reportComment(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("COMMENT DESCRIPTION");
    console.log(this.state.reportCommentDescription);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      if (this.state.reportCommentDescription === "") {
        toast.warn(`${this.props.t("Report description should not be empty")}`);
      } else if (this.state.reportCommentDescription === undefined) {
        toast.warn(`${this.props.t("Report description should not be empty")}`);
      } else {
        let reportComment = {
          userId: this.props.loginDetails.uId,
          commendId: this.state.reportCommentId,
          type: "comment",
          description: this.state.reportCommentDescription,
        };
        PostService.reportByUserComment(reportComment)
          .then((response) => {
            var result = response.data;

            console.log("sharePost");

            console.log(result.data);
            toast.success(
              `${this.props.t("Comment Report Sent Successfully!")}`
            );
            this.handleReportCommentPopupClose();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  getMessageFiles = async (files, type, userId) => {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          // fileList.push({
          //   src: `${APPCONFIG.API_URL}auth/image/${files[i].fileName}`,
          //   width: 1600,
          //   height: 900,

          // });
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileImageData: fileList });
      }
      console.log("fileImageData", fileList);
      // if (files.length > 0) {
      //   for (let i = 0; i < files.length; i++) {
      //     fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
      //   }
      //   await this.setState({ fileData: fileList });
      // }
    } else if (type === "video") {
      let fileLisVideo = [];
      //  if(this.state.isvideoOpen === true){
      if (files.length > 0) {
        // for (let i = 0; i < files.length; i++) {
        //   console.log("video-res", files[i]);
        console.log("video files>>", files);
        await this.setState({
          fileVideoData: getVideoStatus(
            files,
            this.props.loginDetails.uId,
            userId,
            this.props.loginDetails.uType
          ),
        });
        console.log("filter video-Files", this.state.fileVideoData);
        //  fileLisVideo.push(getVideoStatus(files[i]));
        // fileLisVideo.push({
        //   file: files[i]?.streamUrl,
        //   image: files[i]?.thumbnail,
        // });
        // }

        // await this.setState({ fileVideoData: fileLisVideo });
        console.log("FILE VIDEO LIST", fileLisVideo);
      }
      let fileList = [];
      if (files.videoThumbnail !== null) {
        // alert("Null")
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            fileList.push(
              `${APPCONFIG.API_URL}auth/image/${files[i].videoThumbnail}`
            );
          }

          await this.setState({ fileData: fileList });
          console.log("FILE LIST");
          console.log(this.state.fileData);
        }
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST PDF");
        console.log(this.state.fileData);
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      }
    }
  };

  handleReportCommentPopupClose = () => {
    this.setState({ reportCommentState: false });
  };

  advertisementChart = () => {
    if (this.state.postCategory === "advertisement") {
      // this.props.getAdvertisementData(
      //   this.state.postValue.postId,
      //   this.state.postValue.postType
      // );
      this.props.history.push(
        `/${this.props.workspaceType}/advertisement/${this.state.postValue.postId}/${this.state.postValue.postType}`
      );
    }
  };
  subscribeAdvertisement = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      if (this.state.postCategory === "advertisement") {
        this.advertisementChart();
        if (!this.state.postValue.postSubscribe.isSubscribe) {
          this.subscribe(true);
        }
      }
    }
  };
  likeCountPopUp = () => {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      this.setState({
        likeCountPopUpStatus: !this.state.likeCountPopUpStatus,
      });
    }
  };

  shareUrl = () => {
    if (this.state.postCategory === "article") {
      return `${APPCONFIG.BASE_URL}articles/${this.state.article?.slug}`;
    } else if (this.state.postCategory === "advertisement") {
      return `${APPCONFIG.BASE_URL}${this.props.workspaceType}/advertisement/${this.state.postValue.postId}/${this.state.postValue.postType}/yuukke-advertisement`;
    } else if (this.props.data.postCategory === "product") {
      return `${this.props.data?.productDetails?.details_page}`;
    } else {
      return `${APPCONFIG.BASE_URL}yuukke_share_post/?id=${
        this.state.postValue.postId
      }&type=${this.state.postValue.postType}&token=${Math.random() * 100}`;

      // return `${APPCONFIG.BASE_URL}${this.props.workspaceType}/userPost/${this.state.postValue.postId}/${this.state.postValue.postType}/yuukke_share_post`;
    }
  };
  reportReply = (id, commendId) => {
    this.setState({
      reportReplyState: !this.state.reportReplyState,
      reportReplyId: id,
    });
    if (commendId) {
      this.setState({
        replyCommentId: commendId,
      });
    }
  };

  postNotPresent = () => {
    if (this.props.isGroup) {
      this.props.ondeleteList(
        this.state.postValue.moderationId,
        this.state.postValue.postId
      );
      toast.error(`${this.props.t("Post has been deleted by user !")}`);
    } else {
      this.props.postNotPresent(this.state.postValue.postId);
    }
  };
  removePost = (id, type) => {
    if (type === "Post") {
      if (this.props.isGroup) {
        this.props.ondeleteList(
          this.state.postValue.moderationId,
          this.state.postValue.postId
        );
      } else {
        this.props.removePost(this.state.postValue.postId);
      }

      toast.success(`${this.props.t("Reported Successfully ...")}`);
    } else if (type === "Comment") {
      var postList = this.state.commentList;
      postList = this.state.commentList.filter((item) => item.id !== id);
      this.setState({
        commendsCount: postList.length,
        commentList: postList,
        commentsEditText: "",
        commendsLength: this.state.commendsLength - 1,
      });
      if (this.props.isGroup) {
        this.props.commendsUpdate(this.state.postValue.postId, postList.length);
      } else {
        this.props.commentsCountUpdate(
          this.state.postValue.postId,
          postList.length
        );
      }
    } else if (type === "Reply") {
      this.updateReplyStatus(id, this.state.replyCommentId);
    }
  };
  updateReplyStatus = (replyId, comId) => {
    var postList = this.state.replayFullList;
    postList = this.state.replayFullList.filter((item) => item.id !== replyId);
    let commentList = this.state.commentList;
    commentList.forEach((element) => {
      if (element.id === comId) {
        element.replayCount = element.replayCount - 1;
      }
    });
    console.log("commentList old", this.state.commentList);
    this.setState({
      replayFullList: postList,
    });
  };
  generateMetaTags = (post) => {
    return [
      { property: "og:title", content: "Yuukke" },
      { property: "og:description", content: "Take a look at this post" },
      {
        property: "og:image",
        content: getImageAndVideo(post),
        // "https://marketplace.betalearnings.com/assets/uploads/0f447cfbb856b2229f171477f59df607.jpg",
      },
      {
        property: "og:url",
        content: this.shareUrl(),
      },
      { property: "og:type", content: "article" },
    ];
  };
}
const mapDispacthToProps = (dispatch) => {
  return {
    getNavigationType: (type) => dispatch(getNavigationType(type)),
    getPieChartList: (id, type) => dispatch(getPieChartList(id, type)),
    getAdvertisementData: (id, type) =>
      dispatch(getAdvertisementData(id, type)),
    createPostLike: (data, index, newLike) =>
      dispatch(createPostLike(data, index, newLike)),
    deletePost: (id, toastData) => dispatch(deletePost(id, toastData)),
    commentsCountUpdate: (postId, count) =>
      dispatch(commentsCountUpdate(postId, count)),
    sharePost: (data, toastData) => dispatch(sharePost(data, toastData)),
    subscribePost: (result, type, status, moderationId, bookmark) =>
      dispatch(subscribePost(result, type, status, moderationId, bookmark)),
    updatePostData: (data, toastData) =>
      dispatch(updatePostData(data, toastData)),
    getPostTagList: (type, id) => dispatch(getPostTagList(type, id)),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
    removePost: (id) => dispatch(removePost(id)),
    getAllUserPoints: () => dispatch(getAllUserPoints()),
    postNotPresent: (id) => dispatch(postNotPresent(id)),
    likeAndCommentUpdate: (data) => dispatch(likeAndCommentUpdate(data)),
  };
};

const mapStateToProps = function (state) {
  // console.log("PROFILE_LANG_VALUE");
  // console.log(state.loginDetailsApi.loginDetails);
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    loginUserType: state.loginDetailsApi.loginDetails?.uType
      ? state.loginDetailsApi.loginDetails.uType
      : 0,
    loginUserRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role
      ? state.loginDetailsApi.loginDetails.userWorkSpace.role
      : 0,
    authUser: state.loginDetailsApi.authUser,
    // tagDatas: state.subjects.myeventsandgroups,
    FriendsList: state.commonApi.workspaceFriendList,
    tagDatas: state.commonApi.joinedSulikePointPopUpbjectList,
    postError: state.postList.postError,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    memberList: state.commonApi.tagUserList,
    defaultWorkSpaceId:
      state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
    likePointIndex: state.postList.likePointIndex,
    postLikeStatus: state.postList.postLikeStatus,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(PostCard)));
