import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { IoIosArrowDown, IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineCheck } from "react-icons/md";
import { HiOutlineEye } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { WeeklyCalendar } from "../../FirstTimeProfile/WeeklyCalendar";
import EditableTextFieldInsta from "../../../components/EditableTextFieldInsta";
import EditableTextFieldLinkedIn from "../../../components/EditableTextFieldLinkedIn";
import { TextField } from "@material-ui/core";
import EditableTextFieldTwitter from "../../../components/EditableTextFieldTwitter";
import { withTranslation } from "react-i18next";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import CalendarPreference from "./CalendarPreference";
import Swal from "sweetalert2";
import { MdDashboard } from "react-icons/md";
import {
  getDirectoryCategoryV1,
  getSubscriptionList,
  getSubscriptionStatus,
  getUserService,
  getUserSlots,
} from "../../../redux/actions/calendarAction";
import Services from "./Services";
import SubscriptionList from "./SubscriptionList";
import { toast } from "react-toastify";
import {
  giveUserAction,
  instaValidation,
  linkedInvalidation,
  twitterValidation,
} from "../../../utils";
import ProfileService from "../../../api/ProfileService";
import { updateSocialMediaDetails } from "../../../redux/actions/loginDetailsActions";
import BankDetails from "./BankDetails";
import {
  getAllChatFriendsList,
  getAllCommonLanguageList,
  needPhotVerification,
} from "../../../redux/actions/commonApiActions";
import ServiceDashboard from "./ServiceDashBoard";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    padding: theme.spacing(1.5, 3),
    borderRadius: "6px",
    fontWeight: 700,
    padding: "6px 6px",
    paddingLeft: "16px",
    paddingRight: "16px",
    textTransform: "none",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    border: "none",
    letterSpacing: 0.5,
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[4],
    },
  },
  moreActionsButton: {
    background: `linear-gradient(135deg, #a00030 0%, #a00030 100%)`,
    color: theme.palette.common.white,
  },
  homeButton: {
    background: "linear-gradient(135deg, #a00030 0%, #a00030 100%)",
    color: "#ffffff !important",
    borderRadius: "6px",
    padding: "6px 6px",
    fontSize: "11px",
    fontWeight: "bold",
    minWidth: "40px",
    width: "auto",
    paddingLeft: "16px",
    paddingRight: "16px",
    textTransform: "none",
    // boxShadow: "0px 3px 3px #a00030",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: "linear-gradient(135deg, #a00030 0%, #a00030 100%)",
      transform: "translateY(-1px)",
      boxShadow: "0px 4px 10px #a00030",
    },
    "&:active": {
      transform: "scale(0.97)",
      boxShadow: "0px 2px 5px #a00030",
    },
  },
}));

const CalendarContainer = (props) => {
  const { t } = props;
  const {
    userSlots,
    eventLoader,
    subscriptionList,
    subscriptionStatus,
    subscriptionDetails,
    categoryList,
    calendarServices,
    successCount,
    prefComplete,
    calendarComplete,
    serviceComplete,
    categoryV2,
    userServiceList,
  } = useSelector((state) => state.event);

  // const [menuList, setMenuList] = useState([
  //   {
  //     title: "Complete your profile details",
  //     description: "Add your profile pic and description",
  //     id: 1,
  //     menuStatus: false,
  //   },
  //   {
  //     title: "Add your preference",
  //     description: "Add your preference in your service",
  //     id: 2,
  //     menuStatus: prefComplete,
  //   },
  //   {
  //     title: "Select your suitable plan",
  //     description: "Pic your suitable plan for your service",
  //     id: 3,
  //     menuStatus: subscriptionStatus,
  //   },
  //   {
  //     title: "Add availability",
  //     description: "Add your availability so your followers can select a slot",
  //     id: 4,
  //     menuStatus: calendarComplete,
  //   },
  //   {
  //     title: "Create a service",
  //     description: "Add a service so that your followers can book it",
  //     id: 5,
  //     menuStatus: serviceComplete,
  //   },
  //   {
  //     title: "Add Your Bank Details",
  //     description: "Add your bank details for service payments",
  //     id: 6,
  //     menuStatus: false,
  //   },
  // ]);
  const [menuList, setMenuList] = useState([
    {
      title: "Profile Details",
      description: "Add your personal details",
      id: 1,
      menuStatus: false,
    },
    {
      title: "Service Profile",
      description: "Add Your Service Related Info",
      id: 2,
      menuStatus: prefComplete,
    },
    {
      title: "Suitable Plan",
      description: "Choose a Suitable Plan For You",
      id: 3,
      menuStatus: subscriptionStatus,
    },
    {
      title: "Availability Timings",
      description: "Add your available timings",
      id: 4,
      menuStatus: calendarComplete,
    },
    {
      title: "Service",
      description: "Add a service",
      id: 5,
      menuStatus: serviceComplete,
    },
    {
      title: "Bank Details",
      description: "Add your bank details",
      id: 6,
      menuStatus: userSlots?.preference?.accountNumber,
    },
  ]);

  const [state, setState] = useState({
    activeMenu: 0,
    loader: false,
    activeWrapperMenu: null,
    biography: "",
    twitter: "",
    location: "",
    instagram: "",
    linkedIn: "",
    initialServicePage: false,
    websiteLink: "",
    neededTypeRedirect: "",
  });

  const [hasShownModal, setHasShownModal] = useState(false);
  const { loginDetails } = useSelector((state) => state.loginDetailsApi);
  const { languagelist } = useSelector((state) => state.commonApi);
  const friendsList = useSelector((state) => state.commonApi.chatFriendslist);
  const dispatch = useDispatch();


  useEffect(() => {
    const timer = setTimeout(() => {
      const totalItems = menuList.length;
      const completedItems = menuList.filter((item) => item.menuStatus).length;
      const progress = (completedItems / totalItems) * 100;
      console.log("Updated Progress:", progress);
      if (progress < 100 && !hasShownModal) {
        Swal.fire({
          title: "Update Your Service Profile",
          html: `
            <div style="text-align: center;">
              <img src="https://corporate.yuukke.com/bcav2/auth/image/d14916c9-8f4e-4778-a5d7-5b33819460c5"
                   alt="Profile Update"
                   style="width: 65px; height: 65px; margin-bottom: 20px; animation: bounce 1s infinite alternate;" />
              
              <p style="font-size: 18px; font-weight: bold; color: #333;">
                Boost Your Profile – Update Now!
              </p>
      
              <p style="font-size: 16px; color: #666; margin-bottom: 15px;">
                Your service profile is <strong>${progress.toFixed(2)}% complete</strong>.
              </p>
      
              <div style="background: #f8f8f8; border-radius: 12px; padding: 15px; margin: 20px auto; max-width: 300px;">
                <p style="font-size: 14px; color: #444; font-weight: 500;">
                  Progress: <strong>${progress.toFixed(2)}% Complete</strong>
                </p>
                <div style="background: #ddd; border-radius: 6px; height: 12px; width: 100%; position: relative;">
                  <div style="background: #4CAF50; width: ${progress}%; height: 100%; border-radius: 6px;"></div>
                </div>
              </div>
      
              <p style="font-size: 14px; color: #888; margin-top: 10px;">
                A complete profile increases your visibility! ✨
              </p>
            </div>
          `,
          confirmButtonText: "Got it",
          confirmButtonColor: "#4CAF50",
          allowOutsideClick: true,
        }).then(() => {
          setHasShownModal(true);
        });
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [menuList, hasShownModal]);

  useEffect(() => {
    setInitialState();
    dispatch(getUserService());
    dispatch(getUserSlots());
    dispatch(getSubscriptionStatus("calender"));
    dispatch(getSubscriptionList());
    if (categoryV2.length === 0) {
      dispatch(getDirectoryCategoryV1());
    }
    if (languagelist.length === 0) {
      dispatch(getAllCommonLanguageList());
    }
    if (friendsList?.length === 0) {
      dispatch(getAllChatFriendsList());
    }
  }, []);
  useEffect(() => {
    if (
      !state.initialServicePage &&
      serviceComplete &&
      subscriptionStatus &&
      state.activeWrapperMenu !== 5
    ) {
      setState((previous) => ({
        ...previous,
        activeMenu: userServiceList?.length >= 1 ? 7 : 5,
        // activeMenu: 5,
        initialServicePage: true,
      }));
      console.log("Update 5");
    }
  }, [serviceComplete, subscriptionStatus]);
  useEffect(() => {
    let list = menuList.map((menu) => {
      switch (menu.id) {
        case 2:
          return { ...menu, menuStatus: prefComplete };
        case 3:
          return { ...menu, menuStatus: subscriptionStatus };
        case 4:
          return { ...menu, menuStatus: calendarComplete };
        case 5:
          return { ...menu, menuStatus: serviceComplete };
        case 6:
          return {
            ...menu,
            menuStatus: userSlots?.preference?.accountNumber,
          };
        default:
          return menu;
      }
    });
    setMenuList(list);
  }, [
    prefComplete, subscriptionStatus, calendarComplete,
    serviceComplete, userSlots?.preference?.accountNumber,
    loginDetails.biography, loginDetails.location,
    loginDetails.instagram, loginDetails.linkedIn,
    loginDetails.twitter, loginDetails.personalWebsite
  ]);
  const setInitialState = () => {
    setState((previous) => ({
      ...previous,
      biography: loginDetails.biography,
      twitter: loginDetails.twitter,
      location: loginDetails.location,
      instagram: loginDetails.instagram,
      linkedIn: loginDetails.linkedin,
      websiteLink: loginDetails.personalWebsite,
    }));
    if (
      loginDetails.biography &&
      loginDetails.location &&
      loginDetails.instagram
    ) {
      updateMenu(1);
    }
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const menuStatusChange = (id, data) => {
    if (giveUserAction(loginDetails?.profile_complete)) {
      dispatch(needPhotVerification(true));
      return;
    }
    const unComplete =
      !data.menuStatus && menuList.slice(0, 4).find((item) => !item.menuStatus);
    console.log("un", unComplete);
    if (unComplete && !data.menuStatus) {
      if (id !== state.activeWrapperMenu && unComplete.id !== id) {
        toast.warning(`Please complete ${unComplete.title} step`);
      }

      setState((previous) => ({
        ...previous,
        activeWrapperMenu:
          id === state.activeWrapperMenu ? null : unComplete.id,
        initialServicePage: false,
      }));
      return;
    } else if (!subscriptionStatus && id !== 1 && id !== 2) {
      toast.warning("Please subscribe your suitable plan");
      setState((previous) => ({
        ...previous,
        activeWrapperMenu: 3,
        initialServicePage: false,
      }));
      return;
    }
    setState((previous) => ({
      ...previous,
      activeWrapperMenu: id === state.activeWrapperMenu ? null : id,
    }));
  };
  const removeMenuStatus = (type, status) => {
    if (status && type) {
      const list = menuList.map((item) => {
        if (type === "calendar" && item.id === 2) {
          item.menuStatus = true;
          item.title = "preference details";
        } else if (type === "subscription" && item.id === 3) {
          item.menuStatus = true;
        } else if (type === "bank" && item.id === 6) {
          item.menuStatus = true;
        }

        return item;
      });
      setMenuList(list);
    }
    setState((previous) => ({
      ...previous,
      activeWrapperMenu: null,
      activeMenu: 0,
      initialServicePage: false,
    }));
  };

  const saveProfileChanges = (e) => {
    e.preventDefault();
    if (!state.biography) {
      toast.warning("Enter your service details");
    } else if (!state.location) {
      toast.warning("Enter your location");
    } else if (!state.instagram && !state.linkedIn && !state.twitter) {
      toast.warning("Enter your social media url");
    } else {
      let validCount = 0;
      const data = {};
      data["biography"] = state.biography;
      data["location"] = state.location;
      if (state.websiteLink) {
        data["personalWebsite"] = state.websiteLink;
      }
      if (linkedInvalidation(state.linkedIn)) {
        data["linkedin"] = state.linkedIn;
        validCount++;
      }

      if (instaValidation(state.instagram)) {
        data["instagram"] = state.instagram;
        validCount++;
      }

      if (twitterValidation(state.twitter)) {
        data["twitter"] = state.twitter;
        validCount++;
      }
      if (validCount >= 2) {
        updateState("loader", true);
        validProfileDetails(data);
      } else {
        toast.warning(
          "Please add minimum two of your social media profile url"
        );
      }
    }
  };

  const validProfileDetails = (data) => {
    updateState("loader", true);
    ProfileService.userProfileDetailsUpdated(data)
      .then(async (res) => {
        await dispatch(updateSocialMediaDetails(data));
        await dispatch(getUserService()); 
        updateState("loader", false);
        removeMenuStatus();
      })
      .catch((e) => {
        updateState("loader", false);
      });
  };

  useEffect(() => {
    const updatedMenuList = menuList.map((item) => {
      let newStatus, newTitle;

      // Determine completion status
      switch (item.id) {
        case 1:
          newStatus = !!(
            loginDetails.biography &&
            loginDetails.location &&
            (loginDetails.instagram || loginDetails.linkedIn || loginDetails.twitter)
          );
          break;
        case 2: newStatus = prefComplete; break;
        case 3: newStatus = subscriptionStatus; break;
        case 4: newStatus = calendarComplete; break;
        case 6: newStatus = !!userSlots?.preference?.accountNumber; break;
        case 5: newStatus = serviceComplete; break;
        default: newStatus = item.menuStatus;
      }

      newTitle = newStatus
        ? item.title.startsWith("Update Your")
          ? item.title // Keep as is if already updated
          : `Update Your ${item.title.replace(/^Add Your |^Complete /, '')}`
        : item.title.replace(/^Update Your /, 'Add Your ');

      // Special cases for specific titles
      if (item.id === 1) newTitle = newStatus ? "Update Profile Details" : "Complete Profile Details";
      if (item.id === 3) newTitle = newStatus ? "Update Subscription Plan" : "Select Suitable Plan";
      if (item.id === 5) newTitle = newStatus ? "Update Service" : "Create Service";

      return { ...item, menuStatus: newStatus, title: newTitle };
    });

    setMenuList(updatedMenuList);
  }, [
    prefComplete, subscriptionStatus, calendarComplete,
    serviceComplete, userSlots, loginDetails
  ]);

  const updateMenu = (id) => {
    setMenuList(prev => prev.map(item => {
      if (item.id === id) {
        return {
          ...item,
          menuStatus: true,
          title: item.title.startsWith('Update Your') ? item.title : `Update Your ${item.title.replace(/^Add Your |^Complete /, '').trim()}`
        };
      }
      return item;
    }));
  };

  const makeAction = (index) => {
    updateState("activeMenu", index);
  };
  const neededTypeRedirect = (redirect, index) => {
    setState({
      ...state,
      neededTypeRedirect: redirect,
      activeMenu: index,
    });
  };
  const clearNeededType = () => {
    setState({
      ...state,
      neededTypeRedirect: "",
    });
  };
  console.log("menuList", menuList);
  console.log("state", state);
  const handleBackButtonClick = () => {

  //window.location.reload();
  //  this.props.history.push(`/my-profile/service`);
  };
  const classes = useStyles();
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader || eventLoader}
    >
      <div className="cal-v2-wrap" style={{ marginBottom: "10rem" }}>
        {state.activeMenu === 7 ? (
          <ServiceDashboard
            makeAction={makeAction}
            neededTypeRedirect={neededTypeRedirect}
          />
        ) : state.activeMenu === 4 ? (
          <>
            <WeeklyCalendar
              onBack={() => removeMenuStatus()}
              addSuccess={updateMenu}
              userSlots={userSlots?.slots}
              activeMinutes={30}
            />
          </>
        ) : state.activeMenu === 1 ? (
          <>
            <h4
              className="p-3 mt-2 mb-2 heading-h4-v2"
              style={{ borderBottom: "1px solid #80808063" }}
            >
              <IoArrowBackCircleOutline
                onClick={() => removeMenuStatus()}
                className="h-auto  mr-3 svg-icons"
                style={{
                  width: "2rem",
                }}
              />
              {t("Update Profile")}
            </h4>
            <form onSubmit={saveProfileChanges} className="card cal-grid  p-4">
              <section className="d-flex  w-100">
                <EditableTextFieldInsta
                  label={`${t("Instagram")} *`}
                  value={state.instagram}
                  onDataCheck={(key) => { }}
                  handleClickEdits={() => { }}
                  onChange={(value) => updateState("instagram", value)}
                  getSelectedValue={(value) => updateState("instagram", value)}
                />
              </section>

              <section className="d-flex w-100">
                {" "}
                <EditableTextFieldLinkedIn
                  label={`${t("LinkedIn")} *`}
                  value={state.linkedIn}
                  onDataCheck={(key) => { }}
                  handleClickEdits={() => { }}
                  onChange={(value) => updateState("linkedIn", value)}
                  getSelectedValue={(value) => updateState("linkedIn", value)}
                />
              </section>
              <section className="d-flex w-100">
                {" "}
                <EditableTextFieldTwitter
                  label={`${t("Twitter")} `}
                  value={state.twitter}
                  onDataCheck={(key) => { }}
                  handleClickEdits={() => { }}
                  onChange={(value) => updateState("twitter", value)}
                  getSelectedValue={(value) => updateState("twitter", value)}
                />
              </section>
              <section className="w-100 editableTextWrapper formFieldGroup">
                <label className=" mb-0 label-v2">{t("Location")} *</label>
                <TextField
                  placeholder={t("Delhi...")}
                  style={{
                    marginTop: "5px",
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  required
                  name="location"
                  value={state.location}
                  onChange={(e) => updateState("location", e.target.value)}
                />
              </section>
              <section className="w-100 editableTextWrapper formFieldGroup">
                <label className=" mb-0 label-v2">
                  {t("Your Website Link")}
                </label>
                <TextField
                  placeholder={t("www.demo.com")}
                  style={{
                    marginTop: "5px",
                  }}
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="websiteLink"
                  value={state.websiteLink}
                  onChange={(e) => updateState("websiteLink", e.target.value)}
                />
              </section>
              <section className="editableTextWrapper formFieldGroup cal-full w-100">
                <label className="label-v2">{t("About Me")} *</label>
                <textarea
                  className="post-form-control post-pe-4 post-bg-light"
                  rows="1"
                  placeholder={t("About your service ...")}
                  type="text"
                  onChange={(e) => updateState("biography", e.target.value)}
                  value={state.biography}
                  name="biography"
                  style={{ minHeight: "8rem" }}
                  required
                ></textarea>
              </section>
              <button className="w-100" type="submit">
                {" "}
                {t("Update Profile")}
              </button>
              {/* <button className="w-100" onClick={() => saveProfileChanges()}>
              Next <MdDoubleArrow className="ml-1 text-white svg-icons" />
            </button> */}
            </form>
          </>
        ) : state.activeMenu === 5 ? (
          <Services
            addSuccess={updateMenu}
            onBack={makeAction}
            initialServicePage={state.initialServicePage}
            userPrefDetails={userSlots?.preference}
            neededTypeRedirect={state.neededTypeRedirect}
            clearNeededType={clearNeededType}
          />
        ) : state.activeMenu === 2 ? (
          <>
            <CalendarPreference onBack={removeMenuStatus} />
          </>
        ) : state.activeMenu === 3 ? (
          <SubscriptionList onBack={removeMenuStatus} />
        ) : state.activeMenu === 6 ? (
          <BankDetails
            onBack={removeMenuStatus}
            userSlots={userSlots?.preference}
          />
        ) : (
          <>
            <div className="cal-p" style={{ padding: "2rem 2rem 0.5rem" }}>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="heading-h4-v2">{t("Launch Your Serviceee")}</h4>
                <Button
                  // onClick={handleBackButtonClick}
                 onClick={() => makeAction(7)}
                  className={classes.homeButton}
                  style={{ marginLeft: "auto", marginRight: "16px", display: "flex" }}
                  startIcon={<MdDashboard style={{ fontSize: "18px" }} />}
                >
                  Dashboard
                </Button>

                {menuList.every((item) => item.menuStatus) && (
                  <Button
                    variant="outlined"
                    className={`${classes.actionButton} ${classes.moreActionsButton}`}
                    startIcon={<HiOutlineEye style={{ fontSize: 20 }} />}
                    onClick={() => makeAction(5)}
                  >
                    {t("More Actions")}
                  </Button>
                )}
              </div>
              <p className="text-secondary f-12">
                {t("Unlock the potential of your service")}
              </p>
              <ul className="d-flex g-1 l-none p-0 mt-5">
                {menuList.map((item, i) => (
                  <li
                    key={i}
                    style={{
                      flexGrow: 1,
                      height: "8px",
                      background: `${item.menuStatus ? "green" : "gray"}`,
                    }}
                  ></li>
                ))}
              </ul>
            </div>
            <ul className="cal-drawer cal-p m-0">
              {menuList.map((item, i) => (
                <li
                  style={{
                    background:
                      item.id === state.activeWrapperMenu
                        ? "white"
                        : "#b0b0b008",
                  }}
                  className={`cal-box ${item.id === state.activeWrapperMenu ? "open" : ""
                    }`}
                >
                  <section className="d-flex flex-column">
                    <div
                      style={{ height: "4rem" }}
                      className="d-flex align-items-center c-pointer"
                      onClick={() => menuStatusChange(item.id, item)}
                    >
                      {item.menuStatus ? (
                        <span style={{ background: "green" }} className="mr-4">
                          <MdOutlineCheck className="text-white   " />
                        </span>
                      ) : (
                        <p className="cal-dot-border mr-4"></p>
                      )}
                      <p>{t(item.title)}</p>
                      <IoIosArrowDown
                        className={`ml-auto c-pointer arrowIcon-cal ${item.id === state.activeWrapperMenu ? "active" : ""
                          }`}
                      />
                    </div>
                    <div
                      style={{
                        marginTop:
                          item.id === state.activeWrapperMenu
                            ? "-0.9rem"
                            : "0px",
                        marginLeft: "3.3rem",
                        transition: "all 0.5s ease-in-out",
                      }}
                    >
                      <p className="f-12">{t(item.description)}</p>
                      <button
                        className="mt-2"
                        onClick={() => updateState("activeMenu", item.id)}
                      >
                        <IoMdAddCircleOutline
                          className="mr-2"
                          style={{ width: "20px" }}
                        />
                        {t(item.title)}
                      </button>
                    </div>
                  </section>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </BlockUi>
  );
};
export default withTranslation()(CalendarContainer);
