import api from "./BaseAPI.js";
import auth from "./AuthAPI.js";
import * as APPCONFIG from "../config/app-config.js";

import Http from "./Http.js";
import { KEY_CLOAK_URL } from "../config/keycloak-config.js";

class LoginService {
  getMsLogin(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/MSLogin`,
      data: JSON.stringify(formValues),
    });
  }

  //trigger keycloakLogin to the backend and get loginDetails
  getKeycloakLoginValues(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}keycloak/v1/login`,
      data: JSON.stringify(formValues),
    });
  }

  //* NON AUTH *//

  getLogin(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/login`,
      data: JSON.stringify(formValues),
    });
  }

  refreshToken(user) {
    console.log("refreshToken Called");
    return api({
      method: "post",
      // url: `${APPCONFIG.API_URL}keycloak/refresh`,
      url: `${APPCONFIG.API_URL}keycloak/v1/refresh`,
      data: user,
    });
  }

  createInstantUser(formValues) {
    return api({
      method: "POST",
      url: `${APPCONFIG.API_URL}auth/InstanceNewLoginAccount`,
      data: JSON.stringify(formValues),
    });
  }

  save_user_work_space(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/save_user_work_space`,
      data: JSON.stringify(formValues),
    });
  }
  updateUserProfile(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/v1/userProfile/update`,
      data: JSON.stringify(formValues),
    });
  }
  referralUserAddToWorkSpace(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/referralUserAddToWorkSpace`,
      data: JSON.stringify(formValues),
    });
  }
  getKeyCloakUserInfo() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${KEY_CLOAK_URL}realms/yuukke/protocol/openid-connect/userinfo`,
    });
  }
  generateOtp(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/generateOtp`,
      data: JSON.stringify(formValues),
    });
  }
  validateOtp(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/validateOtp`,
      data: JSON.stringify(formValues),
    });
  }
  mobileRegister(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}keycloak/v1/createUser`,
      data: JSON.stringify(formValues),
    });
  }
  validateLoginOtp(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}keycloak/v1/verifyOtp`,
      data: JSON.stringify(formValues),
    });
  }
  keyClockCreateUser(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}keycloak/createUser`,
      data: JSON.stringify(formValues),
    });
  }
  forgotPassword(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}keycloak/forgotPassword`,
      data: JSON.stringify(formValues),
    });
  }
  phoneExitCheck(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/validate-login-phonenumber`,
      data: JSON.stringify(formValues),
    });
  }
  getBlockedUsersList(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/getBlockedUsersList`,
      data: JSON.stringify(formValues),
    });
  }
  updateUserWorkSpace(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/add_user_with_link`,
      data: JSON.stringify(formValues),
    });
  }
  // doLogout() {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/logout`
  //     })
  // }

  // isLogin() {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}islogin`
  //     })
  // }

  // getSettingsList() {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}get-settings`
  //     })
  // }

  // getLoginValues(formValues) {
  //     return auth({
  //         method: 'post',
  //         headers: Http.AUTH_HEADERS(),
  //         url: `${APPCONFIG.API_URL}api/login`,
  //         data: JSON.stringify(formValues)
  //     })
  // }

  // validateOTP(formValues) {
  //     return api({
  //         method: 'post',
  //         url: `${APPCONFIG.API_URL}auth/validateotp`,
  //         data: JSON.stringify(formValues)
  //     })
  // }

  // sendOTP(formValues) {
  //     return api({
  //         method: 'post',
  //         url: `${APPCONFIG.API_URL}auth/sendotp`,
  //         data: JSON.stringify(formValues)
  //     })
  // }

  // setPassword(formValues) {
  //     return api({
  //         method: 'post',
  //         url: `${APPCONFIG.API_URL}auth/setpassword`,
  //         data: JSON.stringify(formValues)
  //     })
  // }

  // sendOTPforForgotPassword(formValues) {
  //     return api({
  //         method: 'post',
  //         url: `${APPCONFIG.API_URL}auth/forgetsendotp`,
  //         data: JSON.stringify(formValues)
  //     })
  // }

  // setForgotPassword(formValues) {
  //     return api({
  //         method: 'post',
  //         url: `${APPCONFIG.API_URL}auth/forgetsetpassword`,
  //         data: JSON.stringify(formValues)
  //     })
  // }

  //* NON AUTH *//

  /*
    sendOTPforForgotPassword(formValues) {
        return api({
            method: 'post',
            url: `${APPCONFIG.API_URL}api/forgetsendotp`,
            data: JSON.stringify(formValues)
        })
    }

    validateOTP(formValues) {
        return api({
            method: 'post',
            url: `${APPCONFIG.API_URL}api/validateotp`,
            data: JSON.stringify(formValues)
        })
    }

    sendOTP(formValues) {
        return api({
            method: 'post',
            url: `${APPCONFIG.API_URL}api/sendotp`,
            data: JSON.stringify(formValues)
        })
    }

    setPassword(formValues) {
        return api({
            method: 'post',
            url: `${APPCONFIG.API_URL}api/setpassword`,
            data: JSON.stringify(formValues)
        })
    }
    */
  saveUserException(formValues) {
    return api({
      method: "post",
      url: `${APPCONFIG.API_URL}auth/v1/save-user-exception`,
      data: JSON.stringify(formValues),
    });
  }
}

export default new LoginService();
