import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { colorPalettes } from "../sampleData/Options";
import ServiceDemoPage from "./ServiceDemoPage";

const professionalColors = [
  "#1E1E1E",
  "#2C3E50",
  "#34495E",
  "#7F8C8D",
  "#95A5A6", // Dark & Neutral
  "#BDC3C7",
  "#D5DBDB",
  "#ECF0F1",
  "#F5F5F5",
  "#FFFFFF", // Light & Subtle
  "#E74C3C",
  "#C0392B",
  "#A93226",
  "#922B21",
  "#7B241C", // Red Variations
  "#E67E22",
  "#D35400",
  "#BA4A00",
  "#9A391A",
  "#7E330C", // Orange & Warm
  "#F1C40F",
  "#F39C12",
  "#D4AC0D",
  "#B7950B",
  "#9C640C", // Yellow & Gold
  "#2ECC71",
  "#27AE60",
  "#229954",
  "#1E8449",
  "#145A32", // Green Tones
  "#3498DB",
  "#2980B9",
  "#2471A3",
  "#1F618D",
  "#154360", // Blue Variations
  "#9B59B6",
  "#8E44AD",
  "#7D3C98",
  "#6C3483",
  "#512E5F", // Purple Shades
  "#EC7063",
  "#D98880",
  "#C39BD3",
  "#A9CCE3",
  "#AED6F1", // Pastel Tones
  "#5D6D7E",
  "#85929E",
  "#AAB7B8",
  "#D7DBDD",
  "#E5E8E8", // Cool & Soft
];

const ColorPickerModal = ({
  open,
  onClose,
  onSelectColor,
  primary,
  secondary,
  heading,
  subHeading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      // maxWidth="xl"
    >
      {" "}
      <DialogTitle>
        Select a Color
        <AiOutlineCloseCircle
          onClick={() => onClose()}
          style={{
            cursor: "pointer",
            float: "right",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{ background: primary }}
        className="d-flex algin-items-center justify-content-center"
      >
        <div className="d-flex">
          <div className="color-scroll-wrap" style={{ paddingTop: "2rem" }}>
            <div className="color-pic-op">
              {colorPalettes.map((palette, index) => (
                <div
                  onClick={() => onSelectColor(palette)}
                  key={index}
                  className="d-flex flex-column align-items-center pick-v2"
                >
                  <div style={{ background: palette.primary }}></div>
                  <div style={{ background: palette.secondary }}></div>
                  <div style={{ background: palette.heading }}></div>
                  <div style={{ background: palette.subHeading }}></div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <ServiceDemoPage
              primary={primary}
              secondary={secondary}
              heading={heading}
              subHeading={subHeading}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// const ColorPickerV2 = () => {
//   const [open, setOpen] = useState(false);
//   const [selectedColor, setSelectedColor] = useState(null);

//   const handleSelectColor = (color) => {
//     setSelectedColor(color);
//     setOpen(false);
//   };

//   return (
//     <div style={{ textAlign: "center", marginTop: "50px" }}>
//       <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
//         Choose a Color
//       </Button>

//       {selectedColor && (
//         <div style={{ marginTop: "20px" }}>
//           <p>Selected Color:</p>
//           <div
//             style={{
//               backgroundColor: selectedColor,
//               width: "100px",
//               height: "50px",
//               borderRadius: "8px",
//               boxShadow: "0 0 5px rgba(0,0,0,0.3)",
//               display: "inline-block",
//             }}
//           ></div>
//         </div>
//       )}

//       <ColorPickerModal
//         open={open}
//         onClose={() => setOpen(false)}
//         onSelectColor={handleSelectColor}
//       />
//     </div>
//   );
// };

function ColorPickerV2({
  primary = primary ?? "#a00030",
  secondary = secondary ?? "#B7B7B7",
  heading = heading ?? "#000000",
  subHeading = subHeading ?? "#EEEEEE",
  data = "#a00030",
  onChange,
}) {
  const [open, setOpen] = useState(false);

  const handleSelectColor = (color) => {
    // setOpen(false);
    onChange(color);
  };
  return (
    <div>
      <div>
        <div
          onClick={() => setOpen(true)}
          style={{ height: "3rem", borderRadius: "3px", overflow: "hidden" }}
          //  style={{ background: data,height:"3rem" }}
          className="w-100 d-flex"
        >
          <div
            className="h-100"
            style={{ background: primary, width: "50%" }}
          ></div>
          <div
            className="h-100"
            style={{ background: secondary, width: "30%" }}
          ></div>
          <div
            className="h-100"
            style={{ background: heading, width: "10%" }}
          ></div>
          <div
            className="h-100"
            style={{ background: subHeading, width: "10%" }}
          ></div>
        </div>
        {/* <input
            className="color-pic"
            type="color"
            value={data}
            onChange={(e) => onChange(e.target.value)}
          />{" "} */}
      </div>
      {/* <p style={{ fontSize: "12px" }}>
        Selected Color Code: <span style={{ data }}>{data}</span>
      </p> */}
      <ColorPickerModal
        open={open}
        onClose={() => setOpen(false)}
        onSelectColor={handleSelectColor}
        primary={primary}
        secondary={secondary}
        heading={heading}
        subHeading={subHeading}
      />
    </div>
  );
}

export default ColorPickerV2;
