import "./App.css";
import Home from "./Page/Home/Home";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const inititalState = {
  username: "",
  userId: "",
};

function App({
  friendsList,
  userDetails,
  socket,
  selectedUser,
  closeCallBack,
}) {
  const [pathname, setPathname] = React.useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user?.userInfo);
  const friends = useSelector((state) => state.user?.friends);

  console.group("Memoized State : App.js Props");
  console.log("Memoized State : userIfo", userInfo);
  console.log("Memoized State : friends", friends);
  console.log("Memoized State : selectedUser", selectedUser);
  console.groupEnd("");

  React.useEffect(() => {
    const listen = history.listen((location) => {
      console.log("location :", location);
      setPathname(location.pathname);
    });
    return () => {
      listen();
    };
  }, [history, pathname]);

  React.useEffect(() => {
    console.log("Memoized State : Is Function Calling", userDetails);

    const dispatchUserDetails = () => {
      console.log("Memoized State : Entering Setting Details", userDetails);
      dispatch({
        type: "USER_DETAILS",
        payload: userDetails,
      });
      dispatch({
        type: "FRIENDS_DETAILS",
        payload: friendsList,
      });
    };
    dispatchUserDetails();
  }, [userDetails, friendsList]);

  return (
    <>
      {window.matchMedia("(min-width:739px)").matches === true &&
      Array.isArray(friends) &&
      friends.length > 0 ? (
        <div className="Desktop-App">
          {userInfo && friends && (
            <Home
              user={userInfo}
              friends={friends}
              socket={socket}
              selectedUser={selectedUser}
              closeCallBack={closeCallBack}
              userDetails={userDetails}
            />
          )}
        </div>
      ) : (
        <>
          {pathname === "/chat-page" &&
            userInfo &&
            friends &&
            Array.isArray(friends) &&
            friends.length > 0 && (
              <div className="Mobile-App">
                <Home
                  user={userInfo}
                  friends={friends}
                  socket={socket}
                  selectedUser={selectedUser}
                  closeCallBack={closeCallBack}
                  userDetails={userDetails}
                />
              </div>
            )}
        </>
      )}
    </>
  );
}

export default App;
