import moment from "moment";
import React, { forwardRef, useMemo, useState } from "react";
import {
  IoIosAddCircleOutline,
  IoIosArrowDropright,
  IoIosArrowForward,
} from "react-icons/io";
import {
  IoArrowBackCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import DatePicker from "react-datepicker";
import { findExitingSlot, findExitingSlotV2, formatTime } from "../../utils";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import SlotCalender from "./SlotCalender";
import MyYuukke from "../../api/MyYuukke";
import BlockUi from "react-block-ui";
import { getUserSlots } from "../../redux/actions/calendarAction";
import { useDispatch } from "react-redux";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <input
    className="date-picker"
    value={value}
    onClick={onClick}
    ref={ref}
    readOnly
    style={{ cursor: "pointer", backgroundColor: "white" }} // Optional styling
  />
));
let slotDetails = [
  {
    from: formatTime(new Date()),
    to: formatTime(new Date(), 30),
  },
];
const defaultSlot = [
  { day: "SUN", timeSlots: [...slotDetails] },
  { day: "MON", timeSlots: [...slotDetails] },
  { day: "TUE", timeSlots: [...slotDetails] },
  { day: "WED", timeSlots: [...slotDetails] },
  { day: "THU", timeSlots: [...slotDetails] },
  { day: "FRI", timeSlots: [...slotDetails] },
  { day: "SAT", timeSlots: [...slotDetails] },
];

export const WeeklyCalendar = ({
  // weeklyHours,
  // modiFyTimeSlot,
  activeMinutes = 30,
  // updateTimeSlots,
  onBack,
  addSuccess,
  userSlots,
}) => {
  console.log("userSlots", userSlots);
  const maxTime = new Date("1970-01-01T23:59:59");
  const minTime = new Date("1970-01-01T00:00:00");
  const [viewingIndex, setViewingIndex] = useState(null);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    weeklyHours: userSlots?.length > 0 ? userSlots : defaultSlot,
    makeChanges: false,
    loader: false,
  });
  // const [weeklyHours, setWeeklyHours] = useState(defaultSlot);
  const arrowDirection = (i) => {
    if (viewingIndex.includes(i)) {
      const newViewingIndex = [...viewingIndex]; // Create a copy of the array
      newViewingIndex.splice(i, 1);
      //   const removeItem = viewingIndex.splice(i, 1);
      console.log("removeItem", newViewingIndex);
      setViewingIndex(newViewingIndex);
      return;
    }
    setViewingIndex(viewingIndex.concat(i));
  };
  //   const arrowStatus = useMemo(() => {
  //     return (i) => {
  //       return viewingIndex.includes(i);
  //     };
  //   }, [viewingIndex]);
  console.log("viewingIndex", viewingIndex);
  const getMin = useMemo(() => {
    return (time, duration) => {
      let date = new Date(`1970-01-01T${time}:00`);
      date.setMinutes(date.getMinutes() + 30);
      return date;
    };
  }, [state.weeklyHours]);
  const modiFyTimeSlot = (type, index, t_i) => {
    let list = state.weeklyHours[index].timeSlots;

    if (type === "delete") {
      console.log("li", list);
      console.log("li", t_i);

      list.splice(t_i, 1);
    } else if (type === "remove") {
      list.length = 0;
    } else {
      // const fromTime = findExitingSlot(list, formatTime(new Date(), 0));
      const fromTime = findExitingSlotV2(list, formatTime(new Date(), 0));

      // const fromTime = "01:00";
      console.log("fromTime", fromTime);

      const timeDuration = [
        {
          from: fromTime,
          to: moment(`2025-01-01T${fromTime}:00`)
            .add(
              activeMinutes,
              // state.activeMinutes.value ? state.activeMinutes.value : 30,
              "minutes"
            )
            .locale("en")
            .format("HH:mm"),
        },
      ];
      console.log("timeDuration", timeDuration);
      list.push(...timeDuration);
    }

    setState((previous) => ({
      ...previous,
      makeChanges: !state.makeChanges,
      // madeChanges: state.calendarId ? true : false,
    }));
  };
  const updateTimeSlots = (index, name, time, t_i, obj, daySlots) => {
    const minutes = formatTime(time);
    const list = state.weeklyHours[index].timeSlots.find(
      (item) => item.from === minutes
    );
    console.log("calendar time", time);
    console.log("calendar list", list);
    console.log("calendar obj", obj);
    console.log("calendar minutes", minutes);
    console.log("calendar name", name);
    console.log("calendar daySlots", daySlots);
    if (name === "from") {
      const from = moment.utc(`2025-01-01T${minutes}:00`); // UTC mode
      const to = moment.utc(`2025-01-01T${obj.to}:00`); // UTC mode

      console.log("calendar from", from.format("YYYY-MM-DD HH:mm"));
      console.log("calendar to", to.format("YYYY-MM-DD HH:mm"));
      let startTime = from.format("YYYY-MM-DD HH:mm");
      let endTime = to.format("YYYY-MM-DD HH:mm");
      console.log("calendar from", from);
      console.log("calendar to", to);
      if (
        daySlots?.timeSlots?.length === 1 &&
        (to.isBefore(from) || to.isSame(from))
      ) {
        toast.warning("Start time should be less than end time");
        return;
      }
    }

    if (list) {
      toast.warning("This slot already selected,choose another slot");
      return;
    }

    setState((previous) => {
      const newTimeDetails = [...previous.weeklyHours];

      const newTimeSlots = [...newTimeDetails[index].timeSlots];

      newTimeSlots[t_i] = {
        ...newTimeSlots[t_i],
        [name]: formatTime(time),
      };

      newTimeDetails[index] = {
        ...newTimeDetails[index],
        timeSlots: newTimeSlots,
      };

      return {
        ...previous,
        madeChanges: state.calendarId ? true : false,
        weeklyHours: newTimeDetails,
      };
    });
  };
  const saveUserAvailability = () => {
    updateState("loader", true);
    let data = {
      slots: state.weeklyHours,
    };

    MyYuukke.saveUserAvailability(data)
      .then((response) => {
        let result = response.data.data;
        console.log("response", result);
        toast.success("Calendar updated successfully");
        updateState("loader", false);
        dispatch(getUserSlots());
        addSuccess(4);
      })
      .catch((e) => {
        toast.error("Something went wrong");
        console.log("error", e);
        updateState("loader", false);
      });
  };
  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  console.log("weeklyHours-state", state);
  return (
    <BlockUi
      tag="div"
      message="Loading, please wait"
      style={{ minHeight: "134px" }}
      className="wallLoadingPage"
      blocking={state.loader}
    >
      <h4
        className="p-3 mt-2 mb-2   heading-h4-v2"
        style={{ borderBottom: "1px solid #80808063" }}
      >
        <IoArrowBackCircleOutline
          onClick={() => onBack("requestMenu", false)}
          className=" h-auto mr-3 svg-icons"
          style={{
            width: "2rem",
          }}
        />
        My Availability
      </h4>
      <ul
        className="l-none g-2 m-3 d-flex flex-column card"
        style={{ padding: "2rem" }}
      >
        <li className="d-flex align-items-center justify-content-between">
          <h6>Default Timings</h6>
          <button onClick={() => saveUserAvailability()}>Save</button>
        </li>
        {state.weeklyHours.map((item, i) => (
          <li key={i} className="d-flex align-items-center g-1">
            <div className="mb-auto" style={{ width: "30px" }}>
              {item.timeSlots.length > 1 && (
                <IoIosArrowDropright
                  onClick={() =>
                    setViewingIndex(viewingIndex === null ? i : null)
                  }
                  className={`h-auto w-28 mt-2 c-pointer arrowIcon-home ${
                    viewingIndex === i && "bg-color arrow-rotate"
                  }`}
                />
              )}
            </div>
            <div className="boxcolor mr-1 event-box c-pointer mb-auto mr-4">
              {/* <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              value={item.timeSlots?.length > 0}
              checked={item.timeSlots?.length > 0}
              defaultChecked={item.timeSlots?.length > 0}
              className="mb-auto mt-2"
              onClick={() =>
                modiFyTimeSlot(
                  item.timeSlots?.length === 0 ? "add" : "remove",
                  i
                )
              }
            /> */}
              <label className="toggle-label">
                <input
                  type="checkbox"
                  value={item.timeSlots?.length > 0}
                  defaultChecked={item.timeSlots?.length > 0}
                  checked={item.timeSlots?.length > 0}
                  onClick={() =>
                    modiFyTimeSlot(
                      item.timeSlots?.length === 0 ? "add" : "remove",
                      i
                    )
                  }
                />
                <span
                  className={
                    item.timeSlots?.length > 0
                      ? "toggle-active"
                      : "toggle-un-active"
                  }
                />
              </label>
            </div>
            <p style={{ width: "5rem" }} className="mb-auto">
              <span>{item.day}</span>
              {item.timeSlots?.length > 0 ? (
                <span className="badge badge-info" style={{ fontSize: "9px" }}>
                  {`${item.timeSlots?.length} ${
                    item.timeSlots?.length > 1 ? "slot's" : "slot"
                  } available`}
                </span>
              ) : (
                <span
                  className="badge badge-danger"
                  style={{ fontSize: "9px" }}
                >
                  Not available
                </span>
              )}
            </p>
            <div
              className={`d-flex flex-column g-1 p-1 week-wrap ${
                viewingIndex === i && "active"
              }`}
            >
              {item.timeSlots.map((time, t_i) => (
                <div key={t_i} className="d-flex align-items-center">
                  <DatePicker
                    selected={new Date(`2025-01-01T${time.from}:00`)}
                    onChange={(date) =>
                      updateTimeSlots(i, "from", date, t_i, time, item)
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={activeMinutes}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="date-picker"
                    minTime={minTime}
                    maxTime={maxTime}
                    customInput={<CustomInput />}
                  />
                  <span className="mr-4 ml-4"> - </span>
                  {item.timeSlots.length > 1 ? (
                    <span className="box-dis">
                      {moment(`2025-01-01T${time.from}:00`)
                        .add(activeMinutes, "minutes")
                        .locale("en")
                        .format("LT")}
                    </span>
                  ) : (
                    <DatePicker
                      style={{ width: "fit-content" }}
                      selected={new Date(`2025-01-01T${time.to}:00`)}
                      onChange={(date) =>
                        updateTimeSlots(i, "to", date, t_i, time, item)
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={activeMinutes}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="date-picker"
                      placeholderText="Select end time"
                      minTime={
                        time.from ? getMin(time.from, activeMinutes) : minTime
                      }
                      // minTime={
                      //   time.from ? new Date(`1970-01-01T${time.from}:00`) : minTime
                      // }
                      maxTime={maxTime}
                      customInput={<CustomInput />}
                    />
                  )}
                  {/* <span className="box-dis">
                  {moment(`2025-01-01T${time.from}:00`)
                    .add(activeMinutes, "minutes")
                    .locale("en")
                    .format("LT")}
                </span> */}

                  <span style={{ marginTop: "8px" }} className="mb-auto ml-4">
                    <IoCloseCircleOutline
                      className="svg-icons"
                      onClick={() => modiFyTimeSlot("delete", i, t_i)}
                    />
                  </span>
                </div>
              ))}
            </div>
            {item.timeSlots.length === 0 && (
              <p className="text-secondary mt-2">Not available ....</p>
            )}
            <IoIosAddCircleOutline
              className="svg-icons mb-auto"
              style={{ marginTop: "13px" }}
              onClick={() => {
                modiFyTimeSlot("add", i);
                setViewingIndex(i);
              }}
            />
          </li>
        ))}
      </ul>{" "}
      <h4 className="p-3 mt-2 mb-2   heading-h4-v2">Choose from Calendar</h4>
      <div className="card p-3 m-3">
        <SlotCalender
          // shareUrl={shareUrl("day", state.calendarId)}
          slot={state.weeklyHours}
          // changeMenu={(e) => updateState("view", e)}
          // slot={state.calendarViewData}
          activeMinutes={state.activeMinutes?.value}
          // needToSaveCalendar={saveUserAvailability}
          // needToSaveCalendar={shareCalendar}
          calendarId={state.calendarId}
          isSubscribed={state.isSubscribed}
          makeChanges={state.makeChanges}
        />
      </div>
    </BlockUi>
  );
};
export default withTranslation()(WeeklyCalendar);
