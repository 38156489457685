import React, { useMemo } from "react";
import dummy_image from "../../assets/img/default_avatar.jpg";
import moment from "moment";
import { API_URL } from "../../../../config/app-config";

function ServiceMessageList({ messageDetails, userId, handleServiceChatList }) {
  return (
    <>
      <div
        className="conversation-list-item position-relative"
        // onClick={() => handleServiceChatList(messageDetails)}
        onClick={() => handleServiceChatList(messageDetails)}
        id={messageDetails?.senderId}
      >
        <img
          src={dummy_image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = dummy_image;
          }}
          alt="avatar"
          className="conversation-user-avatar"
        />

        <div className="conversation-user-name-details">
          <div className="conversation-user-name-details">
            <div className="conversation-user-name-container">
              <h3 className="conversation-user-name">
                {messageDetails?.service?.serviceRequestUserName ?? "Un Known"}
              </h3>
              {messageDetails?.unseenCount > 0 && (
                <span className="unseen-count">
                  {messageDetails?.unseenCount}
                </span>
              )}
              <span>
                {moment(messageDetails?.created_at)
                  .locale("en")
                  .startOf("mini")
                  .fromNow()}
              </span>
            </div>
            <p
              className="conversation-last-message"
              style={{ color: "#898989", fontSize: "13px" }}
            >
              <span>{messageDetails?.senderName}:</span>
              <span>{messageDetails?.message?.text}:</span>
              {userId === +messageDetails?.senderId && (
                <div className="mr-2">
                  <div
                    className={`${
                      messageDetails?.status === "seen"
                        ? "message-seen"
                        : "message-deliver"
                    } message-check position-relative bg-white`}
                    style={{ index: 1 }}
                  ></div>
                  <div
                    className={`${
                      messageDetails?.status === "seen"
                        ? "message-seen"
                        : messageDetails?.status === "sent"
                        ? "d-none"
                        : "message-deliver"
                    } message-check`}
                  ></div>
                </div>
              )}
              {/* Start a Conversation */}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceMessageList;
