import { AttachmentIcon } from "@fluentui/react-northstar";
import { Chip } from "@material-ui/core";
import React from "react";
import { API_URL } from "../config/app-config";

const ImagePreview = (props) => {
  const file = props.file;
  return (
    <div key={file?.name}>
      <Chip
        icon={<AttachmentIcon />}
        style={{ maxWidth: "8rem" }}
        className="fileChip"
        label={file?.name ?? "MediaFile"}
        size="small"
        onDelete={() => props.removeFiles(props.index)}
        variant="outlined"
      />
      {props && props?.fileType !== "pdf" && props?.fileType !== "video" && (
        <img
          style={{
            margin: "8px",
            width: "80px",
            height: "80px",
          }}
          alt="img"
          src={
            props.apiLogo
              ? `${API_URL}auth/image/${props.file}`
              : URL.createObjectURL(file)
          }
        ></img>
      )}
      {props?.fileType === "video" && props?.apiLogo && (
        <video style={{ width: "150px", height: "150px" }} controls>
          <source src={`${API_URL}auth/video/${props.file}`} />
        </video>
      )}
    </div>
  );
};

export default ImagePreview;
