import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    CircularProgress,
    IconButton
} from "@material-ui/core";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import { toast } from "react-toastify";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import PhoneNumberContainer from "../../../components/PhoneNumberContainer";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: "#a00030",
        color: "#fff",
        padding: "20px 24px",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600
    },
    closeButton: {
        position: 'absolute',
        background: 'none',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: "#ffffff",
    },
    content: {
        padding: "30px 40px",
        fontFamily: "'Open Sans', sans-serif"
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: "20px",
        marginBottom: "25px"
    },
    dateContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(70px, 1fr))",
        gap: "10px",
        margin: "20px 0"
    },
    dateItem: {
        padding: "12px 8px",
        borderRadius: "8px",
        cursor: "pointer",
        transition: "all 0.2s ease",
        border: "1px solid #e0e0e0",
        "&:hover": {
            transform: "translateY(-2px)"
        }
    },
    timeSlot: {
        padding: "10px 15px",
        borderRadius: "6px",
        cursor: "pointer",
        transition: "all 0.2s ease",
        border: "1px solid #e0e0e0",
        "&:hover": {
            transform: "translateY(-2px)"
        }
    },
    selectedSlot: {
        backgroundColor: "#2A3F54 !important;",
        color: "white !important",
        borderColor: "#2A3F54 !important;"
    },
    disabledSlot: {
        backgroundColor: "#f5f5f5",
        color: "#bdbdbd !important",
        cursor: "not-allowed",
        position: "relative",
        overflow: "hidden",
        "&::after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: "50%",
            width: "100%",
            height: "1px",
            backgroundColor: "#bdbdbd",
            transform: "rotate(-45deg)"
        }
    },
    submitButton: {
        backgroundColor: "#2A3F54",
        color: "white",
        padding: "12px 30px",
        borderRadius: "8px",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "#1E2C3A"
        }
    },
    descriptionField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            "& textarea": {
                minHeight: "80px !important"
            }
        }
    },
    actionButton: {
        padding: theme.spacing(1.2, 2.5),
        borderRadius: 8,
        fontSize: "0.875rem",
        fontWeight: 600,
        textTransform: "none",
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        border: "none",
        letterSpacing: 0.3,
        display: "flex",
        alignItems: "center",
        gap: "8px",
        "&:hover": {
            transform: "translateY(-1px)",
            boxShadow: theme.shadows[2],
        },
    },
    cancelButton: {
        background: "linear-gradient(34deg, #6c757d 0%, #5a6268 100%)",
        color: "#fff !important",
        "&:hover": {
            background: "linear-gradient(34deg, #5a6268 0%, #495057 100%)",
        },
    },
    confirmButton: {
        background: "linear-gradient(34deg, #a00030 0%, #8b0029 100%)",
        color: "#fff !important",
        "&:hover": {
            background: "linear-gradient(34deg, #8b0029 0%, #760022 100%)",
        },
    },
}));

const BookingModal = ({ open, onClose, onBookingSuccess }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        bookingForm: {
            name: "",
            email: "",
            phoneNumber: "",
            description: "",
            serviceId: ""
        },
        // Lists of data from API
        serviceList: [],
        userSlots: [],
        bookedSlot: [],
        // Calendar & slot data
        monthDates: [],        // Array of date objects for the next X days
        availableSlot: [],     // Time slots for the selected date
        activeIndexData: null, // The currently selected date object
        selectedSlot: null,    // The time slot chosen by user
        dayId: null,
        loader: false,
        // Additional data from API
        eventDetails: null,
        userDetails: null,
        serviceDetails: null
    });

    // Helper to update a single piece of state
    const updateState = (name, value) => {
        setState(prev => ({ ...prev, [name]: value }));
    };

    /*************************************************
     * 1.  FETCH DATA WHEN MODAL OPENS
     *************************************************/
    useEffect(() => {
        if (!open) return;
        fetchData();
    }, [open]);

    const fetchData = async () => {
        try {
            updateState("loader", true);

            // 1. First, fetch userSlots to get the slug
            const userSlotsResponse = await MyYuukke.getUserSlots();
            const slug = userSlotsResponse?.data?.data?.preference?.slug;
            if (!slug) {
                toast.error("No slug found. Please check the link.");
                updateState("loader", false);
                return;
            }

            // 2. Then, fetch detailed slot info using slug
            const slotDetailsResp = await MyYuukke.getSlotDetailsByIdV3(slug);
            const data = slotDetailsResp.data.data;
            const { service = [], slots = [], bookedSlot = [], userDetails, eventData } = data;

            // Generate the array of 30 days and mark which have available slots
            const dayList = slots.reduce((acc, slot) => {
                if (slot.timeSlots?.length > 0) acc.push(slot.day); // e.g. "MON", "TUE"
                return acc;
            }, []);
            const monthDates = generateMonthDates(dayList);

            // Find the first date that’s “available”
            const indexOfDate = monthDates.findIndex(item => item.available === true);
            let activeIndexData = indexOfDate >= 0 ? monthDates[indexOfDate] : null;

            // If we have an available date, pick its slots
            let findSlots = null;
            if (activeIndexData) {
                findSlots = slots.find(
                    (item) => item.day === activeIndexData.day.toUpperCase()
                );
            }

            // Put data in state
            setState(prev => ({
                ...prev,
                serviceList: service,
                userSlots: slots,
                bookedSlot: bookedSlot,
                userDetails: userDetails,
                eventDetails: eventData,
                monthDates: monthDates,
                activeIndexData: activeIndexData,
                loader: false,
                dayId: findSlots?.id || null
            }));

            // If we have a valid “first date,” load its available time slots
            if (activeIndexData && findSlots) {
                viewAvailableSlot(activeIndexData.index, activeIndexData, true);
            }
        } catch (error) {
            toast.error("Failed to load booking data");
            console.error("fetchData error:", error);
            updateState("loader", false);
        }
    };

    /*************************************************
     * 2.  GENERATE MONTH DATES (like reference)
     *************************************************/
    const generateMonthDates = (availableDays) => {
        const today = new Date();
        const daysArray = [];
        for (let i = 0; i < 30; i++) {
            const date = new Date();
            date.setDate(today.getDate() + i);

            const dayName = date.toLocaleDateString("en-US", { weekday: "short" }).toUpperCase();
            daysArray.push({
                date: date.getDate(),
                day: dayName, // e.g. "MON", "TUE"
                value: moment(date).format("YYYY/MM/DD"), // e.g. "2025/04/02"
                available: availableDays.includes(dayName),
                index: i
            });
        }
        return daysArray;
    };

    /*************************************************
     * 3.  GET AVAILABLE TIME SLOTS FOR A DATE
     *************************************************/
    const getAvailableDateSlot = (date, availableSlot, bookedSlot) => {
        // If no bookedSlot, everything is open
        if (!bookedSlot || bookedSlot.length === 0) {
            return availableSlot.map(item => ({ ...item, slotAvailable: true }));
        }

        // If the date is “today,” filter out past times
        const convertToDate = (timeStr) => {
            const [hours, minutes] = timeStr.split(":").map(Number);
            const d = new Date();
            d.setHours(hours, minutes, 0, 0);
            return d;
        };
        let filterCurrentDateSlots = availableSlot;
        if (date === moment(new Date()).format("YYYY/MM/DD")) {
            filterCurrentDateSlots = availableSlot.filter(
                slot => convertToDate(slot.from) >= new Date()
            );
        }

        // For the chosen date, find the already-booked times
        const findDateBasedSlot = bookedSlot
            .filter(item => date === moment(item.startTime).format("YYYY/MM/DD"))
            .map(item => {
                let ts = JSON.parse(item.timeSlot);
                return ts[0].from; // e.g. "13:15"
            });

        // Mark each slot as available or not
        const filteredList = filterCurrentDateSlots.map(slot => {
            return {
                ...slot,
                slotAvailable: !findDateBasedSlot.includes(slot.from)
            };
        });
        return filteredList;
    };

    /*************************************************
     * 4.  WHEN USER SELECTS A DATE
     *************************************************/
    const viewAvailableSlot = (index, dateObj, isAvailable) => {
        if (!isAvailable) return;

        const findSlots = state.userSlots.find(
            (item) => item.day === dateObj.day.toUpperCase()
        );
        if (!findSlots) return;

        const bookedSlotCopy = [...state.bookedSlot];
        const list = getAvailableDateSlot(
            dateObj.value,
            findSlots.timeSlots,
            bookedSlotCopy
        );

        updateState("availableSlot", list);
        updateState("activeIndexData", { ...dateObj, index });
        updateState("selectedSlot", null);
        updateState("dayId", findSlots.id);
    };

    /*************************************************
     * 5.  WHEN USER SELECTS A TIME SLOT
     *************************************************/
    const handleSlotSelect = (slot) => {
        if (!slot.slotAvailable) {
            toast.info("This slot is already booked. Please pick another slot.");
            return;
        }
        updateState("selectedSlot", slot);
    };

    /*************************************************
     * 6.  HANDLE SERVICE SELECTION
     *************************************************/
    const handleServiceChange = (e) => {
        const serviceId = e.target.value;
        const service = state.serviceList.find(s => s.id === serviceId);
        setState(prev => ({
            ...prev,
            serviceDetails: service,
            bookingForm: {
                ...prev.bookingForm,
                serviceId
            }
        }));
    };

    /*************************************************
     * 7.  SUBMIT BOOKING
     *************************************************/
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { bookingForm, selectedSlot, activeIndexData, serviceDetails, userDetails, eventDetails } = state;

        if (!bookingForm.serviceId || !activeIndexData || !selectedSlot) {
            toast.error("Please fill all required fields (service, date, slot).");
            return;
        }

        try {
            updateState("loader", true);

            // 1) Prepare time calculations
            const combinedDateTime = `${activeIndexData.value} ${selectedSlot.from}`;
            const startMoment = moment(combinedDateTime, "YYYY/MM/DD HH:mm");

            if (!startMoment.isValid()) {
                toast.error("Invalid date/time selection");
                return;
            }

            const startTime = startMoment.toISOString();
            const endTime = startMoment
                .clone()
                .add(serviceDetails?.duration || 30, "minutes")
                .toISOString();

            const bookingPayload = {
                email: bookingForm.email,
                userName: bookingForm.name,
                mobileNo: bookingForm.phoneNumber,
                bio: bookingForm.description,
                timeSlot: [{
                    from: selectedSlot.from,
                    to: moment(selectedSlot.from, "HH:mm")
                        .add(serviceDetails?.duration || 30, "minutes")
                        .format("HH:mm")
                }],
                dateSlot: [activeIndexData.value],
                id: eventDetails?.id,
                startTime,
                endTime,
                type: "Service",
                serviceId: bookingForm.serviceId,
                userId: userDetails?.userId,
                dayId: state.dayId
            };

            // 2) Save booking
            const bookingResp = await MyYuukke.saveUserBookingSlots(bookingPayload);
            if (bookingResp?.data?.message === "fail") {
                toast.error("Something went wrong. Please check the link or try again.");
                updateState("loader", false);
                return;
            }
            const bookingId = bookingResp?.data?.data?.bookingslot?.id;
            if (!bookingId) {
                toast.error("Booking failed. Please try again.");
                updateState("loader", false);
                return;
            }

            // 3) Prepare calendar payload with corrected fields
            const calendarPayload = {
                meetingInApplication: eventDetails?.meetingInApplication,
                taskName: serviceDetails?.type === "clarity"
                    ? `Clarity Meeting with ${bookingForm.name}`
                    : `Meeting with ${bookingForm.name}`,
                taskDescription: bookingForm.description || "Scheduled Meeting",
                startDate: startTime,
                endDate: endTime,
                color: "#A00030",
                moderatorList: [userDetails?.userId],
                email: bookingForm.email,
                mobileNo: bookingForm.phoneNumber,
                url: eventDetails?.meetingUrl,
                slotBookingId: bookingId,
                userId: userDetails?.userId,
                workSpaceType: eventDetails?.workSpaceType,
                providerName: userDetails?.userName,
                clientName: bookingForm.name,
                serviceName: serviceDetails?.serviceName,
                type: serviceDetails?.type || "Service", // Corrected type
                amount: serviceDetails?.amount || 0,      // Corrected amount
                formatTime: moment(startTime).locale("en").format("MMMM Do YYYY, h:mm A")
            };

            // 4) Save calendar event
            await MyYuukke.saveCalendarSchedule(calendarPayload);

            Swal.fire({
                icon: "success",
                title: "Booking Successful!",
                text: "You will receive confirmation details via email",
                showConfirmButton: false,
                timer: 3000
            });

            if (onBookingSuccess) onBookingSuccess();
            onClose();
        } catch (error) {
            console.error("Booking error:", error);
            toast.error("Failed to create booking");
        } finally {
            updateState("loader", false);
        }
    };

    /*************************************************
     *  RENDER
     *************************************************/
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ fontSize: "1.3rem" }}>Schedule New Meeting For Client</span>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        className={classes.closeButton}
                    >
                        <AiOutlineCloseCircle size={24} />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers className={classes.content}>
                {state.loader ? (
                    <div style={{ textAlign: "center", padding: "40px" }}>
                        <CircularProgress style={{ color: "#2A3F54" }} />
                        <p style={{ marginTop: "15px", color: "#666" }}>Loading Availability...</p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        {/* Basic Info Fields */}
                        <div className={classes.formGrid}>
                            <TextField
                                label="Full Name "
                                fullWidth
                                required
                                variant="outlined"
                                size="small"
                                value={state.bookingForm.name}
                                onChange={(e) => setState(prev => ({
                                    ...prev,
                                    bookingForm: { ...prev.bookingForm, name: e.target.value }
                                }))}
                            />
                            <TextField
                                label="Email Address "
                                type="email"
                                fullWidth
                                required
                                variant="outlined"
                                size="small"
                                value={state.bookingForm.email}
                                onChange={(e) => setState(prev => ({
                                    ...prev,
                                    bookingForm: { ...prev.bookingForm, email: e.target.value }
                                }))}
                            />
                            <PhoneNumberContainer
                                value={state.bookingForm.phoneNumber}
                                updateData={(phone) => setState(prev => ({
                                    ...prev,
                                    bookingForm: { ...prev.bookingForm, phoneNumber: phone }
                                }))}
                                defaultClass="custom-phone-input"
                            />
                            <Select
                                required
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={state.bookingForm.serviceId}
                                onChange={handleServiceChange}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>Select Service *</MenuItem>
                                {state.serviceList.map((svc) => (
                                    <MenuItem key={svc.id} value={svc.id}>
                                        {svc.serviceName} ({svc.duration} mins)
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                label="Meeting Description"
                                fullWidth
                                variant="outlined"
                                size="small"
                                style={{ width: "207%" }}
                                className={classes.descriptionField}
                                value={state.bookingForm.description}
                                onChange={(e) => setState(prev => ({
                                    ...prev,
                                    bookingForm: { ...prev.bookingForm, description: e.target.value }
                                }))}
                            />

                        </div>

                        {/* Date Selection */}
                        <h5 style={{ marginTop: "30px", color: "#444", fontSize: "1.1rem" }}>Select Date</h5>
                        <div className={classes.dateContainer}>
                            {state.monthDates.map((dateObj, i) => (
                                <div
                                    key={i}
                                    onClick={() => viewAvailableSlot(i, dateObj, dateObj.available)}
                                    className={`${classes.dateItem} ${dateObj.available ? "" : classes.disabledSlot
                                        } ${state.activeIndexData?.index === i && dateObj.available
                                            ? classes.selectedSlot
                                            : ""
                                        }`}
                                    style={{
                                        backgroundColor: dateObj.available ? "#fff" : "#f5f5f5",
                                        color: dateObj.available ? "#444" : "#bdbdbd"
                                    }}
                                >
                                    <div style={{ fontWeight: 600, fontSize: "0.9rem" }}>
                                        {dateObj.day}
                                    </div>
                                    <div style={{ fontSize: "1.1rem" }}>{dateObj.date}</div>
                                </div>
                            ))}
                        </div>

                        {/* Time Slot Selection */}
                        {state.availableSlot?.length > 0 && (
                            <>
                                <h5 style={{ marginTop: "25px", color: "#444", fontSize: "1.1rem" }}>
                                    Available Time Slots
                                </h5>
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                    {state.availableSlot.map((slot, idx) => (
                                        <div
                                            key={idx}
                                            onClick={() => handleSlotSelect(slot)}
                                            className={`${classes.timeSlot} ${slot.slotAvailable ? "" : classes.disabledSlot
                                                } ${state.selectedSlot?.from === slot.from
                                                    ? classes.selectedSlot
                                                    : ""
                                                }`}
                                            style={{
                                                backgroundColor: "#fff",
                                                color: "#444"
                                            }}
                                        >
                                            {moment(slot.from, "HH:mm").format("h:mm A")} -{" "}
                                            {moment(slot.to, "HH:mm").format("h:mm A")}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        <DialogActions style={{ marginTop: "30px", padding: 0, gap: "12px" }}>
                            <Button
                                variant="contained"
                                onClick={onClose}
                                className={`${classes.actionButton} ${classes.cancelButton}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className={`${classes.actionButton} ${classes.confirmButton}`}
                                disabled={state.loader}
                            >
                                {state.loader ? (
                                    <>
                                        <CircularProgress size={20} style={{ color: "white" }} />
                                        Processing...
                                    </>
                                ) : (
                                    "Confirm Booking"
                                )}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default BookingModal;
